/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentPharmacyInvoice, setAccumulatedPharmacyInvoice } from '../../state/actions/collection.actions'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'


const CollectionsPharmacyInvoicesTable = ({ data, showList }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [accumulatedList, setAccumulatedList] = useState([])
    const [filterState, setFilterState] = useState({ search: '', invoices: [] })

    const handleFilter = () => {
        const filtered = data?.filter(invoice => {
            return invoice.patient_details?.patient_id.toLowerCase().includes(filterState.search.toLowerCase())
                || invoice.patient_details?.patient_name.toLowerCase().includes(filterState.search.toLowerCase())
                || invoice.item_details?.title.toLowerCase().includes(filterState.search.toLowerCase())
                || invoice.invoice_amount == filterState.search
        })

        setFilterState({ ...filterState, invoices: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, invoices: data })
        }
    }

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    useEffect(() => {
        setFilterState({ ...filterState, invoices: data })
    }, [data?.length])


    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"

            >
                <div className='flex justify-between'>
                    <input
                        type="text"
                        name="search"
                        // disabled
                        placeholder='Search'
                        className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                        onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                    />

                    <button
                        type="button"
                        className="w-[150px]  mb-2 mt-2 inline-block px-6 py-2 bg-sky-500 text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                        onClick={() => {
                            if (accumulatedList.length > 0) {
                                dispatch(setAccumulatedPharmacyInvoice({ data: accumulatedList }))
                                setModals({ ...modals, showCollectionsPayPharmacyMultipleInvoiceModal: !modals.showCollectionsPayPharmacyMultipleInvoiceModal })
                            }
                        }}
                    >
                        Pay accumulation
                    </button>

                </div>


                <div className="">
                    {showList && (
                        <Fragment>
                            <table className="border-b border-l border-r rounded w-full cursor-default">
                                <thead className="">
                                    <tr className='bg-gray-100'>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>


                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Patient ID
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Patient Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Item
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Quantity
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                        >

                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                        >
                                            <input type="checkbox"
                                                onChange={() => {
                                                    if (accumulatedList.length === 0) {
                                                        setAccumulatedList(currentData)
                                                    } else {
                                                        setAccumulatedList([])
                                                    }
                                                }}
                                            />
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((invoice, index) => (
                                        <tr key={`${invoice.id}`}>
                                            <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                {index + 1}
                                            </td>

                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice?.patient_details?.patient_id}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice?.patient_details?.patient_name}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice?.item_details?.title}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice?.dispense_count}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                &#8358; {invoice?.invoice_amount}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.created_at.slice(0, 10)}
                                            </td>
                                            <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                <input
                                                    type="checkbox"
                                                    checked={accumulatedList?.find((item) => item.id === invoice.id)}
                                                    onChange={(e) => {
                                                        const myIndex = accumulatedList.findIndex(e => e.id === invoice.id)
                                                        console.log(e.target.value)
                                                        if (myIndex >= 0) {
                                                            setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== invoice.pkid)])
                                                        } else {
                                                            setAccumulatedList([...accumulatedList, invoice])
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                <button
                                                    type="button"
                                                    className="w-[80px] inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentPharmacyInvoice({ data: invoice }))
                                                        setModals({ ...modals, showCollectionsPharmacyPaymentModal: !modals.showCollectionsPharmacyPaymentModal })
                                                    }}
                                                >
                                                    Pay
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                data={filterState.invoices}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div >
    )
}

export default CollectionsPharmacyInvoicesTable