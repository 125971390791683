/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { GifWallet } from '../assets'
import { getPharmacyConfig } from '../state/actions/auth.actions'
import {verifyAdminPayStackPayment } from '../state/actions/collection.actions'
import { sendSmsTopupNotification, updateSubscriptionPlan } from '../state/actions/pharmacist.actions'

/**
 * 
 * Refer to zuumapay.zuumatekhis.com.ng for administrator callback payments 
 * 
 * 
 */
const AdminVerifyPayStackPayment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const { pharmacy_config } = useSelector(state => state.auth)
    const { paystack_payment_status } = useSelector(state => state.collection)
    const payment__invoice = localStorage.getItem('payment__invoice') ? JSON.parse(localStorage.getItem('payment__invoice')) : null

    const [ref, setRef] = useState(null)

    useEffect(() => {
        dispatch(getPharmacyConfig())
        const ref = window.location.href.split('reference=')[1]
        setRef(ref)
    }, [])

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])

    useEffect(() => {
        dispatch(verifyAdminPayStackPayment({ params: { ref } }))

        console.log('verified')
    }, [ref])


    useEffect(() => {
        if (payment__invoice?.type === 'sms_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                ...payment__invoice,
                payment_ref: ref
            }
            console.log(formData)
            dispatch(sendSmsTopupNotification({ formData, toast, navigate }))
        }

    }, [paystack_payment_status])

    useEffect(() => {
        if (payment__invoice.type === 'subscription__payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                title: payment__invoice?.title,
                price: payment__invoice?.price,
                amount_paid: payment__invoice?.amount_paid,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            console.log(payment__invoice)
            dispatch(updateSubscriptionPlan({ formData, toast, navigate }))
        }
    }, [paystack_payment_status?.status])

    return (
        <div className='h-screen flex justify-center items-center'>
            <img
                src={GifWallet}
                alt='wallet'
                className='w-1/2'
            />
        </div>
    )
}

export default AdminVerifyPayStackPayment