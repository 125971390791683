import { API } from "../config";


export const getDirectorates = (token) => API.get('/administrator/directorate/', { headers: { Authorization: `Bearer ${token}` } })

export const createAdirectorate = (formData, token) => API.post('/administrator/directorate/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const updateADirectorate = (formData, token) => API.put(`/administrator/directorate_apiview/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const deleteADirectorate = (id, token) => API.delete(`/administrator/directorate_apiview/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const getDepartments = (token) => API.get('/administrator/create_department/', { headers: { Authorization: `Bearer ${token}` } })

export const createAdepartment = (formData, token) => API.post('/administrator/create_department/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const updateADepartment = (formData, token) => API.put(`/administrator/department_apiview/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const deleteADepartment = (id, token) => API.delete(`/administrator/department_apiview/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const createAnEmployee = (formData, token) => API.post('/administrator/create_employee/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const updateAnEmployee = (formData, token) => API.put(`/administrator/employees/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const deleteAEmployee = (id, token) => API.delete(`/administrator/employees/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const getEmployees = (token) => API.get('/profile/all_profiles/', { headers: { Authorization: `Bearer ${token}` } })

export const getBillings = (token) => API.get('/administrator/billings/', { headers: { Authorization: `Bearer ${token}` } })

export const createAbill = (formData, token) => API.post('/administrator/create_billing/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const updateABilling = (formData, token) => API.put(`/administrator/billings/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const deleteABilling = (id, token) => API.delete(`/administrator/billings/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const getWards = (token) => API.get('/administrator/ward_list/', { headers: { Authorization: `Bearer ${token}` } })

export const createAWard = (formData, token) => API.post('/administrator/ward_list/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const updateAWard = (formData, token) => API.put(`/administrator/ward_details/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const deleteAWard = (id, token) => API.delete(`/administrator/ward_details/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const getBedspaces = (token) => API.get('/administrator/bed_list/', { headers: { Authorization: `Bearer ${token}` } })

export const fetchWardBedspaces = (formData, token) => API.get(`/administrator/bed_space/${formData.ward}/`, { headers: { Authorization: `Bearer ${token}` } })

export const addBedSpace = (formData, token) => API.post('/administrator/bed_space/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const patchBedSpace = (formData, token) => API.put(`/administrator/bed_details/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const deleteABedSpace = (id, token) => API.delete(`/administrator/bed_space/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const getSentSMS = (token) => API.get('/sms/sent_messages/', { headers: { Authorization: `Bearer ${token}` } })

export const getReceivedSMS = (token) => API.get('/sms/received_messages/', { headers: { Authorization: `Bearer ${token}` } })


export const sendSMS = (formData, token, recipient_id) => API.post(`/sms/send/${recipient_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const sendEmailMessage = (formData, token) => API.post('/message/send/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const getReceivedEmails = (token) => API.get('/message/emails_received/', { headers: { Authorization: `Bearer ${token}` } })

export const getSentEmails = (token) => API.get('/message/emails_sent/', { headers: { Authorization: `Bearer ${token}` } })

export const addSmsConfig = (formData, token) => API.post('/administrator/sms_list/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const fetchSmsConfig = (token) => API.get(`/administrator/sms_list/`, { headers: { Authorization: `Bearer ${token}` } })

export const patchSmsConfig = (formData, token) => API.put(`/administrator/sms_details/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const addHospitalConfig = (formData, token) => API.post('/administrator/hospital_data/', formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const fetchHospitalConfig = (token) => API.get('/administrator/hospital_data/', { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const patchHospitalConfig = (formData, token) => API.patch(`/administrator/hospital_data/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const fetchUsersByDepartment = (suppl_point, token) => API.get(`/administrator/get_supplypoint_users/${suppl_point}/`, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const createSchedule = (formData, token) => API.post(`/administrator/create_schedule/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getSchedules = (token) => API.get(`/administrator/get_schedules/`, { headers: { Authorization: `Bearer ${token}` } })

export const updateSchedule = (formData, token) => API.put(`/administrator/update_schedule/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const deleteSchedule = (id, token) => API.delete(`/administrator/delete_schedule/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const sendRandomEmailMessage = (formData, token) => API.post('/message/send/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const sendRandomSMS = (formData, token, recipient_id) => API.post(`/sms/send/${recipient_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const generateFlutterwaveDetails = (formData, token) => API.post(`/administrator/flutter_list/`, formData, { headers: { Authorization: `Bearer ${token}` } })
