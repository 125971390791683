/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { setBilling } from "../../../state/actions/actions"
import { createBilling, updateBilling, } from "../../../state/actions/pharmacist.actions"
import ButtonLoader from "../../utils/ButtonLoader"


const PharmacistAddBillingModal = () => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const [checked, setChecked] = useState(false)
	const [formData, setFormData] = useState({ name: "", price: "", description: "", status: false, billing_type: '' })
	const { billing_update } = useSelector(state => state.root)
	const { pharmacistLoading } = useSelector(state => state.pharmacist)

	useEffect(() => {
		setChecked(billing_update?.status ? billing_update?.status : checked)
		setFormData({
			...formData,
			name: billing_update?.name,
			price: billing_update?.price,
			description: billing_update?.description,
			status: billing_update?.status,
			billing_type: billing_update?.billing_type
		})
	}, [billing_update])

	useEffect(() => {
		if (formData.billing_type === 'Consultation') {
			setFormData({ ...formData, name: 'Consultation' })
		}
	}, [formData.billing_type])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}
	const handleCheckboxChange = () => {
		setChecked(!checked)
		setFormData({ ...formData, status: !checked })
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		console.log(formData)
		if (!formData.name && !formData.price && !formData.description && !formData.status) return toast.warning('All fields are required!')
		dispatch(createBilling({ formData, toast, modals, setModals }))
	}

	const handleUpdate = (e) => {
		e.preventDefault()

		console.log(formData)
		dispatch(updateBilling({ formData: { ...formData, id: billing_update?.id }, toast, setModals: setModals, modals: modals }))
	}

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className={`${modals.showPharmacistAddBillingModal ? 'text-dark-green' : 'text-sky-600'} text-[16px] font-medium capitalize`}>
							{modals.showPharmacistAddBillingModal ? 'Add billing' : 'Update billing'}
						</h4>
						<FaTimesCircle
							size={20}
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								dispatch(setBilling({ data: null }))
								modals.showPharmacistAddBillingModal && setModals({ ...modals, showPharmacistAddBillingModal: !modals.showPharmacistAddBillingModal })
								modals.showPharmacistUpdateBillingModal && setModals({ ...modals, showPharmacistUpdateBillingModal: !modals.showPharmacistUpdateBillingModal })
							}}
						/>
					</div>

					{modals.showPharmacistAddBillingModal && (
						<form onSubmit={handleSubmit}>
							<div className="relative">
								<select
									className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
									name="billing_type"
									onChange={(e) => {
										handleChange(e, e.target.name)
									}}

								>
									<option >Item Type</option>
									{[{ title: 'Billing', value: 'billing' }, { title: 'Consultation', value: 'Consultation' }, { title: 'Delivery', value: 'delivery' }]?.map((bill_type, index) => (
										<option
											key={`${bill_type}-${index}`}
											value={bill_type.value}
										>
											{bill_type.title}
										</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
									</svg>
								</div>
							</div>

							{formData.billing_type !== 'Consultation' && (
								<div>
									<input
										type="text"
										name="name"
										placeholder="Billing name"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>
							)}

							<div>
								<input
									type="number"
									name="price"
									placeholder="Price"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div>
								<textarea
									rows="4"
									name="description"
									placeholder="Description"
									className="border rounded w-full px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
									onChange={(e) => handleChange(e, e.target.name)}
								></textarea>
							</div>
							<div className="flex items-center space-x-3">
								<input
									className="rounded border-4 w-5 h-5 border-red-400"
									type="checkbox"
									name="status"
									checked={checked}
									onChange={handleCheckboxChange}
								/>
								<p className="text-[16px] text-gray-700 font-bold">Status</p>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									{!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
								</button>
							</div>
						</form>
					)}

					{modals.showPharmacistUpdateBillingModal && (
						<form onSubmit={handleUpdate}>
							<div className="relative">
								<select
									className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
									name="billing_type"
									onChange={(e) => {
										handleChange(e, e.target.name)
									}}

								>
									<option >Item Type</option>
									{[{ title: 'Billing', value: 'billing' }, { title: 'Delivery', value: 'delivery' }]?.map((bill_type, index) => (
										<option
											key={`${bill_type}-${index}`}
											value={bill_type.value}
										>
											{bill_type.title}
										</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
									</svg>
								</div>
							</div>
							<div>
								<input
									type="text"
									name="name"
									placeholder="Billing name"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									value={formData.name}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div>
								<input
									type="number"
									name="price"
									placeholder="Price"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									value={formData.price}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div>
								<textarea
									rows="4"
									name="description"
									placeholder="Description"
									className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
									value={formData.description}
									onChange={(e) => handleChange(e, e.target.name)}
								></textarea>
							</div>
							<div className="flex items-center space-x-3">
								<input
									className="rounded border-4 w-5 h-5 border-red-400"
									type="checkbox"
									name="status"
									checked={checked}
									onChange={handleCheckboxChange}
								/>
								<p className="text-[16px] text-gray-700 font-bold">Status</p>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									{!pharmacistLoading ? ("Update") : (<ButtonLoader />)}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	)
}

export default PharmacistAddBillingModal
