/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { getSupplyPoints, lendDrugToSupplyPoint } from "../../../state/actions/pharmacist.actions"
import ButtonLoader from "../../utils/ButtonLoader"


const PharmacistLendRefundDrugModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { supplypoint_bincard, supplypoint_inventory_update } = useSelector(state => state.root)
    const { supplyPoints, pharmacistLoading } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState({
        recipient: 0, quantity_issued: 0, bincard_drug: '',
        supplypoint_inventory: '', supplypoint_inventoryitem: '',
        supplypoint: '', recipient_supplypoint: '', lending_item: ''
    })

    const supplypoints = supplyPoints?.filter(sp => sp.pkid !== supplypoint_inventory_update?.supplypoint)

    useEffect(() => {
        dispatch(getSupplyPoints())
    }, [])

    useEffect(() => {
        setFormData({
            ...formData,
            bincard_drug: supplypoint_bincard?.supplypointbincard_inventory?.supplypoint_druginventory?.bincard_drug?.pkid,
            supplypoint_inventory: supplypoint_bincard?.supplypointbincard_inventory?.pkid,
            supplypoint_inventoryitem: supplypoint_bincard?.supplypointbincard_inventory?.supplypoint_inventory?.inventory_item,
            supplypoint: supplypoint_inventory_update?.supplypoint,
            recipient_supplypoint: formData.recipient,
            lending_item: supplypoint_bincard?.supplypointbincard_inventory?.supplypoint_inventory?.inventory_item,
        })
    }, [supplypoint_bincard, supplypoint_inventory_update])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        // const data = 

        console.log(formData)
        dispatch(lendDrugToSupplyPoint({ formData, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-sky-500 text-[16px] font-medium">
                            Lend | Refund To Supply Point
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                // dispatch(setBilling({ data: null }))
                                setModals({ ...modals, showPharmacistLendRefundDrugModal: !modals.showPharmacistLendRefundDrugModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <div className="relative">
                                <select
                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                    name="recipient"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option value="">Supply Point</option>
                                    {supplypoints?.map((item, index) => (
                                        <option
                                            key={`${item.id}`}
                                            value={item.pkid}
                                        >{item.name}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>

                            {/* <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Receipt Or Issue Voucher Number"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div> */}

                            <div>
                                <input
                                    type="number"
                                    name="quantity_issued"
                                    placeholder="Quantity"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                {!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PharmacistLendRefundDrugModal
