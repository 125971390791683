import { createSlice } from '@reduxjs/toolkit'
import {
    getAllEmployeesForLaboratorist, getAllLabEquipments, addALaboratoryEquipment, getAllLabReports,
    getALabBilling, removeSingleLabBilling, generatePatientLabInvoice, getAllLabBillings, getAllInvoiceGeneratedByUser,
    getAllLaboratoryInvestigationCategory, getAllLaboratoryInvestigationTypes, getAllLabSampleTest
} from '../actions/laboratorist.actions'


const laboratoristSlice = createSlice({
    name: 'laboratorist',
    initialState: {
        all_employees: null,
        all_laboratorist_equipment: null,
        laboratoristLoading: false,
        laboratoristErrors: null,
        all_lab_report_requests: null,
        single_lab_billing_details: null,
        all_billings: null,
        all_lab_invoice_by_me: null,
        all_lab_category_test: null,
        all_lab_sample_test: null,


        // all_radiology_category_test: null,
        all_lab_investigation_type: null,





    },
    extraReducers: {
        [getAllEmployeesForLaboratorist.pending]: (state, action) => {
            state.laboratoristLoading = true
        },
        [getAllEmployeesForLaboratorist.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.all_employees = action.payload

        },

        [getAllEmployeesForLaboratorist.rejected]: (state, action) => {
            state.laboratoristLoading = false
        },
        [getAllLabEquipments.pending]: (state, action) => {
            state.laboratoristLoading = true
        },
        [getAllLabEquipments.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.all_laboratorist_equipment = action.payload

        },
        [getAllLabEquipments.rejected]: (state, action) => {
            state.laboratoristLoading = false
        },

        [addALaboratoryEquipment.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [addALaboratoryEquipment.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            // state.all_laboratorist_equipment = action.payload

        },

        [addALaboratoryEquipment.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [getAllLabReports.rejected]: (state, action) => {
            state.laboratoristLoading = false
        },
        [getAllLabReports.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.all_lab_report_requests = action.payload

        },

        [getAllLabReports.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [getALabBilling.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [getALabBilling.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.single_lab_billing_details = action.payload

        },

        [getALabBilling.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [removeSingleLabBilling.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [removeSingleLabBilling.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.single_lab_billing_details = null

        },

        [removeSingleLabBilling.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [generatePatientLabInvoice.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [generatePatientLabInvoice.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            // state.single_lab_billing_details = null

        },

        [generatePatientLabInvoice.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [getAllLabBillings.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [getAllLabBillings.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.all_billings = action.payload

        },

        [getAllLabBillings.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [getAllInvoiceGeneratedByUser.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [getAllInvoiceGeneratedByUser.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.all_lab_invoice_by_me = action.payload

        },

        [getAllInvoiceGeneratedByUser.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [getAllLaboratoryInvestigationCategory.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [getAllLaboratoryInvestigationCategory.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.all_lab_category_test = action.payload

        },

        [getAllLaboratoryInvestigationCategory.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [getAllLaboratoryInvestigationTypes.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [getAllLaboratoryInvestigationTypes.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.all_lab_investigation_type = action.payload

        },

        [getAllLaboratoryInvestigationTypes.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [getAllLabSampleTest.pending]: (state, action) => {
            state.laboratoristLoading = false
        },
        [getAllLabSampleTest.fulfilled]: (state, action) => {
            state.laboratoristLoading = false
            state.all_lab_sample_test = action.payload

        },

        [getAllLabSampleTest.rejected]: (state, action) => {
            state.radiologistLoading = false
        },













    }

})


export default laboratoristSlice.reducer;