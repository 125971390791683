/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { BsDashSquare } from 'react-icons/bs'
import { toast } from 'react-toastify'

import { useStateContext } from '../../../state/context'
import { getInventoryDrugs } from '../../../state/actions/pharmacist.actions'
import { createPatientOtherPrescription } from '../../../state/actions/doctor.actions'


const DoctorAddOtherPrescriptionModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { patient_update } = useSelector(state => state.root)
    const { new_diagnosis } = useSelector(state => state.doctor)
    const { inventory_drugs } = useSelector(state => state.pharmacist)

    const [formData, setFormData] = useState([{
        inventory_item: '', patient: patient_update?.details?.pkid,
        diagnosis: new_diagnosis?.pkid, other_prescription: true,
    }])

    useEffect(() => {
        dispatch(getInventoryDrugs())
    }, [])

    const handleChange = (index, e) => {
        let data = [...formData]
        data[index][e.target.name] = e.target.value
        setFormData(data)
    }

    const addPrescription = (e) => {
        e.preventDefault()

        setFormData([
            ...formData, {
                inventory_item: '', patient: patient_update?.details?.pkid,
                diagnosis: new_diagnosis?.pkid, other_prescription: false
            }
        ])
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)

        dispatch(createPatientOtherPrescription({ formData, toast, setModals, modals }));
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[600px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-600 text-[20px] font-bold'>Add Prescription</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                // dispatch(setSupplyPoint({ data: null }))
                                setModals({ ...modals, showDoctorAddPrescriptionModal: !modals.showDoctorAddPrescriptionModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div
                            className={`space-y-2 ${formData.length > 2 && 'h-[450px] overflow-y-auto patient__prescriptions'}`}
                        >
                            {formData?.map((item, index) => (
                                <Fragment key={index}>
                                    <div className="flex justify-between items-center">
                                        <label
                                            className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded"
                                        >Prescription: {index + 1}</label>

                                        {index !== 0 && (
                                            <BsDashSquare
                                                size={30}
                                                onClick={() => setFormData(formData.filter((data, _index) => (_index + 1) !== (index + 1)))}
                                                className="text-orange-500 px-[5px] py-[0px] text-[10px] rounded cursor-pointer"
                                            />
                                        )}
                                    </div>
                                    <div className={`flex flex-col items-start w-full ${index !== formData.length - 1 && 'pb-5 border-b-2 border-gray-600'}`}>
                                        <div className="relative w-full">
                                            <select
                                                className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                name="inventory_item"
                                                onChange={(e) => handleChange(index, e)}
                                            >
                                                <option value="">Drug</option>
                                                {inventory_drugs?.map((drug, index) => (
                                                    <option
                                                        key={`${drug.id}`}
                                                        value={drug.pkid}
                                                    >
                                                        {drug.title}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg
                                                    className="fill-current h-4 w-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="w-full">
                                            <input
                                                type="text"
                                                name="dispense_count"
                                                placeholder="Quantity"
                                                className="border rounded w-full px-4 py-3 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(index, e)}
                                            />
                                        </div>
                                        {/* <div className="space-y-2 w-[48%]">

                                        </div> */}

                                        {/* <div className="space-y-2 w-[48%]">
                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="units"
                                                    onChange={(e) => handleChange(index, e)}
                                                >
                                                    <option value="">Units</option>
                                                    {drug__units?.map((unit, index) => (
                                                        <option
                                                            key={`${index}`}
                                                            value={unit.value}
                                                        >{unit.title}</option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="frequency"
                                                    onChange={(e) => handleChange(index, e)}
                                                >
                                                    <option value="">Frequency</option>
                                                    {drug__frequency?.map((frequency, index) => (
                                                        <option
                                                            key={`${index}`}
                                                            value={frequency.value}
                                                        >{frequency.title}</option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div>
                                                <input
                                                    type="text"
                                                    name="duration_time"
                                                    placeholder="Times"
                                                    className="border rounded w-full h-5 px-4 py-3 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                    onChange={(e) => handleChange(index, e)}
                                                />
                                            </div> 
                                        </div> */}
                                    </div>
                                </Fragment>
                            ))}

                        </div>
                        <div className='flex justify-between mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 border-2 border-dark-blue text-dark-blue font-medium text-xs leading-tight rounded shadow-md transition duration-500 ease"
                                onClick={addPrescription}
                            >Add</button>

                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>
                        </div>

                    </form>
                </div>


            </div>
        </div>
    )
}
export default DoctorAddOtherPrescriptionModal
