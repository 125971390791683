/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { createLaboratoryInvestigationSample, updateLaboratoryInvestigationSample } from '../../../state/actions/pharmacist.actions'


const PharmacistAddLabInvestigationSampleModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { lab_investigation_categories, laboratory_data } = useSelector((state) => state.pharmacist)

    const [formData, setFormData] = useState({
        investigation_category: '',
        lab_investigation_type: '',
        sample_type: '',
        id: ''
    })

    useEffect(() => {
        setFormData({
            ...formData,
            id: laboratory_data?.id,
            lab_investigation_type: laboratory_data?.lab_investigation_type,
            sample_type: laboratory_data?.sample_type
        })
    }, [laboratory_data])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        if (!formData.sample_type && !formData.lab_investigation_type) return toast.warning('All fields are required!')
        dispatch(createLaboratoryInvestigationSample({ formData, toast, setModals, modals }))
    }

    const handleUpdate = (e) => {
        e.preventDefault()

        console.log(formData)
        if (!formData.sample_type) return toast.warning('All fields are required!')
        dispatch(updateLaboratoryInvestigationSample({ formData, toast, setModals, modals }))
    }

    return (
        <div>
            <div className='fixed grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>
                            <h4 className={`${modals.showPharmacistAddInvestigationSampleModal ? 'text-dark-green' : 'text-sky-600'} text-[16px] font-bold`}>
                                {modals.showPharmacistAddInvestigationSampleModal ? 'Add Sample' : 'Update Sample'}
                            </h4>
                            <FaTimesCircle
                                size={20}
                                className='text-dark-yellow cursor-pointer'
                                onClick={() => {
                                    modals.showPharmacistAddInvestigationSampleModal && setModals({ ...modals, showPharmacistAddInvestigationSampleModal: !modals.showPharmacistAddInvestigationSampleModal })
                                    modals.showPharmacistUpdateInvestigationSampleModal && setModals({ ...modals, showPharmacistUpdateInvestigationSampleModal: !modals.showPharmacistUpdateInvestigationSampleModal })
                                }}
                            />
                        </div>

                        {modals.showPharmacistAddInvestigationSampleModal && (
                            <form onSubmit={handleSubmit}>
                                <div className='flex justify-between-items-center space-x-10'>

                                    <div className='space-y-2 w-full'>
                                        <select name="investigation_category" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                            onChange={(e) =>
                                                handleChange(e, e.target.name)
                                            }
                                        >
                                            <option value="">Select Category</option>
                                            {lab_investigation_categories?.map((category, index) => (
                                                <option value={category.pkid} key={index}>{category.title}</option>
                                            ))}

                                        </select>
                                    </div>
                                </div>

                                <div className="relative">
                                    <input
                                        type="text"
                                        name="sample_type"
                                        placeholder="Sample"
                                        className="border rounded w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className='mt-4 flex justify-center items-center'>
                                    <button
                                        type='submit'
                                        className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}

                        {modals.showPharmacistUpdateInvestigationSampleModal && (
                            <form onSubmit={handleUpdate}>
                                <div className='flex justify-between-items-center space-x-10'>

                                    <div className='space-y-2 w-full'>
                                        <select name="investigation_category" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                            onChange={(e) =>
                                                handleChange(e, e.target.name)
                                            }
                                        >
                                            <option value="">Select Category</option>
                                            {lab_investigation_categories?.map((category, index) => (
                                                <option value={category.pkid} key={index}>{category.title}</option>
                                            ))}

                                        </select>
                                    </div>
                                </div>

                                <div className="relative">
                                    <input
                                        type="text"
                                        name="sample_type"
                                        placeholder="Sample"
                                        value={formData.sample_type}
                                        className="border rounded w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className='mt-4 flex justify-center items-center'>
                                    <button
                                        type='submit'
                                        className='inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PharmacistAddLabInvestigationSampleModal