/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { BiPhone } from "react-icons/bi"
import { MdLocationPin } from "react-icons/md"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { customerCreateProvisionOrder } from "../../../state/actions/pharmacist.actions"


const CustomerProcessProvisionOrderModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { current_customer_invoice } = useSelector(state => state.pharmacist)

    const [formData, setFormData] = useState({ delivery_phone: '', delivery_address: '', provision_invoice: '', order_type: 'provision' })

    useEffect(() => {
        setFormData({ ...formData, provision_invoice: current_customer_invoice?.pkid })
    }, [current_customer_invoice])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(customerCreateProvisionOrder({ formData, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`w-[500px] bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-800 text-[18px] font-medium capitalize`}>
                            Process Order
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showCustomerProcessProvisionOrderModal: !modals.showCustomerProcessProvisionOrderModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-3">
                        <div className="relative">
                            <BiPhone
                                size={20}
                                color={'rgba(40, 51, 66, 0.8)'}
                                className='absolute top-[16px] left-3'
                            />
                            <input
                                type="text"
                                name="delivery_phone"
                                placeholder="Phone number"
                                maxLength={11}
                                className="border rounded w-full h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <div className="relative">
                            <MdLocationPin
                                size={20}
                                color={'rgba(40, 51, 66, 0.8)'}
                                className='absolute top-[16px] left-3'
                            />
                            <textarea
                                name="delivery_address"
                                rows="4"
                                placeholder='Delivery Address'
                                className="border rounded w-full px-5 pl-12 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                onChange={(e) => handleChange(e)}
                            ></textarea>
                        </div>

                        <div className="flex justify-start items-center space-y-5">
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-800 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default CustomerProcessProvisionOrderModal
