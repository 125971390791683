/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EmployeeTable, PageTitle } from '../../components'
import { getPharmacyStaff } from '../../state/actions/pharmacist.actions'


const PharmacistStaff = () => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)
    const { pharmacists, new_staff, courier_profile } = useSelector(state => state.pharmacist)

    const [activeLink, setActiveLink] = useState('Admin')
    const [filterState, setFilterState] = useState({ search: '', pharmacists: [] })

    const handleFilter = () => {
        const filtered = pharmacists?.filter(item => {
            return item.first_name.toLowerCase().includes(filterState.search.toLowerCase()) || item.last_name.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, pharmacists: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, pharmacists })
        }
    }

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    useEffect(() => {
        dispatch(getPharmacyStaff())
    }, [new_staff, courier_profile])

    useEffect(() => {
        if (pharmacists?.length) {
            setFilterState({ ...filterState, pharmacists })
        }
    }, [pharmacists?.length, courier_profile])

    return (
        <div>
            <PageTitle type={'pharmacist-staff'} />

            <div className="space-y-">
                <div className="flex justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
                    <div className='space-x-5'>
                        <button
                            type="button"
                            className={`${activeLink === 'Admin' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => setActiveLink(e.target.textContent)}
                        >
                            Admin
                        </button>
                        <button
                            type="button"
                            className={`${activeLink === 'Pharmacist' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => setActiveLink(e.target.textContent)}
                        >
                            Pharmacist
                        </button>
                        <button
                            type="button"
                            className={`${activeLink === 'Sales' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => setActiveLink(e.target.textContent)}
                        >
                            Sales
                        </button>
                        <button
                            type="button"
                            className={`${activeLink === 'Accountants' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => setActiveLink(e.target.textContent)}
                        >
                            Accountants
                        </button>
                        {user.data.subscription_plan !== "STARTER" && <button
                            type="button"
                            className={`${activeLink === 'Couriers' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => setActiveLink(e.target.textContent)}
                        >
                            Couriers
                        </button>}
                        <button
                            type="button"
                            className={`${activeLink === 'Customers' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => setActiveLink(e.target.textContent)}
                        >
                            Customers
                        </button>
                    </div>

                    <div>
                        <input
                            type="text"
                            name="search"
                            // disabled
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />
                    </div>
                </div>

                {activeLink === "Admin" && (
                    <EmployeeTable
                        data={filterState.pharmacists?.filter(pharmacist => pharmacist.pkid !== user?.data.pkid && pharmacist.is_department_head === true)}
                    />
                )}

                {activeLink === "Pharmacist" && (
                    <EmployeeTable
                        data={filterState.pharmacists?.filter(pharmacist => pharmacist.pkid !== user?.data.pkid && pharmacist.is_pharmacist === true)}
                    />
                )}

                {activeLink === 'Sales' && (
                    <EmployeeTable
                        data={filterState.pharmacists?.filter(pharmacist => pharmacist.pkid !== user?.data.pkid && pharmacist.is_sales === true)}
                    />
                )}

                {activeLink === 'Accountants' && (
                    <EmployeeTable
                        data={filterState.pharmacists?.filter(pharmacist => pharmacist.pkid !== user?.data.pkid && pharmacist.is_collections === true)}
                    />
                )}

                {activeLink === 'Couriers' && user.data.subscription_plan !== "STARTER" && (
                    <EmployeeTable
                        data={filterState.pharmacists?.filter(pharmacist => pharmacist.pkid !== user?.data.pkid && pharmacist.is_courier === true)}
                    />
                )}

                {activeLink === 'Customers' && (
                    <EmployeeTable
                        data={filterState.pharmacists?.filter(pharmacist => pharmacist.pkid !== user?.data.pkid && pharmacist.is_customer === true)}
                    />
                )}

            </div>
        </div>
    )
}

export default PharmacistStaff