/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { getOrders, getPrescriptions, getInvoices, getSupplyPoints, getAllProfiles, fetchAllInventories } from '../../state/actions/pharmacist.actions'
import { NDHPharmacistSupplyPointTable, PageTitle, PharmacistDrugBinCardTable, PharmacistOrdersTable, PharmacistSupplyPointInvoicesTable, PharmacistSupplyPointPrescriptionsTable } from '../../components'


const PharmacistSupplyPoint = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)

    const { appState, setAppState, modals, setModals } = useStateContext()

    const { prescriptions, prescriptions_invoices, prescriptions_invoice, patient_drugdispensed, prescription_orders, prescription_order } = useSelector(state => state.pharmacist)
    const [showList, setShowList] = useState(true)
    const [showPrescriptions, setShowPrescriptions] = useState(true)
    const [showInvoices, setShowInvoices] = useState(true)
    const [showOrders, setShowOrders] = useState(true)
    const [activeLink, setActiveLink] = useState('Catalog')

    useEffect(() => {
        dispatch(getSupplyPoints())
        dispatch(getPrescriptions())
        dispatch(getInvoices())
        dispatch(getOrders())
        dispatch(getAllProfiles())
        dispatch(fetchAllInventories())

        return () => {
            setActiveLink('Catalog')
            setAppState({ ...appState, showManageBinCard: false })
        }
    }, [])

    useEffect(() => {
        dispatch(getPrescriptions())
        dispatch(getInvoices())
        dispatch(getOrders())

    }, [prescriptions_invoice, patient_drugdispensed, prescription_order, modals.showPharmacistProcessOrderModal])

    return (
        <div>
            <PageTitle type={'pharmacist-supply-point'} />

            <div className="space-y-5">
                <Fragment>
                    {!appState.showManageBinCard && (
                        <div className="">
                            <div className="flex justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
                                <div className='space-x-5'>
                                    <button
                                        type="button"
                                        className={`${activeLink === 'Catalog' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                        onClick={(e) => setActiveLink(e.target.textContent)}
                                    >
                                        Catalog
                                    </button>
                                    <button
                                        type="button"
                                        className={`${activeLink === 'Prescriptions' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                        onClick={(e) => setActiveLink(e.target.textContent)}
                                    >
                                        Prescriptions
                                    </button>
                                    <button
                                        type="button"
                                        className={`${activeLink === 'Invoices' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                        onClick={(e) => setActiveLink(e.target.textContent)}
                                    >
                                        Invoices
                                    </button>
                                    {user?.data?.subscription_plan === "STANDARD" && (
                                        <button
                                            type="button"
                                            className={`${activeLink === 'Orders' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                            onClick={(e) => setActiveLink(e.target.textContent)}
                                        >
                                            Orders
                                        </button>
                                    )}
                                    {user?.data?.subscription_plan === "ADVANCED" && (
                                        <button
                                            type="button"
                                            className={`${activeLink === 'Orders' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                            onClick={(e) => setActiveLink(e.target.textContent)}
                                        >
                                            Orders
                                        </button>
                                    )}
                                </div>

                                <button
                                    type="button"
                                    className={`flex items-center text-white bg-sky-600 px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                    onClick={() => {
                                        setModals({ ...modals, showPharmacistAddPrescriptionModal: !modals.showPharmacistAddPrescriptionModal })
                                    }}
                                >
                                    <AiOutlinePlus className='text-white' size={15} /> Prescription / Invoice
                                </button>
                            </div>

                            {activeLink === 'Catalog' && (
                                <NDHPharmacistSupplyPointTable
                                    data={[]}
                                    title={'Catalog'}
                                    showList={showList}
                                    setShowList={setShowList}
                                />
                            )}

                            {activeLink === 'Prescriptions' && (
                                <PharmacistSupplyPointPrescriptionsTable
                                    data={prescriptions}
                                    title={'Prescriptions'}
                                    showList={showPrescriptions}
                                    setShowList={setShowPrescriptions}
                                />
                            )}

                            {activeLink === 'Invoices' && (
                                <PharmacistSupplyPointInvoicesTable
                                    data={prescriptions_invoices}
                                    title={'Invoices'}
                                    showList={showInvoices}
                                    setShowList={setShowInvoices}
                                />
                            )}


                            {activeLink === 'Orders' && (
                                <PharmacistOrdersTable
                                    data={prescription_orders}
                                    title={'Orders'}
                                    showList={showOrders}
                                    setShowList={setShowOrders}
                                />
                            )}
                        </div>
                    )}
                    {appState.showManageBinCard && (
                        <PharmacistDrugBinCardTable
                            data={[]}
                            title={'Paracetamol Bin Card'}
                            showList={showList}
                            setShowList={setShowList}
                        />
                    )}
                </Fragment>
            </div>
        </div>
    )
}

export default PharmacistSupplyPoint