import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { useStateContext } from "../../../state/context";
import { setCurrentPrescriptionInvoice, setCurrentPrescriptions } from "../../../state/actions/actions";
import { dispenseDrug, DispenseMultipleItems, getPaidPrescriptions, resetPaidPrescriptions } from "../../../state/actions/pharmacist.actions";

const PharmacistMutipleDispenseModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const { prescription_invoice_update, supplypoint_update } = useSelector(state => state.root)
    const { paid_prescriptions, supplypoint_inventory, patient_drugdispensed, multiple_dispense } = useSelector(state => state.pharmacist)

    // useEffect(() => {
    //     if (prescription_invoice_update?.pkid) {
    //         const params = {
    //             prescription: prescription_invoice_update?.prescription,
    //         }
    //         dispatch(getPaidPrescriptions({ params }))
    //     }
    // }, [prescription_invoice_update, patient_drugdispensed])

    const handleDispense = (e) => {
        e.preventDefault()
        console.log(multiple_dispense);


        dispatch(DispenseMultipleItems({ formData: multiple_dispense, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`${modals.showPharmacistMultipleDispenseModal ? 'w-[800px]' : 'w-[1200px]'} bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showPharmacistMultipleDispenseModal ? 'text-sky-500' : 'text-dark-green'} text-[20px] font-bold capitalize`}>
                            Multiple item dispense
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {

                                setModals({ ...modals, showPharmacistMultipleDispenseModal: !modals.showPharmacistMultipleDispenseModal })
                            }}
                            size={20}
                        />
                    </div>

                    {/* user info */}
                    {modals.showPharmacistMultipleDispenseModal && (
                        <div className="flex items-center space-x-5">
                            <div className="flex flex-col space-y-1">
                                <p className="text-[14px] text-gray-600 font-bold">{prescription_invoice_update?.invoice_patient?.patient_name}</p>
                                <p className="text-[12px] text-gray-300 font-medium">{prescription_invoice_update?.invoice_patient?.patient_id}</p>
                            </div>
                        </div>
                    )}

                    <table className="border rounded w-full cursor-default">
                        <thead className="">
                            <tr>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                >
                                    Item
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                >
                                    Dispense Type
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 py-4 text-left bg-sky-200"
                                >
                                    Quantity
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 py-4 text-left bg-sky-200"
                                >

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {multiple_dispense?.items?.map((itm, index) => (<tr key={`${itm?.id}`}>
                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                    {itm?.item}
                                </td>
                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                    {itm?.dispense_type}
                                </td>
                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap bg-sky-200">
                                    {itm?.dispense_count}
                                </td>

                            </tr>)

                            )}
                        </tbody>

                    </table>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            onClick={(e) => handleDispense(e)}

                            className={' inline-block w-[100px] px-6 py-2.5  bg-sky-600 text-white font-medium text-xs leading-tight shadow-md hover:scale-[.98] transition duration-500 ease'}
                        >
                            Dispense
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default PharmacistMutipleDispenseModal