import {
    IconHome, IconCalender, IconChat, IconMessage, IconSms, IconProfile, IconDepartment,
    IconPeople, IconBed, IconInventory, IconSupplyPoint, IconDrugs, IconCategory,
    IconFile, IconCheckList, IconSettings, Affliate1, Affliate2, Affliate3, IconTime, IconOPD, IconDatabase,
} from '../assets'

const user_details = localStorage.getItem("pms__user") ? JSON.parse(localStorage.getItem("pms__user"))["data"] : null
const user_plan = user_details ? user_details["subscription_plan"] : null
const plan_expired = localStorage.getItem("plan") ? JSON.parse(localStorage.getItem("plan"))["is_expired"] : null



export const bloodgroups = [
    'A+',
    'A-',
    'AB+',
    'B+',
    'B-',
    'O+',
    'O-',
]

export const maritalstatus = [
    'Married',
    'Single',
    'Divorced',
    'Widow',
    'Widower'
]

export const genotypes = [
    'AA',
    'AS',
    'AC',
    'SS'
]

export const drug__affliates = [
    { id: 1, icon: Affliate1, },
    { id: 2, icon: Affliate2, },
    { id: 3, icon: Affliate3, },
]

export const inventory__types = [
    { title: 'Pack', value: 'pack' },
    { title: 'Card', value: 'card' },
    { title: 'Pieces', value: 'pieces' },
    { title: 'Bottle', value: 'bottle' },
    { title: 'Container', value: 'container' }
]

export const drug__types = [
    { title: 'Pack', value: 'pack' },
    // { title: 'Card', value: 'card' },
    { title: 'Bottle', value: 'bottle' },
    { title: 'Container', value: 'container' }
]

export const drug__compositions = [
    { title: 'None', value: 'none' },
    { title: 'Tablet', value: 'tablet' },
    { title: 'Powder', value: 'powder' },
    { title: 'Liquid', value: 'liquid' }
]

export const drug__frequency = [
    { title: 'Twice Daily', value: 'twice daily' },
    { title: 'At bed time', value: 'at bed time' },
    { title: 'Once a day', value: 'Once a day' },
    { title: 'When necessary', value: 'when necessary' },
    { title: 'Four times daily', value: 'four times daily' },
    { title: 'At once', value: 'at once' },
    { title: 'Thrice daily', value: 'thrice daily' },
]

export const drug__dosage = [
    { title: 'Amp', value: 'amp' },
    { title: 'Cap', value: 'cap' },
    { title: 'sc', value: 'sc' },
    { title: 'supp', value: 'supp' },
    { title: 'tab', value: 'tab' },
    { title: 'tbsp', value: 'tbsp' },
    { title: 'tsp', value: 'tsp' },
]

export const drug__units = [
    { title: 'g', value: 'g' },
    { title: 'kg', value: 'kg' },
    { title: 'mg', value: 'mg' },
    { title: 'mg/kg', value: 'mg/kg' },
    { title: 'ml', value: 'ml' },
    { title: 'mu', value: 'mu' },
]

export const drug__routes = [
    { title: 'amp', value: 'amp' },
    { title: 'au', value: 'au' },
    { title: 'buc', value: 'buc' },
    { title: 'cap', value: 'cap' },
    { title: 'epi', value: 'epi' },
    { title: 'ia', value: 'ia' },
    { title: 'iart', value: 'iart' },
    { title: 'icar', value: 'icar' },
    { title: 'iduc', value: 'iduc' },
    { title: 'ifol', value: 'ifol' },
    { title: 'igas', value: 'igas' },
    { title: 'iles', value: 'iles' },
    { title: 'im', value: 'im' },
    { title: 'imam', value: 'imam' },
    { title: 'imrs', value: 'imrs' },
    { title: 'inf', value: 'inf' },
    { title: 'inh', value: 'inh' },
    { title: 'ip', value: 'ip' },
    { title: 'isin', value: 'isin' },
    { title: 'isyn', value: 'isyn' },
    { title: 'it', value: 'it' },
    { title: 'ites', value: 'ites' },
    { title: 'iu', value: 'iu' },
    { title: 'iv', value: 'iv' },
    { title: 'ives', value: 'ives' },
    { title: 'nas', value: 'nas' },
    { title: 'ng', value: 'ng' },
    { title: 'ou', value: 'ou' },
    { title: 'par', value: 'par' },
    { title: 'pneu', value: 'pneu' },
    { title: 'po', value: 'po' },
    { title: 'pv', value: 'pv' },
    { title: 'rec', value: 'rec' },
    { title: 'sc', value: 'sc' },
    { title: 'SC', value: 'SC' },
    { title: 'SCi', value: 'SCi' },
    { title: 'SL', value: 'SL' },
    { title: 'supp', value: 'supp' },
    { title: 'TOP', value: 'TOP' },
    { title: 'TOPp', value: 'TOPp' },
    { title: 'TD', value: 'TD' },
    { title: 'tab', value: 'tab' },
    { title: 'tbsp', value: 'tbsp' },
    { title: 'tsp', value: 'tsp' },
]

export const Antigens = [
    'BCG',
    'HepB',
    'OPV-0',
    'OPV-1',
    'OPV-2',
    'OPV-3',
    'PCV-1',
    'PCV-2',
    'PCV-3',
    'PENTA-1',
    'PENTA-2',
    'PENTA-3',
    'ROTA-1',
    'ROTA-2',
    'ROTA-3',
    'Measles-1',
    'Measles-2',
    'Vitamin-A1',
    'Vitamin-A2',
    'Yellow Fever',
    'Meningitis',
]

export const payment__methods = [
    { id: 1, title: 'Cash', value: 'cash' },
    { id: 3, title: 'Paystack', value: 'paystack' },
    // { id: 4, title: 'Flutterwave', value: 'flutterwave' },
    { id: 5, title: 'Point Of Sale (POS)', value: 'pos' },
]


export const customer__payment__methods = [
    { id: 3, title: 'Paystack', value: 'paystack' },
    // { id: 4, title: 'Flutterwave', value: 'flutterwave' },
]


export const admin__payment__methods = [
    { id: 3, title: 'Paystack', value: 'paystack' },
]


export const order__delivery__time = [
    { id: 1, time: 'Within 24 Hours' },
    { id: 2, time: '3 to 4 Working Days' },
    { id: 3, time: 'One Week' },
]


export const menus__pharmacist__starter = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]
export const menus__pharmacist__standard = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
    { title: 'Clinical Data', icon: IconDatabase },
    { title: 'Manage Patient', icon: IconInventory },
    { title: 'Chat', icon: IconChat },
]
export const menus__pharmacist__advanced = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
    { title: 'Clinical Data', icon: IconDatabase },
    { title: 'Manage Patient', icon: IconInventory },
    { title: 'Chat', icon: IconChat },
    { title: 'Investigations', icon: IconCategory },
]

export const menus__pharmacist = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
    user_plan === "ADVANCED" | user_plan === "STANDARD" && { title: 'Clinical Data', icon: IconDatabase },
    user_plan === "ADVANCED" | user_plan === "STANDARD" && { title: 'Manage Patient', icon: IconInventory },
    user_plan === "ADVANCED" | user_plan === "STANDARD" && { title: 'Chat', icon: IconChat },
    user_plan === "ADVANCED" && { title: 'Investigations', icon: IconCategory },
]

export const menus__sales__starter = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__sales__standard = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__sales__advanced = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__sales = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
    user_plan === "ADVANCED" | user_plan === "STANDARD" && { title: 'Chat', icon: IconChat },
]

export const menus__pharmacist__departmentalhead__starter = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    (!plan_expired) && { title: 'Inventory', icon: IconCategory },
    (!plan_expired) && { title: 'Staff', icon: IconPeople },
    (!plan_expired) && { title: 'Supply Points', icon: IconSupplyPoint },
    (!plan_expired) && { title: 'Schedule', icon: IconCalender },
    (!plan_expired) && { title: 'Messages', icon: IconMessage },
    (!plan_expired) && { title: 'SMS', icon: IconSms },
    { title: 'Settings', icon: IconSettings },
    (!plan_expired) && { title: 'Profile', icon: IconProfile },
]
export const menus__pharmacist__departmentalhead__standard = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    (!plan_expired) && { title: 'Inventory', icon: IconCategory },
    (!plan_expired) && { title: 'Staff', icon: IconPeople },
    (!plan_expired) && { title: 'Supply Points', icon: IconSupplyPoint },
    (!plan_expired) && { title: 'Schedule', icon: IconCalender },
    (!plan_expired) && { title: 'Chat', icon: IconChat },
    (!plan_expired) && { title: 'Messages', icon: IconMessage },
    (!plan_expired) && { title: 'SMS', icon: IconSms },
    { title: 'Settings', icon: IconSettings },
    (!plan_expired) && { title: 'Profile', icon: IconProfile },
]
export const menus__pharmacist__departmentalhead__advanced = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    (!plan_expired) && { title: 'Inventory', icon: IconCategory },
    (!plan_expired) && { title: 'Staff', icon: IconPeople },
    (!plan_expired) && { title: 'Supply Points', icon: IconSupplyPoint },
    (!plan_expired) && { title: 'Schedule', icon: IconCalender },
    (!plan_expired) && { title: 'Chat', icon: IconChat },
    (!plan_expired) && { title: 'Messages', icon: IconMessage },
    (!plan_expired) && { title: 'SMS', icon: IconSms },
    { title: 'Settings', icon: IconSettings },
    (!plan_expired) && { title: 'Profile', icon: IconProfile },
]

export const menus__pharmacist__departmentalhead = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    (!plan_expired) && { title: 'Inventory', icon: IconCategory },
    (!plan_expired) && { title: 'Staff', icon: IconPeople },
    (!plan_expired) && { title: 'Supply Points', icon: IconSupplyPoint },
    (!plan_expired) && { title: 'Schedule', icon: IconCalender },
    (!plan_expired) && { title: 'Messages', icon: IconMessage },
    (!plan_expired) && { title: 'SMS', icon: IconSms },
    { title: 'Settings', icon: IconSettings },
    (!plan_expired) && { title: 'Profile', icon: IconProfile },
    (!plan_expired && user_plan === "ADVANCED" | user_plan === "STANDARD") && { title: 'Chat', icon: IconChat },
]

export const menus__customer = [
    { title: 'Customer Dashboard', icon: IconHome },
    { title: 'Customer Invoices', icon: IconFile },
    { title: 'Customer Orders', icon: IconInventory },
    { title: 'Profile', icon: IconProfile },
]

export const menus__collections__starter = [
    { title: 'Collections Dashboard', icon: IconHome },
    { title: 'Collections Invoices', icon: IconFile },
    { title: 'Collections Paid Invoices', icon: IconCheckList },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__collections__standard = [
    { title: 'Collections Dashboard', icon: IconHome },
    { title: 'Collections Invoices', icon: IconFile },
    { title: 'Collections Paid Invoices', icon: IconCheckList },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__collections__advanced = [
    { title: 'Collections Dashboard', icon: IconHome },
    { title: 'Collections Invoices', icon: IconFile },
    { title: 'Collections Paid Invoices', icon: IconCheckList },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__collections = [
    { title: 'Collections Dashboard', icon: IconHome },
    { title: 'Collections Invoices', icon: IconFile },
    { title: 'Collections Paid Invoices', icon: IconCheckList },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
    user_plan === "ADVANCED" | user_plan === "STANDARD" && { title: 'Chat', icon: IconChat },
]

export const countries = [
    {
        title: 'Afghanistan',
        value: 'AF',
    },
    {
        title: 'Albania',
        value: 'AL',
    },
    {
        title: 'Algeria',
        value: 'DZ',
    },
    {
        title: 'American Samoa',
        value: 'AS',
    },
    {
        title: 'Andorra',
        value: 'AD',
    },
    {
        title: 'Angola',
        value: 'AO',
    },
    {
        title: 'Anguilla',
        value: 'AI',
    },
    {
        title: 'Argentina',
        value: 'AR',
    },
    {
        title: 'Armenia',
        value: 'AM',
    },
    {
        title: 'Aruba',
        value: 'AW',
    },
    {
        title: 'Australia',
        value: 'AU',
    },
    {
        title: 'Azerbaijan',
        value: 'AZ',
    },
    {
        title: 'Bahamas',
        value: 'BS',
    },
    {
        title: 'Bahrain',
        value: 'BH',
    },
    {
        title: 'Bangladesh',
        value: 'BD',
    },
    {
        title: 'Barbados',
        value: 'BB',
    },
    {
        title: 'Belarus',
        value: 'BY',
    },
    {
        title: 'Belgium',
        value: 'BE',
    },
    {
        title: 'Belize',
        value: 'BZ',
    },
    {
        title: 'Benin',
        value: 'BJ',
    },
    {
        title: 'Bermuda',
        value: 'BM',
    },
    {
        title: 'Bhutan',
        value: 'BT',
    },
    {
        title: 'Bolivia',
        value: 'BO',
    },
    {
        title: 'Bosnia and Herzegovina',
        value: 'BA',
    },
    {
        title: 'Botswana',
        value: 'BW',
    },
    {
        title: 'Brazil',
        value: 'BR',
    },
    {
        title: 'British Virgin Islands',
        value: 'VG',
    },
    {
        title: 'Brunei',
        value: 'BN',
    },
    {
        title: 'Bulgaria',
        value: 'BG',
    },
    {
        title: 'Burkina Faso',
        value: 'BF',
    },
    {
        title: 'Burundi',
        value: 'BI',
    },
    {
        title: 'Cambodia',
        value: 'KH',
    },
    {
        title: 'Cameroon',
        value: 'CM',
    },
    {
        title: 'Canada',
        value: 'CA',
    },
    {
        title: 'Cape Verde',
        value: 'CV',
    },
    {
        title: 'Cayman Islands',
        value: 'KY',
    },
    {
        title: 'Central African Republic',
        value: 'CF',
    },
    {
        title: 'Chad',
        value: 'TD',
    },
    {
        title: 'Chile',
        value: 'CL',
    },
    {
        title: 'China',
        value: 'CN',
    },
    {
        title: 'Columbia',
        value: 'CO',
    },
    {
        title: 'Comoros',
        value: 'KM',
    },
    {
        title: 'Cook Islands',
        value: 'CK',
    },
    {
        title: 'Costa Rica',
        value: 'CR',
    },
    {
        title: 'Croatia',
        value: 'HR',
    },
    {
        title: 'Cuba',
        value: 'CU',
    },
    {
        title: 'Curacao',
        value: 'CW',
    },
    {
        title: 'Cyprus',
        value: 'CY',
    },
    {
        title: 'Czech Republic',
        value: 'CZ',
    },
    {
        title: 'Democratic Republic of the Congo',
        value: 'CD',
    },
    {
        title: 'Denmark',
        value: 'DK',
    },
    {
        title: 'Djibouti',
        value: 'DJ',
    },
    {
        title: 'Dominica',
        value: 'DM',
    },
    {
        title: 'Dominican Republic',
        value: 'DO',
    },
    {
        title: 'East Timor',
        value: 'TL',
    },
    {
        title: 'Ecuador',
        value: 'EC',
    },
    {
        title: 'Egypt',
        value: 'EG',
    },
    {
        title: 'El Salvador',
        value: 'SV',
    },
    {
        title: 'Eritrea',
        value: 'ER',
    },
    {
        title: 'Estonia',
        value: 'EE',
    },
    {
        title: 'Faroe Islands',
        value: 'FO',
    },
    {
        title: 'Fiji',
        value: 'FJ',
    },
    {
        title: 'Finland',
        value: 'FI',
    },
    {
        title: 'France',
        value: 'FR',
    },
    {
        title: 'French Polynesia',
        value: 'PF',
    },
    {
        title: 'Gabon',
        value: 'GA',
    },
    {
        title: 'Gambia',
        value: 'GM',
    },
    {
        title: 'Georgia',
        value: 'GE',
    },
    {
        title: 'Germany',
        value: 'DE',
    },
    {
        title: 'Ghana',
        value: 'GH',
    },
    {
        title: 'Greece',
        value: 'GR',
    },
    {
        title: 'Greenland',
        value: 'GL',
    },
    {
        title: 'Grenada',
        value: 'GD',
    },
    {
        title: 'Guam',
        value: 'GU',
    },
    {
        title: 'Guatemala',
        value: 'GT',
    },
    {
        title: 'Guernsey',
        value: 'GG',
    },
    {
        title: 'Guinea',
        value: 'GN',
    },
    {
        title: 'Guinea-Bissau',
        value: 'GW',
    },
    {
        title: 'Guyana',
        value: 'GY',
    },
    {
        title: 'Haiti',
        value: 'HT',
    },
    {
        title: 'Honduras',
        value: 'HN',
    },
    {
        title: 'Hong Kong',
        value: 'HK',
    },
    {
        title: 'Hungary',
        value: 'HU',
    },
    {
        title: 'Iceland',
        value: 'IS',
    },
    {
        title: 'India',
        value: 'IN',
    },
    {
        title: 'Indonesia',
        value: 'ID',
    },
    {
        title: 'Iran',
        value: 'IR',
    },
    {
        title: 'Iraq',
        value: 'IQ',
    },
    {
        title: 'Ireland',
        value: 'IE',
    },
    {
        title: 'Isle of Man',
        value: 'IM',
    },
    {
        title: 'Israel',
        value: 'IL',
    },
    {
        title: 'Italy',
        value: 'IT',
    },
    {
        title: 'Ivory Coast',
        value: 'CI',
    },
    {
        title: 'Jamaica',
        value: 'JM',
    },
    {
        title: 'Japan',
        value: 'JP',
    },
    {
        title: 'Jersey',
        value: 'JE',
    },
    {
        title: 'Jordan',
        value: 'JO',
    },
    {
        title: 'Kazakhstan',
        value: 'KZ',
    },
    {
        title: 'Kenya',
        value: 'KE',
    },
    {
        title: 'Kiribati',
        value: 'KI',
    },
    {
        title: 'Kosovo',
        value: 'XK',
    },
    {
        title: 'Kuwait',
        value: 'KW',
    },
    {
        title: 'Kyrgyzstan',
        value: 'KG',
    },
    {
        title: 'Laos',
        value: 'LA',
    },
    {
        title: 'Latvia',
        value: 'LV',
    },
    {
        title: 'Lebanon',
        value: 'LB',
    },
    {
        title: 'Lesotho',
        value: 'LS',
    },
    {
        title: 'Liberia',
        value: 'LB',
    },
    {
        title: 'Libya',
        value: 'LY',
    },
    {
        title: 'Liechtenstein',
        value: 'LI',
    },
    {
        title: 'Lithuania',
        value: 'LT',
    },
    {
        title: 'Luxembourg',
        value: 'LU',
    },
    {
        title: 'Macau',
        value: 'MO',
    },
    {
        title: 'Macedonia',
        value: 'MK',
    },
    {
        title: 'Madagascar',
        value: 'MG',
    },
    {
        title: 'Malawi',
        value: 'MW',
    },
    {
        title: 'Malaysia',
        value: 'MY',
    },
    {
        title: 'Maldives',
        value: 'MV',
    },
    {
        title: 'Mali',
        value: 'ML',
    },
    {
        title: 'Malta',
        value: 'MT',
    },
    {
        title: 'Marshall Islands',
        value: 'MH',
    },
    {
        title: 'Mauritania',
        value: 'MR',
    },
    {
        title: 'Mauritius',
        value: 'MU',
    },
    {
        title: 'Mayotte',
        value: 'YT',
    },
    {
        title: 'Mexico',
        value: 'MX',
    },
    {
        title: 'Micronesia',
        value: 'FM',
    },
    {
        title: 'Moldova',
        value: 'MD',
    },
    {
        title: 'Monaco',
        value: 'MC',
    },
    {
        title: 'Mongolia',
        value: 'MN',
    },
    {
        title: 'Montenegro',
        value: 'ME',
    },
    {
        title: 'Morocco',
        value: 'MA',
    },
    {
        title: 'Mozambique',
        value: 'MZ',
    },
    {
        title: 'Myanmar',
        value: 'MM',
    },
    {
        title: 'Namibia',
        value: 'NA',
    },
    {
        title: 'Nepal',
        value: 'NP',
    },
    {
        title: 'Netherlands',
        value: 'NL',
    },
    {
        title: 'Netherlands Antilles',
        value: 'AN',
    },
    {
        title: 'New Caledonia',
        value: 'NC',
    },
    {
        title: 'New Zealand',
        value: 'NZ',
    },
    {
        title: 'Nicaragua',
        value: 'NI',
    },
    {
        title: 'Niger',
        value: 'NE',
    },
    {
        title: 'Nigeria',
        value: 'NG',
    },
    {
        title: 'North Korea',
        value: 'KP',
    },
    {
        title: 'Northern Mariana Islands',
        value: 'MP',
    },
    {
        title: 'Norway',
        value: 'NO',
    },
    {
        title: 'Oman',
        value: 'OM',
    },
    {
        title: 'Pakistan',
        value: 'PK',
    },
    {
        title: 'Palestine',
        value: 'PS',
    },
    {
        title: 'Panama',
        value: 'PA',
    },
    {
        title: 'Papua New Guinea',
        value: 'PG',
    },
    {
        title: 'Paraguay',
        value: 'PY',
    },
    {
        title: 'Peru',
        value: 'PE',
    },
    {
        title: 'Philippines',
        value: 'PH',
    },
    {
        title: 'Poland',
        value: 'PL',
    },
    {
        title: 'Portugal',
        value: 'PT',
    },
    {
        title: 'Puerto Rico',
        value: 'PR',
    },
    {
        title: 'Qatar',
        value: 'QA',
    },
    {
        title: 'Republic of the Congo',
        value: 'CG',
    },
    {
        title: 'Reunion',
        value: 'RE',
    },
    {
        title: 'Romania',
        value: 'RO',
    },
    {
        title: 'Russia',
        value: 'RU',
    },
    {
        title: 'Rwanda',
        value: 'RW',
    },
    {
        title: 'Saint Kitts and Nevis',
        value: 'KN',
    },
    {
        title: 'Saint Lucia',
        value: 'LC',
    },
    {
        title: 'Saint Martin',
        value: 'MF',
    },
    {
        title: 'Saint Pierre and Miquelon',
        value: 'PM',
    },
    {
        title: 'Saint Vincent and the Grenadines',
        value: 'VC',
    },
    {
        title: 'Samoa',
        value: 'WS',
    },
    {
        title: 'San Marino',
        value: 'SM',
    },
    {
        title: 'Sao Tome and Principe',
        value: 'ST',
    },
    {
        title: 'Saudi Arabia',
        value: 'SA',
    },
    {
        title: 'Senegal',
        value: 'SN',
    },
    {
        title: 'Serbia',
        value: 'RS',
    },
    {
        title: 'Seychelles',
        value: 'SC',
    },
    {
        title: 'Sierra Leone',
        value: 'SL',
    },
    {
        title: 'Singapore',
        value: 'SG',
    },
    {
        title: 'Sint Maarten',
        value: 'SX',
    },
    {
        title: 'Slovakia',
        value: 'SK',
    },
    {
        title: 'Slovenia',
        value: 'SI',
    },
    {
        title: 'Solomon Islands',
        value: 'SB',
    },
    {
        title: 'Somalia',
        value: 'SO',
    },
    {
        title: 'South Africa',
        value: 'ZA',
    },
    {
        title: 'South Korea',
        value: 'KR',
    },
    {
        title: 'South Sudan',
        value: 'SS',
    },
    {
        title: 'Spain',
        value: 'ES',
    },
    {
        title: 'Sri Lanka',
        value: 'LK',
    },
    {
        title: 'Sudan',
        value: 'SD',
    },
    {
        title: 'Suriname',
        value: 'SR',
    },
    {
        title: 'Swaziland',
        value: 'SZ',
    },
    {
        title: 'Sweden',
        value: 'SE',
    },
    {
        title: 'Switzerland',
        value: 'CH',
    },
    {
        title: 'Syria',
        value: 'SY',
    },
    {
        title: 'Taiwan',
        value: 'TW',
    },
    {
        title: 'Tajikistan',
        value: 'TJ',
    },
    {
        title: 'Tanzania',
        value: 'TZ',
    },
    {
        title: 'Thailand',
        value: 'TH',
    },
    {
        title: 'Togo',
        value: 'TG',
    },
    {
        title: 'Tonga',
        value: 'TO',
    },
    {
        title: 'Trinidad and Tobago',
        value: 'TT',
    },
    {
        title: 'Tunisia',
        value: 'TN',
    },
    {
        title: 'Turkey',
        value: 'TR',
    },
    {
        title: 'Turkmenistan',
        value: 'TM',
    },
    {
        title: 'Turks and Caicos Islands',
        value: 'TC',
    },
    {
        title: 'Tuvalu',
        value: 'TV',
    },
    {
        title: 'U.S. Virgin Islands',
        value: 'VI',
    },
    {
        title: 'Uganda',
        value: 'UG',
    },
    {
        title: 'Ukraine',
        value: 'UA',
    },
    {
        title: 'United Arab Emirates',
        value: 'AE',
    },
    {
        title: 'United Kingdom',
        value: 'GB',
    },
    {
        title: 'United States',
        value: 'US',
    },
    {
        title: 'Uruguay',
        value: 'UY',
    },
    {
        title: 'Uzbekistan',
        value: 'UZ',
    },
    {
        title: 'Vanuatu',
        value: 'VU',
    },
    {
        title: 'Venezuela',
        value: 'VE',
    },
    {
        title: 'Vietnam',
        value: 'VN',
    },
    {
        title: 'Western Sahara',
        value: 'EH',
    },
    {
        title: 'Yemen',
        value: 'YE',
    },
    {
        title: 'Zambia',
        value: 'ZM',
    },
    {
        title: 'Zimbabwe',
        value: 'ZW',
    },
]


export const root__modals = {
    showSendSmsModal: false,
    showSendMailModal: false,
    updatePasswordModal: false,
    showWebCamModal: false,
    showCapturePhotoModal: false,
    showChangeDoctorModal: false,
    showPharmacistAddStaffModal: false,
    showPharmacistUpdateStaffModal: false,
    showPharmacistDeleteStaffModal: false,
    showPharmacistUpdateStaffProfileModal: false,
    showPharmacistAddDrugCategoryModal: false,
    showPharmacistUpdateDrugCategoryModal: false,
    showPharmacistDeleteDrugCategoryModal: false,
    showPharmacistAddSupplyPointModal: false,
    showPharmacistUpdateSupplyPointModal: false,
    showPharmacistDeleteSupplyPointModal: false,
    showPharmacistAddDrugModal: false,
    showPharmacistUpdateDrugModal: false,
    showPharmacistViewDrugModal: false,
    showPharmacistDeleteDrugModal: false,
    showPharmacistBinCardModal: false,
    showPharmacistBinCardSummaryModal: false,
    showPharmacistIssueOutDrugModal: false,
    showPharmacistLendRefundDrugModal: false,
    showPharmacistDrugSupplyPointModal: false,
    showPharmacistDispenseDrugModal: false,
    showPharmacistViewPrescriptionModal: false,
    showPharmacistViewInvoiceModal: false,
    showPharmacistAssignSupplyPointModal: false,
    showPharmacistSupplyPointAssignStaffModal: false,
    showPharmacistSupplyPointStaffModal: false,
    showPharmacistAddPrescriptionModal: false,
    showPharmacistProcessOrderModal: false,
    showPharmacistViewOrderModal: false,
    showPharmacistAddInventoryModal: false,
    showPharmacistViewProvisionModal: false,
    showPharmacistUpdateProvisionModal: false,
    showPharmacistDeleteProvisionModal: false,
    showPharmacistAddBillingModal: false,
    showPharmacistUpdateBillingModal: false,
    showPharmacistDeleteBillingModal: false,
    showPharmacistAddInvestigationType: false,
    showPharmacistUpdateInvestigationType: false,
    showPharmacistDeleteInvestigationType: false,
    showPharmacistAddInvestigationCategoryModal: false,
    showPharmacistUpdateInvestigationCategoryModal: false,
    showPharmacistDeleteInvestigationCategoryModal: false,
    showPharmacistAddInvestigationSampleModal: false,
    showPharmacistUpdateInvestigationSampleModal: false,
    showPharmacistDeleteInvestigationSampleModal: false,
    showPharmacistAddRadiologyInvestigationTypeModal: false,
    showPharmacistUpdateRadiologyInvestigationTypeModal: false,
    showPharmacistDeleteRadiologyInvestigationTypeModal: false,
    showPharmacistAddRadiologyInvestigationCategoryModal: false,
    showPharmacistUpdateRadiologyInvestigationCategoryModal: false,
    showPharmacistDeleteRadiologyInvestigationCategoryModal: false,
    showPharmacistAddGatewaySettingModal: false,
    showPharmacistUpdateGatewaySettingModal: false,
    showPharmacistDeleteGatewaySettingModal: false,
    showPharmacistMultipleDispenseModal: false,
    showPharmacistUpdatePharmacyInfoModal: false,
    showPharmacistUpdatePharmacyLogoModal: false,
    showAccountsViewLabInvoiceModal: false,
    showCreateScheduleModal: false,
    showEditAndDeleteScheduleModal: false,
    showListOfSchedulesByDateModal: false,
    showDeleteScheduleModal: false,
    showEditDeleteOptionalModal: false,
    showViewScheduleModal: false,
    showAccountsViewPharmacyInvoiceModal: false,
    showAccountsViewLabReportsModal: false,
    showAccountsViewPharmacyReportsModal: false,
    showAccountsViewRadiologyReportsModal: false,
    showAccountsViewRecordsReportsModal: false,
    showCollectionsLabPaymentModal: false,
    showCollectionsPharmacyPaymentModal: false,
    showCollectionsViewInvestigationInvoiceModal: false,
    showCollectionsInvestigationsPaymentModal: false,
    showCollectionRadiologyPaymentModal: false,
    showCollectionRecordsPaymentModal: false,
    showCollectionsViewLabInvoiceModal: false,
    showCollectionsViewPharmacyInvoiceModal: false,
    showCollectionsViewRadiologyInvoiceModal: false,
    showCollectionsViewRecordsInvoiceModal: false,
    showCollectionsViewPharmacyReportsModal: false,
    showCollectionsViewInvestigationReportsModal: false,
    showCollectionPrintInvoiceModal: false,
    showCollectionsPayPharmacyMultipleInvoiceModal: false,
    showCollectionsPayInvestigationMultipleInvoiceModal: false,
    showCollectionPrintMultipleReceiptModal: false,
    showCollectionsPrintInvestigationReceiptsModal: false,
    showChatCreateGroupModal: false,
    showAddGroupParticipantModal: false,
    showCustomerAddOrderModal: false,
    showCustomerAddProvisionModal: false,
    showCustomerPaymentMethodModal: false,
    showCustomerProvisionPaymentMethodModal: false,
    showCustomerProcessOrderModal: false,
    showCustomerProcessProvisionOrderModal: false,
    showCustomerViewOrderModal: false,
    //Create Room Call Modal
    showCreateRoomModal: false,
    showCallNotification: false,
    //Pharmacy Diagnosis Modal
    showDoctorAddOtherPrescriptionModal: false,
    showDoctorUploadPatientFileModal: false,
    showDoctorAddPrescriptionModal: false,
    showDoctorAddOtherRadiologyTestModal: false,
    showDoctorAddTestModal: false,
    showDoctorAddOtherTestModal: false,
    showDoctorUploadOtherLabReportModal: false,
    showDoctorWriteRadiologyReportModal: false,
    showDoctorViewRadiologyTestModal: false,
    showDoctorUploadOtherRadiologyReportModal: false,
    //Pharmacy Lab test Modals
    showAddLaboratoryEquipmentModal: false,
    showUpdateLaboratoryEquipmentModal: false,
    showDeleteLaboratoryEquipmentModal: false,
    showLaboratoryGenerateInvoiceModal: false,
    showLaboratoryUploadReportModal: false,
    showLaboratoryViewReportModal: false,
    showViewLaboratoryInvoiceModal: false,
    showLaboratoryAddCategoryModal: false,
    showLaboratoryUpdateCategoryModal: false,
    showLaboratoryDeleteCategoryModal: false,
    showLaboratoryAddInvestigationType: false,
    showLaboratoryUpdateInvestigationType: false,
    showDeleteLaboratoryInvestigationTypeModal: false,
    showAddLaboratoryTextReportModal: false,
    showAddLaboratorySamplesModal: false,
    showUpdateLaboratorySamplesModal: false,
    showDeleteLaboratorySampleModal: false,
    // Pharmacist Radiology reports 

    showAddRadiologyEquipment: false,
    showUpdateRadiologyEquipment: false,
    showDeleteRadiologyEquipment: false,
    showRadiologyViewReportModal: false,
    showAddRadiologyInvestigationModal: false,
    showAddRadiologyInvestigationCategoryModal: false,
    showAddRadiologyInvestigationTypeModal: false,
    showUpdateRadiologyInvestigationModal: false,
    showViewRadiologyInvestigationModal: false,
    showRadiologyGenerateInvoiceModal: false,
    showViewRadiologyInvoice: false,
    showDeleteRadiologyCategoryTestModal: false,
    showUpdateRadiologyCategoryTestModal: false,
    showDeleteRadiologyInvestigationTypeModal: false,
    showUpdateRadiologyInvestigationTypeModal: false,
    showUpdateSubscriptionPlanModal: false,
    showRenewSubscriptionPlanModal: false,
    showTopUpSMSUnitsModal: false,
}

export const root__appstate = {
    showDrugInventory: false,
    showSupplyPoint: false,
    showManageBinCard: false,
    showDoctorManagePatient: false,
    showDoctorPerformNewDiagnosis: false,
    showDoctorCompleteDiagnosis: false,
    showSettingsBillings: true,
    showSettingsLaboratory: false,
    showSettingsRadiology: false,
    showSettingsGateway: false,
    showSettingsSubscriptionPlan: false,
}

export const root__chatstate = {
    newChat: false,
    showVideoCallUI: true
}

export const root__opdstate = {
    showOpdTable: true,
    showPatientDiagnosisHistory: false,
    showDiagnosisPrescription: false,
    showFamilyPlanningFollowUpRecords: false,
    showChildImmunizationRecords: false,
    showPatientAntenatalRecords: false,
    showAdmittedPatientManagement: false,
}