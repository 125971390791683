import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MdSend } from 'react-icons/md'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setSmsReceiver } from '../../state/actions/actions'

const Icon = ({ title }) => (
    <Fragment>
        {title === 'Sent' && <MdSend className="text-dark-blue" size={15} />}
        {title === 'Received' && <MdSend className="text-dark-blue" size={15} style={{ transform: "rotate(180deg)" }} />}
    </Fragment>
)

const SmsTable = ({ title, data}) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="">
                    {/* <div className="flex justify-between items-center space-x-4 bg-gray-200 p-1">
                        <div
                            className="flex justify-start items-center space-x-4"
                            onClick={() => setShowList(!showList)}
                        >
                            <h4 className="text-dark-blue text-[14px] font-bold">
                                {title}
                            </h4>
                            <Icon title={title} />
                        </div>
                    </div> */}
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Full name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Phone
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Message
                                    </th>
                                    {title === 'Received' && (
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 py-4 text-left"
                                        >
                                            
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {title === 'Sent' && (
                                    <Fragment>
                                        {data?.map((item, index) => (
                                            <tr className="" key={`${item.id}`}>
                                                <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td
                                                    className="text-sm text-gray-900 font-light py-3 whitespace-nowrap"
                                                    value={item.sms_recipient}
                                                >
                                                    {item.sms_recipeints.first_name}{" "}
                                                    {item.sms_recipeints.last_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-3 whitespace-nowrap">
                                                    {item.recipient_phone}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-3 whitespace-nowrap">
                                                    {item.message.slice(0, 50)}....
                                                </td>
                                            </tr>
                                        ))}
                                    </Fragment>
                                )}
                                {title === 'Received' && (
                                    <Fragment>
                                        {data?.map((item, index) => (
                                            <tr className="" key={`${item.id}`}>
                                                <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td
                                                    className="text-sm text-gray-900 font-light py-2 whitespace-nowrap"
                                                    value={item.sms_sender}
                                                >
                                                    {item.sms_senders.first_name}{" "}
                                                    {item.sms_senders.last_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {item.senders_phone}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {item.message.slice(0, 50)}....
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    <button
                                                        type="button"
                                                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                                        onClick={() => {
                                                            console.log(item);
                                                            const data = {
                                                                first_name: item.sms_senders.first_name,
                                                                last_name: item.sms_senders.last_name,
                                                                id: item.sms_senders.id,
                                                                phone: item.senders_phone,
                                                            }
                                                            dispatch(setSmsReceiver({ data }))

                                                            setModals({ ...modals, showSendSmsModal: !modals?.showSendSmsModal })
                                                        }}
                                                    >
                                                        Reply
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </Fragment>
                                )}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div>
    )
}

export default SmsTable