/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineDeliveredProcedure } from 'react-icons/ai'

import { setCurrentSupplyPointBinCard } from '../../state/actions/actions'
import { getSupplyPointInventoryBinCards } from '../../state/actions/pharmacist.actions'
import Pagination from '../utils/Pagination'


const PharmacistDrugBinCardTable = ({ showList, setShowList, title }) => {
    const dispatch = useDispatch()

    const { supplypoint_inventory_update } = useSelector(state => state.root)
    const { supplypoint_inventory_drug_bincards } = useSelector(state => state.pharmacist)

    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        if (supplypoint_inventory_update?.id) {
            dispatch(
                getSupplyPointInventoryBinCards({
                    params: {
                        spoint: supplypoint_inventory_update?.supplypoint,
                        item: supplypoint_inventory_update?.supplypoint_inventoryitem
                    }
                })
            )
        }
    }, [supplypoint_inventory_update])

    useEffect(() => {
        if (supplypoint_inventory_drug_bincards?.length > 0) {
            dispatch(setCurrentSupplyPointBinCard({
                data: Object.keys(supplypoint_inventory_drug_bincards[0]).length > 0 ? supplypoint_inventory_drug_bincards[0] : null
            }))
        }
    }, [supplypoint_inventory_drug_bincards,])

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[18px] font-bold">
                            {supplypoint_inventory_update?.supplypoint_inventory?.inventoryitem_details?.title}
                        </h4>
                        <div className='flex justify-between items-center space-x-5'>
                            <AiOutlineDeliveredProcedure className="text-dark-blue" size={20} />
                            <span className="flex items-center justify-center bg-emerald-600 w-[80px] h-[22px] rounded-sm text-white text-[12px] p-1">{supplypoint_inventory_drug_bincards?.length > 0 ? supplypoint_inventory_drug_bincards[0]?.balance : 0} Left</span>
                        </div>
                    </div>
                    {showList && (
                        <table className="border rounded w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] py-4 text-left"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] py-4 text-left"
                                    >
                                        Receipt/Issued Voucher Number
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] py-4 text-left"
                                    >
                                        Received From/Issued To
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] py-4 text-left"
                                    >
                                        Quantity Received
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] py-4 text-left"
                                    >
                                        Quantity Issued
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] py-4 text-left"
                                    >
                                        Dispense Mode
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] py-4 text-left"
                                    >
                                        Balance
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] py-4 text-left"
                                    >
                                        Issued By
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr
                                        key={`${item.id}`}
                                        className={`${item.quantity_received > 0 && 'bg-yellow-50'} ${item.quantity_issued > 0 && 'bg-sky-50'}`}
                                    >
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-[11px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item?.created_at}
                                            {/* {item.created_at.split('T')[1].split('.')[0]} */}
                                        </td>
                                        <td className="text-[11px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.issued_voucher_number}
                                        </td>
                                        <td className="text-[11px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.received_voucher_number}
                                        </td>
                                        <td className="text-[11px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.quantity_received}
                                        </td>
                                        <td className="text-[11px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.quantity_issued}
                                        </td>
                                        <td className="text-[11px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.dispense_type}
                                        </td>
                                        <td className="text-[11px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.balance}
                                        </td>
                                        <td className="text-[11px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.supplypointinventory_author?.last_name} {item.supplypointinventory_author?.first_name}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}


                    <Pagination
                        data={supplypoint_inventory_drug_bincards}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PharmacistDrugBinCardTable