/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle, } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { createLaboratoryInvestigationCategory, updateLaboratoryInvestigationCategory } from '../../../state/actions/pharmacist.actions'
import ButtonLoader from '../../utils/ButtonLoader'


const PharmacistAddLabInvestigationCategoryModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { lab_investigation_types, laboratory_data, pharmacistLoading } = useSelector((state) => state.pharmacist)
    const [formData, setFormData] = useState({
        id: '',
        title: '',
        investigation_type: ''
    })

    useEffect(() => {
        setFormData({
            ...formData,
            id: laboratory_data?.id,
            title: laboratory_data?.title,
            investigation_type: laboratory_data?.investigation_type,
        })
    }, [laboratory_data])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData)
        if (!formData.title && !formData.investigation_type) return toast.warning('All fields are required!')
        dispatch(createLaboratoryInvestigationCategory(({ formData, toast, setModals, modals })))
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        if (!formData.title) return toast.warning('All fields are required!')
        dispatch(updateLaboratoryInvestigationCategory(({ formData, toast, setModals, modals })))
    }


    return (
        <div className="absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showPharmacistAddInvestigationCategoryModal ? 'text-dark-green' : 'text-sky-600'} text-[16px] font-bold`}>
                            {modals.showPharmacistAddInvestigationCategoryModal ? 'Add Investigation Category' : 'Update Investigation Category'}
                        </h4>
                        <FaTimesCircle
                            size={20}
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                modals.showPharmacistAddInvestigationCategoryModal && setModals({ ...modals, showPharmacistAddInvestigationCategoryModal: !modals.showPharmacistAddInvestigationCategoryModal })
                                modals.showPharmacistUpdateInvestigationCategoryModal && setModals({ ...modals, showPharmacistUpdateInvestigationCategoryModal: !modals.showPharmacistUpdateInvestigationCategoryModal })
                            }}
                        />

                    </div>

                    {modals.showPharmacistAddInvestigationCategoryModal && (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <select name="investigation_type" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option value={formData.investigation_category}>Select Investigation Type</option>
                                    {lab_investigation_types?.map((type, index) => (
                                        <option value={type.pkid}>{type.type_title}</option>
                                    ))}

                                </select>

                                <input
                                    type="title"
                                    name="title"
                                    placeholder="Title"

                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            <div className='mt-4 '>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >{!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}</button>
                            </div>

                        </form>
                    )}

                    {modals.showPharmacistUpdateInvestigationCategoryModal && (
                        <form onSubmit={handleUpdate}>
                            <div>
                                <select name="investigation_type" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option value={formData.investigation_category}>Select Investigation Type</option>
                                    {lab_investigation_types?.map((type, index) => (
                                        <option value={type.pkid}>{type.type_title}</option>
                                    ))}
                                </select>

                                <input
                                    type="title"
                                    name="title"
                                    placeholder="Title"
                                    value={formData?.title}
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            <div className='mt-4 '>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >{!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PharmacistAddLabInvestigationCategoryModal
