import { useEffect, useState } from "react"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"


import { useStateContext } from "../../state/context"
import { addGroupChatParticipant, createGroupConversation, getUsers, setCurrentChatConversation, setCurrentChatUser } from "../../state/actions/chat.actions"


const ChatAddGroupParticipantModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { users, current_chatuser } = useSelector(state => state.chat)

    const [formData, setFormData] = useState({ pkid: '', conversation_name: "", members: [], conversation_icon: '', conversation_icon_preview: '' })
    const [config, setConfig] = useState({ searching: false, filtered__users: [], existing_members: [] })

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    useEffect(() => {
        setFormData({ ...formData, pkid: current_chatuser?.pkid })
        setConfig({ ...config, existing_members: current_chatuser?.conversation_members })
    }, [current_chatuser])

    const handleFilter = (value) => {
        const none_groupmembers = users?.filter(user => {
            return !config.existing_members?.find(u => user.pkid === u)
        })

        const _filtered = none_groupmembers?.filter(user =>
            user.first_name.toLowerCase().includes(value)
            ||
            user.last_name.toLowerCase().includes(value)
            ||
            user.email.toLowerCase().includes(value)
        )
        setConfig({ ...config, filtered__users: _filtered })
    }

    const handleSearch = (user) => {
        setConfig({ ...config, searching: true })

        const index = formData.members.findIndex(id => id === user.pkid)

        if (index !== -1) {
            return setFormData({ ...formData, members: formData.members.filter(id => id !== user.pkid) })
        }

        return setFormData({ ...formData, members: [...formData.members, user.pkid] })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const content = {
            conversation_name: current_chatuser?.conversation_name,
            conversation_id: current_chatuser?.pkid,
            new_members: []
        }

        // const m = users?.filter(user => {
        //     return formData.members?.find(u => u === user.pkid)
        // }).forEach(user => {
        //     let id = Math.random()
        //     const name = user.last_name + ' ' + user.first_name
        //     content.new_members.push({ id, name })
        // })

        // console.log(content)

        dispatch(addGroupChatParticipant({ formData, modals, setModals, }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-violet-600 text-[20px] font-bold">
                            Add Group Participant
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showAddGroupParticipantModal: !modals.showAddGroupParticipantModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                name="user_search"
                                placeholder="Search/Add Users..."
                                className="border-b-2 border-b-gray-600 bg-gradient-to-l from-gray-50 to-gray-200 placeholder:italic w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onClick={() => setConfig({ ...config, searching: !config.searching })}
                                onChange={(e) => handleFilter(e.target.value.toLowerCase())}
                            />
                        </div>

                        {config.searching && (
                            <div className="bg-white shadow-lg max-h-[250px] w-full z-[2] overflow-y-auto users">
                                {config.filtered__users?.map((user => (
                                    <div
                                        key={`${user.id}`}
                                        className={`flex items-center space-x-4 border-b p-1 cursor-pointer group ${formData.members.find(id => id === user.pkid) && 'bg-red-200 border-b-white'}`}
                                        onClick={() => handleSearch(user)}
                                    >
                                        <img
                                            src={`http://localhost:8000${user?.user_profile?.profile_photo}`}
                                            className="w-[40px] h-[40px] rounded-[50%] group-hover:translate-x-2 duration-500 ease-in-out"
                                            alt=""
                                        />
                                        <div className="flex flex-col group-hover:translate-x-2 duration-500 ease-in-out">
                                            <p className="text-[12px] text-gray-600 font-bold">{user.last_name} {user.first_name}</p>
                                            <p className="text-[10px] text-gray-600">{user?.email}</p>
                                        </div>
                                    </div>
                                )))}
                            </div>
                        )}

                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-violet-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChatAddGroupParticipantModal
