import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FiMaximize } from 'react-icons/fi'
import { AiFillVideoCamera, AiFillPhone, AiFillSetting } from 'react-icons/ai'
import { BsMicFill, BsVolumeDownFill, BsFillPeopleFill } from 'react-icons/bs'

import VideoCallUser from './VideoCallUser'
import { useStateContext } from '../../state/context'
import { GroupCall } from './GroupCall'

const VideoCall = ({ users }) => {
    const { chatState, setChatState } = useStateContext()

    const [addParticipant, setAddParticipant] = useState(false)
    const { profile, user } = useSelector(state => state.auth)

    const { me, setMe, getUserMedia, setGetUserMedia, name, setName, callAccepted, myVideo, userVideo, callEnded, stream, call, answerCall, leaveCall, isStreamSet } = useStateContext()
    const personCalling = `${user?.data?.first_name} ${user?.data?.last_name}`

    useEffect(() => {
        setGetUserMedia(!getUserMedia)        // setMe(personCalling)

        return () => {
            setGetUserMedia(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

    }, [isStreamSet])
    return (
        <Fragment>
            {/* video UI */}
            {!chatState.showGroupCallUI && <Fragment>

                <div className={`${callAccepted ? 'flex-1' : 'flex-[0.7]'}`}>
                    <div className="flex justify-between items-center w-full">
                        <div className="flex items-center space-x-2">

                        </div>

                    </div>

                    <div className="flex relative mt-2">
                        <div className='flex-[0.5]'>
                            {/* <p>{user?.data?.first_name} {user?.data?.last_name}</p> */}
                            {stream && <video playsInline muted ref={myVideo} autoPlay />}
                        </div>

                        {callAccepted && !callEnded && (
                            <div className="flex-[0.5]">
                                {/* <p>{call?.name}</p> */}
                                <video playsInline ref={userVideo} autoPlay />

                            </div>
                        )}



                        <div className="absolute right-[-1em] top-2 flex flex-col space-y-4">


                        </div>

                        <div className="absolute bottom-5 justify-between  flex left-[5em] ">

                            <div>

                                {callAccepted && !callEnded && <div className='flex space-x-4 w-[230px] rounded-lg bg-slate-400 py-1 px-1'>
                                    <p className='mt-2 ml-2 text-[20px] italic'> End video call </p>
                                    <AiFillPhone
                                        size={40}
                                        className="cursor-pointer py-1 ml-4 text-white bg-red-600 rounded-md"
                                        style={{ 'transform': 'rotate(90deg)' }}
                                        onClick={() => {
                                            leaveCall()
                                        }}
                                    />

                                </div>}
                            </div>
                        </div>


                    </div>
                </div>



                {!callAccepted && <div className="flex-[0.3] mt-4">
                    <p className='text-lg font-semibold'>Select to call</p>
                    {users?.map((e, index) => (
                        <VideoCallUser
                            key={`${e.id}`}
                            user={e}
                            setName={setName}
                        />
                    ))}


                </div>}

            </Fragment>}

            {chatState.showGroupCallUI && (
                <Fragment>

                    <GroupCall />


                </Fragment>
            )}

        </Fragment>
    )
}

export default VideoCall