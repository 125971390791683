/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { uploadRadReportResult } from '../../../state/actions/pharmacist.actions'


const RadiologyUploadReportModal = () => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()
	const { set_radiology_generate_invoice, set_radiology_view_report } = useSelector((state) => state.root)

	const [formData, setFormData] = useState({
		id: '',
		investigation: '',
		image_report: {},
		photo_display: '',
		text_report: ''
	})

	useEffect(() => {
		setFormData({
			...formData, investigation: set_radiology_generate_invoice?.pkid,
		})
	}, [set_radiology_generate_invoice])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleImageChange = (e) => {
		setFormData({ ...formData, image_report: e.target.files[0], photo_display: URL.createObjectURL(e.target.files[0]) })
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		dispatch(uploadRadReportResult({ formData, toast, setModals, modals }))
	}

	return (
		<div>
			<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
				<div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
					<div className='flex flex-col space-y-5'>
						<div className='flex justify-between items-center'>
							{modals.showUploadReportModal && (
								<>
									<h4 className='text-dark-green text-[16px] font-medium'>
										Upload Reports - {set_radiology_view_report?.patient_details?.first_name} {set_radiology_view_report?.patient_details?.last_name}
									</h4>
									<FaTimesCircle
										size={20}
										className='text-dark-yellow cursor-pointer'
										onClick={() => {
											setModals({ ...modals, showUploadReportModal: !modals.showUploadReportModal })
										}}
									/>
								</>
							)}
							{modals.showRadiologyViewReportModal && (
								<div className='flex flex-col w-full'>
									<div className='flex justify-between'>
										<h4 className='text-sky-600 text-[16px] font-medium'>
											Radiology Investigation - {set_radiology_generate_invoice?.patient_details?.first_name} {set_radiology_generate_invoice?.patient_details?.last_name}
										</h4>
										<FaTimesCircle
											size={20}
											className='text-dark-yellow cursor-pointer'
											onClick={() => {
												setModals({ ...modals, showRadiologyViewReportModal: !modals.showRadiologyViewReportModal })
											}}
										/>
									</div>
									<div className='mt-2'>
										{modals.showRadiologyViewReportModal && (
											<>
												<div className='w-full border rounded'>
													<img src={`http://localhost:8000${set_radiology_view_report?.image_report}`} alt="" />
													<p className='text-[12px] font-semibold ml-2 my-2'>Investgiation Type: {set_radiology_view_report?.text_report}</p>
												</div>
												{/* <img src={`http://localhost:8000${set_radiology_view_report?.image}`} alt="" className='rounded-md' /> */}
											</>
										)}
									</div>
								</div>
							)}
						</div>
						{formData.photo_display.length > 0 && (
							<img src={formData.photo_display} alt="" srcset="" className='w-full h-[200px] border' />
						)}
						{modals.showUploadReportModal && (

							<form onSubmit={handleSubmit} >
								<div className=''>
									<input
										type='file'

										placeholder='Upload'
										name="image_report"


										className='border rounded h-[40px] w-full px-8 py-1 mt-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
										onChange={handleImageChange}

									/>
									<textarea className='w-full h-[200px] mt-1 border pt-4 pl-2 resize-none' name="text_report" id="" cols="30" rows="10" placeholder='Add scan text report '
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
									>
									</textarea>
								</div>
								<div className='mt-4 flex justify-center items-center'>
									<button
										type='submit'
										className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
									>
										Submit
									</button>
								</div>

							</form>

						)}


					</div>
				</div>
			</div>
		</div>
	)
}

export default RadiologyUploadReportModal