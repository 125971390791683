/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { createLaboratoryInvestigationType, updateLaboratoryInvestigationType } from '../../../state/actions/pharmacist.actions'
import ButtonLoader from '../../utils/ButtonLoader'


const PharmacistAddInvestigationTypeModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { laboratory_data, pharmacistLoading } = useSelector((state) => state.pharmacist)

    const [formData, setFormData] = useState({
        id: '',
        type_title: '',
    })

    useEffect(() => {
        setFormData({
            ...formData,
            id: laboratory_data?.id,
            type_title: laboratory_data?.type_title
        })
    }, [laboratory_data])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(formData)
        if (!formData.type_title) return toast.warning('All fields are required!')
        dispatch(createLaboratoryInvestigationType({ formData, toast, setModals, modals }))
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        if (!formData.type_title) return toast.warning('All fields are required!')
        dispatch(updateLaboratoryInvestigationType({ formData, toast, setModals: setModals, modals: modals }))
    }

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>
                            <h4 className={`${modals.showPharmacistAddInvestigationType ? 'text-dark-green' : 'text-sky-600'} text-[16px] font-medium`}>
                                {modals.showPharmacistAddInvestigationType ? 'Add Investigation Type' : 'Update Investigation Type'}
                            </h4>

                            <FaTimesCircle
                                size={20}
                                className='text-dark-yellow cursor-pointer'
                                // showLaboratoryUpdateInvestigationType
                                onClick={() => {
                                    modals.showPharmacistAddInvestigationType && setModals({ ...modals, showPharmacistAddInvestigationType: !modals.showPharmacistAddInvestigationType })
                                    modals.showPharmacistUpdateInvestigationType && setModals({ ...modals, showPharmacistUpdateInvestigationType: !modals.showPharmacistUpdateInvestigationType })
                                }}
                            />
                        </div>

                        {modals.showPharmacistAddInvestigationType && (
                            <form onSubmit={handleSubmit} >
                                <div className='flex'>
                                    <input
                                        type='text'
                                        name='type_title'
                                        placeholder='Name'
                                        className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className='mt-4 flex justify-center items-center'>
                                    <button
                                        type='submit'
                                        className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                    >
                                        {!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
                                    </button>
                                </div>

                            </form>
                        )}

                        {modals.showPharmacistUpdateInvestigationType && (
                            <form onSubmit={handleUpdate} >
                                <div className='flex'>
                                    <input
                                        type='text'
                                        name='type_title'
                                        placeholder='Name'
                                        className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                        value={formData.type_title}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className='mt-4 flex justify-center items-center'>
                                    <button
                                        type='submit'
                                        className='inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                    >
                                        {!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
                                    </button>
                                </div>

                            </form>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PharmacistAddInvestigationTypeModal