import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { GoMail } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonLoader } from '../../components'
import { Logo, ArtResetPassword } from '../../assets'
import { requestPasswordReset } from '../../state/actions/auth.actions'


const ResetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { pharmacy_config, authLoading } = useSelector(state => state.auth)
    const [formData, setFormData] = useState({ email: '' })

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])


    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!formData.email) return toast.warning('Email is required!')

        dispatch(requestPasswordReset({ params: { email: formData.email }, toast, navigate }))
    }


    return (
        <div className="main__login relative min-h-screen text-gray-800 antialiased px-4 py-6 flex flex-col justify-center sm:py-12 font-poppins">
            <div className="absolute right-[220px] top-[40px]">
                <div className="w-[100px] h-[100px] bg-white  bg-opacity-50 backdrop-blur-sm rounded-full"></div>
            </div>
            <div className="absolute right-[250px] top-[60px]">
                <div className="w-[100px] h-[100px] bg-white bg-opacity-50 backdrop-blur-sm rounded-full"></div>
            </div>
            <div className="relative py-3 sm:w-[800px] mx-auto text-center sm:auto">

                <div className="mt-4 bg-white shadow-md">
                    <div className="flex justify-between items-center">
                        <div className="px-8 py-[7em] w-[400px] border-r-2 border-light-gray">
                            <img src={ArtResetPassword} alt="art__login" />
                        </div>

                        <div className="px-8 w-[400px]">
                            <div className="space-y-10 mb-4">
                                <div className="">
                                    <img
                                        src={pharmacy_config ? `http://localhost:8000${pharmacy_config?.logo}` : Logo}
                                        alt="logo"
                                        className='w-[40px] h-[40px] '
                                    />
                                </div>

                                <div className="flex flex-col">
                                    <h4 className="text-center text-[25px] font-medium">
                                        Reset Password
                                    </h4>
                                    <p className="text-gray-400 text-[12px]">Enter the email associated with your account and we’ll send an email with instructions to reset your password.</p>
                                </div>
                            </div>

                            <form
                                className="space-y-3"
                                onSubmit={handleSubmit}
                            >
                                <div className="relative">
                                    <GoMail
                                        size={25}
                                        color={'rgba(40, 51, 66, 0.8)'}
                                        className='absolute top-[16px] left-3'
                                    />
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        className="border rounded w-full h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="flex flex-col justify-between items-center space-y-5">
                                    <button
                                        type="submit"
                                        className="mt-4 w-[175px] bg-dark-yellow text-white py-2 px-6 rounded-md hover:bg-dark-yellow"
                                    >
                                        {!authLoading ? (
                                            'Reset Password'
                                        ) : (
                                            <ButtonLoader />
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword