/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import { customer__payment__methods, payment__methods } from '../../../data'
import { useStateContext } from '../../../state/context'
import { createAdminPayStackConfig, createFlutterWaveConfig, createPayStackConfig, setCurrentPharmacyInvoice, updateLabInvoice, updateLabInvoiceViaCash, updateLabInvoiceViaPOSTransaction, updatePharmacyInvoiceViaCash, updatePharmacyInvoiceViaPOSTransaction } from '../../../state/actions/collection.actions'
import { getPlanRenewalDetails, initializeZuumPayCredentials, resetPlanUpdateDetails } from '../../../state/actions/auth.actions'


const PharmacistRenewSubscriptionPlanModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { access, data } = useSelector(state => state.auth.user)
    const { plan_upgrade_details, pharmacy_config } = useSelector(state => state.auth)
    const {
        paystack_config, collectionLoading,
        flutterwave_config
    } = useSelector(state => state.collection)
    const { subscription_data } = useSelector(state => state.pharmacist)

    const [formData, setFormData] = useState({ payment_method: '', email: '', amount: 0, phone: '', fullname: '' })

    useEffect(() => {
        dispatch(resetPlanUpdateDetails({ data: null }))
    }, [])

    useEffect(() => {
        dispatch(getPlanRenewalDetails({ params: { title: subscription_data?.title } }))
    }, [subscription_data])

    useEffect(() => {
        setFormData({
            ...formData,
            email: data?.email,
            amount: plan_upgrade_details?.price,
            phone: '09030391123',
            fullname: data?.last_name.concat(' ').concat(data?.first_name)
        })
    }, [data, plan_upgrade_details])

    useEffect(() => {
        const _data = {
            ...subscription_data,
            price: plan_upgrade_details?.price,
            amount_paid: plan_upgrade_details?.price,
        }
        if (paystack_config?.authorization_url) {
            const config = {
                ...formData,
                amount: plan_upgrade_details?.price + (plan_upgrade_details?.price * 0.075),
                subtitle: subscription_data?.title,
                organization: pharmacy_config?.name,
                payment_title: 'pms_renew_subscription_payment',
                payment_author: data?.pkid,
                reference: paystack_config?.reference,
                authorization: access,
                api_callback_url: '/pharmacist/application_plan/'
            }
            dispatch(initializeZuumPayCredentials({ formData: { ...config }, paystack_url: paystack_config?.authorization_url }))
            // localStorage.setItem('payment__invoice', JSON.stringify({ ..._data, type: 'subscription__payment' }))

            // window.location.href = paystack_config?.authorization_url
        }
        if (flutterwave_config?.link) {
            localStorage.setItem('payment__invoice', JSON.stringify({ ..._data, type: 'subscription__payment' }))

            window.location.href = flutterwave_config?.link
        }
    }, [paystack_config, flutterwave_config])

    const handleChange = (name, e) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (formData.payment_method === 'paystack') {
            dispatch(createAdminPayStackConfig({ formData }))
        }

        if (formData.payment_method === 'flutterwave') {
            // dispatch(createFlutterWaveConfig({ formData }))
        }
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div
                className="absolute right-[4em] top-0 text-[60px] font-medium text-white cursor-pointer"
                onClick={() => {
                    dispatch(resetPlanUpdateDetails({ data: null }))
                    setModals({ ...modals, showRenewSubscriptionPlanModal: !modals.showRenewSubscriptionPlanModal })
                }}
            >
                x
            </div>

            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-500 text-[20px] font-bold'>
                            {formData.payment_method ? formData.payment_method[0].toUpperCase().concat(formData.payment_method.slice(1, formData.payment_method.length)) : 'Payment Method'}
                        </h4>

                    </div>
                    <div className="flex flex-col items-end">
                        <div className="grid grid-cols-2">
                            <span className="text-gray-800 px-[10px] text-[10px] font-bold py-[1px] rounded">Plan:</span>
                            <span className="text-gray-600 px-[10px] text-[10px] font-medium py-[1px] underline underline-offset-1 rounded">{subscription_data?.title}</span>
                            <span className="text-gray-800 px-[10px] text-[10px] font-bold py-[1px] rounded">Price:</span>
                            <span className="text-gray-600 px-[10px] text-[10px] font-medium py-[1px] rounded">&#8358; {subscription_data?.renewal_price}</span>
                            <span className="text-gray-800 px-[10px] text-[10px] font-bold py-[1px] rounded">Amount:</span>
                            <span className="text-gray-600 px-[10px] text-[10px] font-medium py-[1px] rounded">&#8358; {plan_upgrade_details?.price + (plan_upgrade_details?.price * 0.075)}</span>
                        </div>
                    </div>


                    {formData.payment_method !== 'cash' && formData.payment_method !== 'pos' && (
                        <form onSubmit={handleSubmit}>
                            <div className='space-y-2'>
                                <div className="relative">
                                    <select
                                        className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                        name="payment_method"
                                        onChange={(e) => handleChange(e.target.name, e)}
                                    >
                                        <option>Payment Method...</option>
                                        {customer__payment__methods?.map((type, index) => (
                                            <option
                                                key={`${type.id}-${index}`}
                                                value={type.value}
                                            >
                                                {type.title}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-start mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-full px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!collectionLoading ? (
                                        'Submit'
                                    ) : (
                                        <svg className="flex justify-center w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                            </path>
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}

                    <div className="flex justify-end w-[300px] font-medium">
                        <p className="text-[12px] italic">Note that this payment includes an additional 7.5% VAT fee to it. Thank you</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PharmacistRenewSubscriptionPlanModal