/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PharmacistRadiologistReportsTable from '../../components/tables/PharmacistRadiologistReportsTable'
import PharmacistLaboratoristReportsTable from '../../components/tables/PharmacistLaboratoristReportsTable'
import { getLabInvestigationReports, getRadInvestigationReports } from '../../state/actions/pharmacist.actions'


const PharmacistInvestigations = () => {
    const dispatch = useDispatch()

    const {
        lab_investigation_reports, rad_investigation_reports, lab_report_invoice, rad_report_invoice,
        lab_report_upload, rad_report_upload
    } = useSelector(state => state.pharmacist)

    const [showLabInvestigations, setShowLabInvestigations] = useState(true)
    const [showRadiologyInvestigations, setShowRadiologyInvestigations] = useState(false)

    useEffect(() => {
        dispatch(getLabInvestigationReports())
        dispatch(getRadInvestigationReports())
    }, [lab_report_invoice, rad_report_invoice, lab_report_upload, rad_report_upload])

    return (
        <Fragment>
            <div className="">
                <div className="flex justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
                    <div className='space-x-5'>
                        <button
                            type="button"
                            className={`${showLabInvestigations ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => {
                                setShowLabInvestigations(true)
                                setShowRadiologyInvestigations(false)
                            }}
                        >
                            Lab investigation
                        </button>
                        <button
                            type="button"
                            className={`${showRadiologyInvestigations ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => {
                                setShowLabInvestigations(false)
                                setShowRadiologyInvestigations(true)
                            }}
                        >
                            Radiology investigation
                        </button>
                    </div>
                </div>

                {showLabInvestigations && (
                    <PharmacistLaboratoristReportsTable
                        data={lab_investigation_reports}
                    />
                )}

                {showRadiologyInvestigations && (
                    <PharmacistRadiologistReportsTable
                        data={rad_investigation_reports}
                    />
                )}
            </div>


        </Fragment>
    )
}

export default PharmacistInvestigations