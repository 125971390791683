import { createAsyncThunk } from '@reduxjs/toolkit';


import {
    getDirectorates, createAdirectorate,
    getDepartments, createAdepartment,
    createAnEmployee, getEmployees,
    getBillings, createAbill, getWards,
    createAWard, getSentSMS, getReceivedSMS,
    sendSMS, sendEmailMessage, getReceivedEmails,
    getSentEmails, updateADirectorate, deleteADirectorate,
    updateADepartment, deleteADepartment, updateAnEmployee,
    deleteAEmployee, updateABilling, deleteABilling, updateAWard,
    deleteAWard, addBedSpace, getBedspaces, addSmsConfig,
    fetchSmsConfig, patchSmsConfig, addHospitalConfig,
    fetchHospitalConfig, patchHospitalConfig, fetchWardBedspaces,
    patchBedSpace, deleteABedSpace, fetchUsersByDepartment, createSchedule,
    getSchedules, updateSchedule, deleteSchedule, sendRandomEmailMessage, sendRandomSMS, generateFlutterwaveDetails
} from '../routes/admin.routes';


export const getAllDirectorates = createAsyncThunk(
    'admin/getAllDirectorate',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getDirectorates(token)

            return data
        } catch (error) {
            // console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createDirectorate = createAsyncThunk(
    'admin/createADirectorate',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await createAdirectorate(formData, token)

            toast.success("Directorate created successfully")
            setModals({ ...modals, showAddDirectorateModal: !modals.showAddDirectorateModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on creating directorate")
            return rejectWithValue(null)
        }
    }
)

export const updateDirectorate = createAsyncThunk(
    'admin/updateDirectorate',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateADirectorate(formData, token)

            toast.success("Directorate updated successfully")
            setModals({ ...modals, showUpdateDirectorateModal: !modals.showUpdateDirectorateModal })


            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on updating directorate")
            return rejectWithValue(null)
        }
    }
)

export const deleteDirectorate = createAsyncThunk(
    'admin/deleteDirectorate',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await deleteADirectorate(id, token)

            toast.success("Directorate deleted successfully")
            setModals({ ...modals, showDeleteDirectorateModal: !modals.showDeleteDirectorateModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on deleting a directorate")
            return rejectWithValue(null)
        }
    }
)

export const getAllDepartments = createAsyncThunk(
    'admin/getAllDepartments',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getDepartments(token)


            return data
        } catch (error) {
            // console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createDepartment = createAsyncThunk(
    'admin/createADepartment',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await createAdepartment(formData, token)

            toast.success("Department created successfully")
            setModals({ ...modals, showAddDepartmentModal: !modals.showAddDepartmentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on creating department")
            return rejectWithValue(null)
        }
    }
)


export const updateDepartment = createAsyncThunk(
    'admin/updateDepartment',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateADepartment(formData, token)

            toast.success("Department updated successfully")
            setModals({ ...modals, showUpdateDepartmentModal: !modals.showUpdateDepartmentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on updating department")
            return rejectWithValue(null)
        }
    }
)

export const deleteDepartment = createAsyncThunk(
    'admin/deleteDepartment',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await deleteADepartment(id, token)

            toast.success("Department deleted successfully")
            setModals({ ...modals, showDeleteDepartmentModal: !modals.showDeleteDepartmentModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on deleting a department")
            return rejectWithValue(null)
        }
    }
)

export const createEmployee = createAsyncThunk(
    'admin/createAnEmployee',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await createAnEmployee(formData, token)

            toast.success("Employee created successfully")
            setModals({ ...modals, showAddEmployeeModal: !modals.showAddEmployeeModal })

            return data
        } catch (error) {
            console.log(error)
            if (error.response.data.email) {
                toast.warning(error.response.data.email[0])
            } else {
                toast.warning("An error occurred on creating employee")
            }
            return rejectWithValue(null)
        }
    }
)

export const updateEmployee = createAsyncThunk(
    'admin/updateEmployee',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateAnEmployee(formData, token)

            toast.success("Employee updated successfully")
            setModals({ ...modals, showUpdateEmployeeModal: !modals.showUpdateEmployeeModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on updating employee")
            return rejectWithValue(null)
        }
    }
)

export const deleteEmployee = createAsyncThunk(
    'admin/deleteEmployee',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await deleteAEmployee(id, token)

            toast.success("Employee deleted successfully")
            setModals({ ...modals, showDeleteEmployeeModal: !modals.showDeleteEmployeeModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on deleting a employee")
            return rejectWithValue(null)
        }
    }
)

export const getAllEmployees = createAsyncThunk(
    'admin/getEmployees',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getEmployees(token)

            return data
        } catch (error) {
            // console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const getAllBillings = createAsyncThunk(
    'admin/getBillings',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getBillings(token)

            return data
        } catch (error) {
            // console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const createBilling = createAsyncThunk(
    'admin/createBillings',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await createAbill(formData, token)

            toast.success("Billing created successfully")
            setModals({ ...modals, showAddBillingModal: !modals.showAddBillingModal })

            return data
        } catch (error) {
            // console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const updateBilling = createAsyncThunk(
    'admin/updateBilling',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            console.log(formData);
            const { data } = await updateABilling(formData, token)

            toast.success("Billing updated successfully")
            setModals({ ...modals, showUpdateBillingModal: !modals.showUpdateBillingModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on updating billing")
            return rejectWithValue(null)
        }
    }
)


export const deleteBilling = createAsyncThunk(
    'admin/deleteBilling',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await deleteABilling(id, token)

            toast.success("Billing deleted successfully")
            setModals({ ...modals, showDeleteBillingModal: !modals.showDeleteBillingModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on deleting a billing type")
            return rejectWithValue(null)
        }
    }
)

export const getAllWards = createAsyncThunk(
    'admin/getWards',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getWards(token)

            return data
        } catch (error) {
            // console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const getWardBedSpaces = createAsyncThunk(
    'admin/getWardBedSpaces',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            console.log(formData);
            const { data } = await fetchWardBedspaces(formData, token)

            console.log(data)
            return data
        } catch (error) {
            // console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const createWard = createAsyncThunk(
    'admin/createWard',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await createAWard(formData, token)

            toast.success("Ward Added successfully")
            setModals({ ...modals, showCreateWardModal: !modals.showCreateWardModal })

            return data
        } catch (error) {
            // console.log(error.response)
            // toast.warning("An error occurred on creating Billing")
            return rejectWithValue(null)
        }
    }
)


export const updateWard = createAsyncThunk(
    'admin/updateWard',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateAWard(formData, token)

            toast.success("Ward updated successfully")
            setModals({ ...modals, showUpdateWardModal: !modals.showUpdateWardModal })
            console.log(data);
            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on updating ward")
            return rejectWithValue(null)
        }
    }
)


export const deleteWard = createAsyncThunk(
    'admin/deleteWard',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await deleteAWard(id, token)

            toast.success("Ward deleted successfully")
            setModals({ ...modals, showDeleteWardModal: !modals.showDeleteWardModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on deleting a ward")
            return rejectWithValue(null)
        }
    }
)

export const getAllBedspaces = createAsyncThunk(
    'admin/getAllBedspaces',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getBedspaces(token)

            return data
        } catch (error) {
            return rejectWithValue(null)
        }
    }
)

export const addBedSpaceToWard = createAsyncThunk(
    'admin/addBedSpace',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData);
            const { data } = await addBedSpace(formData, token)

            toast.success("Bedspace added successfully")
            setModals({ ...modals, showAddBedSpaceModal: !modals.showAddBedSpaceModal })

            return data
        } catch (error) {
            console.log(error)
            if (error.response.data.message) toast.warning(error.response.data.message)
            // toast.warning("An error occurred on creating Billing")
            return rejectWithValue(null)
        }
    }
)


export const updateBedSpace = createAsyncThunk(
    'admin/updateBedSpaceToWard',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData);
            const { data } = await patchBedSpace(formData, token)

            toast.success("Bedspace updated successfully")
            setModals({ ...modals, showUpdateBedSpaceModal: !modals.showUpdateBedSpaceModal })
            console.log(data);
            return data
        } catch (error) {
            console.log(error)
            if (error.response.data.message) return toast.warning(error.response.data.message)
            toast.warning("An error occurred on updating bedspace")
            return rejectWithValue(null)
        }
    }
)

export const deleteBedSpace = createAsyncThunk(
    'admin/deleteBedSpace',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deleteABedSpace(id, token)
            console.log(data);
            toast.success("Bedspace deleted successfully")
            setModals({ ...modals, showDeleteBedSpaceModal: !modals.showDeleteBedSpaceModal })

            return id
        } catch (error) {
            console.log(error)
            if (error.response.data.message) return toast.warning(error.response.data.message)
            toast.warning("An error occurred on deleting bedspace")
            return rejectWithValue(null)
        }
    }
)


export const getAllSentSMS = createAsyncThunk(
    'admin/getAllSentMessages',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getSentSMS(token)

            return data
        } catch (error) {
            return rejectWithValue(null)
        }
    }
)

export const getAllReceivedSMS = createAsyncThunk(
    'admin/getAllReceivedMessages',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getReceivedSMS(token)

            return data
        } catch (error) {
            return rejectWithValue(null)
        }
    }
)

export const sendAnSMS = createAsyncThunk(
    'admin/sendSMS',
    async ({ formData, toast, recipient_id, setModals, modals, socket, dispatch, createUserNotification }, { rejectWithValue, getState }) => {
        try {
            // const { socket } = useStateContext()
            const state = getState()
            const token = state.auth.user.access
            const { data } = await sendSMS(formData, token, recipient_id)
            const _data = {
                notification_type: "message notification",
                notification_title: "Sms ",
                receiver: formData['receiver'],
                notification_recipient: formData['notification_recipient'],
                notification: formData['message'],
                sender: formData['sender'],
                id: formData['receiver']
            }

            socket.emit("send_sms", _data, () => { })
            dispatch(createUserNotification({ formData: _data }))

            toast.success("Sms sent successfully")
            setModals({ ...modals, showSendSmsModal: !modals.showSendSmsModal })

            return data
        } catch (error) {
            console.log(error)
            if (error.response.data.message) return toast.warning(error.response.data.message)
            toast.warning("An error occurred proccessing your message")
            return rejectWithValue(null)
        }
    }
)

export const sendAnEmailMessage = createAsyncThunk(
    'admin/sendEmail',
    async ({ formData, toast, setModals, modals, dispatch, createUserNotification, socket }, { rejectWithValue, getState }) => {
        try {
            // const { socket } = useStateContext()
            const state = getState()
            const token = state.auth.user.access
            const _data = {
                notification_type: "email notification",
                notification_title: formData['subject'],
                receiver: formData['receiver'],
                notification_recipient: formData['notification_recipient'],
                notification: formData['message'],
                sender: formData['sender'],
                id: formData['patient_id']
            }

            const { data } = await sendEmailMessage(formData, token)

            toast.success("Email sent successfully")
            console.log(_data)


            socket.emit("send_email", _data, () => { })


            dispatch(createUserNotification({ formData: _data }))


            setModals({ ...modals, showSendMailModal: !modals.showSendMailModal })


            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on sending your mail.")
            return rejectWithValue(null)
        }
    }
)

export const getAllSentEmails = createAsyncThunk(
    'admin/getAllEmailMessages',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getSentEmails(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const getAllReceivedEmails = createAsyncThunk(
    'admin/getAllReceivedEmails',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await getReceivedEmails(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getSmsConfig = createAsyncThunk(
    'admin/getSmsConfig',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSmsConfig(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createSmsConfig = createAsyncThunk(
    'admin/createSmsConfig',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData);
            const { data } = await addSmsConfig(formData, token)

            toast.success("Sms config added successfully")

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on configuring sms config data.")
            return rejectWithValue(null)
        }
    }
)

export const updateSmsConfig = createAsyncThunk(
    'admin/updateSmsConfig',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData);
            const { data } = await patchSmsConfig(formData, token)

            toast.success("Sms config updated successfully")

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on updating sms config data.")
            return rejectWithValue(null)
        }
    }
)

export const createHospitalConfig = createAsyncThunk(
    'admin/createHospitalConfig',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData);
            const { data } = await addHospitalConfig(formData, token)

            toast.success("Hospital config added successfully")
            console.log(data)
            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on added hospital config data.")
            return rejectWithValue(null)
        }
    }
)

export const getHospitalConfig = createAsyncThunk(
    'admin/getHospitalConfig',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchHospitalConfig(token)

            console.log(data)
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const updateHospitalConfig = createAsyncThunk(
    'admin/updateHospitalConfig',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchHospitalConfig(formData, token)

            toast.success("Hospital config updated successfully")
            console.log(data)
            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occured while updating hospital config.")
            return rejectWithValue(null)
        }
    }
)

export const fetchAllUsersByDepartment = createAsyncThunk(
    'admin/fetchUsersByDepartment',
    async ({ supply_point }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchUsersByDepartment(supply_point, token)

            console.log(data)
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const setUsersByDeparmentToNull = createAsyncThunk(
    'admin/setUsersByDeparmentToNull',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const createASchedule = createAsyncThunk(
    'admin/createSchedule',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await createSchedule(formData, token)

            toast.success("Schedule created successfully")
            setModals({ ...modals, showCreateScheduleModal: !modals.showCreateScheduleModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while creating schedule.")
            return rejectWithValue(null)
        }
    }
)


export const getAllSchedules = createAsyncThunk(
    'admin/getSchedules',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getSchedules(token)
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const updateASchedule = createAsyncThunk(
    'admin/updateASchedule',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await updateSchedule(formData, token)

            toast.success("Schedule updated successfully")
            setModals({ ...modals, showEditAndDeleteScheduleModal: !modals.showEditAndDeleteScheduleModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while updating schedule.")
            return rejectWithValue(null)
        }
    }
)

export const deleteASchedule = createAsyncThunk(
    'admin/deleteASchedule',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await deleteSchedule(id, token)

            toast.success("Schedule deleted successfully")
            setModals({ ...modals, showDeleteScheduleModal: !modals.showDeleteScheduleModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while deleting schedule.")
            return rejectWithValue(null)
        }
    }
)



export const sendARandomEmailMessage = createAsyncThunk(
    'admin/sendRandomEmailMessage',
    async ({ formData, dispatch, createUserNotification }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const _data = {
                notification_type: `${formData.notification_type}`,
                notification_title: `${formData.notification_title}`,
                receiver: formData['receiver'],
                notification_recipient: formData['notification_recipient'],
                notification: formData['notification'],
                sender: formData['sender'],
                id: formData['id'],
            }
            const { data } = await sendRandomEmailMessage(formData, token)
            dispatch(createUserNotification({ formData: _data }))

            return data
        } catch (error) {
            return rejectWithValue(null)
        }
    }
)


export const sendARandomSMS = createAsyncThunk(
    'admin/sendARandomSMS',
    async ({ formData, recipient_id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await sendRandomSMS(formData, token, recipient_id)
            return data
        } catch (error) {
            return rejectWithValue(null)
        }
    }
)


export const createFlutterwaveConfig = createAsyncThunk(
    'admin/createFlutterwaveConfig',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            
            const { data } = await generateFlutterwaveDetails(formData, token)

            toast.success('Flutterwave config generated successfully')
            
            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occurred while generating flutterwave config')
            return rejectWithValue(null)
        }
    }
)