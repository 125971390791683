import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import { useStateContext } from '../../state/context'
import { setBilling } from '../../state/actions/actions'
import { setCurrentLaboratoryData } from '../../state/actions/pharmacist.actions'


const PharmacistRadInvestigationCategoriesTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [showList, setShowList] = useState(false)
    const [currentData, setCurrentData] = useState([])


    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    <Fragment>
                        <div
                            className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 p-1"
                            onClick={() => setShowList(!showList)}
                        >
                            <h4 className="text-dark-blue text-[14px] font-bold">
                                Investigation Categories
                            </h4>
                            {/* <CgPill className="text-dark-blue" size={20} /> */}
                        </div>
                        {showList && (
                            <table className="border-b border-l border-r rounded w-full cursor-default">
                                <thead className="">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 py-4 text-left"
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 py-4 text-left"
                                        >
                                            Investigation Type
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 py-4 text-left"
                                        >

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((category, index) => (
                                        <tr key={`${category.id}`}>
                                            <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                {index + 1}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {category.title}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {category?.investigations_type?.title}
                                            </td>
                                            <td className="flex space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                <FaPencilAlt
                                                    size={20}
                                                    className='text-green-600 cursor-pointer'
                                                    onClick={() => {
                                                        dispatch(setCurrentLaboratoryData({ data: category }))
                                                        setModals({ ...modals, showPharmacistUpdateRadiologyInvestigationCategoryModal: !modals.showPharmacistUpdateRadiologyInvestigationCategoryModal })
                                                    }}
                                                />
                                                <FaTrash
                                                    size={20}
                                                    className="text-orange-400 cursor-pointer"
                                                    onClick={() => {
                                                        dispatch(setCurrentLaboratoryData({ data: category }))
                                                        setModals({ ...modals, showPharmacistDeleteRadiologyInvestigationCategoryModal: !modals.showPharmacistDeleteRadiologyInvestigationCategoryModal })
                                                    }}
                                                />
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default PharmacistRadInvestigationCategoriesTable
