/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CustomerOrdersTable, PageTitle } from "../../components"
import { getCustomerOrders } from "../../state/actions/pharmacist.actions"


const CustomerOrders = () => {
    const dispatch = useDispatch()

    const { customer_orders } = useSelector(state => state.pharmacist)
    const [filterState, setFilterState] = useState({ search: '', orders: [] })

    const handleFilter = () => {
        const filtered = customer_orders?.filter(item => {
            return item.order_id.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, orders: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, orders: customer_orders })
        }
    }

    useEffect(() => {
        dispatch(getCustomerOrders())
    }, [dispatch])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    useEffect(() => {
        setFilterState({ ...filterState, orders: customer_orders })
    }, [customer_orders?.length])

    return (
        <div>
            <PageTitle type={'customer-orders'} />

            <div>
                <CustomerOrdersTable
                    data={customer_orders}
                />
            </div>
        </div>
    )
}

export default CustomerOrders