/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'
import Lottie from 'lottie-react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import loadingJSON from '../../assets/animations/loading__2.json'
import { activateAccount, getPharmacyConfig } from '../../state/actions/auth.actions'


const ActivateAccount = () => {
    const { token } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const options = {
        loop: true,
        animationData: loadingJSON,
    }

    const { pharmacy_config } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(getPharmacyConfig())
    })

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])

    useEffect(() => {
        setTimeout(() => {
            dispatch(activateAccount({ token, navigate, toast }))
        }, 3000)
    }, [token])
    

    return (
        <div className='h-screen flex justify-center items-center'>
            <Lottie options={options} width={600} height={600} />
        </div>
    )
}

export default ActivateAccount