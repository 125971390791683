/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { getBillings, createLabInvestigationReportInvoice } from '../../../state/actions/pharmacist.actions'


const LaboratoryGenerateInvoiceModal = () => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()

    const { set_laboratory_reports } = useSelector((state) => state.root)
    const { billings } = useSelector((state) => state.pharmacist)

    const [formData, setFormData] = useState({
        billing: '',
        amount: '',
        patient: '',
        investigation: ''
    })

    useEffect(() => {
        dispatch(getBillings())
    }, [])

    useEffect(() => {
        setFormData({
            ...formData,
            patient: set_laboratory_reports?.patient,
            investigation: set_laboratory_reports?.pkid
        })
    }, [set_laboratory_reports])

    useEffect(() => {
        if (formData.billing > 0) {
            const index = billings.findIndex(billing => billing.pkid === parseInt(formData.billing))

            setFormData({
                ...formData,
                amount: billings[index]?.price
            })
        }
    }, [formData.billing])


    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)

        dispatch(createLabInvestigationReportInvoice({ formData, toast, setModals, modals }))
    }

    return (
        <div className="absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[16px] font-medium'>
                            Generate Invoice - {`${set_laboratory_reports?.patient_details?.first_name} ${set_laboratory_reports?.patient_details?.last_name}`}
                            <br />
                            <span className='text-slate-600 text-[12px]'>Test Category:  {`${set_laboratory_reports?.investigation_details?.name}`}</span>
                        </h4>
                        <FaTimesCircle
                            size={20}
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                setModals({ ...modals, showLaboratoryGenerateInvoiceModal: !modals.showLaboratoryGenerateInvoiceModal })
                            }}
                        />
                    </div>

                    <form onSubmit={handleSubmit} >
                        <div className="w-full mb-6 md:mb-0">
                            <div className="relative w-full">
                                <select
                                    id="grid-state"
                                    className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    name='billing'
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option>Billing Type</option>
                                    {billings?.map((billings, index) => (
                                        <option
                                            key={index}
                                            value={billings.pkid}
                                        >{billings.name}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        </div>

                        <div>
                            <input
                                type="number"
                                name="amount"
                                placeholder="Price"
                                value={formData?.amount}
                                disabled
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                            // onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>

                        <div className='mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Generate</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default LaboratoryGenerateInvoiceModal