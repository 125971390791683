/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { GifWallet } from '../assets'
import { getPharmacyConfig } from '../state/actions/auth.actions'
import { updateInvestigationInvoice, updateLabInvoice, updateMultipleInventoryInvoices, updateMultipleInvestigationInvoices, updatePharmacyInvoice, updateProvisionInvoice, updateRadiologyInvoice, updateRecordsInvoice, verifyPayStackPayment } from '../state/actions/collection.actions'


const CollectionsVerifyPayStackPayment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const ref = window.location.href.split('reference=')[1]

    const { pharmacy_config } = useSelector(state => state.auth)
    const { paystack_payment_status } = useSelector(state => state.collection)

    const [ref, setRef] = useState(null)

    useEffect(() => {
        dispatch(getPharmacyConfig())
        const ref = window.location.href.split('reference=')[1]
        setRef(ref)
    }, [])

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])

    useEffect(() => {
        if (ref) {
            dispatch(verifyPayStackPayment({ params: { ref } }))
        }
    }, [ref])

    useEffect(() => {
        const payment__invoice = localStorage.getItem('payment__invoice') ? JSON.parse(localStorage.getItem('payment__invoice')) : null

        if (payment__invoice.type === 'laboratory_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            dispatch(updateLabInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'pharmacy_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            dispatch(updatePharmacyInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'investigation_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.pkid,
                mode_of_payment: paystack_payment_status?.channel,
                date_paid: paystack_payment_status?.paid_at?.slice(0, 10),
                payment_reference: ref,
            }
            console.log(formData)
            dispatch(updateInvestigationInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'provision_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                invoice_id: payment__invoice?.pkid,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            dispatch(updateProvisionInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'radiology_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            dispatch(updateRadiologyInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'records_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            dispatch(updateRecordsInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'pharmacy_multiple_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                invoices: payment__invoice?.invoices,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            dispatch(updateMultipleInventoryInvoices({ formData, toast, navigate }))
        }


        if (payment__invoice.type === 'investigation_multiple_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                invoices: payment__invoice?.invoices,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            dispatch(updateMultipleInvestigationInvoices({ formData, toast, navigate }))
        }


        // if (payment__invoice.type === 'subscription__payment' && paystack_payment_status?.status === 'success') {
        //     const formData = {
        //         title: payment__invoice?.title,
        //         price: payment__invoice?.price,
        //         amount_paid: payment__invoice?.amount_paid,
        //         mode_of_payment: paystack_payment_status?.channel,
        //         payment_reference: ref,
        //     }
        //     console.log(payment__invoice)
        //     dispatch(updateSubscriptionPlan({ formData, toast, navigate }))
        // }
    }, [paystack_payment_status])

    return (
        <div className='h-screen flex justify-center items-center'>
            <img
                src={GifWallet}
                alt='wallet'
                className='w-1/2'
            />
        </div>
    )
}

export default CollectionsVerifyPayStackPayment