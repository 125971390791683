/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { updatePharmacyConfig } from '../../../state/actions/auth.actions'
import { AiOutlineCloudUpload } from 'react-icons/ai'


const PharmacistUpdatePharmacyInfoModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { pharmacy_config } = useSelector(state => state.auth)

    const logoRef = useRef(null)
    const [image, setImage] = useState('')
    const [formData, setFormData] = useState({ config: '', name: '', phone: '', sms_tag: '', email: '', logo: '', address: '' })

    const urlToObject = async (image) => {
        const response = await fetch(image)
        const blob = await response.blob()
        // console.log(blob)
        const file = new File([blob], `image.${blob.type}`, { type: blob.type });

        await setImage(file)
        // console.log(file)
    }

    useEffect(() => {
        const newImage = async () => {
            await urlToObject(`${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`)
        }
        newImage()
    }, [pharmacy_config])

    useEffect(() => {
        setFormData({
            ...formData,
            config: pharmacy_config?.pkid,
            name: pharmacy_config?.name,
            phone: pharmacy_config?.phone,
            sms_tag: pharmacy_config?.sms_tag,
            email: pharmacy_config?.email,
            logo: image,
            address: pharmacy_config?.address
        })

    }, [pharmacy_config])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleFileChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.files[0] })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(updatePharmacyConfig({ formData, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-600 text-[16px] font-medium capitalize`}>
                            Update Pharmacy Data
                        </h4>
                        <FaTimesCircle
                            size={20}
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showPharmacistUpdatePharmacyInfoModal: !modals.showPharmacistUpdatePharmacyInfoModal })
                            }}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                name="name"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                value={formData?.name}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <div>
                            <input
                                type="text"
                                name="phone"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                value={formData?.phone}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <div>
                            <input
                                type="text"
                                name="email"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                value={formData?.email}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <div>
                            <input
                                type="text"
                                name="sms_tag"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                value={formData?.sms_tag}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        {/* <div className='flex justify-center space-x-5 items-center w-full py-4'>
                            <img
                                src={`http://localhost:8000${pharmacy_config?.logo}`}
                                alt=""
                                className='w-[30px] h-[30px] rounded-full'
                            />
                            <div
                                onClick={() => logoRef.current.click()}
                                className="border p-2 rounded-md cursor-pointer"
                            >
                                <AiOutlineCloudUpload
                                    size={40}
                                />
                            </div>
                            <input
                                ref={logoRef}
                                type="file"
                                name="logo"
                                className="hidden"
                                accept='image/jpeg, image/png, image/jpg'
                                onChange={(e) => handleFileChange(e)}
                            />
                        </div> */}

                        <div>
                            <textarea
                                rows="4"
                                type="text"
                                name="address"
                                className="border rounded w-full px-4 py-2 resize-none mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                value={formData?.address}
                                onChange={(e) => handleChange(e)}
                            ></textarea>
                        </div>


                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default PharmacistUpdatePharmacyInfoModal
