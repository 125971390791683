/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from '../../../state/context'
import { setEmployee } from "../../../state/actions/actions"
import { getPharmacyCourierProfile, updatePharmacyCourierProfile, updatePharmacyStaff } from "../../../state/actions/pharmacist.actions"


const PharmacistUpdateStaffProfileModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { employee_update } = useSelector((state) => state.root)
    const { courier_profile } = useSelector((state) => state.pharmacist)

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        address: '',
        patient_phone: '',
        state: '',
        lga_or_province: '',
    })

    useEffect(() => {
        dispatch(getPharmacyCourierProfile({ courier: employee_update?.pkid }))
    }, [employee_update])

    useEffect(() => {
        setFormData({
            ...formData,
            first_name: employee_update?.first_name,
            last_name: employee_update?.last_name,
            email: employee_update?.email,
            address: courier_profile?.address,
            patient_phone: courier_profile?.patient_phone,
            state: courier_profile?.state,
            city: courier_profile?.city,
            lga_or_province: courier_profile?.lga_or_province,
        })
    }, [employee_update, courier_profile])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }


    const handleUpdate = (e) => {
        e.preventDefault()

        dispatch(updatePharmacyCourierProfile({ formData: { ...formData, courier: employee_update?.pkid }, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white px-[30px] py-[30px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-sky-600 text-[18px] font-medium">
                            Update Courier Profile
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(setEmployee({ data: null }))
                                setModals({ ...modals, showPharmacistUpdateStaffProfileModal: !modals.showPharmacistUpdateStaffProfileModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleUpdate} className="space-y-3">
                        <div className="flex justify-between-items-center space-x-10">
                            <div className="space-y-2 w-full">
                                <div className="-space-y-1 flex flex-col">
                                    <label className="text-[12px] font-semibold">First Name</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        placeholder="First name"
                                        className="border rounded w-[300px] h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.first_name}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="-space-y-1 flex flex-col">
                                    <label className="text-[12px] font-semibold">Last Name</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        placeholder="Last name"
                                        className="border rounded w-[300px] h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.last_name}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="-space-y-1 flex flex-col">
                                    <label className="text-[12px] font-semibold">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email address"
                                        className="border rounded w-[300px] h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.email}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="-space-y-1 flex flex-col">
                                    <label className="text-[12px] font-semibold">Phone</label>
                                    <input
                                        type="number"
                                        name="patient_phone"
                                        min={0}
                                        minLength={'11'}
                                        maxLength={'11'}
                                        placeholder="Phone"
                                        className="border rounded w-[300px] h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.patient_phone}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>
                            <div className="space-y-2 w-full">
                                <div className="-space-y-1 flex flex-col">
                                    <label className="text-[12px] font-semibold">Address</label>
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Address"
                                        className="border rounded w-[300px] h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.address}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="-space-y-1 flex flex-col">
                                    <label className="text-[12px] font-semibold">State</label>
                                    <input
                                        type="text"
                                        name="state"
                                        placeholder="State"
                                        className="border rounded w-[300px] h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.state}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="-space-y-1 flex flex-col">
                                    <label className="text-[12px] font-semibold">City</label>
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="City"
                                        className="border rounded w-[300px] h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.city}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="-space-y-1 flex flex-col">
                                    <label className="text-[12px] font-semibold">LGA/Province</label>
                                    <input
                                        type="text"
                                        name="lga_or_province"
                                        placeholder="LGA/Province"
                                        className="border rounded w-[300px] h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.lga_or_province}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 flex justify-center">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PharmacistUpdateStaffProfileModal
