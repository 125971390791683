import { createSlice } from '@reduxjs/toolkit'

import { InitializeAuth } from './'
import { authLogin, updateUserPassword, getUserProfile, userLogout, updateProfilePassword, updateUserProfile, profileUpdate, updateProfilePhoto, activateAccount, updateOnlineStatus, updateUserSocketId, createCustomer, getSubscriptionPlan, getSMSBalance, getPharmacyConfig, updatePharmacyConfig, getSubscriptionPlans, getPlanUpgradeDetails, getPlanRenewalDetails, getAdminDashboardInfo, requestPasswordReset, resetUserPassword, updatePharmacyLogo, resetPlanUpdateDetails } from '../actions/auth.actions'


let USERFROMLS = localStorage.getItem('pms__user') ? JSON.parse(localStorage.getItem('pms__user')) : null
const PROFILEFROMLS = localStorage.getItem('pms__user__profile') ? JSON.parse(localStorage.getItem('pms__user__profile')) : null


InitializeAuth(USERFROMLS)


const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: USERFROMLS ? USERFROMLS : null,
        profile: PROFILEFROMLS ? PROFILEFROMLS : null,
        user_onlinestatus: null,
        user_socket_id: null,
        subscription_plan: null,
        sms_balance: null,
        pharmacy_config: null,
        subscription_plans: null,
        plan_upgrade_details: null,
        authErrors: null,
        authLoading: false
    },
    extraReducers: {
        [activateAccount.pending]: (state, action) => {
            state.authLoading = true
        },
        [activateAccount.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [activateAccount.pending]: (state, action) => {
            state.authLoading = true
        },
        [authLogin.pending]: (state, action) => {
            state.authLoading = true
            
        },
        [authLogin.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user = action.payload
            localStorage.setItem('pms__user', JSON.stringify({ ...action.payload }))
        },
        [authLogin.rejected]: (state, action) => {
            state.authLoading = false
            state.authErrors = action.payload
        },
        [updateUserPassword.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateUserPassword.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user = null
            state.profile = null
        },
        [updateUserPassword.rejected]: (state, action) => {
            state.authLoading = false
        },

        [requestPasswordReset.pending]: (state, action) => {
            state.authLoading = true
        },
        [requestPasswordReset.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [requestPasswordReset.rejected]: (state, action) => {
            state.authLoading = false
        },

        [resetUserPassword.pending]: (state, action) => {
            state.authLoading = true
        },
        [resetUserPassword.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [resetUserPassword.rejected]: (state, action) => {
            state.authLoading = false
        },

        [profileUpdate.pending]: (state, action) => {
            state.authLoading = true
        },
        [profileUpdate.fulfilled]: (state, action) => {
            state.authLoading = false

            const userFromLS = JSON.parse(localStorage.getItem('pms__user'))
            console.log(userFromLS);
            const details = { ...userFromLS, data: action.payload.user_details }
            state.user = details

            localStorage.removeItem('pms__user')
            localStorage.setItem('pms__user', JSON.stringify(details))

            const profileFromLS = JSON.parse(localStorage.getItem('pms__user__profile'))
            console.log(profileFromLS)
            const profile = { ...action.payload, profile_photo: profileFromLS.profile_photo }
            state.profile = profile
            localStorage.removeItem('pms__user__profile')
            localStorage.setItem('pms__user__profile', JSON.stringify(profile))
        },
        [profileUpdate.rejected]: (state, action) => {
            state.authLoading = false
        },
        [updateUserProfile.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateUserProfile.fulfilled]: (state, action) => {
            state.authLoading = false
            state.profile = action.payload
        },
        [updateUserProfile.rejected]: (state, action) => {
            state.authLoading = false
        },
        [updateProfilePhoto.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateProfilePhoto.fulfilled]: (state, action) => {
            state.authLoading = false
            console.log({ payload: action.payload });

            const profileFromLS = JSON.parse(localStorage.getItem('pms__user__profile'))
            console.log(profileFromLS)
            const profile = { ...profileFromLS, profile_photo: 'http://localhost:8000'.concat(action.payload.profile_photo) }
            state.profile = profile
            localStorage.removeItem('pms__user__profile')
            localStorage.setItem('pms__user__profile', JSON.stringify(profile))

        },
        [updateProfilePhoto.rejected]: (state, action) => {
            state.authLoading = false
        },
        [updateProfilePassword.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateProfilePassword.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [updateProfilePassword.rejected]: (state, action) => {
            state.authLoading = false
        },
        [getUserProfile.pending]: (state, action) => {
            state.authLoading = true
        },
        [getUserProfile.fulfilled]: (state, action) => {
            state.authLoading = false
            state.profile = action.payload
            localStorage.setItem('pms__user__profile', JSON.stringify({ ...state.profile }))
        },
        [getUserProfile.rejected]: (state, action) => {
            state.authLoading = false
        },

        [updateOnlineStatus.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateOnlineStatus.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user_onlinestatus = action.payload
            state.user.data['is_online'] = action.payload.is_online

            const userFROMLS = localStorage.getItem('pms__user') ? JSON.parse(localStorage.getItem('pms__user')) : null
            userFROMLS.data['is_online'] = action.payload.is_online
            
            localStorage.removeItem('pms__user')
            localStorage.setItem('pms__user', JSON.stringify(userFROMLS))
        },
        [updateOnlineStatus.rejected]: (state, action) => {
            state.authLoading = false
        },

        [userLogout.pending]: (state, action) => {
            state.authLoading = true
        },
        [userLogout.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user = null
            state.profile = null
            // state.user_onlinestatus = action.payload
        },
        [userLogout.rejected]: (state, action) => {
            state.authLoading = false
        },

        [updateUserSocketId.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateUserSocketId.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user_socket_id= action.payload
           
        },
        [updateUserSocketId.rejected]: (state, action) => {
            state.authLoading = false
        },
        
        [createCustomer.pending]: (state, action) => {
            state.authLoading = true
        },
        [createCustomer.fulfilled]: (state, action) => {
            state.authLoading = false
           
        },
        [createCustomer.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [getSubscriptionPlan.pending]: (state, action) => {
            state.authLoading = true
        },
        [getSubscriptionPlan.fulfilled]: (state, action) => {
            state.authLoading = false
            state.subscription_plan = action.payload.subscription_plan
        },
        [getSubscriptionPlan.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [getSMSBalance.pending]: (state, action) => {
            state.authLoading = true
        },
        [getSMSBalance.fulfilled]: (state, action) => {
            state.authLoading = false
            state.sms_balance = action.payload
        },
        [getSMSBalance.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [getPharmacyConfig.pending]: (state, action) => {
            state.authLoading = true
        },
        [getPharmacyConfig.fulfilled]: (state, action) => {
            state.authLoading = false
            state.pharmacy_config = action.payload
        },
        [getPharmacyConfig.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [updatePharmacyConfig.pending]: (state, action) => {
            state.authLoading = true
        },
        [updatePharmacyConfig.fulfilled]: (state, action) => {
            state.authLoading = false
            state.pharmacy_config = action.payload
        },
        [updatePharmacyConfig.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [updatePharmacyLogo.pending]: (state, action) => {
            state.authLoading = true
        },
        [updatePharmacyLogo.fulfilled]: (state, action) => {
            state.authLoading = false
            state.pharmacy_config = action.payload
        },
        [updatePharmacyLogo.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [getSubscriptionPlans.pending]: (state, action) => {
            state.authLoading = true
        },
        [getSubscriptionPlans.fulfilled]: (state, action) => {
            state.authLoading = false
            state.subscription_plans = action.payload
        },
        [getSubscriptionPlans.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [getPlanUpgradeDetails.pending]: (state, action) => {
            state.authLoading = true
        },
        [getPlanUpgradeDetails.fulfilled]: (state, action) => {
            state.authLoading = false
            state.plan_upgrade_details = action.payload
        },
        [getPlanUpgradeDetails.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [resetPlanUpdateDetails.pending]: (state, action) => {
            state.authLoading = true
        },
        [resetPlanUpdateDetails.fulfilled]: (state, action) => {
            state.authLoading = false
            state.plan_upgrade_details = action.payload
        },
        [resetPlanUpdateDetails.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [getPlanRenewalDetails.pending]: (state, action) => {
            state.authLoading = true
        },
        [getPlanRenewalDetails.fulfilled]: (state, action) => {
            state.authLoading = false
            state.plan_upgrade_details = action.payload
        },
        [getPlanRenewalDetails.rejected]: (state, action) => {
            state.authLoading = false
        },
                
        [getAdminDashboardInfo.pending]: (state, action) => {
            state.authLoading = true
        },
        [getAdminDashboardInfo.fulfilled]: (state, action) => {
            state.authLoading = false
            state.admin_dashboard_info = action.payload
        },
        [getAdminDashboardInfo.rejected]: (state, action) => {
            state.authLoading = false
        },
    }
})


export default authSlice.reducer;