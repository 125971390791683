/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { PageTitle, SmsTable, SmsUsersTable } from "../../components"
import { getAllEmployees, getAllReceivedSMS, getAllSentSMS } from "../../state/actions/admin.actions"


const SMS = () => {
	const dispatch = useDispatch()

	const { user } = useSelector((state) => state.auth)
	const { getSentMessages, getReceivedMessages, employees, sent_sms } = useSelector((state) => state.admin)

	const [showUsers, setShowUsers] = useState(true)
	const [showSent, setShowSent] = useState(false)
	const [showReceived, setShowReceived] = useState(false)
	const [activeLink, setActiveLink] = useState('Users')
	const [filterState, setFilterState] = useState({ search: '', users: [], sent_sms: getSentMessages, received_sms: getReceivedMessages })

	const handleUsersFilter = () => {
		const filtered = employees?.filter(employee => {
			return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, users: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, users: employees?.filter(employee => employee.pkid !== user?.data?.pkid) })
		}
	}

	const handleSentSmsFilter = () => {
		const filtered = getSentMessages?.filter(sms => {
			return sms.sms_recipeints.first_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| sms.sms_recipeints.last_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| sms.sms_recipeints.email.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, sent_sms: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, sent_sms: getSentMessages })
		}
	}

	const handleReceivedSmsFilter = () => {
		const filtered = getReceivedMessages?.filter(sms => {
			return sms.sms_senders.first_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| sms.sms_senders.last_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| sms.sms_senders.email.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, received_sms: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, received_sms: getReceivedMessages })
		}
	}

	useEffect(() => {
		dispatch(getAllEmployees())
		dispatch(getAllSentSMS())
		dispatch(getAllReceivedSMS())
	}, [])
	
	useEffect(() => {
		dispatch(getAllEmployees())
		dispatch(getAllSentSMS())
		dispatch(getAllReceivedSMS())
	}, [sent_sms])

	// useEffect(() => {
	// 	setUsers(employees?.filter(employee => employee.pkid !== user?.data?.pkid))
	// }, [employees, user])

	useEffect(() => {
		if (activeLink === 'Users') {
			handleUsersFilter()
		}
		if (activeLink === 'Sent') {
			handleSentSmsFilter()
		}
		if (activeLink === 'Received') {
			handleReceivedSmsFilter()
		}
	}, [filterState.search])


	return (
		<div>
			<PageTitle type={"messages"} />

			<div className="">
				<div className="flex space-x-5 justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
					<div className="space-x-5">
						<button
							type="button"
							className={`${activeLink === 'Users' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Users
						</button>

						<button
							type="button"
							className={`${activeLink === 'Sent' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Sent
						</button>

						<button
							type="button"
							className={`${activeLink === 'Received' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Received
						</button>
					</div>

					<div>
						<input
							type="text"
							name="search"
							// disabled
							placeholder='Search'
							className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
							onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
						/>
					</div>
				</div>

				{activeLink === 'Users' && (
					<SmsUsersTable
						data={filterState.users?.filter(employee => employee.pkid !== user?.data?.pkid)}
						showList={showUsers}
						setShowList={setShowUsers}
					/>
				)}

				{activeLink === 'Sent' && (
					<SmsTable
						title={'Sent'}
						data={filterState.sent_sms}
						showList={showSent}
						setShowList={setShowSent}
					/>
				)}

				{activeLink === 'Received' && (
					<SmsTable
						title={'Received'}
						data={filterState.received_sms}
						showList={showReceived}
						setShowList={setShowReceived}
					/>
				)}
			</div>
		</div>
	)
}

export default SMS
