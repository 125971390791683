/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Modals, SideBar, TopBar } from '../../components'
import {
    CollectionsDashboard, CollectionsPaidInvoices, CollectionsUnpaidInvoices, PharmacistStaff,
    Messages, PharmacistBillsDispense, PharmacistDashboard, PharmacistInventory, PharmacistPrescriptions,
    Chat, PharmacistDrugsCategory, PharmacistSupplyPoint, PharmacistSupplyPoints, Profile, Schedule, SMS,
    PharmacistSettings, CustomerDashboard, CustomerInvoices, CustomerOrders, PharmacistProvisions, PharmacistPatient, PharmacistInvestigations,
    PharmacistClinicalDataRepository
} from '..'
import { getAdminDashboardInfo, getPharmacyConfig } from '../../state/actions/auth.actions'


const Dashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [activeLink, setActiveLink] = useState('Admin Dashboard')

    const { user, pharmacy_config } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(getPharmacyConfig())
        dispatch(getAdminDashboardInfo())
    }, [])

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])

    useEffect(() => {
        if (!user) return navigate('/')

        if (user?.data.is_customer) return setActiveLink('Customer Dashboard')
        if (user?.data.is_pharmacist || user?.data.is_sales) return setActiveLink('Pharmacist Dashboard')
        if (user?.data.is_collections) return setActiveLink('Collections Dashboard')
    }, [user])



    return (
        <div className='relative flex flex-row font-poppins'>
            <Modals />

            {/* sidebar */}
            <SideBar
                activeLink={activeLink}
                setActiveLink={setActiveLink}
            />

            {/* body */}
            <div className="w-10 text-2xl font-semibold flex-1">
                <TopBar />
                <div className="p-2">
                    {/* pharmacist */}
                    {activeLink === 'Pharmacist Dashboard' && <PharmacistDashboard />}
                    {/* {activeLink === 'Drugs Category' && <PharmacistDrugsCategory />} */}
                    {activeLink === 'Inventory' && <PharmacistInventory />}
                    {activeLink === 'Settings' && <PharmacistSettings />}
                    {activeLink === 'Prescriptions' && <PharmacistPrescriptions />}
                    {activeLink === 'Staff' && <PharmacistStaff />}
                    {activeLink === 'Supply Point' && <PharmacistSupplyPoint />}
                    {activeLink === 'Supply Points' && <PharmacistSupplyPoints />}
                    {activeLink === 'Bills/Dispense' && <PharmacistBillsDispense />}
                    {activeLink === 'Manage Patient' && <PharmacistPatient />}
                    {activeLink === 'Investigations' && <PharmacistInvestigations />}
                    {activeLink === 'Clinical Data' && <PharmacistClinicalDataRepository />}


                    {/* collections */}
                    {activeLink === 'Collections Dashboard' && <CollectionsDashboard />}
                    {activeLink === 'Collections Invoices' && <CollectionsUnpaidInvoices />}
                    {activeLink === 'Collections Paid Invoices' && <CollectionsPaidInvoices />}

                    {/* collections */}
                    {activeLink === 'Customer Dashboard' && <CustomerDashboard />}
                    {activeLink === 'Customer Invoices' && <CustomerInvoices />}
                    {activeLink === 'Customer Orders' && <CustomerOrders />}

                    {/* general */}
                    {activeLink === 'Schedule' && <Schedule />}
                    {activeLink === 'Chat' && <Chat />}
                    {activeLink === 'Profile' && (<Profile />)}
                    {activeLink === 'Messages' && (<Messages />)}
                    {activeLink === 'SMS' && (<SMS />)}
                </div>
            </div>

        </div>
    )
}

export default Dashboard 