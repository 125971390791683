import { API } from "../config";


export const fetchDrugCategories = (token) => API.get('/pharmacist/drug_categories/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyStaffs = (token) => API.get('/pharmacist/pharmacist_staff/', { headers: { Authorization: `Bearer ${token}` } })


export const generatePharmacyStaff = (formData, token) => API.post('/pharmacist/add_pharmacist_staff/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyCourierProfile = (courier, token) => API.get(`/pharmacist/courier_profile/${courier}/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchPharmacyCourierProfile = (formData, token) => API.patch(`/pharmacist/courier_profile/${formData.courier}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchPharmacyStaff = (formData, token) => API.patch('/pharmacist/pharmacist_staff/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const removePharmacyStaff = (id, token) => API.delete(`/pharmacist/pharmacist_staff/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateDrugCategory = (formData, token) => API.post('/pharmacist/add_drug_category/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchDrugCategory = (formData, token) => API.put(`/pharmacist/drug_category/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeDrugCategory = (id, token) => API.delete(`/pharmacist/drug_category/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPoints = (token) => API.get(`/pharmacist/supply_points/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateSupplyPoint = (formData, token) => API.post(`/pharmacist/add_supply_point/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchSupplyPoint = (formData, token) => API.put(`/pharmacist/supply_point/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeSupplyPoint = (id, token) => API.delete(`/pharmacist/supply_point/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDrugs = (token) => API.get(`/pharmacist/drugs/`, { headers: { Authorization: `Bearer ${token}` } })


export const customerFetchDrugs = (token) => API.get(`/pharmacist/customer_drugs/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateDrug = (formData, token) => API.post(`/pharmacist/add_drug/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchDrug = (formData, token) => API.put(`/pharmacist/drug/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeDrug = (id, token) => API.delete(`/pharmacist/drug/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDrugBinCards = (id, token) => API.get(`/pharmacist/drug_inventory/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateDrugBinCard = (formData, token) => API.post(`/pharmacist/add_drug_inventory/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const stockInventoryToSupplyPoint = (formData, token) => API.post(`/pharmacist/supplypoint_inventory/`, formData, { headers: { Authorization: `Bearer ${token}` } })


// export const patchInventory = (formData, token) => API.post(`/pharmacist/druginventory_dispatch/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchInventory = (formData, token) => API.post(`/pharmacist/inventory_dispatch/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPointInventories = (supplypoint_name, token) => API.get(`/pharmacist/supplypoint_inventories/${supplypoint_name}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPointInventoryBinCards = (params, token) => API.get(`/pharmacist/supplypoint_inventories/${params.spoint}/${params.item}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateInventoryForSupplyPoint = (formData, token) => API.post(`/pharmacist/dispense_to_supplypoint/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchInventoryForSupplyPoint = (formData, token) => API.patch(`/pharmacist/dispense_to_supplypoint/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const issueDrugOut = (formData, token) => API.patch(`/pharmacist/issue_out_drug/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateDrugDispense = (formData, token) => API.patch(`/pharmacist/dispense_drug/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPointBinCards = (params, token) => API.post(`/pharmacist/supplypoint_bincards/`, params, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPrescriptions = (token) => API.get(`/pharmacist/non_opd_prescriptions/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDrugPrices = (params, token) => API.post(`/pharmacist/drug_prices/`, params, { headers: { Authorization: `Bearer ${token}` } })


export const generateInvoice = (formData, token) => API.post(`/pharmacist/prescriptions_invoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPrescriptionInvoices = (token) => API.get(`/pharmacist/prescriptions_invoice/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPaidPrescriptions = (params, token) => API.get(`/pharmacist/paid_prescriptions/${params.prescription}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacistProfiles = (token) => API.get(`/pharmacist/assign_supply_point/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchPharmacistSupplyPoint = (formData, token) => API.patch(`/pharmacist/assign_supply_point/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPointStaff = (params, token) => API.get(`/pharmacist/supplypoint_staff/${params.spoint}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generatePrescription = (formData, token) => API.post(`/pharmacist/add_prescription/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const customerFetchPrescriptions = (token) => API.get(`/pharmacist/customer_prescription/`, { headers: { Authorization: `Bearer ${token}` } })


export const customerGeneratePrescription = (formData, token) => API.post(`/pharmacist/customer_prescription/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const customerFetchInvoices = (token) => API.get(`/pharmacist/customer_prescriptions_invoice/`, { headers: { Authorization: `Bearer ${token}` } })


export const customerFetchOrders = (token) => API.get(`/pharmacist/customer_order/`, { headers: { Authorization: `Bearer ${token}` } })


export const customerGenerateOrder = (formData, token) => API.post(`/pharmacist/customer_order/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchOrders = (token) => API.get(`/pharmacist/orders/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchOrder = (formData, token) => API.patch(`/pharmacist/orders/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchCouriers = (token) => API.get(`/pharmacist/couriers/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchProvisions = (token) => API.get(`/pharmacist/provisions/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateProvision = (formData, token) => API.post(`/pharmacist/provisions/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const patchProvision = (formData, token) => API.patch(`/pharmacist/provisions/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeProvision = (id, token) => API.delete(`/pharmacist/provisions/${id}`, { headers: { Authorization: `Bearer ${token}`, } })


export const fetchProvisionInvoices = (token) => API.get(`/pharmacist/provision_invoice/`, { headers: { Authorization: `Bearer ${token}`, } })


export const generateProvisionInvoice = (formData, token) => API.post(`/pharmacist/provision_invoice/`, formData, { headers: { Authorization: `Bearer ${token}`, } })


export const customerGenerateProvisionOrder = (formData, token) => API.post(`/pharmacist/customer_provision_order/`, formData, { headers: { Authorization: `Bearer ${token}`, } })

export const getCustomersProfile = (token) => API.get(`/nurse/all_profiles/`, { headers: { Authorization: `Bearer ${token}`, } })
export const consultationFee = (token) => API.get(`/pharmacist/consultation_fee/`, { headers: { Authorization: `Bearer ${token}`, } })

/**
    inventory fixes    
*/

export const generateInventoryItem = (formData, token) => API.post(`/pharmacist/inventory_item/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const fetchInventoryDrugItems = (token) => API.get(`/pharmacist/inventory_drugs/`, { headers: { Authorization: `Bearer ${token}`, } })


export const patchInventoryDrug = (formData, token) => API.patch(`/pharmacist/inventory_drugs/`, formData, { headers: { Authorization: `Bearer ${token}`, } })


export const patchInventoryProvision = (formData, token) => API.patch(`/pharmacist/inventory_provisions/`, formData, { headers: { Authorization: `Bearer ${token}`, } })


export const removeInventoryDrug = (id, token) => API.delete(`/pharmacist/inventory_drugs/${id}/`, { headers: { Authorization: `Bearer ${token}`, } })


export const removeInventoryProvision = (id, token) => API.delete(`/pharmacist/inventory_provisions/${id}/`, { headers: { Authorization: `Bearer ${token}`, } })


export const fetchInventoryProvisionItems = (token) => API.get(`/pharmacist/inventory_provisions/`, { headers: { Authorization: `Bearer ${token}`, } })


export const fetchInventoryItemInventory = (id, token) => API.get(`/pharmacist/inventoryitem_inventory/${id}/`, { headers: { Authorization: `Bearer ${token}`, } })


export const generateInventory = (formData, token) => API.post(`/pharmacist/inventoryitem_inventory/`, formData, { headers: { Authorization: `Bearer ${token}`, } })
export const fetchClinicalData = (formData, token) => API.post(`/pharmacist/db/`, formData, { headers: { Authorization: `Bearer ${token}`, } })
export const fetchCustomerInventoryItems = (token) => API.get(`/pharmacist/customer_inventory_items/`, { headers: { Authorization: `Bearer ${token}`, } })
export const fetchInventories = (token) => API.get(`/pharmacist/get_inventories/`, { headers: { Authorization: `Bearer ${token}`, } })



export const fetchBillings = (token) => API.get('/pharmacist/billings/', { headers: { Authorization: `Bearer ${token}` } })

export const generateBilling = (formData, token) => API.post('/pharmacist/create_billing/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const patchBilling = (formData, token) => API.put(`/pharmacist/billings/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const removeBilling = (id, token) => API.delete(`/pharmacist/billings/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryInvestigationTypes = (token) => API.get('/pharmacist/lab_investigation_types/', { headers: { Authorization: `Bearer ${token}` } })

export const generateLaboratoryInvestigationType = (formData, token) => API.post('/pharmacist/lab_investigation_types/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const patchLaboratoryInvestigationType = (formData, token) => API.patch(`/pharmacist/lab_investigation_types/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const removeLaboratoryInvestigationType = (id, token) => API.delete(`/pharmacist/lab_investigation_types/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryInvestigationCategories = (token) => API.get('/pharmacist/lab_investigation_category/', { headers: { Authorization: `Bearer ${token}` } })

export const generateLaboratoryInvestigationCategory = (formData, token) => API.post('/pharmacist/lab_investigation_category/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const removeLaboratoryInvestigationCategory = (id, token) => API.delete(`/pharmacist/lab_investigation_category/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const patchLaboratoryInvestigationCategory = (formData, token) => API.patch(`/pharmacist/lab_investigation_category/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryInvestigationSamples = (token) => API.get('/pharmacist/lab_investigation_sample/', { headers: { Authorization: `Bearer ${token}` } })

export const generateLaboratoryInvestigationSample = (formData, token) => API.post('/pharmacist/lab_investigation_sample/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const removeLaboratoryInvestigationSample = (id, token) => API.delete(`/pharmacist/lab_investigation_sample/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const patchLaboratoryInvestigationSample = (formData, token) => API.patch(`/pharmacist/lab_investigation_sample/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyInvestigationTypes = (token) => API.get('/pharmacist/rad_investigation_type/', { headers: { Authorization: `Bearer ${token}` } })

export const generateRadiologyInvestigationType = (formData, token) => API.post('/pharmacist/rad_investigation_type/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const removeRadiologyInvestigationType = (id, token) => API.delete(`/pharmacist/rad_investigation_type/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const patchRadiologyInvestigationType = (formData, token) => API.patch(`/pharmacist/rad_investigation_type/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyInvestigationCategories = (token) => API.get('/pharmacist/rad_investigation_category/', { headers: { Authorization: `Bearer ${token}` } })

export const generateRadiologyInvestigationCategory = (formData, token) => API.post('/pharmacist/rad_investigation_category/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const removeRadiologyInvestigationCategory = (id, token) => API.delete(`/pharmacist/rad_investigation_category/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const patchRadiologyInvestigationCategory = (formData, token) => API.patch(`/pharmacist/rad_investigation_category/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryInvestigationReports = (token) => API.get(`/pharmacist/lab_investigation_report/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchLaboratoryInvestigationReport = (formData, token) => API.patch(`/pharmacist/lab_investigation_report/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchLaboratoryInvestigationResult = (formData, token) => API.put(`/pharmacist/lab_investigation_report/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const fetchRadiologyInvestigationReports = (token) => API.get(`/pharmacist/rad_investigation_report/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchRadiologyInvestigationReport = (formData, token) => API.patch(`/pharmacist/rad_investigation_report/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchRadiologyInvestigationResult = (formData, token) => API.put(`/pharmacist/rad_investigation_report/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const fetchPharmacistInventoryItems = (token) => API.get(`/pharmacist/pharmacist_inventoryItems/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchGatewaySettings = (token) => API.get(`/pharmacist/gateway_setting/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateGatewaySetting = (formData, token) => API.post(`/pharmacist/gateway_setting/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchGatewaySetting = (formData, token) => API.patch(`/pharmacist/gateway_setting/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeGatewaySetting = (id, token) => API.delete(`/pharmacist/gateway_setting/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchSubcriptionPlan = (formData, token) => API.patch(`/pharmacist/application_plan/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const fetchSubscriptionPlan = (token) => API.get(`/pharmacist/application_plan/`, { headers: { Authorization: `Bearer ${token}` } })

export const multipleDispense = (formData, token) => API.patch(`/pharmacist/multiple_dispense/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const generateSmsTopupNotification = (formData) => API.patch(`/pharmacist/sms_topup/`, formData)