import { createSlice } from '@reduxjs/toolkit'

import {
    createDrugCategory, createSupplyPoint, deleteDrugCategory, deleteSupplyPoint, getDrugCategories, getSupplyPoints, updateDrugCategory, updateSupplyPoint,
    createDrug, getDrugs, updateDrug, deleteDrug, getDrugBinCards, createDrugBinCard, dispenseDrugToSupplyPoint, resetDrugBinCards, getSupplyPointInventory,
    getSupplyPointInventoryBinCards, resetSupplyPointDrugBinCards, lendDrugToSupplyPoint, issueOutDrug, getSupplyPointBinCards, resetSupplyPointBinCards,
    getPrescriptions, getDrugPrices, getPaidPrescriptions, createInvoice, getInvoices, getPharmacistProfiles, assignPharmacistToSupplyPoint, getSupplyPointStaff,
    resetSupplyPointStaff, resetSupplyPointInventory, dispenseDrug, createPrescription, createPharmacyStaff, getPharmacyStaff, updatePharmacyStaff, deletePharmacyStaff,
    customerCreatePrescription, getCustomerPrescriptions, customerGetInvoices, setCurrentCustomerInvoice, getCustomerOrders, getOrders, getCouriers, setCurrentOrder,
    customerCreateOrder, processOrder, customerGetDrugs, getProvisions, createProvision, updateProvision, deleteProvision, setCurrentProvision, createProvisionInvoice,
    getProvisionInvoices, customerCreateProvisionOrder, getAllProfiles, getConsultationFee, createInventoryItem, getInventoryDrugs, getInventoryProvisions, updateInventoryDrug,
    deleteInventoryDrug, updateInventoryProvision, deleteInventoryProvision, getInventoryItemInventory, createInventory, resetPaidPrescriptions, getBillings, createBilling,
    updateBilling, deleteBilling, getLaboratoryInvestigationTypes, createLaboratoryInvestigationType, updateLaboratoryInvestigationType, deleteLaboratoryInvestigationType,
    getLaboratoryInvestigationCategories, createLaboratoryInvestigationCategory, updateLaboratoryInvestigationCategory, deleteLaboratoryInvestigationCategory, getLaboratoryInvestigationSamples,
    createLaboratoryInvestigationSample, updateLaboratoryInvestigationSample, deleteLaboratoryInvestigationSample, getRadiologyInvestigationTypes, createRadiologyInvestigationType,
    updateRadiologyInvestigationType, deleteRadiologyInvestigationType, getRadiologyInvestigationCategories, createRadiologyInvestigationCategory, updateRadiologyInvestigationCategory,
    deleteRadiologyInvestigationCategory, setCurrentLaboratoryData, getLabInvestigationReports, getRadInvestigationReports, createLabInvestigationReportInvoice, uploadLabReportResult,
    createRadInvestigationReportInvoice, uploadRadReportResult, getClinicalData, fetchAllInventories, getPharmacistInventoryItems, getGatewaySettings, createGatewaySetting, updateGatewaySetting, deleteGatewaySetting,
    setCurrentSubscriptionPlan, updateSubscriptionPlan, getSubscriptionPlan, SalesMutlipleDispense, DispenseMultipleItems, sendSmsTopupNotification, getPharmacyCourierProfile, updatePharmacyCourierProfile
} from '../actions/pharmacist.actions'



const pharmacistSlice = createSlice({
    name: 'pharmacist',
    initialState: {
        pharmacist: null,
        pharmacists: null,
        courier_profile: null,
        drugs: null,
        customer_drugs: null,
        drug_bincards: null,
        drugCategories: null,
        supplyPoints: null,
        dispensed_drug: null,
        supplypoint_inventory: null,
        supplypoint_inventory_drug_bincards: null,
        supplypoint_bincards: null,
        prescription: null,
        prescriptions: null,
        drugs_price: null,
        prescriptions_invoice: null,
        prescriptions_invoices: null,
        paid_prescriptions: null,
        pharmacist_profiles: null,
        assigned_supplypoint: null,
        supplypoint_staff: null,
        patient_drugdispensed: null,
        customer_prescriptions: null,
        customer_invoices: null,
        current_customer_invoice: null,
        prescription_order: null,
        provision_order: null,
        customer_orders: null,
        prescription_orders: null,
        couriers: null,
        current_order: null,
        provisions: null,
        provision: null,
        provision_invoice: null,
        provision_invoices: null,
        inventory_item: null,
        inventory_drugs: null,
        inventory_provisions: null,
        inventories: null,
        profiles: null,
        consultation: null,
        billing: null,
        billings: null,
        lab_investigation_types: null,
        lab_investigation_categories: null,
        lab_investigation_samples: null,
        rad_investigation_types: null,
        rad_investigation_categories: null,
        laboratory_data: null,
        lab_investigation_reports: null,
        rad_investigation_reports: null,
        lab_report_invoice: null,
        lab_report_upload: null,
        rad_report_invoice: null,
        rad_report_upload: null,
        clinical_data: null,
        all_inventories: null,
        pharmacist_inventory_items: null,
        gateway_settings: null,
        current_data: null,
        subscription_data: null,
        subscription_plan: null,
        multiple_dispense: null,

        pharmacistLoading: null,
        pharmacistErrors: null,
    },
    extraReducers: {
        [getPharmacyStaff.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPharmacyStaff.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacists = action.payload
        },
        [getPharmacyStaff.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createPharmacyStaff.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createPharmacyStaff.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.new_staff = action.payload
            state.pharmacists = [action.payload, ...state.pharmacists]
        },
        [createPharmacyStaff.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPharmacyCourierProfile.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPharmacyCourierProfile.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.courier_profile = action.payload
        },
        [getPharmacyCourierProfile.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updatePharmacyCourierProfile.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updatePharmacyCourierProfile.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.courier_profile = action.payload
        },
        [updatePharmacyCourierProfile.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updatePharmacyStaff.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updatePharmacyStaff.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacists = state.pharmacists.filter(pharmacist => pharmacist.pkid !== action.payload.pkid)
            state.pharmacists = [action.payload, ...state.pharmacists]
        },
        [updatePharmacyStaff.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deletePharmacyStaff.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deletePharmacyStaff.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacists = state.pharmacists.filter(pharmacist => pharmacist.pkid !== action.payload)
        },
        [deletePharmacyStaff.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getDrugCategories.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDrugCategories.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugCategories = action.payload
        },
        [getDrugCategories.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createDrugCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createDrugCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugCategories = [action.payload, ...state.drugCategories]
        },
        [createDrugCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateDrugCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateDrugCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.drugCategories.findIndex(drugCategory => drugCategory.id === action.payload.id)
            state.drugCategories[index] = action.payload
        },
        [updateDrugCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteDrugCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteDrugCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugCategories = state.drugCategories.filter(drugCategory => drugCategory.id !== action.payload)
        },
        [deleteDrugCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getSupplyPoints.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPoints.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplyPoints = action.payload
        },
        [getSupplyPoints.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplyPoints = [action.payload, ...state.supplyPoints]
        },
        [createSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.supplyPoints.findIndex(supplyPoint => supplyPoint.id === action.payload.id)
            state.supplyPoints[index] = action.payload
        },
        [updateSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplyPoints = state.supplyPoints.filter(supplyPoint => supplyPoint.id !== action.payload)
        },
        [deleteSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getDrugs.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDrugs.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugs = action.payload
        },
        [getDrugs.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [customerGetDrugs.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [customerGetDrugs.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.customer_drugs = action.payload
        },
        [customerGetDrugs.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugs = [action.payload, ...state.drugs]
        },
        [createDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.drugs.findIndex(drug => drug.id === action.payload.id)
            state.drugs[index] = action.payload
        },
        [updateDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugs = state.drugs.filter(drug => drug.id !== action.payload)
        },
        [deleteDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getDrugBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDrugBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drug_bincards = action.payload
        },
        [getDrugBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createDrugBinCard.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createDrugBinCard.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drug_bincards = [action.payload, ...state.drug_bincards]
        },
        [createDrugBinCard.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [dispenseDrugToSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [dispenseDrugToSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.dispensed_drug = action.payload.data
            state.inventories = [action.payload.inventory, ...state.inventories]
            // state.drug_bincards = state.drug_bincards.unshift(action.payload.inventory)
        },
        [dispenseDrugToSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [dispenseDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [dispenseDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.patient_drugdispensed = action.payload
        },
        [dispenseDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [resetDrugBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetDrugBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drug_bincards = action.payload
        },
        [resetDrugBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getSupplyPointInventory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPointInventory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory = action.payload
        },
        [getSupplyPointInventory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            // state.pharmacistErrors = action.payload
        },

        [resetSupplyPointInventory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetSupplyPointInventory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory = action.payload
        },
        [resetSupplyPointInventory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            // state.pharmacistErrors = action.payload
        },

        [getSupplyPointInventoryBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPointInventoryBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory_drug_bincards = action.payload
        },
        [getSupplyPointInventoryBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            // state.pharmacistErrors = action.payload
        },

        [resetSupplyPointDrugBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetSupplyPointDrugBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory_drug_bincards = action.payload
        },
        [resetSupplyPointDrugBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [lendDrugToSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [lendDrugToSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory_drug_bincards = [action.payload, ...state.supplypoint_inventory_drug_bincards]
        },
        [lendDrugToSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [issueOutDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [issueOutDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory_drug_bincards = [action.payload, ...state.supplypoint_inventory_drug_bincards]
        },
        [issueOutDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getSupplyPointBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPointBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_bincards = action.payload
        },
        [getSupplyPointBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [resetSupplyPointBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetSupplyPointBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_bincards = action.payload
        },
        [resetSupplyPointBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescriptions = action.payload
        },
        [getPrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createPrescription.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createPrescription.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescription = action.payload[0]
            state.prescriptions = [...action.payload, ...state.prescriptions]
        },
        [createPrescription.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getDrugPrices.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDrugPrices.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugs_price = action.payload
        },
        [getDrugPrices.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createInvoice.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createInvoice.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescriptions_invoice = action.payload
        },
        [createInvoice.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getInvoices.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getInvoices.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescriptions_invoices = action.payload
        },
        [getInvoices.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPaidPrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPaidPrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.paid_prescriptions = action.payload
        },
        [getPaidPrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [resetPaidPrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetPaidPrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.paid_prescriptions = action.payload
        },
        [resetPaidPrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPharmacistProfiles.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPharmacistProfiles.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacist_profiles = action.payload
        },
        [getPharmacistProfiles.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [assignPharmacistToSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [assignPharmacistToSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.assigned_supplypoint = action.payload
        },
        [assignPharmacistToSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getSupplyPointStaff.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPointStaff.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_staff = action.payload
        },
        [getSupplyPointStaff.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [resetSupplyPointStaff.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetSupplyPointStaff.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_staff = action.payload
        },
        [resetSupplyPointStaff.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getCustomerPrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getCustomerPrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.customer_prescriptions = action.payload
        },
        [getCustomerPrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [customerCreatePrescription.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [customerCreatePrescription.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.customer_prescriptions = [...action.payload, ...state.customer_prescriptions]
        },
        [customerCreatePrescription.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [customerGetInvoices.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [customerGetInvoices.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.customer_invoices = action.payload
        },
        [customerGetInvoices.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [setCurrentCustomerInvoice.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [setCurrentCustomerInvoice.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.current_customer_invoice = action.payload
        },
        [setCurrentCustomerInvoice.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [customerCreateOrder.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [customerCreateOrder.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescription_order = action.payload
        },
        [customerCreateOrder.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [customerCreateProvisionOrder.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [customerCreateProvisionOrder.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.provision_order = action.payload
        },
        [customerCreateProvisionOrder.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getCustomerOrders.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getCustomerOrders.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.customer_orders = action.payload
        },
        [getCustomerOrders.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getOrders.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getOrders.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescription_orders = action.payload
        },
        [getOrders.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [processOrder.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [processOrder.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescription_order = action.payload
        },
        [processOrder.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getCouriers.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getCouriers.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.couriers = action.payload
        },
        [getCouriers.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [setCurrentOrder.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [setCurrentOrder.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.current_order = action.payload
        },
        [setCurrentOrder.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getProvisions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getProvisions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.provisions = action.payload
        },
        [getProvisions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createProvision.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createProvision.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.provisions = [action.payload, ...state.provisions]
        },
        [createProvision.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateProvision.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateProvision.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.provisions.findIndex(provision => provision.id === action.payload.id)
            state.provisions[index] = action.payload
        },
        [updateProvision.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteProvision.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteProvision.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.provisions = state.provisions.filter(provision => provision.pkid !== action.payload)
        },
        [deleteProvision.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [setCurrentProvision.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [setCurrentProvision.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.provision = action.payload
        },
        [setCurrentProvision.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getProvisionInvoices.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getProvisionInvoices.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.provision_invoices = action.payload
        },
        [getProvisionInvoices.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createProvisionInvoice.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createProvisionInvoice.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.provision_invoice = action.payload
        },
        [createProvisionInvoice.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getAllProfiles.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getAllProfiles.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.profiles = action.payload
        },
        [getAllProfiles.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getInventoryDrugs.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getInventoryDrugs.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventory_drugs = action.payload
        },
        [getInventoryDrugs.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getInventoryProvisions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getInventoryProvisions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventory_provisions = action.payload
        },
        [getInventoryProvisions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createInventoryItem.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createInventoryItem.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventory_item = action.payload
        },
        [createInventoryItem.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateInventoryDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateInventoryDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventory_item = action.payload
        },
        [updateInventoryDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateInventoryProvision.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateInventoryProvision.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventory_item = action.payload
        },
        [updateInventoryProvision.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteInventoryDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteInventoryDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventory_drugs = state.inventory_drugs.filter(item => item.pkid !== action.payload)
        },
        [deleteInventoryDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteInventoryProvision.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteInventoryProvision.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventory_provisions = state.inventory_provisions.filter(item => item.pkid !== action.payload)
        },
        [deleteInventoryProvision.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getInventoryItemInventory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getInventoryItemInventory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventories = action.payload
        },
        [getInventoryItemInventory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createInventory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createInventory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.inventories = [action.payload, ...state.inventories]
        },
        [createInventory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getBillings.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getBillings.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.billings = action.payload
            console.log(action)
            // state.create_employee= [...state.create_employee, action.payload]
        },
        [getBillings.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createBilling.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createBilling.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.billing = action.payload
            state.billings = [action.payload, ...state.billings]
        },
        [createBilling.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateBilling.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateBilling.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.billings.findIndex(billing => billing.id === action.payload.id)
            state.billings[index] = action.payload
        },
        [updateBilling.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteBilling.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteBilling.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.billings = state.billings.filter(billing => billing.id !== action.payload)
        },
        [deleteBilling.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getLaboratoryInvestigationTypes.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getLaboratoryInvestigationTypes.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_types = action.payload
        },
        [getLaboratoryInvestigationTypes.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createLaboratoryInvestigationType.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createLaboratoryInvestigationType.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_types = [action.payload, ...state.lab_investigation_types]
        },
        [createLaboratoryInvestigationType.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateLaboratoryInvestigationType.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateLaboratoryInvestigationType.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.lab_investigation_types.findIndex(type => type.id === action.payload.id)
            state.lab_investigation_types[index] = action.payload
        },
        [updateLaboratoryInvestigationType.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteLaboratoryInvestigationType.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteLaboratoryInvestigationType.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_types = state.lab_investigation_types.filter(type => type.id !== action.payload)
        },
        [deleteLaboratoryInvestigationType.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getLaboratoryInvestigationCategories.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getLaboratoryInvestigationCategories.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_categories = action.payload
        },
        [getLaboratoryInvestigationCategories.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createLaboratoryInvestigationCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createLaboratoryInvestigationCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_categories = [action.payload, ...state.lab_investigation_categories]
        },
        [createLaboratoryInvestigationCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateLaboratoryInvestigationCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateLaboratoryInvestigationCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.lab_investigation_categories.findIndex(cat => cat.id === action.payload.id)
            state.lab_investigation_categories[index] = action.payload
        },
        [updateLaboratoryInvestigationCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteLaboratoryInvestigationCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteLaboratoryInvestigationCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_categories = state.lab_investigation_categories.filter(cat => cat.id !== action.payload)
        },
        [deleteLaboratoryInvestigationCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getLaboratoryInvestigationSamples.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getLaboratoryInvestigationSamples.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_samples = action.payload
        },
        [getLaboratoryInvestigationSamples.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createLaboratoryInvestigationSample.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createLaboratoryInvestigationSample.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_samples = [action.payload, ...state.lab_investigation_samples]
        },
        [createLaboratoryInvestigationSample.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateLaboratoryInvestigationSample.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateLaboratoryInvestigationSample.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.lab_investigation_samples.findIndex(cat => cat.id === action.payload.id)
            state.lab_investigation_samples[index] = action.payload
        },
        [updateLaboratoryInvestigationSample.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteLaboratoryInvestigationSample.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteLaboratoryInvestigationSample.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_samples = state.lab_investigation_samples.filter(cat => cat.id !== action.payload)
        },
        [deleteLaboratoryInvestigationSample.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getRadiologyInvestigationTypes.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getRadiologyInvestigationTypes.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_investigation_types = action.payload
        },
        [getRadiologyInvestigationTypes.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createRadiologyInvestigationType.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createRadiologyInvestigationType.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_investigation_types = [action.payload, ...state.rad_investigation_types]
        },
        [createRadiologyInvestigationType.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateRadiologyInvestigationType.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateRadiologyInvestigationType.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.rad_investigation_types.findIndex(type => type.id === action.payload.id)
            state.rad_investigation_types[index] = action.payload
        },
        [updateRadiologyInvestigationType.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteRadiologyInvestigationType.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteRadiologyInvestigationType.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_investigation_types = state.rad_investigation_types.filter(type => type.id !== action.payload)
        },
        [deleteRadiologyInvestigationType.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getRadiologyInvestigationCategories.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getRadiologyInvestigationCategories.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_investigation_categories = action.payload
        },
        [getRadiologyInvestigationCategories.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createRadiologyInvestigationCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createRadiologyInvestigationCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_investigation_categories = [action.payload, ...state.rad_investigation_categories]
        },
        [createRadiologyInvestigationCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateRadiologyInvestigationCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateRadiologyInvestigationCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.rad_investigation_categories.findIndex(type => type.id === action.payload.id)
            state.rad_investigation_categories[index] = action.payload
        },
        [updateRadiologyInvestigationCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteRadiologyInvestigationCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteRadiologyInvestigationCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_investigation_categories = state.rad_investigation_categories.filter(type => type.id !== action.payload)
        },
        [deleteRadiologyInvestigationCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [setCurrentLaboratoryData.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [setCurrentLaboratoryData.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.laboratory_data = action.payload
        },
        [setCurrentLaboratoryData.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getConsultationFee.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getConsultationFee.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.consultation = action.payload
        },
        [getConsultationFee.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getLabInvestigationReports.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getLabInvestigationReports.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_investigation_reports = action.payload
        },
        [getLabInvestigationReports.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getRadInvestigationReports.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getRadInvestigationReports.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_investigation_reports = action.payload
        },
        [getRadInvestigationReports.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createLabInvestigationReportInvoice.pending]: (state, action) => {
            state.consultation = true
        },
        [createLabInvestigationReportInvoice.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_report_invoice = action.payload
        },
        [createLabInvestigationReportInvoice.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [uploadLabReportResult.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [uploadLabReportResult.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.lab_report_upload = action.payload
        },
        [uploadLabReportResult.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createRadInvestigationReportInvoice.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createRadInvestigationReportInvoice.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_report_invoice = action.payload
        },
        [createRadInvestigationReportInvoice.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [uploadRadReportResult.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [uploadRadReportResult.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.rad_report_upload = action.payload
        },
        [uploadRadReportResult.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getClinicalData.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getClinicalData.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.clinical_data = action.payload
        },
        [getClinicalData.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [fetchAllInventories.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [fetchAllInventories.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.all_inventories = action.payload
        },
        [fetchAllInventories.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPharmacistInventoryItems.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPharmacistInventoryItems.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacist_inventory_items = action.payload
        },
        [getPharmacistInventoryItems.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getGatewaySettings.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getGatewaySettings.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.gateway_settings = action.payload
        },
        [getGatewaySettings.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createGatewaySetting.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createGatewaySetting.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.gateway_settings = [action.payload, ...state.gateway_settings]
        },
        [createGatewaySetting.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateGatewaySetting.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateGatewaySetting.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.gateway_settings.findIndex(type => type.id === action.payload.id)
            state.gateway_settings[index] = action.payload
        },
        [updateGatewaySetting.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteGatewaySetting.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteGatewaySetting.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.gateway_settings = state.gateway_settings.filter(type => type.id !== action.payload)
        },
        [deleteGatewaySetting.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [setCurrentSubscriptionPlan.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [setCurrentSubscriptionPlan.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.subscription_data = action.payload
        },
        [setCurrentSubscriptionPlan.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateSubscriptionPlan.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateSubscriptionPlan.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.subscription_data = action.payload
        },
        [updateSubscriptionPlan.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },
        [getSubscriptionPlan.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSubscriptionPlan.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.subscription_plan = action.payload
        },
        [getSubscriptionPlan.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },
        [SalesMutlipleDispense.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [SalesMutlipleDispense.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.multiple_dispense = action.payload
        },
        [SalesMutlipleDispense.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [DispenseMultipleItems.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [DispenseMultipleItems.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.patient_drugdispensed = action.payload
        },
        [DispenseMultipleItems.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [sendSmsTopupNotification.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [sendSmsTopupNotification.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
        },
        [sendSmsTopupNotification.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },
    }
})


export default pharmacistSlice.reducer