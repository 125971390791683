import { Fragment } from "react";

import { UpdatePasswordModal, WebCamModal } from "..";
import SendMailModal from '../modals/SendMailModal'
import SendSmsModal from '../modals/SendSmsModal'
import PharmacistFormModals from "./modals/PharmacistFormModals";
import CollectionsFormModals from './modals/CollectionsFormModals'
import ChatCreateGroupModal from "../modals/ChatCreateGroupModal";
import ChatAddGroupParticipantModal from "../modals/ChatAddGroupParticipantModal";
import CustomerAddOrderModal from "../modals/customer/CustomerAddOrderModal";
import CustomerPaymentMethodModal from "../modals/customer/CustomerPaymentMethodModal";
import CustomerProcessOrderModal from "../modals/customer/CustomerProcessOrderModal";
import CustomerViewOrderModal from "../modals/customer/CustomerViewOrderModal";
import CustomerOrderProvisionModal from "../modals/customer/CustomerOrderProvisionModal";
import CustomerProvisionPaymentMethodModal from "../modals/customer/CustomerProvisionPaymentMethodModal";
import CustomerProcessProvisionOrderModal from "../modals/customer/CustomerProcessProvisionOrderModal";
import ShowIncomingCallModal from "../modals/showIncomingCallModal";
import ShowCallNotificationsModal from "../modals/showCallNotificationsModal";


const FormModal = (props) => {
	// nurse_add_patient
	return (
		<Fragment>

			<CollectionsFormModals type={props.type} />

			<PharmacistFormModals type={props.type} />

			{props.type === 'customer__add__order' && (
				<CustomerAddOrderModal />
			)}

			{props.type === 'customer__view__paymentmethod__modal' && (
				<CustomerPaymentMethodModal />
			)}

			{props.type === 'customer__generate__order' && (
				<CustomerProcessOrderModal />
			)}

			{props.type === 'customer__view__order' && (
				<CustomerViewOrderModal />
			)}

			{props.type === 'customer__order__provision' && (
				<CustomerOrderProvisionModal />
			)}

			{props.type === 'customer__view__provision__paymentmodal' && (
				<CustomerProvisionPaymentMethodModal />
			)}

			{props.type === 'customer__generate__provision__order' && (
				<CustomerProcessProvisionOrderModal />
			)}

			{props.type === 'chat__creategroup' && (
				<ChatCreateGroupModal />
			)}

			{props.type === 'chat__addgroup_participant' && (
				<ChatAddGroupParticipantModal />
			)}

			{props.type === 'send__sms__modal' && (
				<SendSmsModal />
			)}

			{props.type === 'send__mail__modal' && (
				<SendMailModal />
			)}

			{props.type === "profile__update__password" && (
				<UpdatePasswordModal />
			)}

			{props.type === "profile__photo__webcam" && (
				<WebCamModal />
			)}
			{props.type === "show_incoming_call" && (
				<ShowIncomingCallModal
					activeLink={props.activeLink}
					setActiveLink={props.setActiveLink}

				/>

			)}
			{props.type === "show_call_notifications" && (
				<ShowCallNotificationsModal
				/>

			)}
		</Fragment >
	)
}

export default FormModal