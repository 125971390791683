/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { setCurrentDrug } from "../../../state/actions/actions"
import { getDrugCategories, getSupplyPoints, updateInventoryDrug } from "../../../state/actions/pharmacist.actions"


const PharmacistAddDrugModal = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const { drugCategories } = useSelector(state => state.pharmacist)
	const { drug_update } = useSelector(state => state.root)

	const [checkIsCounterItem, setCheckIsCounterItem] = useState(false)
	const [formData, setFormData] = useState({
		drug_category: '',
		title: "",
		drug_substitute: "",
		quantity: "",
		drug_alert_quantity: "",
		is_counter_item: false,
	})

	useEffect(() => {
		setCheckIsCounterItem(drug_update?.is_counter_item ? drug_update?.is_counter_item : checkIsCounterItem)
		setFormData({
			...formData,
			drug_category: drug_update?.drug_category,
			title: drug_update?.title,
			drug_substitute: drug_update?.drug_substitute,
			quantity: drug_update?.quantity,
			drug_alert_quantity: drug_update?.drug_alert_quantity,
			is_counter_item: drug_update?.is_counter_item
		})
	}, [drug_update?.id])

	useEffect(() => {
		dispatch(getDrugCategories())
		dispatch(getSupplyPoints())
	}, [])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleUpdate = (e) => {
		e.preventDefault()
		
		console.log(formData)
		dispatch(updateInventoryDrug({ formData: { ...formData, item_id: drug_update?.pkid }, toast, setModals, modals, dispatch, setCurrentDrug }))
	}

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className={`${drug_update ? 'text-sky-500' : 'text-dark-green'} text-[16px] font-bold capitalize`}>
							{modals.showPharmacistAddDrugModal ? 'Add Drug' : modals.showPharmacistUpdateDrugModal ? 'Update Drug' : 'View Drug'}
						</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								dispatch(setCurrentDrug({ data: null }))
								modals.showPharmacistUpdateDrugModal && setModals({ ...modals, showPharmacistUpdateDrugModal: !modals.showPharmacistUpdateDrugModal })
								modals.showPharmacistViewDrugModal && setModals({ ...modals, showPharmacistViewDrugModal: !modals.showPharmacistViewDrugModal })
							}}
							size={20}
						/>
					</div>

					{modals.showPharmacistUpdateDrugModal && (
						<form onSubmit={handleUpdate}>
							<div className="space-y-2">
								<div className="relative">
									<select
										className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
										name="drug_category"
										onChange={(e) => handleChange(e, e.target.name)}
									>
										<option>{drug_update?.drugcategory_details.category}</option>
										{drugCategories?.map((category, index) => (
											<option
												key={`${category.id}`}
												value={category.id}
											>{category.category}</option>
										))}
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg
											className="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>

								<div>
									<input
										type="text"
										name="title"
										placeholder="Medicine Name"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										value={formData?.title}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="text"
										name="drug_substitute"
										placeholder="Medicine Substitute"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										value={formData?.drug_substitute}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="hidden"
										name="quantity"
										placeholder="Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										value={formData?.quantity}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="number"
										name="drug_alert_quantity"
										placeholder="Alert Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										value={formData?.drug_alert_quantity}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div className="flex items-center space-x-3">
									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="locked"
											checked={formData?.is_counter_item}
											onChange={() => {
												setCheckIsCounterItem(!checkIsCounterItem);
												setFormData({ ...formData, is_counter_item: !checkIsCounterItem });
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Counter Item</p>
									</div>
								</div>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Update
								</button>
							</div>
						</form>
					)}

					{modals.showPharmacistViewDrugModal && (
						<div>
							<div className="space-y-2">
								<div>
									<label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded mb-[-10px]">Medicine Name</label>
									<input
										type="text"
										name="name"
										placeholder="Medicine Name"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update?.title}
									/>
								</div>

								<div>
									<label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded mb-[-10px]">Medicine Substitute</label>
									<input
										type="text"
										name="drug_substitute"
										placeholder="Medicine Substitute"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update?.drug_substitute}
									/>
								</div>

								<div>
									<label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded mb-[-10px]">Quantity</label>
									<input
										type="number"
										name="quantity"
										placeholder="Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update?.quantity}
									/>
								</div>

								<div>
									<label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded mb-[-10px]">Alert Quantity</label>
									<input
										type="number"
										name="alert_quantity"
										placeholder="Alert Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update?.drug_alert_quantity}
									/>
								</div>

								<div className="flex justify-between">
									{/* <label className={`${drug_update?.locked ? 'bg-yellow-500' : 'bg-teal-500'} text-white px-[10px] py-[4px] text-[10px] rounded mb-[-10px]`}>Locked: {drug_update?.locked ? 'True' : 'False'}</label> */}
									<label className={`${drug_update?.is_counter_item ? 'bg-purple-500' : 'bg-teal-500'} text-white px-[10px] py-[4px] text-[10px] rounded mb-[-10px]`}>Counter Drug: {drug_update?.is_counter_item ? 'True' : 'False'}</label>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default PharmacistAddDrugModal
