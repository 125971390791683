/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import { jsPDF } from 'jspdf'
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { PaymentStatus } from "../../utils/PaymentStatus"
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai"
import { getPharmacyConfig } from "../../../state/actions/auth.actions"


const CollectionsViewPharmacyReportsModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { pharmacy_config, user } = useSelector(state => state.auth)
    const { inventory_reports } = useSelector(state => state.collection)

    const [currentData, setCurrentData] = useState([])
    const [logobase64, setLogobase64] = useState('')
    const [filterState, setFilterState] = useState({ search: '', total: 0 })

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                setLogobase64(base64data)
                resolve(base64data);
            }
        });
    }

    useEffect(() => {
        dispatch(getPharmacyConfig())
    }, [])

    useEffect(() => {
        const logo = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`

        getBase64FromUrl(logo)
    }, [pharmacy_config])

    useEffect(() => {
        let total = 0   

        setCurrentData(inventory_reports)

        inventory_reports?.forEach(item => {
            total += item.invoice_amount
        })
        setFilterState({ ...filterState, total })
    }, [inventory_reports])

    useEffect(() => {
        let total = 0
        const filtered = inventory_reports?.filter(item => {
            return item.invoice_amount == filterState.search 
            || item.item_details?.title?.toLowerCase().includes(filterState.search.toLowerCase())
            || item.patient_details?.patient_id?.toLowerCase().includes(filterState.search.toLowerCase()) 
            || item.patient_details.patient_name.toLowerCase().includes(filterState.search.toLowerCase())
        })

        filtered?.forEach(item => {
            total += item.invoice_amount
        })
        setFilterState({ ...filterState, total })

        setCurrentData(filtered)

        if (filterState.search === '') {
            setCurrentData(inventory_reports)
        }
    }, [filterState.search])

    const exportXLSX = () => {
        let json_data = []
        inventory_reports?.forEach((invoice, index) => {
            const tableData = {
                'S/N': (index + 1),
                'Patient ID': invoice.patient_details.patient_id,
                'Invoice ID': invoice.invoice_number,
                'Drug': invoice.item_details.item,
                'Amount': invoice.invoice_amount,
                'Date': invoice.created_at,
                'Payment Author': invoice.payment_author && `${invoice.payment_author_details.last_name} ${invoice.payment_author_details.first_name}`,
                'Status': invoice.is_paid ? 'Paid' : 'Pending'
            }

            json_data.push(tableData)
        })

        console.log(json_data)
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.json_to_sheet(json_data)

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')
        XLSX.writeFile(wb, `Inventory Report - ${inventory_reports[0]?.created_at}.xlsx`)
    }

    const exportPdf = () => {
        const doc = new jsPDF({
            orientation: 'landscape'
        })
        doc.addImage(logobase64, 'JPEG', 15, 4, 8, 8)

        let total = 0
        const tableRows = []
        const tableColumns = ['S/N', 'Patient ID', 'Invoice ID', 'Drug', 'Amount(₦)', 'Date', 'Author', 'Status', 'Total']

        inventory_reports?.forEach((invoice, index) => {
            const tableData = [
                (index + 1),
                invoice.patient_details.patient_id,
                invoice.invoice_number,
                invoice.item_details.title,
                invoice.invoice_amount,
                invoice.created_at,
                invoice.payment_author && `${invoice.payment_author_details.last_name} ${invoice.payment_author_details.first_name}`,
                invoice.is_paid ? 'Paid' : 'Pending',
            ]
            total += invoice.invoice_amount
            tableRows.push(tableData)
        })

        const tableTotal = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `₦ ${total}`
        ]
        const emptyRow1 = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
        const emptyRow2 = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
        const emptyRow3 = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
        const signatureRow = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '________________________',
        ]
        const authorRow = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            user?.data?.first_name + ' ' + user?.data?.last_name,
        ]

        tableRows.push(tableTotal, emptyRow1, emptyRow2, emptyRow3, signatureRow, authorRow)
        doc.autoTable(tableColumns, tableRows)

        doc.text(`${pharmacy_config?.name} Inventory Report: ${inventory_reports[0]?.created_at}`, 30, 10)
        doc.save(`Inventory Report - ${inventory_reports[0]?.created_at} `)
    }
    

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[1000px] px-[5px] py-[5px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-dark-green text-[20px] font-bold">
                            {/* Laboratory Reports */}
                        </h4>
                        <div className="flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-300 cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
                            }}
                        >
                            <span className='w-2 h-2 rounded-full bg-orange-400'></span>
                            <p className='text-[10px] font-medium'>Close</p>
                        </div>
                    </div>

                    <div className="flex justify-between items-center space-x-4">
                        <div className="flex items-center space-x-5">
                            <input
                                type="text"
                                name="search"
                                placeholder='Search'
                                className="border border-gray-400 w-[220px] px-4 py-1 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                            />
                        </div>

                        <div className="flex items-center space-x-5">
                            <button
                                type="button"
                                className="flex items-center px-2 py-1 bg-sky-600 shadow-inner text-white font-bold text-[10px] leading-tight capitalize focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                onClick={exportPdf}
                            >
                                <span className="">Export Pdf</span>
                                <span className=""><AiOutlineFilePdf size={15} /></span>
                            </button>
                            <button
                                type="button"
                                className="flex items-center px-2 py-1 bg-indigo-600 shadow-inner text-white font-bold text-[10px] leading-tight capitalize focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                onClick={exportXLSX}
                            >
                                <span className="">Export Excel</span>
                                <span className=""><AiOutlineFileExcel size={15} /></span>
                            </button>
                        </div>
                    </div>

                    <table className="border-b border-l border-r rounded w-full cursor-default">
                        <thead className="">
                            <tr className='bg-gray-100'>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                >
                                    S/N
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Patient ID
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Invoice ID
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Item
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Amount
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Date
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                >
                                    Author
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                >

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData?.map((invoice, index) => (
                                <tr key={`${invoice.id}-${index}`}>
                                    <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                        {index + 1}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice?.patient_details?.patient_id}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice?.invoice_number}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice?.item_details?.title}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        &#8358; {invoice.invoice_amount}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice.created_at}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice.payment_author && `${invoice.payment_author_details.last_name} ${invoice.payment_author_details.first_name}`}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        <PaymentStatus status={invoice.is_paid} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="min-w-[200px] mx-auto flex justify-center font-medium bg-sky-200 p-2 rounded-full text-[14px]">
                        Total: &nbsp; &#8358;{filterState?.total}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionsViewPharmacyReportsModal