import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BsBagPlusFill, BsFillPlusCircleFill } from 'react-icons/bs'

import { useStateContext } from '../../state/context'
import { resetDrugBinCards, resetSupplyPointDrugBinCards, resetSupplyPointInventory } from '../../state/actions/pharmacist.actions'
import { setCurrentBinCard, setCurrentDrug, setCurrentSupplyPoint, setCurrentSupplyPointBinCard, setCurrentSupplyPointInventory, setCurrentPatient } from '../../state/actions/actions'
import { resetNewDiagnosis } from '../../state/actions/doctor.actions'


const PageTitle = ({ type, showDirectorateDropDown, setShowDirectorateDropDown, directorateDropdownItem, setDirectorateDropdownItem, showAddDirectorateModal, setShowAddDirectorateModal, showAddDepartmentModal, setShowAddDepartmentModal, ...props }) => {
    const dispatch = useDispatch()

    const { appState, setAppState, modals, setModals, chatState, setChatState } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const { drug_update, supplypoint_update } = useSelector(state => state.root)

    return (
        <div className="flex justify-between items-center">
            <div className="flex flex-col space-y-[-13px]">
                {type === 'pharmacist-drugs-category' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Drugs Category</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Drug category management.</p>
                    </Fragment>
                )}

                {type === 'pharmacist-inventory' && (
                    <Fragment>
                        {!appState.showDrugInventory && (
                            <Fragment>
                                <h1 className='text-[16px] text-dark-blue font-bold'>Inventory</h1>
                                <p className='text-[12px] text-gray-600 font-normal'>Inventory management.</p>
                            </Fragment>
                        )}
                        {appState.showDrugInventory && (
                            <Fragment>
                                <h1 className='text-[16px] text-dark-blue font-bold'>{drug_update?.title}</h1>
                                <p className='text-[12px] text-gray-600 font-normal'>{drug_update?.title} card management.</p>
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {type === 'settings' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Settings</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Settings management.</p>
                    </Fragment>
                )}

                {type === 'pharmacist-staff' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Staff</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Pharmacy staff management.</p>
                    </Fragment>
                )}

                {type === 'pharmacist-supply-point' && (
                    <Fragment>
                        <Fragment>
                            {!appState.showManageBinCard && (
                                <Fragment>
                                    <h1 className='text-[16px] text-dark-blue font-bold'>{user?.data?.assigned_supplypoint_details?.name}</h1>
                                    <p className='text-[12px] text-gray-600 font-normal'>{user?.data?.assigned_supplypoint_details?.name} supply point management.</p>
                                </Fragment>
                            )}

                            {appState.showManageBinCard && (
                                <Fragment>
                                    <h1 className='text-[16px] text-dark-blue font-bold'>Bin Card</h1>
                                    <p className='text-[12px] text-gray-600 font-normal'>Paracetamol bin card management.</p>
                                </Fragment>
                            )}
                        </Fragment>
                    </Fragment>
                )}
                {type === 'pharmacist-investigations' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Patients</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Patients management.</p>
                    </Fragment>
                )}

                {type === 'pharmacist-supply-points' && (
                    <Fragment>
                        {!appState.showSupplyPoint && (
                            <Fragment>
                                <h1 className='text-[16px] text-dark-blue font-bold'>Supply Points</h1>
                                <p className='text-[12px] text-gray-600 font-normal'>Supply points management.</p>
                            </Fragment>
                        )}
                        {appState.showSupplyPoint && (
                            <Fragment>
                                {!appState.showManageBinCard && (
                                    <Fragment>
                                        <h1 className='text-[16px] text-dark-blue font-bold'>{supplypoint_update?.name}</h1>
                                        <p className='text-[12px] text-gray-600 font-normal'>{supplypoint_update?.name} supply point.</p>
                                    </Fragment>
                                )}
                                {appState.showManageBinCard && (
                                    <Fragment>
                                        <h1 className='text-[16px] text-dark-blue font-bold'>Bin Card</h1>
                                        <p className='text-[12px] text-gray-600 font-normal'>Paracetamol bin card management.</p>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {type === 'pharmacist-prescriptions' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Prescriptions</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Prescriptions management.</p>
                    </Fragment>
                )}

                {type === 'pharmacist-bills' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Bills | Dispense</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Bills and drugs dispense management.</p>
                    </Fragment>
                )}

                {type === 'customer-invoices' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Invoices</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Invoices management.</p>
                    </Fragment>
                )}

                {type === 'customer-orders' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Orders</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Prescription order management.</p>
                    </Fragment>
                )}

                {type === 'chat' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Chat</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Chat and video call communication.</p>
                    </Fragment>
                )}

                {type === 'messages' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Text SMS</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Text sms management.</p>
                    </Fragment>
                )}

                {type === 'emails' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Emails</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Email management.</p>
                    </Fragment>
                )}

                {type === 'schedule' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Schedules</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Schedule management.</p>
                    </Fragment>
                )}

                {type === 'profile' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Profile</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Profile management.</p>
                    </Fragment>
                )}
            </div>


            {type === 'pharmacist-drugs-category' && (
                <div className="flex items-center">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showPharmacistAddDrugCategoryModal: !modals.showPharmacistAddDrugCategoryModal })}
                    >
                        Add Drug Category
                    </button>
                </div>
            )}

            {type === 'pharmacist-inventory' && (
                <Fragment>
                    {appState.showDrugInventory && (
                        <div className="flex justify-between space-x-5">
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-4 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:translate-x-1 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                    onClick={() => setModals({ ...modals, showPharmacistBinCardModal: !modals.showPharmacistBinCardModal })}
                                >
                                    <span className="flex justify-between items-center"><BsFillPlusCircleFill className='mr-2' />Add To Bin Card</span>
                                </button>
                            </div>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:translate-x-1 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                    onClick={() => setModals({ ...modals, showPharmacistDrugSupplyPointModal: !modals.showPharmacistDrugSupplyPointModal })}
                                >
                                    <span className="flex justify-between items-center"><BsBagPlusFill className='mr-2' />Release To Supply Point</span>
                                </button>
                            </div>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => {
                                        dispatch(setCurrentDrug({ data: null }))
                                        dispatch(setCurrentBinCard({ data: null }))
                                        dispatch(resetDrugBinCards())
                                        setAppState({ ...appState, showDrugInventory: !appState.showDrugInventory })
                                    }}
                                >
                                    Exit
                                </button>
                            </div>
                        </div>
                    )}

                    {!appState.showDrugInventory && (
                        <div className="flex items-center space-x-5">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddInventoryModal: !modals.showPharmacistAddInventoryModal })}
                            >
                                Add Inventory
                            </button>

                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddDrugCategoryModal: !modals.showPharmacistAddDrugCategoryModal })}
                            >
                                Add Drug Category
                            </button>
                        </div>
                    )}

                </Fragment>
            )}

            {type === 'pharmacist-staff' && (
                <div className="flex justify-between space-x-5 items-center">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showPharmacistAddStaffModal: !modals.showPharmacistAddStaffModal })}
                    >
                        Add Staff
                    </button>
                </div>
            )}

            {type === 'pharmacist-provisions' && (
                <div className="flex justify-between space-x-5 items-center">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showPharmacistAddProvisionModal: !modals.showPharmacistAddProvisionModal })}
                    >
                        Add Provision
                    </button>
                </div>
            )}

            {type === 'pharmacist-supply-point' && (
                <Fragment>
                    {!appState.showManageBinCard && (
                        <div className="flex justify-between space-x-5 items-center">
                            {!user?.data?.is_sales && (
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => setModals({ ...modals, showPharmacistBinCardSummaryModal: !modals.showPharmacistBinCardSummaryModal })}
                                >
                                    Bin Card Summary
                                </button>
                            )}
                        </div>
                    )}
                    {appState.showManageBinCard && (
                        <div className="flex justify-between space-x-5 items-center">
                            {!user?.data?.is_sales && (
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => setModals({ ...modals, showPharmacistLendRefundDrugModal: !modals.showPharmacistLendRefundDrugModal })}
                                >
                                    Lend/Return To Supply Point
                                </button>
                            )}
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => {
                                    dispatch(setCurrentSupplyPointBinCard({ data: null }))
                                    dispatch(resetSupplyPointDrugBinCards({ data: null }))
                                    dispatch(setCurrentSupplyPointInventory({ data: null }))
                                    // setAppState({ ...appState, showSupplyPoint: true })
                                    setAppState({ ...appState, showManageBinCard: !appState.showManageBinCard })
                                }}
                            >
                                Exit
                            </button>
                        </div>
                    )}
                </Fragment>
            )}

            {type === 'pharmacist-supply-points' && (
                <Fragment>
                    {!appState.showSupplyPoint && (
                        <div className="flex justify-between space-x-5 items-center">
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => setModals({ ...modals, showPharmacistAssignSupplyPointModal: !modals.showPharmacistAssignSupplyPointModal })}
                                >
                                    Assign Pharmacist
                                </button>
                            </div>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => setModals({ ...modals, showPharmacistAddSupplyPointModal: !modals.showPharmacistAddSupplyPointModal })}
                                >
                                    Add Supply Point
                                </button>
                            </div>
                        </div>
                    )}
                    {appState.showSupplyPoint && (
                        <Fragment>
                            {!appState.showManageBinCard && (
                                <div className="flex justify-between space-x-5 items-center">
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-teal-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistSupplyPointStaffModal: !modals.showPharmacistSupplyPointStaffModal })}
                                    >
                                        Staff
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistSupplyPointAssignStaffModal: !modals.showPharmacistSupplyPointAssignStaffModal })}
                                    >
                                        Assign Staff
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistBinCardSummaryModal: !modals.showPharmacistBinCardSummaryModal })}
                                    >
                                        Bin Card Summary
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            dispatch(setCurrentSupplyPoint({ data: null }))
                                            dispatch(resetSupplyPointInventory())
                                            setAppState({ ...appState, showSupplyPoint: !appState.showSupplyPoint })
                                        }}
                                    >
                                        Exit
                                    </button>
                                </div>
                            )}
                            {appState.showManageBinCard && (
                                <div className="flex justify-between space-x-5 items-center">
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistLendRefundDrugModal: !modals.showPharmacistLendRefundDrugModal })}
                                    >
                                        Lend/Return To Supply Point
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            dispatch(setCurrentSupplyPointBinCard({ data: null }))
                                            dispatch(resetSupplyPointDrugBinCards({ data: null }))
                                            dispatch(setCurrentSupplyPointInventory({ data: null }))
                                            setAppState({ ...appState, showSupplyPoint: true })
                                            setAppState({ ...appState, showManageBinCard: !appState.showManageBinCard })
                                        }}
                                    >
                                        Exit
                                    </button>
                                </div>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
            {type === 'pharmacist-investigations' && (
                <Fragment>
                    {appState.showDoctorManagePatient && (
                        <div className="flex justify-between space-x-5 items-center">
                            {/* <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showDoctorTransferPatientModal: !modals.showDoctorTransferPatientModal })}
                            >
                                Transfer
                            </button> */}
                            <Fragment>
                                {!appState.showDoctorPerformNewDiagnosis && (
                                    <Fragment>
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => setAppState({ ...appState, showDoctorPerformNewDiagnosis: !modals.showDoctorPerformNewDiagnosis })}
                                        >
                                            New Diagnosis
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-violet-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => {
                                                dispatch(setCurrentPatient({ data: null }))
                                                dispatch(resetNewDiagnosis())
                                                setAppState({ ...appState, showDoctorManagePatient: !appState.showDoctorManagePatient })
                                            }}
                                        >
                                            Exit
                                        </button>
                                    </Fragment>
                                )}
                                {appState.showDoctorPerformNewDiagnosis && (
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            dispatch(resetNewDiagnosis())
                                            setAppState({ ...appState, showDoctorPerformNewDiagnosis: false })
                                        }}
                                    >
                                        Complete Diagnosis
                                    </button>
                                )}
                            </Fragment>

                        </div>
                    )}
                </Fragment>
            )}


            {type === 'customer-invoices' && (
                <div className="flex items-start space-x-5">
                    {/* <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-sky-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showCustomerAddProvisionModal: !modals.showCustomerAddProvisionModal })}
                    >
                        Purchase Provision
                    </button> */}
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-purple-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showCustomerAddOrderModal: !modals.showCustomerAddOrderModal })}
                    >
                        Purchase Item
                    </button>
                </div>
            )}

            {type === 'profile' && (
                <div className="flex items-start">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-sky-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, updatePasswordModal: !modals.updatePasswordModal })}
                    >
                        Change Password
                    </button>
                </div>
            )}

            {type === 'settings' && (
                <Fragment>
                    {appState.showSettingsBillings && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddBillingModal: !modals.showPharmacistAddBillingModal })}
                            >
                                Add Billing
                            </button>
                        </div>
                    )}
                    {appState.showSettingsLaboratory && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddInvestigationSampleModal: !modals.showPharmacistAddInvestigationSampleModal })}
                            >
                                Add Test Sample
                            </button>

                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddInvestigationCategoryModal: !modals.showPharmacistAddInvestigationCategoryModal })}
                            >
                                Add Test Category
                            </button>

                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddInvestigationType: !modals.showPharmacistAddInvestigationType })}
                            >
                                Add Test Type
                            </button>
                        </div>
                    )}

                    {appState.showSettingsRadiology && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddRadiologyInvestigationCategoryModal: !modals.showPharmacistAddRadiologyInvestigationCategoryModal })}
                            >
                                Add Test Category
                            </button>

                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddRadiologyInvestigationTypeModal: !modals.showPharmacistAddRadiologyInvestigationTypeModal })}
                            >
                                Add Test Type
                            </button>
                        </div>
                    )}

                    {appState.showSettingsGateway && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddGatewaySettingModal: !modals.showPharmacistAddGatewaySettingModal })}
                            >
                                Add Gateway
                            </button>
                        </div>
                    )}
                </Fragment>
            )}

            {type === 'chat' && (
                <div className="flex items-center space-x-4">
                    {/* <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    // onClick={() => props?.setShowCreateWardModal(!props?.showCreateWardModal)}
                    >
                        New Chat
                    </button> */}
                    {user.data.subscription_plan === "ADVANCED" && chatState?.showVideoCallUI && (
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-indigo-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-blue active:shadow-lg transition duration-500 ease"
                            onClick={() => setChatState({ ...chatState, showGroupCallUI: !chatState?.showGroupCallUI })}
                        >
                            {!chatState.showGroupCallUI ? 'Group Call' : 'Single Call'}
                        </button>
                    )}
                    {user.data.subscription_plan === "ADVANCED" && <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-blue active:shadow-lg transition duration-500 ease"
                        onClick={() => setChatState({ ...chatState, showVideoCallUI: !chatState?.showVideoCallUI })}
                    >
                        {!chatState?.showVideoCallUI ? 'Video Call' : 'Text Chat'}
                    </button>}
                </div>
            )}
        </div >
    )
}

export default PageTitle