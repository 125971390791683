/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import EmojiData from '@emoji-mart/data'
import EmojiPicker from '@emoji-mart/react'
import { RiSendPlane2Fill } from 'react-icons/ri'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillFilePdf, AiOutlinePaperClip } from 'react-icons/ai'
import { BsFillEmojiSmileFill, BsImageFill } from 'react-icons/bs'

import Role from '../utils/Role'
import ChatBubble from './ChatBubble'
import { useStateContext } from '../../state/context'
import { getCurrentChatMessages, getNextPageMessages, sendChatDocument, sendChatImage, sendGroupChatMessage, sendSingleChatMessage, setActiveChatState, setCurrentChatMessages, updateConversationMessages } from '../../state/actions/chat.actions'


const ChatInterface = (props) => {
    const dispatch = useDispatch()

    const { socket } = useStateContext()
    const { user } = useSelector(state => state.auth)
    const { current_chatuser, current_chat_messages, active_chat, current_chatconversation } = useSelector(state => state.chat)

    const ImagePicker = useRef(null)
    const FilePicker = useRef(null)
    const [formData, setFormData] = useState({ message: '', attachments: '', caption: '', attachment_previews: '', attachment_type: '', attachment_names: '' })
    const [messages, setMessages] = useState([])
    const [fetching, setFetching] = useState(true)
    const [pagination, setPagination] = useState({ next_page: 1, can_go_next: false })
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [showFileTypeDropdown, setShowFileTypeDropdown] = useState(false)

    const scrollToBottom = () => {
        setTimeout(() => {
            let chatArea = document.getElementById("chatArea")

            if (chatArea?.scrollHeight > 100) {
                chatArea.scrollTop = chatArea?.scrollHeight
            }
        }, 300)
    }

    useEffect(() => {
        dispatch(getCurrentChatMessages({ params: { conversation_id: current_chatconversation?.pkid }, dispatch, setActiveChatState }))
    }, [current_chatuser])

    useEffect(() => {
        if (current_chatconversation?.conversation_type === 2) {
            socket.emit('join_group', { conversation_name: current_chatconversation?.conversation_name }, () => { })
        }
    }, [current_chatconversation])

    useEffect(() => {
        const formData = {
            conversation_id: current_chatconversation?.pkid,
        }

        dispatch(updateConversationMessages({ formData }))
    }, [current_chatconversation])

    useEffect(() => {
        if (!current_chat_messages?.previous) {
            setPagination({ ...pagination, can_go_next: false })
        
            Object.freeze(current_chat_messages?.results)
            setMessages(current_chat_messages?.results)
            dispatch(setCurrentChatMessages({ data: current_chat_messages?.results }))

            if (current_chat_messages?.next) {
                setPagination({
                    ...pagination,
                    can_go_next: true,
                    next_page: pagination.next_page + 1
                })
                setFetching(false)
            }
            setFetching(false)
            scrollToBottom()
        }
    }, [current_chat_messages])

    useEffect(() => {
        if (current_chatconversation?.pkid == active_chat?.conversation) {
            dispatch(getCurrentChatMessages({ params: { conversation_id: active_chat?.conversation }, dispatch, setActiveChatState }))
        }
    }, [active_chat])


    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    const handleEmojiClick = (emoji) => {
        setFormData({ ...formData, message: formData.message.concat(emoji.native) })
    }

    const handleFilesChange = (e) => {
        const files = [], file_previews = [], file_types = [], file_names = []

        for (let i = 0; i < e.target.files.length; i++) {
            const url = URL.createObjectURL(e.target.files[i])

            file_types.push(e.target.files[i].type)
            file_names.push(e.target.files[i].name)
            file_previews.push(url)
            files.push(e.target.files[i])
        }

        setFormData({
            ...formData,
            attachments: files,
            attachment_previews: file_previews,
            attachment_type: file_types[0],
            attachment_names: file_names,
        })
    }

    const handleScroll = (e) => {
        if (e.target.scrollTop <= 80) {
            if (pagination.can_go_next) {
                const chatArea = e.target
                const savedHeight = e.target.offsetHeight

                const url = current_chat_messages?.next?.split('http://localhost:8000/api/v1/chat/')[1]

                dispatch(getNextPageMessages({ url, messages, setMessages, pagination, setPagination, chatArea, savedHeight }))
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let params = {
            chat_conversation: current_chatconversation?.pkid,
            message: formData.message,
            receiver: current_chatconversation?.receiver
        }

        setFormData({ ...formData, message: '' })

        if (current_chatconversation?.conversation_type === 1) {
            return dispatch(sendSingleChatMessage({ params, messages, setMessages, scrollToBottom }))
        }

        if (current_chatconversation?.conversation_type === 2) {
            const message = {
                conversation_id: current_chatconversation?.pkid,
                conversation_name: current_chatconversation?.conversation_name,
                message: formData.message
            }
            return dispatch(sendGroupChatMessage({ params, message, messages, setMessages, scrollToBottom, socket }))
        }

        console.log(current_chatconversation)
    }

    const handleMediaUpload = (e) => {
        e.preventDefault()

        let params = {
            chat_conversation: current_chatconversation?.pkid,
            attachments: formData.attachments,
            conversation_name: current_chatconversation?.conversation_name
        }

        if (current_chatconversation?.conversation_type === 1 && formData.attachment_type.includes('image')) {
            return dispatch(sendChatImage({ params, formData, setFormData, messages, setMessages, scrollToBottom, socket }))
        }

        if (current_chatconversation?.conversation_type === 1 && !formData.attachment_type.includes('image')) {
            return dispatch(sendChatDocument({ params, formData, setFormData, messages, setMessages, scrollToBottom, socket }))
        }

        if (current_chatconversation?.conversation_type === 2 && formData.attachment_type.includes('image')) {
            return dispatch(sendChatImage({ params, formData, setFormData, messages, setMessages, scrollToBottom, socket }))
        }

        if (current_chatconversation?.conversation_type === 2 && !formData.attachment_type.includes('image')) {
            return dispatch(sendChatDocument({ params, formData, setFormData, messages, setMessages, scrollToBottom, socket }))
        }
    }

    return (
        <div className={`${!props.showUserProfile ? 'flex-[0.7]' : 'flex-[0.5]'}`}>
            <div className="px-4 py-2 flex justify-between items-center rounded-t-lg border-b border-b-gray-200 shadow__bottom">
                <div className="flex items-center">
                    <img
                        src={`${current_chatuser?.profile_photo ? `http://localhost:8000${current_chatuser?.profile_photo}` : current_chatuser?.conversation_icon}`}
                        alt="user"
                        className='mr-2 w-[50px] h-[50px] rounded-[100%]'
                    />

                    <div className="flex flex-col -space-y-4">
                        <p className="text-[12px] text-gray-600">{current_chatuser?.last_name ? `${current_chatuser?.last_name} ${current_chatuser?.first_name}` : `${current_chatuser?.conversation_name}`}</p>
                        {!current_chatuser?.is_online && (
                            <Role user={current_chatuser} type={current_chatuser?.first_name ? 'chat' : 'group'} />
                        )}

                        {current_chatuser?.is_online && (
                            <div className="flex space-x-2">
                                <Role user={current_chatuser} type={current_chatuser?.first_name ? 'chat' : 'group'} />
                                <p className="text-[8px] text-teal-600 font-medium underline">Online</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="relative">
                    <BiDotsVerticalRounded
                        className='text-gray-300 cursor-pointer'
                        onClick={() => props.setShowProfileDropDown(!props.showProfileDropDown)}
                    />

                    {props.showProfileDropDown && (
                        <div className="absolute right-2 mt-[4px] z-[3] bg-white px-6 py-2 shadow-md rounded">
                            <p className="cursor-pointer text-[12px] text-sky-500"
                                onClick={() => {
                                    props.setShowProfileDropDown(!props.showProfileDropDown)
                                    props.setShowUserProfile(!props.showUserProfile)
                                }}
                            >
                                Profile
                            </p>
                        </div>
                    )}
                </div>
            </div>

            <div className="relative flex flex-col justify-between">
                <div
                    id='chatArea'
                    onScroll={handleScroll}
                    className='space-y-3 pb-10 h-[55vh] overflow-y-auto chat bg-[url("/src/assets/images/art__bg__1.svg")] bg-opacity-5 bg-repeat'
                >

                    {!fetching && messages?.length > 0 && [...messages].reverse()?.map((item, index) => (
                        <ChatBubble
                            key={`${item.id}-${index}`}
                            data={item}
                            index={index}
                            type={item.sender === user?.data?.pkid ? 'sender' : 'receiver'}
                        />
                    ))}
                </div>

                <div className="bottom-0 -mb-[5px] w-full">
                    <form onSubmit={handleSubmit} className="relative">
                        <textarea
                            name="message"
                            cols="30"
                            placeholder='Start a message...'
                            className='border-2 border-blue-200 resize-none pl-2 pr-[8em] pt-1 text-[12px] font-normal w-full rounded-[15px] placeholder:text-[12px] placeholder:italic focus:outline-none focus:border-blue-500 focus:ring-blue leading-[15px] duration-300 ease-in users'
                            rows="4"
                            value={formData.message}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        ></textarea>

                        <div className="absolute right-0 top-2 flex justify-between items-center space-x-3 pr-2">
                            <BsFillEmojiSmileFill
                                size={30}
                                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                className={`${showEmojiPicker ? '' : 'text-orange-400'} cursor-pointer`}
                            />

                            <div className="relative">
                                <AiOutlinePaperClip
                                    size={30}
                                    // onClick={() => ImagePicker.current.click()}
                                    onClick={() => setShowFileTypeDropdown(!showFileTypeDropdown)}
                                    className='text-slate-600 cursor-pointer'
                                />
                                {showFileTypeDropdown && (
                                    <div className="absolute top-7 bg-slate-200 rounded shadow-md w-[120px] p-1 -left-10 flex space-x-4">
                                        <BsImageFill
                                            size={30}
                                            onClick={() => {
                                                setShowFileTypeDropdown(!showFileTypeDropdown)
                                                ImagePicker.current.click()
                                            }}
                                            className='text-slate-600 cursor-pointer'
                                        />
                                        <AiFillFilePdf
                                            size={30}
                                            onClick={() => {
                                                FilePicker.current.click()
                                                setShowFileTypeDropdown(!showFileTypeDropdown)
                                            }}
                                            className='text-slate-600 cursor-pointer'
                                        />
                                    </div>
                                )}
                            </div>

                            <button type='submit'>
                                <RiSendPlane2Fill
                                    size={35}
                                    className="text-gray-800 rounded p-[2px] cursor-pointer"
                                />
                            </button>
                        </div>

                        {/* emoji picker */}
                        <div className="absolute bottom-[3em] right-0">
                            {showEmojiPicker && (
                                <EmojiPicker
                                    data={EmojiData}
                                    onEmojiSelect={handleEmojiClick}
                                />
                            )}
                        </div>
  
                        {/* fileupload */}
                        <div className="absolute bottom-[-20px] right-0 w-full bg-slate-600">
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                ref={ImagePicker}
                                name="attachments"
                                className='hidden'
                                onChange={handleFilesChange}
                            />
                            <input
                                type="file"
                                multiple
                                accept=".doc, .docx, .xml, .pdf, .xlsx"
                                ref={FilePicker}
                                name="attachments"
                                className='hidden'
                                onChange={handleFilesChange}
                            />

                            {formData.attachments.length > 0 && (
                                <div>
                                    <div className="w-full px-2 pt-1 flex justify-end">
                                        <div className="flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-300 cursor-pointer"
                                            onClick={() => {
                                                setFormData({ ...formData, attachments: '', attachment_previews: '' })
                                            }}
                                        >
                                            <span className='w-2 h-2 rounded-full bg-orange-400'></span>
                                            <p className='text-[10px] font-medium'>Close</p>
                                        </div>
                                    </div>

                                    <div className="w-full flex justify-start items-start space-x-4 overflow-x-auto py-4 px-2 shadow-sm scroll__hide">
                                        {formData.attachments.length > 0 && formData.attachment_type.includes('image/') && formData.attachment_previews.map((url, index) => (
                                            <img
                                                key={index}
                                                src={url}
                                                alt='img'
                                                className='w-[100px] h-[100px] rounded-md'
                                            />
                                        ))}
                                        {formData.attachments.length > 0 && !formData.attachment_type.includes('image/') && formData.attachment_names.map((name, index) => (
                                            <p
                                                key={index}
                                                className='text-[12px] text-white font-normal leading-[16px]'
                                            >{name}</p>
                                        ))}
                                    </div>

                                    <div>
                                        <div className="flex justify-between items-center px-2 py-2">
                                            <input
                                                type="text"
                                                name="caption"
                                                placeholder="Add a caption to this"
                                                autoComplete='off'
                                                className="border bg-transparent rounded-full w-full px-4 text-[12px] text-white hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />

                                            <button type='submit'>
                                                <RiSendPlane2Fill
                                                    size={35}
                                                    onClick={handleMediaUpload}
                                                    className="text-white rounded p-[2px] cursor-pointer"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChatInterface
