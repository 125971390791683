import React, { useState, useEffect } from 'react'
import { GiResize, GiRotaryPhone } from 'react-icons/gi'
import { BsFillMicMuteFill } from 'react-icons/bs'
import { BsMicFill } from 'react-icons/bs'
import { BsFillCameraVideoOffFill } from 'react-icons/bs'
import { BsFillCameraVideoFill } from 'react-icons/bs'
import { MdOutlineScreenShare } from 'react-icons/md'
import * as roomHandler from '../../config/groupcallConfig/roomHandler'
import { useDispatch, useSelector } from 'react-redux'
import VideoContainer from './VideoContainer'
import * as webRTCHandler from '../../config/groupcallConfig/webRTCHandler'
import reducer from '../../state/store'
import { setScreenSharingStream, setAudioOnly } from '../../state/actions/roomActions'


const constraints = {
    audio: false,
    video: true

}

const Room = ({ isRoomMinimized, setIsRoomMinimized, roomResizeHandler }) => {


    const dispatch = useDispatch()

    const [isMicMuted, setIsMicMuted] = useState(false)
    const [isCameraOn, setIsCameraOn] = useState(true)

    const { localStream,
        screenSharingStream,

        isScreenSharingActive, } = useSelector(state => state.room);
    const handleleaveRoom = () => {
        roomHandler.leaveRoom();
        dispatch(setScreenSharingStream(null));
    }

    const handleToggleCamera = () => {
        localStream.getVideoTracks()[0].enabled = !isCameraOn;
        setIsCameraOn(!isCameraOn);

    }

    const handleToggleMic = () => {
        setIsMicMuted(!isMicMuted);
        console.log('audio status', isMicMuted);
        localStream.getAudioTracks()[0].enabled = isMicMuted;


    }

    const handleScreenShareToggle = async () => {


        if (!isScreenSharingActive) {
            let stream = null;
            try {
                stream = await navigator.mediaDevices.getDisplayMedia(constraints);

            } catch (err) {
                console.log(
                    "error occured when trying to get an access to screen share stream"
                );
            }

            if (stream) {
                dispatch(setScreenSharingStream(stream));
                webRTCHandler.switchOutgoingTracks(stream);
            }
        } else {
            webRTCHandler.switchOutgoingTracks(localStream);
            screenSharingStream.getTracks().forEach((t) => t.stop());
            dispatch(setScreenSharingStream(null));
        }
    };



    return (
        <div className={`${isRoomMinimized ? 'absolute mr-2  h-[200px] w-[400px] bottom-0 right-0' : 'h-[600px] w-full '} bg-[#000000]`}>

            <VideoContainer isRoomMinimized={isRoomMinimized} />
            <div className={`flex justify-between py-1 px-2 h-[30px] bg-indigo-700  ${isRoomMinimized ? 'absolute bottom-0 flex space-x-  mb-1 w-[400px]' : 'absolute bottom-2 flex space-x-2 mb-6 w-[600px]'}`}>

                <div className='flex space-x-4'>
                    <MdOutlineScreenShare color='white' size={20}
                        onClick={() => handleScreenShareToggle()}

                    />

                    <GiResize color='white' size={20}
                        onClick={() => setIsRoomMinimized(!isRoomMinimized)}

                    />
                    {isMicMuted && <
                        BsFillMicMuteFill
                        color='white' size={20}
                        onClick={() => handleToggleMic()}

                    />}
                    {!isMicMuted && <
                        BsMicFill
                        BsFillMicMuteFill
                        color='white' size={20}
                        onClick={() => handleToggleMic()}

                    />}
                    {isCameraOn && <
                        BsFillCameraVideoFill
                        color='white' size={20}
                        onClick={() => handleToggleCamera()}

                    />}
                    {!isCameraOn && <
                        BsFillCameraVideoOffFill
                        color='white' size={20}
                        onClick={() => handleToggleCamera()}

                    />}
                </div>
                <div className='mb-3'>
                    <GiRotaryPhone
                        onClick={() => handleleaveRoom()}
                        className='bg-red mb-2'
                        color='orange' size={30}

                    />
                </div>
            </div>
        </div>
    )
}

export default Room