/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { getDrugCategories, getDrugs, getInventoryDrugs, getInventoryProvisions, getProvisions } from '../../state/actions/pharmacist.actions'
import { PageTitle, PharmacistDrugsCategoryTable, PharmacistDrugsTable, PharmacistManageDrugTable, PharmacistProvisionsTable } from '../../components/'


const PharmacistInventory = () => {
	const dispatch = useDispatch()

	const { appState, setAppState } = useStateContext()

	const { drugCategories, inventory_item, inventory_drugs, inventory_provisions } = useSelector(state => state.pharmacist)

	const [showList, setShowList] = useState(true)
	const [activeLink, setActiveLink] = useState("Drug Category")
	const [filterState, setFilterState] = useState({ search: '', drug_categories: [], inventory_drugs: [], inventory_provisions: [] })

	const handleDrugCategoryFilter = () => {
		const filtered = drugCategories?.filter(item => {
			return item.category.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, drug_categories: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, drug_categories: drugCategories })
		}
	}

	const handleDrugsFilter = () => {
		const filtered = inventory_drugs?.filter(item => {
			return item.title.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, inventory_drugs: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, inventory_drugs })
		}
	}

	const handleProvisonsFilter = () => {
		const filtered = inventory_provisions?.filter(item => {
			return item.title.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, inventory_provisions: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, inventory_provisions })
		}
	}

	useEffect(() => {
		dispatch(getInventoryDrugs())
		dispatch(getDrugCategories())
		dispatch(getInventoryProvisions())
	}, [inventory_item, appState.showDrugInventory])

	useEffect(() => {
		dispatch(getDrugs())
	}, [appState.showDrugInventory])

	useEffect(() => {
		return () => {
			setAppState({ ...appState, showDrugInventory: false })
		}
	}, [])

	useEffect(() => {
		if (activeLink === 'Drug Category') {
			handleDrugCategoryFilter()
		}
		if (activeLink === 'Drugs') {
			handleDrugsFilter()
		}
		if (activeLink === 'Provisions') {
			handleProvisonsFilter()
		}
	}, [filterState.search])

	useEffect(() => {
		setFilterState({ ...filterState, drug_categories: drugCategories, inventory_drugs, inventory_provisions })
	}, [drugCategories?.length, inventory_drugs?.length, inventory_provisions?.length])


	return (
		<div>
			<PageTitle type={'pharmacist-inventory'} />

			<div className="space-y-5">
				{!appState.showDrugInventory && (
					<div>
						<div className="flex space-x-5 justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
							<div className="space-x-5">
								<button
									type="button"
									className={`${activeLink === 'Drug Category' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
									onClick={(e) => setActiveLink(e.target.textContent)}
								>
									Drug Category
								</button>

								<button
									type="button"
									className={`${activeLink === 'Drugs' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
									onClick={(e) => setActiveLink(e.target.textContent)}
								>
									Drugs
								</button>

								<button
									type="button"
									className={`${activeLink === 'Provisions' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
									onClick={(e) => setActiveLink(e.target.textContent)}
								>
									Provisions
								</button>
							</div>

							<div>
								<input
									type="text"
									name="search"
									// disabled
									placeholder='Search'
									className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
									onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
								/>
							</div>
						</div>

						{activeLink === "Drug Category" && (
							<PharmacistDrugsCategoryTable
								title={'Drug Category'}
								data={filterState.drug_categories}
								showList={showList}
								setShowList={setShowList}
							/>
						)}

						{activeLink === "Drugs" && (
							<PharmacistDrugsTable
								title={'Drugs'}
								data={filterState.inventory_drugs}
								showList={showList}
								setShowList={setShowList}
							/>
						)}

						{activeLink === "Provisions" && (
							<PharmacistProvisionsTable
								title={'Provisions'}
								data={filterState.inventory_provisions}
								showList={showList}
								setShowList={setShowList}
							/>
						)}
					</div>
				)}

				{appState.showDrugInventory && (
					<PharmacistManageDrugTable
						title={'Manage Paracetamol'}
						showList={showList}
						setShowList={setShowList}
					/>
				)}
			</div>
		</div>
	)
}

export default PharmacistInventory