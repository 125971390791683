import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setLaboratoryReport } from '../../../state/actions/actions'
import { setRadiologyGenerateInvoice } from '../../../state/actions/actions'
import { uploadLabReportResult } from '../../../state/actions/pharmacist.actions'


const LaboratoryUploadReport = () => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()

    const { set_laboratory_reports } = useSelector((state) => state.root)
    const [formData, setFormData] = useState({
        investigation: '',
        image_report: {},
        photo_display: '',
        text_report: ''
    })

    useEffect(() => {
        setFormData({
            ...formData, investigation: set_laboratory_reports?.pkid,
        })
    }, [set_laboratory_reports])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleImageChange = (e) => {
        setFormData({ ...formData, image_report: e.target.files[0], photo_display: URL.createObjectURL(e.target.files[0]) })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(uploadLabReportResult({ formData, toast, setModals: setModals, modals: modals }))
    }

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>
                            {modals.showLaboratoryUploadReportModal && (
                                <>
                                    <h4 className='text-dark-green text-[16px] font-bold'>
                                        Upload reports - {set_laboratory_reports?.first_name} {set_laboratory_reports?.last_name} [{set_laboratory_reports?.patient_no}]
                                    </h4>
                                    <FaTimesCircle
                                        className='text-dark-yellow cursor-pointer'
                                        onClick={() => {
                                            dispatch(setRadiologyGenerateInvoice({ data: null }))
                                            setModals({
                                                ...modals, showLaboratoryUploadReportModal: !modals.showLaboratoryUploadReportModal,
                                            })
                                        }}
                                        size={20}
                                    />
                                </>
                            )}

                            {modals.showLaboratoryViewReportModal && (
                                <div className='flex flex-col w-full'>
                                    <div className='flex justify-between'>
                                        <h4 className='text-sky-600 text-[16px] font-medium'>
                                            Lab investigation - {`${set_laboratory_reports?.patient_details?.first_name} ${set_laboratory_reports?.patient_details?.last_name}`}
                                            <br />
                                            <span className='text-slate-600 text-[12px]'>Test Category:  {`${set_laboratory_reports?.investigation_details?.name}`}</span>
                                        </h4>
                                        <FaTimesCircle
                                            size={20}
                                            className='text-dark-yellow cursor-pointer'
                                            onClick={() => {
                                                dispatch(setLaboratoryReport({ data: null }))
                                                setModals({ ...modals, showLaboratoryViewReportModal: !modals.showLaboratoryViewReportModal })
                                            }}
                                        />
                                    </div>

                                    <div className='mt-2'>
                                        {modals.showLaboratoryViewReportModal && (
                                            <>
                                                <div className='w-full border rounded'>
                                                    <img src={`http://localhost:8000${set_laboratory_reports?.image_report}`} alt="" />
                                                    <p className='text-[12px] font-semibold ml-2 my-2'> Investigation Report: {set_laboratory_reports?.text_report}</p>
                                                </div>

                                                {/* <img src={`http://localhost:8000${set_radiology_view_report?.image}`} alt="" className='rounded-md' /> */}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}

                        </div>
                        {formData.photo_display.length > 0 && (
                            <img src={formData.photo_display} alt="" srcset="" className='h-[150px] border w-full' />
                        )}
                        {modals.showLaboratoryUploadReportModal && (

                            <form onSubmit={handleSubmit} >
                                <div className=''>
                                    <input
                                        type='file'
                                        placeholder='Upload'
                                        name="image_report"
                                        className='border rounded h-[40px] w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                        onChange={handleImageChange}
                                    />
                                    <textarea className='w-full mt-1 border pt-4 pl-2' name="text_report" id="" cols="25" rows="10" placeholder='Add test report or test conducted'
                                        onChange={(e) =>
                                            handleChange(e, e.target.name)
                                        }
                                    >
                                    </textarea>
                                </div>

                                <div className='mt-4 flex justify-center items-center'>
                                    <button
                                        type='submit'
                                        className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LaboratoryUploadReport