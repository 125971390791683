import { useEffect, useRef, useState } from "react"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"


import { useStateContext } from "../../state/context"
import { createGroupConversation, getUsers, setCurrentChatConversation, setCurrentChatUser } from "../../state/actions/chat.actions"
import { AiOutlineCloudUpload } from "react-icons/ai"


const ChatCreateGroupModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals, socket } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const { users } = useSelector(state => state.chat)

    const FilePicker = useRef(null)
    const [formData, setFormData] = useState({ conversation_name: "", members: [], conversation_icon: '', conversation_icon_preview: '' })
    const [config, setConfig] = useState({ searching: false, filtered__users: [] })

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }
    const handleImageChange = (e) => {
        setFormData({ ...formData, conversation_icon: e.target.files[0], conversation_icon_preview: URL.createObjectURL(e.target.files[0]) })
    }

    const handleFilter = (value) => {
        const _filtered = users?.filter(staff => staff.pkid !== user?.data?.pkid)?.filter(user =>
            user.first_name.toLowerCase().includes(value)
            ||
            user.last_name.toLowerCase().includes(value)
            ||
            user.email.toLowerCase().includes(value)
        )
        setConfig({ ...config, filtered__users: _filtered })
    }

    const handleSearch = (user) => {
        setConfig({ ...config, searching: true })

        const index = formData.members.findIndex(id => id === user.pkid)

        if (index !== -1) {
            return setFormData({ ...formData, members: formData.members.filter(id => id !== user.pkid) })
        }

        return setFormData({ ...formData, members: [...formData.members, user.pkid] })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            ...formData,
            members: formData.members.join('')
        }

        dispatch(createGroupConversation({ formData: data, modals, setModals, dispatch, setCurrentChatUser, setCurrentChatConversation, socket }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-violet-600 text-[20px] font-bold">
                            Create Group Conversation
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showChatCreateGroupModal: !modals.showChatCreateGroupModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="w-full flex space-x-10 items-center">
                            <div className="border-2 w-[40%] rounded-lg py-2">
                                <div
                                    onClick={() => FilePicker.current.click()}
                                    className="flex flex-col justify-center items-center cursor-pointer"
                                >
                                    <AiOutlineCloudUpload
                                        size={40}
                                    />
                                    <p className='text-[11px] text-gray-400'>Upload your group icon</p>
                                </div>
                            </div>

                            <input
                                type="file"
                                accept="image/.png, .jpg, .jpeg"
                                name='conversation_icon'
                                ref={FilePicker}
                                onChange={(e) => handleImageChange(e)}
                                className='hidden'
                            />

                            {formData.conversation_icon && (
                                <div className="flex flex-col justify-center items-center py-5">
                                    <img src={formData.conversation_icon_preview} className='w-[80px] h-[80px] rounded-full' alt="" />
                                </div>
                            )}
                        </div>

                        <div>
                            <input
                                type="text"
                                name="conversation_name"
                                placeholder="Group Name"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>

                        <div>
                            <input
                                type="text"
                                name="user_search"
                                placeholder="Search/Add Users..."
                                className="border-b-2 border-b-gray-600 bg-gradient-to-l from-gray-50 to-gray-200 placeholder:italic w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onClick={() => setConfig({ ...config, searching: !config.searching })}
                                onChange={(e) => handleFilter(e.target.value.toLowerCase())}
                            />
                        </div>

                        {config.searching && (
                            <div className="bg-white shadow-lg max-h-[250px] w-full z-[2] overflow-y-auto users">
                                {config.filtered__users?.map((user => (
                                    <div
                                        key={`${user.id}`}
                                        className={`flex items-center space-x-4 border-b p-1 cursor-pointer group ${formData.members.find(id => id === user.pkid) && 'bg-red-200 border-b-white'}`}
                                        onClick={() => handleSearch(user)}
                                    >
                                        <img
                                            src={`${process.env.REACT_APP_API_ROOT}${user?.user_profile?.profile_photo}`}
                                            className="w-[40px] h-[40px] rounded-[50%] group-hover:translate-x-2 duration-500 ease-in-out"
                                            alt=""
                                        />
                                        <div className="flex flex-col group-hover:translate-x-2 duration-500 ease-in-out">
                                            <p className="text-[12px] text-gray-600 font-bold">{user.last_name} {user.first_name}</p>
                                            <p className="text-[10px] text-gray-600">{user?.email}</p>
                                        </div>
                                    </div>
                                )))}
                            </div>
                        )}


                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-violet-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Create
                            </button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    )
}

export default ChatCreateGroupModal
