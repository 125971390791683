import { createAsyncThunk } from '@reduxjs/toolkit'

import { signIn, patchOldPassword, updatePasswordStatus, getProfile, updateProfile, updateUserDetails, patchProfilePhoto, activateUserAccount, patchOnlineStatus, updateSocketId, generateCustomer, generateCustomerProfile, fetchSubscriptionPlan, fetchSMSBalance, fetchPharmacyConfig, patchPharmacyConfig, fetchSubscriptionPlans, fetchPlanUpgradeDetails, fetchPlanRenewalDetails, generateResetPasswordLink, patchUserPassword, fetchAdminDashboardInfo, patchPharmacyLogo, generateZuumpayCredentials } from '../routes/auth.routes'


export const activateAccount = createAsyncThunk(
    'auth/activateAccount',
    async ({ token, navigate, toast }, { rejectWithValue }) => {
        try {
            await activateUserAccount(token)

            localStorage.removeItem('pms__user')

            toast.success('Account activated successfully')
            navigate('/login')

            return null
        } catch (error) {
            toast.warning('Account activation failed, refer to your site administrator to proceed.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const authLogin = createAsyncThunk(
    'auth/login',
    async ({ formData, navigate, toast }, { rejectWithValue }) => {
        try {
            const { data } = await signIn(formData)

            if (data.data.is_active) {
                toast.success('Login successfully.', { autoClose: 800 })
                navigate('/updatepassword')
                return data
            } else {
                toast.warning('Activate your account first.')
                navigate('/login')
                return
            }
        } catch (error) {
            toast.warning('Login failed, validate your credentials and try again.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateUserPassword = createAsyncThunk(
    'auth/updateUserPassword',
    async ({ formData, navigate, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await patchOldPassword(formData, token)
            await updatePasswordStatus(token)

            toast.success('Password update successful.')

            localStorage.removeItem('pms__user')
            navigate('/login')

            // window.location.reload()

            return null
        } catch (error) {
            if (error.response.data["message"] === "You can't use this password twice") {
                toast.warning("You can't use this password for the second time.")
            }
            if (error.response.data.new_password[0]) return toast.warning(error.response.data.new_password[0])
            return rejectWithValue(error.response.data)
        }
    }
)

export const requestPasswordReset = createAsyncThunk(
    'auth/requestPasswordReset',
    async ({ params, navigate, toast }, { rejectWithValue }) => {
        try {
            await generateResetPasswordLink(params)

            toast.success('Check your mail inbox for a password generate url.')
            navigate('/login')

            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const resetUserPassword = createAsyncThunk(
    'auth/resetUserPassword',
    async ({ formData, navigate, toast }, { rejectWithValue }) => {
        try {
            await patchUserPassword(formData)

            toast.success('Password updated successfully..')
            navigate('/login')

            return null
        } catch (error) {
            console.log(error.response)
            if (error.response.data["message"] === "Password can not be used twice") {
                toast.warning("You can't use this password for the second time.")
            }
            if (error.response.data.error) {
                toast.warning(error.response.data.error)
            }
            return rejectWithValue(null)
        }
    }
)

export const updateProfilePassword = createAsyncThunk(
    'auth/updateprofilepassword',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            await patchOldPassword(formData, token)

            toast.success('Password update successful.')
            setModals({ ...modals, updatePasswordModal: !modals.updatePasswordModal })

            return null
        } catch (error) {
            console.log(error.response);
            error.response.data.current_password[0] && toast.warning('Incorrect current password.')
            toast.warning('An error occured while updating password')
            return rejectWithValue(error.response.data)
        }
    }
)


export const getUserProfile = createAsyncThunk(
    'auth/getprofile',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const response = await getProfile(token)

            return response.data.profile
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const profileUpdate = createAsyncThunk(
    'auth/profileUpdate',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await updateUserDetails(formData, token)
            const { data } = await updateProfile(formData, token)

            toast.success('Successful.')

            return data.profile
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured on profile update.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateProfilePhoto = createAsyncThunk(
    'auth/updateprofile_photo',
    async ({ formData, toast, setModals, modals, type }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchProfilePhoto(formData, token)

            if (type === 'webcam') {
                toast.success('Profile update was successful.')
                modals.showWebCamModal && setModals({ ...modals, showWebCamModal: !modals.showWebCamModal })
            }
            if (type === 'upload') {
                toast.success('Profile update was successful.')
            }

            return data
        } catch (error) {
            console.log(error.response);
            toast.warning('An error occured on profile update.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateUserProfile = createAsyncThunk(
    'auth/updateprofile',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateProfile(formData, token)

            toast.success('Profile update was successful.')

            // console.log(data)
            // localStorage.removeItem('pms__user')
            localStorage.removeItem('pms__user__profile')
            const profile_image = `${process.env.REACT_APP_API_ROOT}${data.profile.profile_photo}`
            localStorage.setItem('pms__user__profile', JSON.stringify({ ...data.profile, profile_photo: profile_image }))
            navigate('/dashboard')
            // window.location.reload()

            return { ...data.profile, profile_photo: profile_image }
        } catch (error) {
            console.log(error.response);
            toast.warning('An error occured on profile update.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateOnlineStatus = createAsyncThunk(
    'auth/updateOnlineStatus',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchOnlineStatus(formData, token)

            return data
        } catch (error) {
            console.log(error);
            return rejectWithValue(null)
        }
    }
)


export const userLogout = createAsyncThunk(
    'auth/logout',
    async ({ toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const formData = {
                status: false
            }

            const { data } = await patchOnlineStatus(formData, token)

            localStorage.removeItem('pms__user')
            localStorage.removeItem('pms__user__profile')

            toast.success('Successfully logged out.')
            navigate('/login')

            return data
        } catch (error) {
            toast.warning('An error occured on logout.')
            return rejectWithValue(error)
        }
    }
)

export const updateUserSocketId = createAsyncThunk(
    'auth/UpdateSocketId',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateSocketId(formData, token)

            return data
        } catch (error) {
            console.log(error);
            return rejectWithValue(null)
        }
    }
)

export const createCustomer = createAsyncThunk(
    'auth/createCustomer',
    async ({ formData, navigate, toast }, { rejectWithValue }) => {
        try {
            const { data } = await generateCustomer(formData)

            const _data = {
                ...formData,
                user_id: data.pkid
            }

            await generateCustomerProfile(_data)

            navigate('/login')
            toast.success('Account created successfully.')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured on account creation.')
            return rejectWithValue(null)
        }
    }
)


export const getSubscriptionPlan = createAsyncThunk(
    'auth/getSubscriptionPlan',
    async (_, { rejectWithValue, }) => {
        try {
            const { data } = await fetchSubscriptionPlan()

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getSMSBalance = createAsyncThunk(
    'auth/getSMSBalance',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSMSBalance(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyConfig = createAsyncThunk(
    'auth/getPharmacyConfig',
    async (_, { rejectWithValue, getState }) => {
        try {
            const { data } = await fetchPharmacyConfig()

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updatePharmacyConfig = createAsyncThunk(
    'auth/updatePharmacyConfig',
    async ({ formData, toast, modals, setModals }, { rejectWithValue }) => {
        try {
            const { data } = await patchPharmacyConfig(formData)

            toast.success('Pharmacy information updated successfully.')
            setModals({ ...modals, showPharmacistUpdatePharmacyInfoModal: !modals.showPharmacistUpdatePharmacyInfoModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured on pharmacy information update.')
            return rejectWithValue(null)
        }
    }
)


export const updatePharmacyLogo = createAsyncThunk(
    'auth/updatePharmacyLogo',
    async ({ formData, toast, modals, setModals }, { rejectWithValue }) => {
        try {
            const { data } = await patchPharmacyLogo(formData)

            toast.success('Pharmacy logo updated successfully.')
            setModals({ ...modals, showPharmacistUpdatePharmacyLogoModal: !modals.showPharmacistUpdatePharmacyLogoModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured on pharmacy logo update.')
            return rejectWithValue(null)
        }
    }
)


export const getSubscriptionPlans = createAsyncThunk(
    'auth/getSubscriptionPlans',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await fetchSubscriptionPlans()

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const getPlanUpgradeDetails = createAsyncThunk(
    'auth/getPlanUpgradeDetails',
    async ({ params }, { rejectWithValue }) => {
        try {
            const { data } = await fetchPlanUpgradeDetails(params)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const getPlanRenewalDetails = createAsyncThunk(
    'auth/getPlanRenewalDetails',
    async ({ params }, { rejectWithValue }) => {
        try {
            const { data } = await fetchPlanRenewalDetails(params)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const getAdminDashboardInfo = createAsyncThunk(
    'auth/getAdminDashboardInfo',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAdminDashboardInfo(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const resetPlanUpdateDetails = createAsyncThunk(
    'auth/resetPlanUpdateDetails',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            return rejectWithValue(null)
        }
    }
)


export const initializeZuumPayCredentials = createAsyncThunk(
    'admin/initializeZuumPayCredentials',
    async ({ formData, paystack_url }, { rejectWithValue }) => {
        try {
            const { data } = await generateZuumpayCredentials(formData)

            console.log(':::', data)

            window.location.href = paystack_url

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)