/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAdminDashboardInfo } from '../../state/actions/auth.actions'
import { PageTitle, PharmacistSectionsSummary, InventorySummary } from '../../components'


const PharmacistDashboard = () => {
	const dispatch = useDispatch()

	const { admin_dashboard_info } = useSelector(state => state.auth)

	useEffect(() => {
		dispatch(getAdminDashboardInfo())
	}, [])

	return (
		<div>
			<PageTitle type={'pharmacist-dashboard'} />

			<PharmacistSectionsSummary
				data={admin_dashboard_info}
			/>

			<div className="flex flex-wrap justify-between items-start mt-10">
				<InventorySummary
					title={'Inventory'}
					left_count={admin_dashboard_info?.drugs}
					left_title={'Total number of medicines'}
					right_count={admin_dashboard_info?.drug_categories}
					right_title={'Medicine Groups'}
				/>

				<InventorySummary
					title={'Quick Report'}
					left_count={admin_dashboard_info?.drugs_sold}
					left_title={'Medicines Sold'}
					right_count={admin_dashboard_info?.invoices}
					right_title={'Invoices Generated'}
				/>

				<InventorySummary
					title={'My Pharmacy'}
					left_count={admin_dashboard_info?.couriers}
					left_title={'Total no. of Suppliers'}
					right_count={admin_dashboard_info?.staff}
					right_title={'Total no. of Staff'}
				/>

				<InventorySummary
					title={'Customers'}
					left_count={admin_dashboard_info?.customers}
					left_title={'Total no. of Customers'}
				/>
			</div>
		</div>
	)
}

export default PharmacistDashboard