import { createAsyncThunk } from '@reduxjs/toolkit';


export const setSmsReceiver = createAsyncThunk(
    'root/setSmsReceiver',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setDirectorate = createAsyncThunk(
    'root/setDirectorate',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const setPayStackPaymentDetails = createAsyncThunk(
    'root/setPayStackPaymentDetails',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setcurrentBill = createAsyncThunk(
    'root/setcurrentBill',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setViewInoice = createAsyncThunk(
    'root/setViewInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)



export const setEmailReceiver = createAsyncThunk(
    'root/setEmailReceiver',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setDepartment = createAsyncThunk(
    'root/setDepartment',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setEmployee = createAsyncThunk(
    'root/setEmployee',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setBilling = createAsyncThunk(
    'root/setBilling',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setWard = createAsyncThunk(
    'root/setWard',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setBedSpace = createAsyncThunk(
    'root/setBedSpace',
    async ({ data }, { rejectWithValue }) => {
        try {
            console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const assignDoctor = createAsyncThunk(
    'root/assignDoctor',
    async ({ data }, { rejectWithValue }) => {
        try {
            console.log("data", data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setProfileImage = createAsyncThunk(
    'root/setProfileImage',
    async ({ data }, { rejectWithValue }) => {
        try {
            console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setShowBirthRecord = createAsyncThunk(
    'root/setShowBirthRecord',
    async ({ data }, { rejectWithValue }) => {
        try {
            console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setShowDeathRecord = createAsyncThunk(
    'root/setShowDeathRecord',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setWardReport = createAsyncThunk(
    'root/setWardReport',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setSingleBirthRecord = createAsyncThunk(
    'root/setSingleBirthRecord',
    async ({ data }, { rejectWithValue }) => {
        try {
            console.log("data", data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const bookAppointment = createAsyncThunk(
    'root/bookAppointment',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setRadiologyEquipment = createAsyncThunk(
    'root/setRadiologyEquipment',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setRadiologyGenerateInvoice = createAsyncThunk(
    'root/setRadiologyGenerateInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setRadiologyViewReport = createAsyncThunk(
    'root/setRadiologyViewReport',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setViewRadiologyInvoice = createAsyncThunk(
    'root/setRadiologyViewInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setDeleteRadiologyCategory = createAsyncThunk(
    'root/setDeleteRadiologyCategory',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setDeleteRadiologyInvestigationType = createAsyncThunk(
    'root/setDeleteRadiologyInvestigationType',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setLaboratoryEquipment = createAsyncThunk(
    'root/setLaboratoryEquipment',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setLaboratoryReport = createAsyncThunk(
    'root/setLaboratoryReport',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setLabInvoice = createAsyncThunk(
    'root/setLabInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setLabCategoryTest = createAsyncThunk(
    'root/setLabCategoryTest',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setLabInvestigationType = createAsyncThunk(
    'root/setLabInvestigationType',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)
export const setLabSampleTest = createAsyncThunk(
    'root/setLabSampleTest',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setPatientDiagnosisDetails = createAsyncThunk(
    'root/setPatientDiagnosisDetails',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setGetDiagnosisPrescriptions = createAsyncThunk(
    'root/setGetDiagnosisPrescriptions',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setDrugCategory = createAsyncThunk(
    'root/setDrugCategory',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setRecordsGenerateCard = createAsyncThunk(
    'root/setRecordsGenerateCard',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setSupplyPoint = createAsyncThunk(
    'root/setSupplyPoint',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentDrug = createAsyncThunk(
    'root/setCurrentDrug',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setCurrentBinCard = createAsyncThunk(
    'root/setCurrentBinCard',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentSupplyPoint = createAsyncThunk(
    'root/setCurrentSupplyPoint',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setCurrentSupplyPointInventory = createAsyncThunk(
    'root/setCurrentSupplyPointInventory',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setAdministerOpd = createAsyncThunk(
    'root/setAdministerOpd',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setCurrentSupplyPointBinCard = createAsyncThunk(
    'root/setCurrentSupplyPointBinCard',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setPatientID = createAsyncThunk(
    'root/setPatientID',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setInpatientDetails = createAsyncThunk(
    'root/setInpatientDetails',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setNursesDeleteDetails = createAsyncThunk(
    'root/setNursesDeleteDetails',
    async ({ data }, { rejectWithValue }) => {
        try {

            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setCurrentPrescriptions = createAsyncThunk(
    'root/setCurrentPrescriptions',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentPrescriptionInvoices = createAsyncThunk(
    'root/setCurrentPrescriptionInvoices',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setCurrentPrescriptionInvoice = createAsyncThunk(
    'root/setCurrentPrescriptionInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentTeam = createAsyncThunk(
    'root/setCurrentTeam',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentPatient = createAsyncThunk(
    'root/setCurrentPatient',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setManageAdmittedPatient = createAsyncThunk(
    'root/setManageAdmittedPatient',
    async ({ mydata }, { rejectWithValue }) => {
        try {
            return mydata
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setImmunizationPatient = createAsyncThunk(
    'root/setImmunizationPatient',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const setBirthAndDeathRecordDetail = createAsyncThunk(
    'root/setBirthAndDeathRecordDetail',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)
export const setFollowUpRecords = createAsyncThunk(
    'root/setFollowUpRecords',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setCurrentPatienFile = createAsyncThunk(
    'root/setCurrentPatienFile',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentAppointment = createAsyncThunk(
    'root/setCurrentAppointment',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentLabTest = createAsyncThunk(
    'root/setCurrentLabTest',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentRadiologyTest = createAsyncThunk(
    'root/setCurrentRadiologyTest',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setCurrentAccountsInvoice = createAsyncThunk(
    'root/setCurrentAccountsInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)


export const setPatientInvoiceModal = createAsyncThunk(
    'root/setPatientInvoiceModal',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setScheduleDate = createAsyncThunk(
    'root/setScheduleDate',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setScheduleEdit = createAsyncThunk(
    'root/setScheduleEdit',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const setFilteredSchedules = createAsyncThunk(
    'root/setFilteredSchedules',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)



export const setCurrentAccountsPharmacyInvoice = createAsyncThunk(
    'root/setCurrentAccountsPharmacyInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentClinicalDataRepo = createAsyncThunk(
    'root/setCurrentClinicalDataRepo',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const setCallReceiver = createAsyncThunk(
    'root/setCallReceiver',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)