/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BsDashSquare } from 'react-icons/bs'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { customerCreatePrescription, customerGetDrugs, getInventoryDrugs, fetchAllInventories, getBillings, getPharmacistInventoryItems } from '../../../state/actions/pharmacist.actions'


const CustomerAddOrderModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { profile } = useSelector(state => state.auth)

    const { customer_drugs, inventory_drugs, all_inventories, billings, pharmacist_inventory_items } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState([{
        prescription_type: '', drug: '', routes: 'JK', units: '', frequency: '',
        duration: '', duration_time: '', strength: '', patient_name: '',
        patient_phone: '', other_prescription: false, dispense_count: 0,
        gender: '', patient_pregnancy_status: '', patient_allergies: '',
        patient_id: '',
        deliveryFee: ''
    }])

    const [total, setTotal] = useState(0)
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [billSelected, setBillSelected] = useState('')

    const billing_types = billings?.filter(e => e?.billing_type === 'delivery')



    useEffect(() => {
        dispatch(customerGetDrugs())
        dispatch(fetchAllInventories())
        dispatch(getBillings())
        dispatch(getPharmacistInventoryItems())
        // dispatch(getInventoryDrugs())
    }, [])

    useEffect(() => {
        let data = [...formData]
        data[0]['patient_name'] = `${profile?.first_name} ${profile?.last_name}`
        data[0]['patient_phone'] = profile?.patient_phone
        data[0]['gender'] = profile?.gender
        data[0]['patient_pregnancy_status'] = profile?.pregnancy_status
        data[0]['patient_allergies'] = profile?.allergies_and_reactions
        data[0]['patient_id'] = profile?.user
        setFormData(data)
    }, [profile])

    const handleChange = (index, e) => {
        let data = [...formData]
        data[index][e.target.name] = e.target.value
        setFormData(data)
    }

    const addPrescription = (e) => {
        e.preventDefault()
        setFormData([
            ...formData,
            {
                prescription_type: '', drug: '', routes: 'JK', units: 'kk', frequency: 'jk',
                duration: 'dl', duration_time: 'ld', strength: 'fk',
                patient_name: formData[0].patient_name, patient_phone: formData[0].patient_phone,
                other_prescription: false, dispense_count: 0, gender: formData[0].gender,
                patient_pregnancy_status: formData[0].patient_pregnancy_status, patient_allergies: formData[0].patient_allergies,
                patient_id: formData[0].patient_id
            }
        ])
    }

    const handleSubmit = (e) => {
        e.preventDefault()


        console.log(formData)


        dispatch(customerCreatePrescription({ formData, toast, setModals, modals }))
    }

    const addItemPriceToTotal = () => {
        let _total = deliveryPrice === NaN ? 0 : deliveryPrice
        for (let i = 0; i < formData.length; i++) {
            const invt_item = all_inventories?.filter((e) => e?.inventory_item_details?.title === formData[i].drug)[0]
            _total += parseInt(invt_item.selling_price * formData[i]?.dispense_count)

        }

        setTotal(_total)
    }
    const removeItemPriceFromTotal = (name, item_count) => {
        const item = all_inventories?.filter((e) => e?.inventory_item_details?.title === name)[0]
        if (!item) {
            return
        } else {
            setTotal(total - parseInt(item?.selling_price * item_count))
        }
    }

    const addDeliveryFeeToTotal = (e) => {
        console.log(e.target.value, 'val')
        const selectedBill = billing_types?.filter(_type => _type?.pkid == e.target.value)
        setDeliveryPrice(selectedBill[0]?.price)
        let data = [...formData]
        data[0]['deliveryFee'] = selectedBill[0]?.price
        setFormData(data)
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[600px] px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-600 text-[16px] font-medium'>Purchase Item</h4>
                        <FaTimesCircle
                            size={20}
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                setModals({ ...modals, showCustomerAddOrderModal: !modals.showCustomerAddOrderModal })
                            }}
                        />
                    </div>
                    <p className='bg-blue-400 px-2 py-1 rounded-sm text-[15px] text-white italic'>Total Price: ₦ {total}</p>
                    <p className='flex italic justify-end'>Delivery fee: ₦ {deliveryPrice === NaN ? 0 : deliveryPrice}</p>


                    <select
                        className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                        name="delivery_type"
                        onChange={(e) => addDeliveryFeeToTotal(e)}
                    >
                        <option value="">Select Delivery Method</option>
                        {billing_types?.map((item, index) => (
                            <option
                                key={`${item.pkid}`}
                                value={item.pkid}
                            >
                                {item.name}
                            </option>
                        ))}
                    </select>

                    <form onSubmit={handleSubmit}>
                        <div
                            className={`space-y-2 ${formData.length > 2 && 'h-[450px] overflow-y-auto patient__prescriptions'}`}
                        >
                            {formData?.map((item, index) => (
                                <Fragment key={index}>

                                    <div className="flex justify-between items-center">
                                        <label
                                            className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded"
                                        >Item: {index + 1}</label>

                                        {index !== 0 && (
                                            <BsDashSquare
                                                size={30}
                                                onClick={() => {
                                                    setFormData(formData.filter((data, _index) => (_index + 1) !== (index + 1)))
                                                    removeItemPriceFromTotal(formData[index]?.drug, formData[index]?.dispense_count)

                                                }}
                                                className="text-orange-500 px-[5px] py-[0px] text-[10px] rounded cursor-pointer"
                                            />
                                        )}
                                    </div>


                                    <div className={`flex justify-between items-start w-full ${index !== formData.length - 1 && 'pb-5 border-b-2 border-gray-600'}`}>
                                        <div className="space-y-2 w-full">
                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="drug"
                                                    onChange={(e) => {
                                                        handleChange(index, e)
                                                        if (formData[index]?.dispense_count !== '') {
                                                            addItemPriceToTotal()
                                                        }

                                                    }}
                                                >
                                                    <option value="">Add Item</option>
                                                    {pharmacist_inventory_items?.map((drug, index) => (
                                                        <option
                                                            key={`${drug.id}`}
                                                            value={drug.title}
                                                        >
                                                            {drug.title}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            {formData[index]?.drug !== '' && <>

                                                <div className="relative">

                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input
                                                        type="text"
                                                        name="dispense_count"
                                                        placeholder="Pack Count"
                                                        className="border rounded w-full h-5 px-4 py-3 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                        onChange={(e) => {
                                                            handleChange(index, e)
                                                            addItemPriceToTotal()
                                                        }}
                                                    />
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                </Fragment>
                            ))}

                        </div>
                        <div className='flex justify-between mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 border-2 border-dark-blue text-dark-blue font-medium text-xs leading-tight rounded shadow-md transition duration-500 ease"
                                onClick={addPrescription}
                            >Add</button>

                            {formData[0]?.deliveryFee !== undefined && formData[0]?.deliveryFee !== '' && <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-800 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>}
                        </div>

                    </form>
                </div>


            </div>
        </div>
    )
}
export default CustomerAddOrderModal
