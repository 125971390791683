import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FaUser } from 'react-icons/fa'

import UserPosition from '../utils/UserPosition'
import { useStateContext } from '../../state/context'
import { setSmsReceiver } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'


const SmsUsersTable = ({ data, showList, setShowList, }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="">
                    {/* <div className="flex justify-between items-center space-x-4 bg-gray-200 p-1">
                        <div
                            className="flex justify-start items-center space-x-4"
                            onClick={() => setShowList(!showList)}
                        >
                            <h4 className="text-dark-blue text-[14px] font-bold">
                                Users
                            </h4>
                            <FaUser className="text-dark-blue" size={20} />
                        </div>
                    </div> */}
                    {showList && (
                        <Fragment>
                            <table className="border rounded w-full cursor-default">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Full name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Position
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Gender
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Phone
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 py-4 text-left"
                                        >
                                            
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((user, index) => (
                                        <tr className="" key={user.id}>
                                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {index + 1}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {user.first_name} {user.last_name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                <UserPosition user={user} />
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {user.gender}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {user.patient_phone ? user.patient_phone : '-'}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                <button
                                                    type="button"
                                                    className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                                    onClick={() => {
                                                        const data = {
                                                            first_name: user.first_name,
                                                            last_name: user.last_name,
                                                            id: user.user_id,
                                                            phone: user.patient_phone,
                                                            pkid: user.user_details.pkid
                                                        }
                                                        dispatch(setSmsReceiver({ data }))

                                                        setModals({ ...modals, showSendSmsModal: !modals?.showSendSmsModal })
                                                    }}
                                                >
                                                    Send SMS
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                data={data}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SmsUsersTable