/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { AiOutlineLock } from 'react-icons/ai'

import { ArtUpdatePassword, Logo } from '../../assets'
import { getPharmacyConfig, updateUserPassword } from '../../state/actions/auth.actions'


const UpdatePassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({ current_password: '', new_password: '', re_new_password: '' })
    const { user, pharmacy_config } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(getPharmacyConfig())
    }, [])

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])


    useEffect(() => {
        if (user && user?.data?.is_password_reset) {
            toast.info('Update your profile.')
            navigate('/updateprofile')
        }
    }, [user])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!formData.current_password && !formData.new_password && !formData.re_new_password) return toast.warning('All fields are required!')
        if (formData.new_password !== formData.re_new_password) return toast.warning('Passwords does not match!')

        dispatch(updateUserPassword({ formData, navigate, toast }))
    }

    return (
        <div className="main__login relative min-h-screen text-gray-800 antialiased flex flex-col justify-center sm:py-12 font-poppins">
            <div className="absolute right-[220px] top-[40px]">
                <div className="w-[100px] h-[100px] bg-white  bg-opacity-50 backdrop-blur-sm rounded-full"></div>
            </div>
            <div className="absolute right-[250px] top-[60px]">
                <div className="w-[100px] h-[100px] bg-white bg-opacity-50 backdrop-blur-sm rounded-full"></div>
            </div>

            <div className="relative sm:w-[800px] mx-auto text-center sm:auto">

                <div className="mt-4 bg-white shadow-md">
                    <div className="flex justify-between items-center">
                        <div className="px-8 py-[5em] w-[400px] border-r-2 border-light-gray">
                            <img src={ArtUpdatePassword} alt="art__login" />
                        </div>

                        <div className="px-8 w-[400px]">
                            <div className="space-y-5 mb-4">
                                <div className="">
                                    <img
                                        src={pharmacy_config ? `http://localhost:8000${pharmacy_config?.logo}` : Logo}
                                        alt="logo"
                                        className='w-[40px] h-[40px] '
                                    />
                                </div>

                                <div className="flex flex-col">
                                    <h4 className="text-center text-[25px] font-medium">
                                        Update Password
                                    </h4>
                                    <p className='text-gray-400 text-[16px]'>For security reasons you have to update your password for before your first login.</p>
                                </div>
                            </div>

                            <div className="space-y-3">
                                <div className="relative">
                                    <AiOutlineLock
                                        size={25}
                                        color={'rgba(40, 51, 66, 0.8)'}
                                        className='absolute top-[16px] left-3'
                                    />
                                    <input
                                        type="password"
                                        name="current_password"
                                        placeholder="Old Password"
                                        className="border w-full rounded h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                                <div className="relative">
                                    <AiOutlineLock
                                        size={25}
                                        color={'rgba(40, 51, 66, 0.8)'}
                                        className='absolute top-[16px] left-3'
                                    />
                                    <input
                                        type="password"
                                        name="new_password"
                                        placeholder="New Password"
                                        className="border w-full rounded h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                                <div className="relative">
                                    <AiOutlineLock
                                        size={25}
                                        color={'rgba(40, 51, 66, 0.8)'}
                                        className='absolute top-[16px] left-3'
                                    />
                                    <input
                                        type="password"
                                        name="re_new_password"
                                        placeholder="Confirm New Password"
                                        className="border w-full rounded h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col justify-between items-center space-y-5">
                                <button
                                    type="submit"
                                    className="mt-4 w-full bg-dark-yellow text-white py-2 px-6 rounded-md hover:bg-dark-yellow"
                                    onClick={handleSubmit}
                                >
                                    Update Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdatePassword