import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'


import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setCurrentLaboratoryData } from '../../state/actions/pharmacist.actions'


const PharmacistGatewaySettingsTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { sms_balance } = useSelector(state => state.auth)
    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    <Fragment>
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Secret Key
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        {/* Private Key */}
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((setting, index) => (
                                    <tr key={`${setting.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {setting?.title}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {setting?.secret_key && '***********************'}...
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {/* {setting?.private_key && '***********************'}... */}
                                        </td>
                                        <td className="flex space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {setting.title !== 'SMS' && (
                                                <Fragment>
                                                    <FaPencilAlt
                                                        size={20}
                                                        className='text-green-600 cursor-pointer'
                                                        onClick={() => {
                                                            dispatch(setCurrentLaboratoryData({ data: setting }))
                                                            setModals({ ...modals, showPharmacistUpdateGatewaySettingModal: !modals.showPharmacistUpdateGatewaySettingModal })
                                                        }}
                                                    />
                                                    <FaTrash
                                                        size={20}
                                                        className="text-orange-400 cursor-pointer"
                                                        onClick={() => {
                                                            dispatch(setCurrentLaboratoryData({ data: setting }))
                                                            setModals({ ...modals, showPharmacistDeleteGatewaySettingModal: !modals.showPharmacistDeleteGatewaySettingModal })
                                                        }}
                                                    />
                                                </Fragment>
                                            )}

                                            {setting.title === 'SMS' && (
                                                <Fragment>
                                                    <p>₦ {sms_balance?.balance.split('.')[0]}</p>

                                                    <button
                                                        type="button"
                                                        className="inline-block px-2 py-1 bg-teal-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                        onClick={() => {
                                                            // dispatch(setCurrentPharmacyInvoice({ data: invoice }))
                                                            setModals({ ...modals, showTopUpSMSUnitsModal: !modals.showTopUpSMSUnitsModal })
                                                        }}
                                                    >
                                                        Topup
                                                    </button>
                                                </Fragment>
                                            )}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default PharmacistGatewaySettingsTable
