import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentLabTest, setCurrentRadiologyTest } from '../../../state/actions/actions'

import { useStateContext } from '../../../state/context'


const DoctorViewRadiologyTestModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { patient_radiotest_update } = useSelector(state => state.root)

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[800px]">
                <div className="flex flex-col space-y-1">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showDoctorAddTestModal ? 'text-dark-green' : 'text-sky-500'} text-[20px] font-bold`}>
                            {/* Patient Laboratory Test */}
                        </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(setCurrentRadiologyTest({ data: null }))
                                setModals({ ...modals, showDoctorViewRadiologyTestModal: !modals.showDoctorViewRadiologyTestModal })
                            }}
                            size={20}
                        />
                    </div>


                    <div className="">
                        {patient_radiotest_update?.is_local && patient_radiotest_update?.image_report && (
                            <img
                                src={`http://localhost:8000${patient_radiotest_update?.image_report}`}
                                alt=""
                                className='cursor-pointer w-[800px] h-[400px]'
                            />
                        )}

                        {!patient_radiotest_update?.is_local && patient_radiotest_update?.other_scanned_test_results && (
                            <img
                                src={`http://localhost:8000${patient_radiotest_update?.other_scanned_test_results}`}
                                alt=""
                                className='cursor-pointer w-[800px] h-[400px]'
                            />
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}
export default DoctorViewRadiologyTestModal
