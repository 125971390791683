import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import {
    fetchAllMessages, fetchChatConversations, fetchCurrentChatMessages,
    fetchNextPageMessages, fetchUserLastChatParticipant, fetchUsers, generateChatConversation,
    generateChatDocument, generateChatImage, generateChatMessage, generateGroupChatMessage,
    generateGroupConversation, generateGroupParticipant, generateGroupWelcomeMessage,
    generateSingleChatMessage, patchConversationMessages, patchGroupIcon
} from '../routes/chat.routes'


export const getUsers = createAsyncThunk(
    'chat/getUsers',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchUsers(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentChatConversation = createAsyncThunk(
    'chat/setCurrentChatConversation',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentChatUser = createAsyncThunk(
    'chat/setCurrentChatUser',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getCurrentChatMessages = createAsyncThunk(
    'chat/getCurrentChatMessages',
    async ({ params, dispatch, setActiveChatState }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchCurrentChatMessages(params, token)

            dispatch(setActiveChatState({ data: null }))

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const sendChatMessage = createAsyncThunk(
    'chat/sendChatMessage',
    async ({ params, messages, setMessages, scrollToBottom }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateChatMessage(params, token)

            setMessages([...messages, data])
            scrollToBottom()

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setActiveChatState = createAsyncThunk(
    'chat/setActiveChatState',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getUserLastChat = createAsyncThunk(
    'chat/getUserLastChat',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchUserLastChatParticipant(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getAllMessages = createAsyncThunk(
    'chat/getAllMessages',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllMessages(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getNextPageMessages = createAsyncThunk(
    'chat/getNextPageMessages',
    async ({ url, messages, setMessages, pagination, setPagination, chatArea, savedHeight }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchNextPageMessages(url, token)

            setMessages([ ...messages, ...data.results])
            // chatArea.scrollTo(0, savedHeight + 200)
            chatArea.scrollTo({
                top: savedHeight + 480,
                behavior: 'smooth'
            })

            if (!data.next) {
                setPagination({ ...pagination, can_go_next: false })
            } else {
                setPagination({ ...pagination, can_go_next: true })
            }

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getChatConversations = createAsyncThunk(
    'chat/getChatConversations',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchChatConversations(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


// export const getReceiverChatConversations = createAsyncThunk(
//     'chat/getReceiverChatConversations',
//     async (_, { rejectWithValue, getState }) => {
//         try {
//             const state = getState()
//             const token = state.auth.user.access

//             const { data } = await fetchReceiverChatConversations(token)

//             return data
//         } catch (error) {
//             console.log(error)
//             return rejectWithValue(null)
//         }
//     }
// )


export const createChatConversation = createAsyncThunk(
    'chat/createChatConversation',
    async ({ formData, dispatch, setCurrentChatUser, _data }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateChatConversation(formData, token)

            dispatch(setCurrentChatUser({ data: _data }))
            dispatch(setCurrentChatConversation({ data }))

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const sendSingleChatMessage = createAsyncThunk(
    'chat/sendSingleChatMessage',
    async ({ params, messages, setMessages, scrollToBottom }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateSingleChatMessage(params, token)

            setMessages([...messages, data])
            scrollToBottom()

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createGroupConversation = createAsyncThunk(
    'chat/createGroupConversation',
    async ({ formData, modals, setModals, dispatch, setCurrentChatUser, setCurrentChatConversation, socket }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateGroupConversation(formData, token)

            const body = {
                conversation_id: data?.pkid,
                conversation_name: data?.conversation_name
            }
            const request = await generateGroupWelcomeMessage(body, token)

            setModals({ ...modals, showChatCreateGroupModal: !modals.showChatCreateGroupModal })
            dispatch(setCurrentChatUser({ data }))
            dispatch(setCurrentChatConversation({ data }))

            socket.emit('create_group', data, (error) => { })

            return { data, message: request.data }
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const sendGroupChatMessage = createAsyncThunk(
    'chat/sendGroupChatMessage',
    async ({ params, message, messages, setMessages, scrollToBottom, socket }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateGroupChatMessage(params, token)

            setMessages([...messages, data])
            scrollToBottom()

            socket.emit('send_group_message', message, () => {})

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const sendChatImage = createAsyncThunk(
    'chat/sendChatImage',
    async ({ params, formData, setFormData, messages, setMessages, scrollToBottom, socket }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateChatImage(params, token)

            setMessages([...messages, ...data])
            setFormData({ ...formData, attachments: '', attachment_previews: '', attachment_type: '', attachment_names: '' })
            scrollToBottom()

            const content = {
                conversation_id: params?.chat_conversation,
            }

            socket.emit('send_chat_media', content, () => {})

            return data[0]
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const sendChatDocument = createAsyncThunk(
    'chat/sendChatDocument',
    async ({ params, formData, setFormData, messages, setMessages, scrollToBottom, socket }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateChatDocument(params, token)

            setMessages([...messages, ...data])
            setFormData({ ...formData, attachments: '', attachment_previews: '', attachment_type: '', attachment_names: '' })
            scrollToBottom()

            const content = {
                conversation_id: params?.chat_conversation,
            }

            socket.emit('send_chat_media', content, () => {})

            return data[0]
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const sendGroupChatImage = createAsyncThunk(
    'chat/sendGroupChatImage',
    async ({ params, formData, setFormData, messages, setMessages, scrollToBottom, socket }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateChatImage(params, token)

            setMessages([...messages, ...data])
            setFormData({ ...formData, attachments: '', attachment_previews: '', attachment_type: '', attachment_names: '' })
            scrollToBottom()

            const content = {
                conversation_id: params?.chat_conversation,
                conversation_name: params.conversation_name
            }

            socket.emit('send_groupchat_media', content, () => { })

            return data[0]
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const sendGroupChatDocument = createAsyncThunk(
    'chat/sendGroupChatDocument',
    async ({ params, formData, setFormData, messages, setMessages, scrollToBottom, socket }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateChatDocument(params, token)

            setMessages([...messages, ...data])
            setFormData({ ...formData, attachments: '', attachment_previews: '', attachment_type: '', attachment_names: '' })
            scrollToBottom()

            const content = {
                conversation_id: params?.chat_conversation,
                conversation_name: params.conversation_name
            }

            socket.emit('send_groupchat_media', content, () => {

            })

            return data[0]
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateGroupIcon = createAsyncThunk(
    'chat/updateGroupIcon',
    async ({ formData, setFormData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchGroupIcon(formData, token)

            setFormData({ ...formData, conversation_icon: '', conversation_icon_preview: '' })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const addGroupChatParticipant = createAsyncThunk(
    'chat/addGroupChatParticipant',
    async ({ formData, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateGroupParticipant(formData, token)

            setModals({ ...modals, showAddGroupParticipantModal: !modals.showAddGroupParticipantModal })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateConversationMessages = createAsyncThunk(
    'chat/updateConversationMessages',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchConversationMessages(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentChatMessages = createAsyncThunk(
    'chat/setCurrentChatMessages',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const resetChatState = createAsyncThunk(
    'chat/resetChatState',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)
