import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdLocalPharmacy } from 'react-icons/md'
import { FaPencilAlt, FaTrash, FaWallet } from 'react-icons/fa'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setEmployee } from '../../state/actions/actions'


const EmployeeTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { user } = useSelector(state => state.auth)

    const [_data, setData] = useState([])
    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        setData(data)
    }, [data])


    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="">
                    {/* <div className="flex justify-start items-center space-x-4 bg-gray-200 p-1 pl-4"
                        onClick={() => setShowTable(!showTable)}
                    >
                        <h4 className="text-sidebar text-[14px] font-bold">
                            {title}
                        </h4>
                       {title === 'Pharmacist' ?  <MdLocalPharmacy className="text-sidebar" size={25} />:  <FaWallet className="text-sidebar" size={25} />}
                    </div> */}
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[4em] pl-2 py-2 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[5em] py-2 text-left"
                                    >
                                        Full name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[5em] py-2 text-left"
                                    >
                                        Email
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[5em] py-2 text-left"
                                    >
                                        Phone
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[5em] py-2 text-left"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 py-2 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {currentData?.map((item, index) => (
                                    <tr key={`${item.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item?.first_name} {item?.last_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.email}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.details?.patient_phone}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.is_active ? "Active" : "Inactive"}
                                        </td>
                                        <td className={`flex items-center text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap`}>
                                            <FaPencilAlt
                                                size={20}
                                                className='text-green-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setEmployee({ data: item }))
                                                    console.log(item)
                                                    setModals({ ...modals, showPharmacistUpdateStaffModal: !modals.showPharmacistUpdateStaffModal })
                                                }}
                                            />
                                            <FaTrash
                                                onClick={() => {
                                                    dispatch(setEmployee({ data: item }))
                                                    setModals({ ...modals, showPharmacistDeleteStaffModal: !modals.showPharmacistDeleteStaffModal })
                                                }}
                                                className="text-orange-400 cursor-pointer"
                                                size={20}
                                            />

                                            {user?.data?.subscription_plan !== 'STARTER' && item.is_courier && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 bg-indigo-500 text-white font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setEmployee({ data: item }))
                                                        setModals({ ...modals, showPharmacistUpdateStaffProfileModal: !modals.showPharmacistUpdateStaffProfileModal })
                                                    }}
                                                >
                                                    Profile
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={_data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div>
    )
}

export default EmployeeTable