import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Role from '../utils/Role'
import { setCurrentChatConversation, setCurrentChatUser } from '../../state/actions/chat.actions'


const ChatUser = ({ info, conversation, }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)
    const { active_chat, current_chatuser, chat_message, chat_conversations, chat_readmessages } = useSelector(state => state.chat)

    const [unreadMessages, setUnreadMessages] = useState(0)

    useEffect(() => {
        const unread_messages = conversation?.conversation_messages?.filter(message => !message.members_read.includes(user?.data?.pkid))
        // console.log(unread_messages)

        setUnreadMessages(unread_messages?.length)
    }, [conversation, chat_message, current_chatuser, chat_readmessages, active_chat, chat_conversations])

    return (
        <div className='flex justify-between items-center p-2 bg-white border-b border-gray-200 cursor-pointer group'
            onClick={() => {
                dispatch(setCurrentChatConversation({ data: conversation }))
                dispatch(setCurrentChatUser({ data: info }))
            }}
        >
            <div className="relative w-full flex items-center group-hover:translate-x-1 duration-500 ease-in-out">
                <img
                    src={`http://localhost:8000${info?.profile_photo}`}
                    alt="user"
                    className='mr-2 w-[50px] h-[50px] rounded-[100%]'
                />

                {!info?.is_online && (
                    <div className="absolute bottom-1 ml-10 flex justify-center items-center w-[12px] h-[12px] bg-slate-400 border-2 border-white rounded-full"></div>
                )}

                {info?.is_online && (
                    <div className="absolute bottom-1 ml-10 flex justify-center items-center w-[12px] h-[12px] bg-teal-400 border-2 border-white rounded-full"></div>
                )}

                <div className="flex flex-col -space-y-4">
                    <p className="text-[12px] text-gray-600">{info?.last_name} {info?.first_name}</p>
                    <Role user={info} type={'chat'} />
                </div>
            </div>

            {unreadMessages > 0 && (
                <div className='bg-teal-400 text-white text-[10px] flex items-center justify-center w-[25px] h-[25px] rounded-full'>
                    {unreadMessages}
                </div>
            )}
        </div>
    )
}

export default ChatUser