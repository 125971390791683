import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle, PharmacistBillsDispenseTable } from '../../components'
import { getInvoices } from '../../state/actions/pharmacist.actions'


const PharmacistBillsDispense = () => {
	const dispatch = useDispatch()

	const { prescriptions_invoices } = useSelector(state => state.pharmacist)
	const [showList, setShowList] = useState(true)

	useEffect(() => {
		dispatch(getInvoices())
	}, [])

	return (
		<div>
			<PageTitle type={'pharmacist-bills'} />

			<div className="space-y-5">
				<PharmacistBillsDispenseTable
					data={prescriptions_invoices}
					title={'Bills | Drugs Dispense'}
					showList={showList}
					setShowList={setShowList}
				/>
			</div>
		</div>
	)
}

export default PharmacistBillsDispense