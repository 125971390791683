import { createSlice } from "@reduxjs/toolkit"

import {
    setSmsReceiver, setDirectorate, setEmailReceiver, setDepartment,
    setEmployee, setBilling, setWard, assignDoctor, setShowBirthRecord,
    setBedSpace, setProfileImage, setWardReport, setShowDeathRecord,
    setcurrentBill, setViewInoice, setSingleBirthRecord, bookAppointment,
    setDrugCategory, setSupplyPoint, setCurrentDrug, setCurrentBinCard,
    setCurrentSupplyPoint, setCurrentSupplyPointInventory, setCurrentSupplyPointBinCard,
    setCurrentPrescriptions, setCurrentPrescriptionInvoice, setCurrentPrescriptionInvoices,
    setRadiologyEquipment, setRadiologyGenerateInvoice, setRadiologyViewReport,
    setViewRadiologyInvoice, setDeleteRadiologyInvestigationType,
    setDeleteRadiologyCategory, setLaboratoryEquipment, setLaboratoryReport, setLabInvoice,
    setLabCategoryTest, setLabInvestigationType, setLabSampleTest, setPatientDiagnosisDetails,
    setGetDiagnosisPrescriptions, setAdministerOpd, setPatientID, setInpatientDetails,
    setNursesDeleteDetails, setCurrentPatient, setManageAdmittedPatient, setCurrentTeam, setCurrentPatienFile,
    setCurrentAppointment, setCurrentLabTest, setCurrentRadiologyTest, setRecordsGenerateCard,
    setImmunizationPatient, setBirthAndDeathRecordDetail, setFollowUpRecords, setPayStackPaymentDetails, setPatientInvoiceModal,
    setCurrentAccountsInvoice, setScheduleDate, setScheduleEdit, setFilteredSchedules, setCurrentAccountsPharmacyInvoice, setCurrentClinicalDataRepo,
    setCallReceiver
} from "../actions/actions";


const rootSlice = createSlice({
    name: 'root',
    initialState: {
        accounts_invoice: null,
        accounts_pharmacyinvoice: null,
        sms_receiver: null,
        directorate_update: null,
        department_update: null,
        employee_update: null,
        billing_update: null,
        ward_update: null,
        bedspace_update: null,
        loading: false,
        show_death_records: null,
        show_birth_records: null,
        set_single_birth_record: null,
        email_receiver: null,
        image_capture: null,
        assign_doctor: null,
        ward_report: null,
        current_bill: null,
        book_appointment: null,
        view_invoice: null,
        drug_update: null,
        drug_category_update: null,
        supplypoint_update: null,
        drug_bincard: null,
        supplypoint_inventory_update: null,
        supplypoint_bincard: null,
        prescriptions_update: null,
        prescription_invoice_update: null,
        prescription_invoices_update: null,
        set_radiology_equipment: null,
        set_radiology_generate_invoice: null,
        set_radiology_view_report: null,
        set_view_radiology_invoice: null,
        set_delete_radiology_cat: null,
        set_delete_radiology_investigation_type: null,
        set_laboratory_equipment: null,
        set_laboratory_reports: null,
        set_laboratory_invoice: null,
        set_laboratory_category_test: null,
        set_laboratory_investigation_type: null,
        set_lab_sample_test: null,
        set_patient_diagnosis_details: null,
        set_get_diagnosisPrescriptions: null,
        set_administer_opd: null,
        set_patient_id: null,
        set_inpatient_details: null,
        set_nurses_delete_details: null,
        set_manage_admitted_patient: null,
        doctorteam_update: null,
        patient_update: null,
        patient_file_update: null,
        appointment_update: null,
        patient_labtest_update: null,
        patient_radiotest_update: null,
        set_records_generate_card: null,
        set_immunization_patient: null,
        set_birth_and_death_record: null,
        set_follow_up_record: null,
        set_paystack_payment_details: null,
        set_patient_invoice_modal: null,
        set_Schedule_date: null,
        set_edit_schedule: null,
        filtered_schedules: null,
        current_clinical_data: null,
        call_receiver:null


    },
    extraReducers: {
        [setSmsReceiver.pending]: (state, action) => {
            state.loading = true
        },
        [setSmsReceiver.fulfilled]: (state, action) => {
            state.loading = false
            state.sms_receiver = action.payload
        },
        [setSmsReceiver.rejected]: (state, action) => {
            state.loading = false
        },

        [setDirectorate.pending]: (state, action) => {
            state.loading = true
        },
        [setDirectorate.fulfilled]: (state, action) => {
            state.loading = false
            state.directorate_update = action.payload
        },
        [setDirectorate.rejected]: (state, action) => {
            state.loading = false
        },

        [setEmailReceiver.pending]: (state, action) => {
            state.loading = true
        },
        [setEmailReceiver.fulfilled]: (state, action) => {
            state.loading = false
            state.email_receiver = action.payload
        },
        [setEmailReceiver.rejected]: (state, action) => {
            state.loading = false
        }
        ,
        [setDepartment.pending]: (state, action) => {
            state.loading = true
        },
        [setDepartment.fulfilled]: (state, action) => {
            state.loading = false
            state.department_update = action.payload
        },
        [setDepartment.rejected]: (state, action) => {
            state.loading = false
        },

        [setEmployee.pending]: (state, action) => {
            state.loading = true
        },
        [setEmployee.fulfilled]: (state, action) => {
            state.loading = false
            state.employee_update = action.payload
        },
        [setEmployee.rejected]: (state, action) => {
            state.loading = false
        },

        [setBilling.pending]: (state, action) => {
            state.loading = true
        },
        [setBilling.fulfilled]: (state, action) => {
            state.loading = false
            state.billing_update = action.payload
        },
        [setBilling.rejected]: (state, action) => {
            state.loading = false
        },

        [setWard.pending]: (state, action) => {
            state.loading = true
        },
        [setWard.fulfilled]: (state, action) => {
            state.loading = false
            state.ward_update = action.payload
        },
        [setWard.rejected]: (state, action) => {
            state.loading = false
        },

        [setBedSpace.pending]: (state, action) => {
            state.loading = true
        },
        [setBedSpace.fulfilled]: (state, action) => {
            state.loading = false
            state.bedspace_update = action.payload
        },
        [setBedSpace.rejected]: (state, action) => {
            state.loading = false
        },

        [setProfileImage.pending]: (state, action) => {
            state.loading = true
        },
        [setProfileImage.fulfilled]: (state, action) => {
            state.loading = false
            state.image_capture = action.payload
        },
        [setProfileImage.rejected]: (state, action) => {
            state.loading = false
        },
        [assignDoctor.pending]: (state, action) => {
            state.loading = true
        },
        [assignDoctor.fulfilled]: (state, action) => {
            state.loading = false
            state.assign_doctor = action.payload
        },
        [assignDoctor.rejected]: (state, action) => {
            state.loading = false
        },
        [setShowBirthRecord.pending]: (state, action) => {
            state.loading = true
        },
        [setShowBirthRecord.fulfilled]: (state, action) => {
            state.loading = false
            state.show_birth_records = action.payload
        },
        [setShowBirthRecord.rejected]: (state, action) => {
            state.loading = false
        },

        [setShowDeathRecord.pending]: (state, action) => {
            state.loading = true
        },
        [setShowDeathRecord.fulfilled]: (state, action) => {
            state.loading = false
            state.show_death_records = action.payload
        },
        [setShowDeathRecord.rejected]: (state, action) => {
            state.loading = false
        },
        [setWardReport.pending]: (state, action) => {
            state.loading = true
        },
        [setWardReport.fulfilled]: (state, action) => {
            state.loading = false
            state.ward_report = action.payload
        },
        [setWardReport.rejected]: (state, action) => {
            state.loading = false
        },
        [setSingleBirthRecord.pending]: (state, action) => {
            state.loading = true
        },
        [setSingleBirthRecord.fulfilled]: (state, action) => {
            state.loading = false
            state.set_single_birth_record = action.payload
        },
        [setSingleBirthRecord.rejected]: (state, action) => {
            state.loading = false
        },
        [bookAppointment.pending]: (state, action) => {
            state.loading = true
        },
        [bookAppointment.fulfilled]: (state, action) => {
            state.loading = false
            state.book_appointment = action.payload
        },
        [bookAppointment.rejected]: (state, action) => {
            state.loading = false
        },
        [setcurrentBill.pending]: (state, action) => {
            state.loading = true
        },
        [setcurrentBill.fulfilled]: (state, action) => {
            state.loading = false
            state.book_appointment = action.payload
        },
        [setcurrentBill.rejected]: (state, action) => {
            state.loading = false
        },
        [setViewInoice.pending]: (state, action) => {
            state.loading = true
        },
        [setViewInoice.fulfilled]: (state, action) => {
            state.loading = false
            state.view_invoice = action.payload
        },
        [setViewInoice.rejected]: (state, action) => {
            state.loading = false
        },

        [setDrugCategory.pending]: (state, action) => {
            state.loading = true
        },
        [setDrugCategory.fulfilled]: (state, action) => {
            state.loading = false
            state.drug_category_update = action.payload
        },
        [setDrugCategory.rejected]: (state, action) => {
            state.loading = false
        },

        [setSupplyPoint.pending]: (state, action) => {
            state.loading = true
        },
        [setSupplyPoint.fulfilled]: (state, action) => {
            state.loading = false
            state.supplypoint_update = action.payload
        },
        [setSupplyPoint.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentDrug.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentDrug.fulfilled]: (state, action) => {
            state.loading = false
            state.drug_update = action.payload
        },
        [setCurrentDrug.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentBinCard.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentBinCard.fulfilled]: (state, action) => {
            state.loading = false
            state.drug_bincard = action.payload
        },
        [setCurrentBinCard.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentSupplyPoint.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentSupplyPoint.fulfilled]: (state, action) => {
            state.loading = false
            state.supplypoint_update = action.payload
        },
        [setCurrentSupplyPoint.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentSupplyPointInventory.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentSupplyPointInventory.fulfilled]: (state, action) => {
            state.loading = false
            state.supplypoint_inventory_update = action.payload
        },
        [setCurrentSupplyPointInventory.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentSupplyPointBinCard.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentSupplyPointBinCard.fulfilled]: (state, action) => {
            state.loading = false
            state.supplypoint_bincard = action.payload
        },
        [setCurrentSupplyPointBinCard.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentPrescriptions.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentPrescriptions.fulfilled]: (state, action) => {
            state.loading = false
            state.prescriptions_update = action.payload
        },
        [setCurrentPrescriptions.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentPrescriptionInvoice.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentPrescriptionInvoice.fulfilled]: (state, action) => {
            state.loading = false
            state.prescription_invoice_update = action.payload
        },
        [setCurrentPrescriptionInvoice.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentPrescriptionInvoices.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentPrescriptionInvoices.fulfilled]: (state, action) => {
            state.loading = false
            state.prescription_invoices_update = action.payload
        },
        [setCurrentPrescriptionInvoices.rejected]: (state, action) => {
            state.loading = false
        },

        [setRadiologyEquipment.pending]: (state, action) => {
            state.loading = true
        },
        [setRadiologyEquipment.fulfilled]: (state, action) => {
            state.loading = false
            state.set_radiology_equipment = action.payload
        },
        [setRadiologyEquipment.rejected]: (state, action) => {
            state.loading = false
        },
        [setRadiologyGenerateInvoice.pending]: (state, action) => {
            state.loading = true
        },
        [setRadiologyGenerateInvoice.fulfilled]: (state, action) => {
            state.loading = false
            state.set_radiology_generate_invoice = action.payload
        },
        [setRadiologyGenerateInvoice.rejected]: (state, action) => {
            state.loading = false
        },
        [setRadiologyViewReport.pending]: (state, action) => {
            state.loading = true
        },
        [setRadiologyViewReport.fulfilled]: (state, action) => {
            state.loading = false
            state.set_radiology_view_report = action.payload
        },
        [setRadiologyViewReport.rejected]: (state, action) => {
            state.loading = false
        },
        [setViewRadiologyInvoice.pending]: (state, action) => {
            state.loading = true
        },
        [setViewRadiologyInvoice.fulfilled]: (state, action) => {
            state.loading = false
            state.set_view_radiology_invoice = action.payload
        },
        [setViewRadiologyInvoice.rejected]: (state, action) => {
            state.loading = false
        },
        [setDeleteRadiologyCategory.pending]: (state, action) => {
            state.loading = true
        },
        [setDeleteRadiologyCategory.fulfilled]: (state, action) => {
            state.loading = false
            state.set_delete_radiology_cat = action.payload
        },
        [setDeleteRadiologyCategory.rejected]: (state, action) => {
            state.loading = false
        },
        [setDeleteRadiologyInvestigationType.pending]: (state, action) => {
            state.loading = true
        },
        [setDeleteRadiologyInvestigationType.fulfilled]: (state, action) => {
            state.loading = false
            state.set_delete_radiology_investigation_type = action.payload
        },
        [setDeleteRadiologyInvestigationType.rejected]: (state, action) => {
            state.loading = false
        },
        [setLaboratoryEquipment.pending]: (state, action) => {
            state.loading = true
        },
        [setLaboratoryEquipment.fulfilled]: (state, action) => {
            state.loading = false
            state.set_laboratory_equipment = action.payload
        },
        [setLaboratoryEquipment.rejected]: (state, action) => {
            state.loading = false
        },
        [setLaboratoryReport.pending]: (state, action) => {
            state.loading = true
        },
        [setLaboratoryReport.fulfilled]: (state, action) => {
            state.loading = false
            state.set_laboratory_reports = action.payload
        },
        [setLaboratoryReport.rejected]: (state, action) => {
            state.loading = false
        },
        [setLabInvoice.pending]: (state, action) => {
            state.loading = true
        },
        [setLabInvoice.fulfilled]: (state, action) => {
            state.loading = false
            state.set_laboratory_invoice = action.payload
        },
        [setLabInvoice.rejected]: (state, action) => {
            state.loading = false
        },
        [setLabCategoryTest.pending]: (state, action) => {
            state.loading = true
        },
        [setLabCategoryTest.fulfilled]: (state, action) => {
            state.loading = false
            state.set_laboratory_category_test = action.payload
        },
        [setLabCategoryTest.rejected]: (state, action) => {
            state.loading = false
        },
        [setLabInvestigationType.pending]: (state, action) => {
            state.loading = true
        },
        [setLabInvestigationType.fulfilled]: (state, action) => {
            state.loading = false
            state.set_laboratory_investigation_type = action.payload
        },
        [setLabInvestigationType.rejected]: (state, action) => {
            state.loading = false
        },
        [setLabSampleTest.pending]: (state, action) => {
            state.loading = true
        },
        [setLabSampleTest.fulfilled]: (state, action) => {
            state.loading = false
            state.set_lab_sample_test = action.payload
        },
        [setLabSampleTest.rejected]: (state, action) => {
            state.loading = false
        },
        [setPatientDiagnosisDetails.pending]: (state, action) => {
            state.loading = true
        },
        [setPatientDiagnosisDetails.fulfilled]: (state, action) => {
            state.loading = false
            state.set_patient_diagnosis_details = action.payload
        },
        [setPatientDiagnosisDetails.rejected]: (state, action) => {
            state.loading = false
        },
        [setGetDiagnosisPrescriptions.pending]: (state, action) => {
            state.loading = true
        },
        [setGetDiagnosisPrescriptions.fulfilled]: (state, action) => {
            state.loading = false
            state.set_get_diagnosisPrescriptions = action.payload
        },
        [setGetDiagnosisPrescriptions.rejected]: (state, action) => {
            state.loading = false
        },
        [setAdministerOpd.pending]: (state, action) => {
            state.loading = true
        },
        [setAdministerOpd.fulfilled]: (state, action) => {
            state.loading = false
            state.set_administer_opd = action.payload
        },
        [setAdministerOpd.rejected]: (state, action) => {
            state.loading = false
        },
        [setPatientID.pending]: (state, action) => {
            state.loading = true
        },
        [setPatientID.fulfilled]: (state, action) => {
            state.loading = false
            state.set_patient_id = action.payload
        },
        [setPatientID.rejected]: (state, action) => {
            state.loading = false
        },
        [setInpatientDetails.pending]: (state, action) => {
            state.loading = true
        },
        [setInpatientDetails.fulfilled]: (state, action) => {
            state.loading = false
            state.set_inpatient_details = action.payload
        },
        [setInpatientDetails.rejected]: (state, action) => {
            state.loading = false
        },
        [setNursesDeleteDetails.pending]: (state, action) => {
            state.loading = true
        },
        [setNursesDeleteDetails.fulfilled]: (state, action) => {
            state.loading = false
            state.set_nurses_delete_details = action.payload
        },
        [setNursesDeleteDetails.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentTeam.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentTeam.fulfilled]: (state, action) => {
            state.loading = false
            state.doctorteam_update = action.payload
        },
        [setCurrentTeam.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentPatient.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentPatient.fulfilled]: (state, action) => {
            state.loading = false
            state.patient_update = action.payload
        },
        [setCurrentPatient.rejected]: (state, action) => {
            state.loading = false
        },

        [setManageAdmittedPatient.pending]: (state, action) => {
            state.loading = true
        },
        [setManageAdmittedPatient.fulfilled]: (state, action) => {
            state.loading = false
            state.set_manage_admitted_patient = action.payload
        },
        [setManageAdmittedPatient.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentPatienFile.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentPatienFile.fulfilled]: (state, action) => {
            state.loading = false
            state.patient_file_update = action.payload
        },
        [setCurrentPatienFile.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentAppointment.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentAppointment.fulfilled]: (state, action) => {
            state.loading = false
            state.appointment_update = action.payload
        },
        [setCurrentAppointment.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentLabTest.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentLabTest.fulfilled]: (state, action) => {
            state.loading = false
            state.patient_labtest_update = action.payload
        },
        [setCurrentLabTest.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentRadiologyTest.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentRadiologyTest.fulfilled]: (state, action) => {
            state.loading = false
            state.patient_radiotest_update = action.payload
        },
        [setCurrentRadiologyTest.rejected]: (state, action) => {
            state.loading = false
        },

        [setRecordsGenerateCard.pending]: (state, action) => {
            state.loading = true
        },
        [setRecordsGenerateCard.fulfilled]: (state, action) => {
            state.loading = false
            state.set_records_generate_card = action.payload
        },
        [setRecordsGenerateCard.rejected]: (state, action) => {
            state.loading = false
        },
        [setImmunizationPatient.pending]: (state, action) => {
            state.loading = true
        },
        [setImmunizationPatient.fulfilled]: (state, action) => {
            state.loading = false
            state.set_immunization_patient = action.payload
        },
        [setImmunizationPatient.rejected]: (state, action) => {
            state.loading = false
        },
        [setBirthAndDeathRecordDetail.pending]: (state, action) => {
            state.loading = true
        },
        [setBirthAndDeathRecordDetail.fulfilled]: (state, action) => {
            state.loading = false
            state.set_birth_and_death_record = action.payload
        },
        [setBirthAndDeathRecordDetail.rejected]: (state, action) => {
            state.loading = false
        },
        [setFollowUpRecords.pending]: (state, action) => {
            state.loading = true
        },
        [setFollowUpRecords.fulfilled]: (state, action) => {
            state.loading = false
            state.set_follow_up_record = action.payload
        },
        [setFollowUpRecords.rejected]: (state, action) => {
            state.loading = false
        },
        [setPayStackPaymentDetails.pending]: (state, action) => {
            state.loading = true
        },
        [setPayStackPaymentDetails.fulfilled]: (state, action) => {
            state.loading = false
            state.set_paystack_payment_details = action.payload
        },
        [setPayStackPaymentDetails.rejected]: (state, action) => {
            state.loading = false
        },
        [setCurrentAccountsInvoice.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentAccountsInvoice.fulfilled]: (state, action) => {
            state.loading = false
            state.accounts_invoice = action.payload
        },
        [setCurrentAccountsInvoice.rejected]: (state, action) => {
            state.loading = false
        },

        [setPatientInvoiceModal.pending]: (state, action) => {
            state.loading = true
        },
        [setPatientInvoiceModal.fulfilled]: (state, action) => {
            state.loading = false
            state.set_patient_invoice_modal = action.payload
        },
        [setPatientInvoiceModal.rejected]: (state, action) => {
            state.loading = false
        },
        [setScheduleDate.pending]: (state, action) => {
            state.loading = true
        },
        [setScheduleDate.fulfilled]: (state, action) => {
            state.loading = false
            state.set_Schedule_date = action.payload
        },
        [setScheduleDate.rejected]: (state, action) => {
            state.loading = false
        },
        [setScheduleEdit.pending]: (state, action) => {
            state.loading = true
        },
        [setScheduleEdit.fulfilled]: (state, action) => {
            state.loading = false
            state.set_edit_schedule = action.payload
        },
        [setScheduleEdit.rejected]: (state, action) => {
            state.loading = false
        },

        [setFilteredSchedules.pending]: (state, action) => {
            state.loading = true
        },
        [setFilteredSchedules.fulfilled]: (state, action) => {
            state.loading = false
            state.filtered_schedules = action.payload
        },
        [setFilteredSchedules.rejected]: (state, action) => {
            state.loading = false
        },

        [setCurrentAccountsPharmacyInvoice.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentAccountsPharmacyInvoice.fulfilled]: (state, action) => {
            state.loading = false
            state.accounts_pharmacyinvoice = action.payload
        },
        [setCurrentAccountsPharmacyInvoice.rejected]: (state, action) => {
            state.loading = false
        },


        [setCurrentClinicalDataRepo.pending]: (state, action) => {
            state.loading = true
        },
        [setCurrentClinicalDataRepo.fulfilled]: (state, action) => {
            state.loading = false
            state.current_clinical_data = action.payload
        },
        [setCurrentClinicalDataRepo.rejected]: (state, action) => {
            state.loading = false
        },

        [setCallReceiver.pending]: (state, action) => {
            state.loading = true
        },
        [setCallReceiver.fulfilled]: (state, action) => {
            state.loading = false
            state.call_receiver = action.payload
        },
        [setCallReceiver.rejected]: (state, action) => {
            state.loading = false
        },
    }
})


export default rootSlice.reducer;
