import React, { useState, useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { FaTimesCircle } from 'react-icons/fa'
import { TiCancel } from 'react-icons/ti'
import { useStateContext } from '../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { setScheduleDate, setScheduleEdit } from '../../state/actions/actions'
import { updateASchedule, setUsersByDeparmentToNull } from '../../state/actions/admin.actions'
import { toast } from 'react-toastify'


const EditAndDeleteScheduleModal = () => {
    const dispatch = useDispatch()
    const { set_Schedule_date, set_edit_schedule } = useSelector(state => state.root)
    const [scheduleButtonListener, setScheduleButtonListener] = useState(0)
    const dateString = `${set_Schedule_date?.date}${set_Schedule_date?.date_number}`
    const dayString = `${set_Schedule_date?.day}`
    const [labelState, setLabelState] = useState('')



    const [formData, setFormData] = useState({
        id: '',
        schedule_title: '',
        members: [],
        date: '',
        member: [],
        time: '',
        label: ''

    })
    const membersAdded = []
    const { users_by_department, departments } = useSelector(state => state.admin)
    const listEvent = localStorage.setItem("Events", JSON.stringify([{ title: "Vacations", label: "green", day: Date.now() }, { title: "Holiday", label: "blue", day: Date.now() }]))
    const { modals, setModals } = useStateContext()
    const [selectedColor, setSelectedColor] = useState("")

    const colorClasses = [

        "green",
        "purple",
        "indigo",
    ]
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateASchedule({ formData, toast, setModals: setModals, modals: modals }))
        dispatch(setScheduleEdit({ data: null }))


    };
    const handleChange = (e, name) => {

        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
    };


    useEffect(() => {
        setModals({ ...modals, showListOfSchedulesByDateModal: false })
        // setModals({ ...modals, showEditDeleteOptionalModal: false })
    }, [!modals.showListOfSchedulesByDateModal])


    useEffect(() => {

    }, [scheduleButtonListener])

    const addMembers = () => {
        set_edit_schedule?.members.map((item, index) => (
            formData.members.push(item.pkid)
        ))

    }

    useEffect(() => {
        setFormData({
            ...formData, schedule_title: set_edit_schedule?.title,
            id: set_edit_schedule?.id,
            date: set_edit_schedule?.date,
            time: set_edit_schedule?.time,
            label: set_edit_schedule?.label,
            member: set_edit_schedule?.members,
        })

        addMembers()


    }, [set_edit_schedule])





    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    {modals.showEditAndDeleteScheduleModal && (
                        <div className="flex justify-between items-center">
                            <h4 className='text-dark-green text-[14px] font-semibold'>Edit schedule  - {set_edit_schedule?.department} Supplypoint</h4>
                            <FaTimesCircle
                                className='text-dark-yellow cursor-pointer'
                                onClick={() => {
                                    dispatch(setScheduleEdit({ data: null }))
                                    // dispatch(setUsersByDeparmentToNull({ data: null }))
                                    setModals({ ...modals, showEditAndDeleteScheduleModal: !modals.showEditAndDeleteScheduleModal })
                                }}
                                size={20}
                            />
                        </div>
                    )}
                    {modals.showViewScheduleModal && (
                        <div className="flex justify-between items-center">
                            <h4 className='text-dark-green text-[14px] font-semibold'>{set_edit_schedule?.title} schedule - {set_edit_schedule?.department} Supplypoint </h4>
                            <FaTimesCircle
                                className='text-dark-yellow cursor-pointer'
                                onClick={() => {
                                    dispatch(setScheduleEdit({ data: null }))
                                    // dispatch(setUsersByDeparmentToNull({ data: null }))
                                    setModals({ ...modals, showViewScheduleModal: !modals.showViewScheduleModal })
                                }}
                                size={20}
                            />
                        </div>
                    )}
                    {modals.showEditAndDeleteScheduleModal && (
                        <form
                            onSubmit={handleSubmit}
                        >
                            {/* <p className='text-sm font-semibold mb-1'>Schedule Date : {set_Schedule_date?.stringDay}, {set_Schedule_date?.date}{set_Schedule_date?.date_number}</p> */}
                            <div>
                                <input
                                    type="text"
                                    name="schedule_title"
                                    placeholder="Title"
                                    value={formData.schedule_title}
                                    className="border rounded w-full h-4 px-4 py-5 mb-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>
                            <div>
                                <p className='text-sm font-light'>Date</p>
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    className="border rounded w-full h-4 px-4 py-5 mb-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>
                            <div>
                                <p className='text-sm font-light'>Time</p>
                                <input
                                    type="time"
                                    name="time"
                                    value={formData.time}
                                    placeholder="Select time"
                                    className="border rounded w-full h-4 px-4 py-5 mb-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>


                            <p className='text-sm text-gray-400 font-semibold'>Members: </p>
                            <div className="flex flex-col space-y-2 max-h-[200px] my-4 overflow-y-auto patient__prescriptions">
                                {formData.member?.map((user, index) => (
                                    <div key={`${user.pkid}`} className="flex justify-between w-full">
                                        <div className="w-[90%] flex items-center space-x-4 border p-1 rounded-tl rounded-bl">

                                            <div className="flex flex-col -space-y-1">
                                                <p className="text-[12px] text-gray-600 font-bold">{user.first_name} {user.last_name} - {user.email}</p>

                                            </div>
                                        </div>


                                        <div className="w-[10%] flex items-center justify-center border-r border-t border-b p-1 rounded-tr rounded-br hover:cursor-pointer  ease-in-out duration-500">
                                            {formData.members.includes(user.pkid) == true && (

                                                <TiCancel
                                                    // color='skyblue'
                                                    size={30}
                                                    onClick={() => {
                                                        const item = formData.members.indexOf(user.pkid)
                                                        formData.members.splice(item, 1)
                                                        setScheduleButtonListener(scheduleButtonListener - 1)
                                                    }}
                                                    className="text-white ease-in-out duration-500 bg-blue-400"
                                                />
                                            )}
                                            {formData.members.includes(user.pkid) == false && (
                                                <AiOutlinePlus
                                                    size={30}
                                                    onClick={() => {
                                                        formData.members.push(user.pkid)
                                                        setScheduleButtonListener(scheduleButtonListener + 1)


                                                    }}
                                                    className="text-teal-600 hover:text-white ease-in-out duration-500 bg-yellow-400"
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}


                            </div>

                            <div className='mt-4 flex justify-center'>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Submit</button>
                            </div>
                        </form>
                    )}

                    {modals.showViewScheduleModal && (<form
                        onSubmit={handleSubmit}
                    >
                        {/* <p className='text-sm font-semibold mb-1'>Schedule Date : {set_Schedule_date?.stringDay}, {set_Schedule_date?.date}{set_Schedule_date?.date_number}</p> */}
                        <div>
                            <input
                                type="text"
                                name="schedule_title"
                                placeholder="Title"
                                value={set_edit_schedule?.title}
                                disabled
                                className="border rounded w-full h-4 px-4 py-5 mb-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>
                        <div>
                            <p className='text-sm font-light'>Date</p>
                            <input
                                type="date"
                                name="date"
                                disabled
                                value={set_edit_schedule?.date}
                                className="border rounded w-full h-4 px-4 py-5 mb-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>
                        <div>
                            <p className='text-sm font-light'>Time</p>
                            <input
                                type="time"
                                name="time"
                                disabled
                                value={set_edit_schedule?.time}
                                placeholder="Select time"
                                className="border rounded w-full h-4 px-4 py-5 mb-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>


                        <p className='text-sm text-gray-400 font-semibold'>Members: </p>
                        <div className="flex flex-col space-y-2 max-h-[200px] my-4 overflow-y-auto patient__prescriptions">
                            {set_edit_schedule?.members?.map((user, index) => (
                                <div key={`${user.pkid}`} className="flex justify-between w-full">
                                    <div className="w-[90%] flex items-center space-x-4 border p-1 rounded-tl rounded-bl">

                                        <div className="flex flex-col -space-y-1">
                                            <p className="text-[12px] text-gray-600 font-bold">{user.first_name} {user.last_name} - {user.email}</p>

                                        </div>
                                    </div>


                                    <div className="w-[10%] flex items-center justify-center border-r border-t border-b p-1 rounded-tr rounded-br hover:cursor-pointer  ease-in-out duration-500">
                                        {set_edit_schedule?.members.includes(user.pkid) == true && (

                                            <TiCancel
                                                // color='skyblue'
                                                size={30}
                                                // onClick={() => {
                                                //     const item = formData.members.indexOf(user.pkid)
                                                //     formData.members.splice(item, 1)
                                                //     setScheduleButtonListener(scheduleButtonListener - 1)
                                                // }}
                                                className="text-white ease-in-out duration-500 bg-blue-400"
                                            />
                                        )}
                                        {formData.members.includes(user.pkid) == false && (
                                            <AiOutlinePlus
                                                size={30}
                                                // onClick={() => {
                                                //     formData.members.push(user.pkid)
                                                //     setScheduleButtonListener(scheduleButtonListener + 1)


                                                // }}
                                                className="text-teal-600 hover:text-white ease-in-out duration-500 bg-yellow-400"
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}


                        </div>

                    </form>)}
                </div>


            </div>
        </div>
    )

}

export default EditAndDeleteScheduleModal