/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react"
import { FaUser } from "react-icons/fa"
import { HiMail } from "react-icons/hi"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"

import { MessageUsersTable, PageTitle, ReceivedMessagesTable, SentMessagesTable } from "../../components"
import { getAllEmployees, getAllReceivedEmails, getAllSentEmails } from "../../state/actions/admin.actions"


const Messages = () => {
	const dispatch = useDispatch()

	const { user } = useSelector((state) => state.auth)
	const { employees, all_sent_emails, all_received_emails, sent_email } = useSelector((state) => state.admin)

	const [activeLink, setActiveLink] = useState('Users')
	const [filterState, setFilterState] = useState({ search: '', users: [], sent_messages: all_sent_emails, received_messages: all_received_emails })

	const handleUsersFilter = () => {
		const filtered = employees?.filter(employee => {
			return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, users: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, users: employees?.filter(employee => employee.pkid !== user?.data?.pkid) })
		}
	}

	const handleSentMessagesFilter = () => {
		const filtered = all_sent_emails?.filter(mail => {
			return mail.receiver.first_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| mail.receiver.last_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| mail.receiver.email.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, sent_messages: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, sent_messages: all_sent_emails })
		}
	}

	const handleReceivedMessagesFilter = () => {
		const filtered = all_received_emails?.filter(mail => {
			return mail.receiver.first_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| mail.receiver.last_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| mail.receiver.email.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, received_messages: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, received_messages: all_received_emails })
		}
	}

	useEffect(() => {
		dispatch(getAllEmployees())
		dispatch(getAllSentEmails())
		dispatch(getAllReceivedEmails())
	}, [])

	useEffect(() => {
		dispatch(getAllEmployees())
		dispatch(getAllSentEmails())
		dispatch(getAllReceivedEmails())
	}, [sent_email])

	useEffect(() => {
		if (activeLink === 'Users') {
			handleUsersFilter()
		}
		if (activeLink === 'Sent') {
			handleSentMessagesFilter()
		}
		if (activeLink === 'Received') {
			handleReceivedMessagesFilter()
		}
	}, [filterState.search])


	return (
		<div>
			<PageTitle type={"emails"} />

			<div className="">
				<div className="flex space-x-5 justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
					<div className="space-x-5">
						<button
							type="button"
							className={`${activeLink === 'Users' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Users
						</button>

						<button
							type="button"
							className={`${activeLink === 'Sent' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Sent
						</button>

						<button
							type="button"
							className={`${activeLink === 'Received' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Received
						</button>
					</div>

					<div>
						<input
							type="text"
							name="search"
							// disabled
							placeholder='Search'
							className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
							onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
						/>
					</div>
				</div>

				{activeLink === 'Users' && (
					<MessageUsersTable
						data={filterState.users?.filter(employee => employee.pkid !== user?.data?.pkid)}
					/>
				)}

				{activeLink === 'Sent' && (
					<SentMessagesTable
						data={filterState.sent_messages}
					/>
				)}

				{activeLink === 'Received' && (
					<ReceivedMessagesTable
						data={filterState.received_messages}
					/>
				)}
			</div>
		</div>
	)
}

export default Messages
