/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { GoMail } from 'react-icons/go'
import { FaAllergies } from 'react-icons/fa'
import { AiOutlineLock } from 'react-icons/ai'
import { BiUser, BiPhone } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Logo } from '../../assets'
import { createCustomer, getPharmacyConfig, getSubscriptionPlan } from '../../state/actions/auth.actions'
import { ButtonLoader } from '../../components'


const Signup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, subscription_plan, pharmacy_config, authLoading } = useSelector((state) => state.auth)
    const [formData, setFormData] = useState({
        first_name: '', last_name: '', email: '',
        patient_phone: '', password: '', confirm_password: '',
        gender: '', pregnancy_status: false, allergies_and_reactions: '',
        is_password_reset: true
    })

    useEffect(() => {
        dispatch(getPharmacyConfig())
        dispatch(getSubscriptionPlan())
    }, [])

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])

    useEffect(() => {
        if (user) return navigate('/dashboard')
        if (subscription_plan === 'STARTER') return navigate('/')
    }, [user, navigate, subscription_plan])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSignup = (e) => {
        e.preventDefault()

        if (!formData.first_name && !formData.last_name && !formData.patient_phone && !formData.email && !formData.password && !formData.gender && !formData.pregnancy_status) {
            return toast.warning('All fields are required!')
        }
        if (formData.password !== formData.confirm_password) return toast.warning('Passwords do not match!')

        console.log(formData)
        dispatch(createCustomer({ formData, navigate, toast }))
    }


    return (
        <div className="main__login relative min-h-screen text-gray-800 antialiased px-4 py-6 flex flex-col justify-center sm:py-12 font-poppins">
            <div className="absolute right-[220px] top-[40px]">
                <div className="w-[100px] h-[100px] bg-white  bg-opacity-50 backdrop-blur-sm rounded-full"></div>
            </div>
            <div className="absolute right-[250px] top-[60px]">
                <div className="w-[100px] h-[100px] bg-white bg-opacity-50 backdrop-blur-sm rounded-full"></div>
            </div>
            <div className="relative py-3 sm:w-[800px] mx-auto text-center sm:auto">

                <div className="mt-4 bg-white shadow-md py-5">
                    <div className="flex flex-col">
                        <div className="space-y-10 mb-4 px-8">
                            <div className="flex space-x-5 items-center">
                                <img
                                    src={pharmacy_config ? `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}` : Logo}
                                    alt="logo"
                                    className='w-[40px] h-[40px] '
                                />
                                <h4 className="text-center text-[16px] font-bold">
                                    Welcome
                                </h4>
                            </div>
                        </div>

                        <form onSubmit={handleSignup}>
                            <div className="flex justify-between items-start">
                                <div className="px-8 w-[400px]">
                                    <div className="space-y-3">
                                        <div className="relative">
                                            <BiUser
                                                size={25}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            />
                                            <input
                                                type="text"
                                                name="first_name"
                                                placeholder="First name"
                                                className="border rounded w-full h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div className="relative">
                                            <BiUser
                                                size={25}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            />
                                            <input
                                                type="text"
                                                name="last_name"
                                                placeholder="Last name"
                                                className="border rounded w-full h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div className="relative">
                                            <GoMail
                                                size={25}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            />
                                            <input
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                className="border rounded w-full h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div className="relative">
                                            <AiOutlineLock
                                                size={25}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            />
                                            <input
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                className="border w-full rounded h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div className="relative">
                                            <AiOutlineLock
                                                size={25}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            />
                                            <input
                                                type="password"
                                                name="confirm_password"
                                                placeholder="Confirm password"
                                                className="border w-full rounded h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="px-8 w-[400px]">
                                    <div className="space-y-3">
                                        <div className="relative">
                                            <BiPhone
                                                size={25}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            />
                                            <input
                                                type="text"
                                                name="patient_phone"
                                                placeholder="Phone number"
                                                className="border rounded w-full h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div className="relative">
                                            <FaAllergies
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            />
                                            <textarea
                                                name="allergies_and_reactions"
                                                rows="4"
                                                placeholder='Allergies'
                                                className="border rounded w-full px-5 pl-12 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            ></textarea>
                                        </div>

                                        <div className="flex items-center space-x-8">
                                            <div className='flex items-center space-x-4'>
                                                <label className='text-[14px]'>Male</label>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    value={'Male'}
                                                    className="border rounded h-5 p-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                />
                                            </div>
                                            <div className='flex items-center space-x-4'>
                                                <label className='text-[14px]'>Female</label>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    value={'Female'}
                                                    className="border rounded h-5 p-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                />
                                            </div>
                                        </div>

                                        {formData.gender === 'Female' && (
                                            <div className="flex items-center space-x-5">
                                                <label className='text-[14px]'>Pregnancy Status</label>
                                                <input
                                                    type="checkbox"
                                                    name="pregnancy_status"
                                                    placeholder="Pregnancy status"
                                                    className="border rounded h-5 p-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                    checked={formData.pregnancy_status}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, pregnancy_status: !formData.pregnancy_status })
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col justify-between items-center space-y-5">
                                <button
                                    type="submit"
                                    className="mt-4 w-[175px] bg-light-blue text-white py-2 px-6 rounded-md hover:bg-light-blue capitalize"
                                >
                                    {!authLoading ? (
                                        'Sign Up'
                                    ) : (
                                        <ButtonLoader />
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Signup