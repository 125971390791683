import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { setSupplyPoint } from "../../../state/actions/actions"
import { createSupplyPoint, updateSupplyPoint } from "../../../state/actions/pharmacist.actions"
import ButtonLoader from "../../utils/ButtonLoader"

            
const PharmacistAddSupplyPointModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [formData, setFormData] = useState({ name: "", })
    const { supplypoint_update } = useSelector(state => state.root)
    const { supplyPoints, pharmacist_profiles, pharmacistLoading } = useSelector(state => state.pharmacist)

    useEffect(() => {
        setFormData({ ...formData, name: supplypoint_update?.name })
    }, [supplypoint_update])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(createSupplyPoint({ formData, toast, setModals, modals }))
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        if (!formData.name) return toast.warning('All fields are required!')
        dispatch(updateSupplyPoint({ formData: { ...formData, id: supplypoint_update?.id }, toast, setModals, modals, dispatch, setSupplyPoint }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`${supplypoint_update ? 'text-sky-500' : 'text-dark-green'} text-[20px] font-bold capitalize`}>
                            {modals.showPharmacistAddSupplyPointModal ? 'Add supply point' : 'Update supply point'}
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(setSupplyPoint({ data: null }))
                                modals.showPharmacistAddSupplyPointModal && setModals({ ...modals, showPharmacistAddSupplyPointModal: !modals.showPharmacistAddSupplyPointModal })
                                modals.showPharmacistUpdateSupplyPointModal && setModals({ ...modals, showPharmacistUpdateSupplyPointModal: !modals.showPharmacistUpdateSupplyPointModal })
                            }}
                            size={20}
                        />
                    </div>

                    {modals.showPharmacistAddSupplyPointModal && (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Supply Point Name"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
                                </button>
                            </div>
                        </form>
                    )}

                    {modals.showPharmacistUpdateSupplyPointModal && (
                        <form onSubmit={handleUpdate}>
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Billing name"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    value={formData.name}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!pharmacistLoading ? ("Update") : (<ButtonLoader />)}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PharmacistAddSupplyPointModal

