import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setCurrentPrescriptions } from '../../state/actions/actions'


const CustomerPrescriptionsTable = ({ data, showList }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    <Fragment>
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Drug
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((prescription, index) => (
                                    <tr key={`${prescription.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.drugs}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.dispense_count}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.created_at}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {!prescription.invoice_generated && (<button
                                                type="button"
                                                className="inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentPrescriptions({ data: prescription }))
                                                    setModals({ ...modals, showPharmacistDrugInvoiceModal: !modals.showPharmacistDrugInvoiceModal })
                                                }}
                                            >
                                                Generate Invoice
                                            </button>)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default CustomerPrescriptionsTable
