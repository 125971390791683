/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../state/context'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { TbReportAnalytics } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRadiologyReportRequest } from '../../state/actions/radiologist.actions'
import { setRadiologyGenerateInvoice, setRadiologyViewReport } from '../../state/actions/actions'
// import { getAllBillings } from '../../state/actions/records.actions'
import { PageTitle } from '..'
import Pagination from '../utils/Pagination'


const PharmacistRadiologistReportsTable = ({ data }) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const [currentData, setCurrentData] = useState([])
	const [showActionsDropdown, setShowActionsDropdown] = useState()
	const [currentRadInvestigationIndex, setcurrentRadInvestigationIndex] = useState(0)

	useEffect(() => {
		dispatch(getAllRadiologyReportRequest())
		// dispatch(getAllBillings())

		if (currentData != null) {
			setcurrentRadInvestigationIndex(currentData[0]?.pkid)
		}
	}, [modals.showUploadReportModal, modals.showRadiologyGenerateInvoiceModal])

	return (
		<div>
			<PageTitle type={'radiologist-reports'} />

			<div className='flex flex-col w-full cursor-pointer'>
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200  p-1'>
						<h4 className='text-dark-blue text-[16px] font-medium'>
							Reports
						</h4>
						<TbReportAnalytics
							className='text-dark-blue'
							size={20}
						/>
						{/* <input type="text" name="filter" placeholder='Search patient by name or patient number' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
					</div>
					<table className='border rounded w-full cursor-default'>
						<thead className=''>
							<tr>
								<th
									scope='col'
									className='text-[10px] font-medium text-gray-900 text-left'
								>
									S/N
								</th>

								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left'
								>
									Patient Name
								</th>
								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left'
								>
									Patient Number
								</th>
								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left'
								>
									Investigation Type
								</th>
								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left'
								>
									Pharmacist
								</th>
								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left'
								>
								</th>


								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left'
								>

								</th>
							</tr>
						</thead>
						<tbody>
							{currentData?.map((report, index) => (
								<tr
									className='relative'
									key={index}
								>
									<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
										{index + 1}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{report?.patient_details?.first_name} {report?.patient_details?.last_name}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{report?.patient_details?.patient_number}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{report?.investigation_details?.name}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{report?.pharmacist_details?.last_name} {report?.pharmacist_details?.first_name}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{/* {report.invoice_details && (
								report?.invoice_details?.is_paid ? (
									<p className='flex items-center justify-center text-[12px] bg-green-500 text-white font-semibold py-1 rounded w-[80px] '>Paid</p>
								) : (
									<p className='flex items-center justify-center text-[10px] bg-orange-400 text-white font-semibold py-1 rounded w-[80px] '>Pending</p>
								))
							} */}
										{report.invoice_details == null && (
											<p className='flex items-center justify-center text-[8px] text-white font-semibold bg-indigo-500 w-[80px] px-2 py-1 rounded-sm'>Not generated</p>
										)}
									</td>


									<td className=' text-[12px] text-gray-900 font-light py-2 whitespace-nowrap'>
										<div className='relative'>
											<button
												type='button'
												className='inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
												onClick={() => {
													setShowActionsDropdown(!showActionsDropdown)

													setcurrentRadInvestigationIndex(report.pkid)
												}}
											>
												<span className='flex items-center justify-between'>
													Actions
												</span>
											</button>

											{showActionsDropdown && currentRadInvestigationIndex === report.pkid && (
												<div className='absolute z-20 top-[25px] left-[-40px] p-1 bg-white shadow-lg border border-gray-200 rounded w-[150px]'>
													<div className='flex flex-col justify-start space-y-1'>
														{!report.invoice_generated && (
															<button
																onClick={() => {
																	setShowActionsDropdown(!showActionsDropdown)

																	dispatch(setRadiologyGenerateInvoice({ data: report }))
																	setModals({ ...modals, showRadiologyGenerateInvoiceModal: !modals.showRadiologyGenerateInvoiceModal })
																}}
																className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
															>
																Generate Invoice
															</button>
														)}

														{report?.invoice_details?.status && !report?.investigation_conducted && (
															<button
																onClick={() => {
																	setShowActionsDropdown(!showActionsDropdown)

																	dispatch(setRadiologyGenerateInvoice({ data: report }))
																	setModals({ ...modals, showUploadReportModal: !modals.showUploadReportModal })
																}}


																className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
															>
																Upload Investigation
															</button>
														)}

														<button
															onClick={() => {
																dispatch(setRadiologyViewReport({ data: report }))
																setShowActionsDropdown(!showActionsDropdown)
																setModals({ ...modals, showRadiologyViewReportModal: !modals.showRadiologyViewReportModal })
															}}

															className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
														>
															See Investigation
														</button>
													</div>
												</div>
											)}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					<Pagination
						data={data}
						currentData={currentData}
						setCurrentData={setCurrentData}
					/>
				</div>
			</div>
		</div>
	)
}

export default PharmacistRadiologistReportsTable