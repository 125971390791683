import React, { Fragment } from "react"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { useStateContext } from "../../../state/context"
import { setBilling, setCurrentPrescriptions } from "../../../state/actions/actions"
import { createBilling, getAllBillings, updateBilling, } from "../../../state/actions/admin.actions"


const PharmacistViewPrescriptionModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { prescriptions_update } = useSelector(state => state.root)

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center overflow-y-auto prescriptions">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-sky-500 text-[20px] font-bold">
                            Prescription
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(setCurrentPrescriptions({ data: null }))
                                setModals({ ...modals, showPharmacistViewPrescriptionModal: !modals.showPharmacistViewPrescriptionModal })
                            }}
                            size={20}
                        />
                    </div>

                    <div className={`flex flex-col justify-center`}>
                        {/* <div className={`${prescriptions_update?.routes ? 'bg-sky-100' : 'bg-yellow'} px-2 rounded-t-md py-2`}>
                            <h4 className="font-bold text-gray-600">Routes</h4>
                            <div>
                                <h4>{prescriptions_update?.routes}</h4>
                            </div>
                        </div> */}
                        <div className={`${prescriptions_update?.drugs ? 'bg-yellow-100' : 'bg-yellow'} px-2 py-2`}>
                            <h4 className="font-bold text-gray-600">Drug</h4>
                            <div>
                                <h4>{prescriptions_update?.drugs}</h4>
                            </div>
                        </div>
                        <div className={`${prescriptions_update?.dispense_count ? 'bg-sky-100' : 'bg-yellow'} px-2 py-2`}>
                            <h4 className="font-bold text-gray-600">Dispense Count</h4>
                            <div>
                                <h4>{prescriptions_update?.dispense_count}</h4>
                            </div>
                        </div>
                        {/* <div className={`${prescriptions_update?.units ? 'bg-yellow-100' : 'bg-yellow'} px-2 py-2`}>
                            <h4 className="font-bold text-gray-600">Units</h4>
                            <div>
                                <h4>{prescriptions_update?.units}</h4>
                            </div>
                        </div>
                        <div className={`${prescriptions_update?.frequency ? 'bg-sky-100' : 'bg-yellow'} px-2 py-2`}>
                            <h4 className="font-bold text-gray-600">Frequency</h4>
                            <div>
                                <h4>{prescriptions_update?.frequency}</h4>
                            </div>
                        </div>
                        <div className={`${prescriptions_update?.duration ? 'bg-yellow-100' : 'bg-yellow'} px-2 py-2`}>
                            <h4 className="font-bold text-gray-600">Duration</h4>
                            <div>
                                <h4>{prescriptions_update?.duration}</h4>
                            </div>
                        </div>
                        <div className={`bg-sky-100 px-2 rounded-b-md py-2`}>
                            <h4 className="font-bold text-gray-600">Duration (Times)</h4>
                            <div>
                                <h4>{prescriptions_update?.times}</h4>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PharmacistViewPrescriptionModal
