/* eslint-disable use-isnan */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { BsDashSquare } from 'react-icons/bs'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { createPrescription, getAllProfiles, getBillings, getConsultationFee, getDrugs, getInventoryDrugs, getPharmacistInventoryItems } from '../../../state/actions/pharmacist.actions'
import ButtonLoader from '../../utils/ButtonLoader'


const PharmacistAddPrescriptionModal = () => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)
    const { inventory_drugs, profiles, billings, all_inventories, pharmacist_inventory_items, pharmacistLoading } = useSelector(state => state.pharmacist)

    const { modals, setModals } = useStateContext()
    const [diabetic, setDiabetic] = useState(false)
    const [hypertensive, setHypertensive] = useState(false)
    const [stroke, setStroke] = useState(false)
    const [addConsultationFee, setAddConsultationFee] = useState(false)
    const [total, setTotal] = useState(0)
    const prevCountRef = useRef();


    const [consultationFee, setConsultationFee] = useState(0)
    const [formData, setFormData] = useState([{
        prescription_type: '', drug: '', routes: 'JK', units: '', frequency: '',
        duration: '', duration_time: '', strength: '', patient_name: '',
        patient_phone: '', other_prescription: false, dispense_count: 0,
        gender: '', patient_allergies: '', patient_pregnancy_status: false,
        get_patient: '', is_hypertensive: false, is_diabetic: false, is_stroke: stroke,
        consultation_fee: 0, email: '',
    }])

    const patient = profiles?.filter(profile => profile?.patient_phone === formData[0].get_patient || profile?.pharmacy_number === formData[0].get_patient)[0]



    useEffect(() => {
        dispatch(getDrugs())
        dispatch(getAllProfiles())
        dispatch(getBillings())
        dispatch(getPharmacistInventoryItems())
        dispatch(getInventoryDrugs())
    }, [])

    useEffect(() => {
        let data = [...formData]

        data[0].patient_name = patient?.full_name
        data[0].patient_phone = patient?.patient_phone
        data[0].patient_allergies = patient?.allergies_and_reactions
        data[0].gender = patient?.gender
        data[0].email = patient?.email

        setFormData(data)

    }, [patient])

    useEffect(() => {
        const index = billings?.findIndex(billing => billing.name === 'Consultation')

        if (index > -1) {
            setConsultationFee(billings[index]?.price)
        }
    }, [billings, consultationFee])



    useEffect(() => {
        let data = [...formData]
        data[0].is_stroke = stroke
        data[0].is_hypertensive = hypertensive
        data[0].is_diabetic = diabetic
        if (addConsultationFee) {
            data[0].consultation_fee = consultationFee
            setFormData(data)
        } else {
            data[0].consultation_fee = 0
            setFormData(data)
        }
        setFormData(data)

    }, [stroke, diabetic, hypertensive, addConsultationFee, total])

    useEffect(() => {
        prevCountRef.current = consultationFee

    }, [total])



    const handleChange = (index, e) => {
        let data = [...formData]
        data[index][e.target.name] = e.target.value
        setFormData(data)
        console.log()
    }

    const handleToggle = (getter, setter) => {
        setter(!getter)
    }

    const addConsultation = (getter, setter) => {
        setter(!getter)
        if (!addConsultationFee) {
            setTotal(total + consultationFee)
        } else {
            setTotal(total - consultationFee)
        }
    }

    const addPrescription = (e) => {
        e.preventDefault()
        setFormData([
            ...formData,
            {
                prescription_type: '', drug: '', routes: 'JK', units: 'kk', frequency: 'jk',
                duration: 'dl', duration_time: 'ld', strength: 'fk',
                patient_name: formData[0].patient_name, patient_phone: formData[0].patient_phone,
                other_prescription: false, dispense_count: 0, gender: formData[0].gender,
                patient_allergies: formData[0].patient_allergies, patient_pregnancy_status: formData[0].patient_pregnancy_status,
                email: formData[0].email
            }
        ])
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData)
        console.log(total)

        dispatch(createPrescription({ formData, toast, setModals, modals }))
    }

    const addItemPriceToTotal = () => {

        let _total = 0
        for (let i = 0; i < formData.length; i++) {
            const invt_item = all_inventories?.filter((e) => e?.inventory_item_details?.title === formData[i].drug)[0]
            _total += parseInt(invt_item.selling_price * formData[i]?.dispense_count)

        }

        setTotal(_total + formData[0]?.consultation_fee)



    }


    const removeItemPriceFromTotal = (name, item_count) => {
        const item = all_inventories?.filter((e) => e?.inventory_item_details?.title === name)[0]
        if (!item) {
            return
        } else {
            setTotal(total - parseInt(item?.selling_price * item_count))
        }
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[600px] rounded px-[30px] py-[10px]">
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[20px] font-bold'>Items</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                // dispatch(setSupplyPoint({ data: null }))
                                setModals({ ...modals, showPharmacistAddPrescriptionModal: !modals.showPharmacistAddPrescriptionModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit} className>
                        <div className={`space-y-2 max-h-[450px] overflow-y-auto patient__prescriptions`}>
                            <div className="mb-5">
                                <div>
                                    <input
                                        type="text"
                                        name="get_patient"
                                        placeholder="Search patient by phone number or pharmacy number"
                                        className="w-full bg-gray-200 italic border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(0, e)}
                                    />
                                </div>
                                {patient === undefined && <>

                                    <div>
                                        <input
                                            type="text"
                                            name="patient_name"
                                            placeholder="Patient Name"
                                            className="w-full border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(0, e)}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Patient Email"
                                            className="w-full border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(0, e)}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="patient_phone"
                                            placeholder="Patient Phone"
                                            className="w-full border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(0, e)}
                                        />
                                    </div>

                                    <div>
                                        <input
                                            type="text"
                                            name="patient_allergies"
                                            placeholder="Allergies | Reactions"
                                            className="w-full border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(0, e)}
                                        />
                                    </div>
                                </>}



                                {patient !== undefined && formData[0].get_patient !== '' && <>

                                    <div>
                                        <input
                                            type="text"
                                            name="patient_name"
                                            disabled
                                            value={`${patient.first_name} ${patient.last_name}`}
                                            className="w-full border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(0, e)}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="email"
                                            disabled
                                            value={patient?.email}
                                            className="w-full border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(0, e)}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="patient_phone"
                                            disabled
                                            value={`${patient.patient_phone}`}
                                            className="w-full border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(0, e)}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="" className='text-sm mb-0 font-bold'>Allergies</label>
                                        <input
                                            type="text"
                                            name="patient_allergies"
                                            disabled
                                            value={`${patient.allergies_and_reactions}`}
                                            placeholder="Allergies | Reactions"
                                            className="w-full border rounded px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(0, e)}
                                        />
                                    </div>
                                </>}
                                {patient === undefined &&
                                    <div className="flex items-center space-x-8 mt-5">
                                        <div className='flex items-center space-x-4'>
                                            <label className='text-[14px]'>Male</label>
                                            <input
                                                type="radio"
                                                name="gender"
                                                value={'Male'}
                                                className="border rounded h-5 p-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(0, e)}
                                            />
                                        </div>
                                        <div className='flex items-center space-x-4'>
                                            <label className='text-[14px]'>Female</label>
                                            <input
                                                type="radio"
                                                name="gender"
                                                value={'Female'}
                                                className="border rounded h-5 p-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(0, e)}
                                            />
                                        </div>


                                    </div>}

                                {!user?.data?.is_sales && (
                                    <Fragment>
                                        {billings?.length !== 0 && (
                                            <div className='flex justify-between mt-2'>
                                                <div className='flex space-x-2'>
                                                    <input type="checkbox" id="" onClick={() => addConsultation(addConsultationFee, setAddConsultationFee)} /> <p className='text-sm '>Add Consultation fee</p>
                                                </div>

                                                {addConsultationFee === true && (
                                                    <p className='text-[12px] color-grey text-white bg-violet-600 px-2 py-1 rounded-sm'>Consultation fee: ₦ {consultationFee}</p>
                                                )}
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                                <div className='flex justify-between'>
                                    <div className='flex space-x-2 mt-2'>
                                        <div className='flex space-x-2'><input type="checkbox" name="is_diabetic" id="" onClick={() => handleToggle(diabetic, setDiabetic)} /> <p className='text-sm '>Diabetic</p></div>
                                        <div className='flex space-x-2'><input type="checkbox" name="is_hypertensive" id="" onClick={() => handleToggle(hypertensive, setHypertensive)} /> <p className='text-sm '>Hypertensive</p></div>
                                        <div className='flex space-x-2'><input type="checkbox" id="" onClick={() => handleToggle(stroke, setStroke)} /> <p className='text-sm '>Stroke</p></div>
                                    </div>

                                    <div className='text-[10px] bg-blue-300 rounded-sm px-1 py-2'>
                                        Total Amount : ₦ {total}
                                    </div>
                                </div>
                                {formData[0].gender === 'Female' && (
                                    <div className="flex items-center space-x-5 mb-5">
                                        <label className='text-[14px]'>Pregnancy Status</label>
                                        <input
                                            type="checkbox"
                                            name="patient_pregnancy_status"
                                            placeholder="Pregnancy status"
                                            className="border rounded h-5 p-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            checked={formData.patient_pregnancy_status}
                                            onChange={(e) => {
                                                let data = [...formData]
                                                data[0][e.target.name] = !data[0].patient_pregnancy_status
                                                setFormData(data)
                                            }}
                                        />
                                    </div>
                                )}
                            </div>

                            {formData?.map((item, index) => (
                                <Fragment key={index}>
                                    <div className="flex justify-between items-center">
                                        <label
                                            className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded"
                                        >Item: {index + 1}</label>

                                        {index !== 0 && (
                                            <BsDashSquare
                                                size={30}
                                                onClick={() => {
                                                    setFormData(formData.filter((data, _index) => (_index + 1) !== (index + 1)))
                                                    removeItemPriceFromTotal(formData[index]?.drug, formData[index]?.dispense_count)
                                                }}
                                                className="text-orange-500 px-[5px] py-[0px] text-[10px] rounded cursor-pointer"
                                            />
                                        )}
                                    </div>
                                    <div className={`flex justify-between items-start w-full ${index !== formData.length - 1 && 'pb-5 border-b-2 border-gray-600'}`}>
                                        <div className="space-y-2 w-full">
                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="drug"
                                                    onChange={(e) => {
                                                        handleChange(index, e)
                                                    }}

                                                >
                                                    <option value="">Drug</option>
                                                    {pharmacist_inventory_items?.map((drug, index) => (
                                                        <option
                                                            key={`${drug.id}`}
                                                            // value={drug.pkid}
                                                            value={drug.title}
                                                        // onClick={() => {
                                                        //     addItemPriceToTotal(drug?.pkid)
                                                        // }}
                                                        >
                                                            {drug.title}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            {/* <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="prescription_type"
                                                    onChange={(e) => handleChange(index, e)}
                                                >
                                                    <option value="">Prescription Type</option>
                                                    {['pack', 'card', 'bottle', 'container']?.map((type, index) => (
                                                        <option
                                                            key={`${index}`}
                                                            value={type}
                                                        >
                                                            {type}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div> */}

                                            <div>
                                                <input
                                                    type="number"
                                                    min="0"
                                                    name="dispense_count"
                                                    placeholder="Pack Count"
                                                    className="border rounded w-full h-5 px-4 py-3 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                    onChange={(e) => {
                                                        handleChange(index, e)

                                                        // if (index === formData[index]) {
                                                        // setTimeout(removeItemPriceFromTotal(formData[index]?.drug, formData[index]?.dispense_count), 500)
                                                        addItemPriceToTotal()

                                                        // }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </Fragment>
                            ))}

                        </div>
                        <div className='flex justify-between mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 border-2 border-dark-blue text-dark-blue font-medium text-xs leading-tight rounded shadow-md transition duration-500 ease"
                                onClick={addPrescription}
                            >Add</button>

                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >{!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}</button>
                        </div>

                    </form>
                </div>


            </div >
        </div >
    )
}
export default PharmacistAddPrescriptionModal