/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { GifWallet } from '../assets'
import { updateInvestigationInvoice, updateLabInvoice, updateMultipleInventoryInvoices, updateMultipleInvestigationInvoices, updatePharmacyInvoice, updateRadiologyInvoice, updateRecordsInvoice, verifyFlutterWavePayment } from '../state/actions/collection.actions'
import { updateSubscriptionPlan } from '../state/actions/pharmacist.actions'
import { getPharmacyConfig } from '../state/actions/auth.actions'


const CollectionsVerifyFlutterWavePayment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const ref = window.location.href.split('transaction_id=')[1]
    const status = window.location.href.split('?status=')[1].split('&tx_ref=')[0]

    const { pharmacy_config } = useSelector(state => state.auth)
    const { flutterwave_payment_status } = useSelector(state => state.collection)

    useEffect(() => {
        dispatch(getPharmacyConfig())
    }, [])

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])

    useEffect(() => {
        if (!ref && status === 'cancelled') {
            toast.info('Transaction cancelled')
            navigate('/dashboard')
        }

        if (ref && status === 'successful') {
            dispatch(verifyFlutterWavePayment({ params: { ref } }))
        }
    }, [ref])

    useEffect(() => {
        const payment__invoice = localStorage.getItem('payment__invoice') ? JSON.parse(localStorage.getItem('payment__invoice')) : null

        if (payment__invoice.type === 'laboratory_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updateLabInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'pharmacy_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updatePharmacyInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'investigation_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.pkid,
                mode_of_payment: flutterwave_payment_status?.payment_type,
                date_paid: flutterwave_payment_status?.created_at?.slice(0, 10)
            }
            // console.log(formData, flutterwave_payment_status)
            dispatch(updateInvestigationInvoice({ formData, toast, navigate }))
        }


        if (payment__invoice.type === 'radiology_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updateRadiologyInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'records_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updateRecordsInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'pharmacy_multiple_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                invoices: payment__invoice?.invoices,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updateMultipleInventoryInvoices({ formData, toast, navigate }))
        }


        if (payment__invoice.type === 'investigation_multiple_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                invoices: payment__invoice?.invoices,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updateMultipleInvestigationInvoices({ formData, toast, navigate }))
        }

        // if (payment__invoice.type === 'subscription__payment' && flutterwave_payment_status?.status === 'successful') {
        //     const formData = {
        //         title: payment__invoice?.title,
        //         price: payment__invoice?.price,
        //         amount_paid: payment__invoice?.amount_paid,
        //         mode_of_payment: flutterwave_payment_status?.channel,
        //         payment_reference: ref,
        //     }

        //     dispatch(updateSubscriptionPlan({ formData, toast, navigate }))
        // }
    }, [flutterwave_payment_status])

    return (
        <div className='h-screen flex justify-center items-center'>
            <img
                src={GifWallet}
                alt='wallet'
                className='w-1/2'
            />
        </div>
    )
}

export default CollectionsVerifyFlutterWavePayment
