/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { AiOutlineCloudUpload } from "react-icons/ai"
import { FaAllergies, FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { createInventoryItem, createProvision, getDrugCategories } from "../../../state/actions/pharmacist.actions"
import ButtonLoader from "../../utils/ButtonLoader"


const PharmacistAddProvisionModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { drugCategories, pharmacistLoading } = useSelector(state => state.pharmacist)

    const imageRef = useRef(null)
    const [checked, setChecked] = useState(false)
    const [checkIsCounterDrug, setCheckIsCounterDrug] = useState(false)
    const [formType, setFormType] = useState(null)
    const [formData, setFormData] = useState({
        item_type: '',
        title: '',
        price: '',
        description: '',
        image: '',
        image_preview: '',
        is_available: false,
        drug_category: '',
        drug_substitute: "",
        quantity: "",
        drug_alert_quantity: "",
        locked: false,
        is_counter_item: false,
    })

    useEffect(() => {
        dispatch(getDrugCategories())
    }, [])


    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }
    const handleImageChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0], image_preview: URL.createObjectURL(e.target.files[0]) })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        // if (!formData.description && !formData.image && !formData.drug_alert_quantity && !formData.drug_category && !formData.image) return toast.warning('All fields are required!')
        dispatch(createInventoryItem({ formData, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[600px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-600 text-[16px] font-bold capitalize`}>
                            Add Inventory
                        </h4>
                        <FaTimesCircle
                            size={20}
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showPharmacistAddInventoryModal: !modals.showPharmacistAddInventoryModal })
                            }}
                        />
                    </div>

                    <div className="relative">
                        <select
                            className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                            // name="drug_category"
                            onChange={(e) => {
                                if (e.target.value === '') setFormType(null)

                                if (e.target.value === 'Drug') {
                                    setFormType(e.target.value)
                                    setFormData({ ...formData, item_type: 'Drug' })
                                }

                                if (e.target.value === 'Provision') {
                                    setFormType(e.target.value)
                                    setFormData({ ...formData, item_type: 'Provision' })
                                }
                            }}
                        >
                            <option value="">Form Type</option>
                            {['Drug', 'Provision']?.map((type, index) => (
                                <option
                                    key={`${index}`}
                                    value={type}
                                >{type}</option>
                            ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>

                    {formType === 'Provision' && (
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-2">
                                <div>
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder="Title | Name"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                                {/* 
                                <div>
                                    <input
                                        type="number"
                                        min={'5'}
                                        name="price"
                                        placeholder="Price"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
 */}

                                <div className="relative">
                                    <textarea
                                        name="description"
                                        placeholder='Description'
                                        className="border rounded w-full px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    ></textarea>
                                </div>

                                <div className="flex items-center space-x-10">
                                    <div className="flex items-center space-x-3">
                                        <input
                                            className="rounded border-4 w-5 h-5 border-red-400"
                                            type="checkbox"
                                            name="is_counter_item"
                                            checked={checkIsCounterDrug}
                                            onChange={() => {
                                                setCheckIsCounterDrug(!checkIsCounterDrug);
                                                setFormData({ ...formData, is_counter_item: !checkIsCounterDrug });
                                            }}
                                        />
                                        <p className="text-[12px] text-gray-700 font-bold">Counter Item</p>
                                    </div>
                                </div>

                                <input
                                    type="file"
                                    name="image"
                                    ref={imageRef}
                                    accept="image/jpg, image/png, image/jpeg"
                                    className="border hidden rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={handleImageChange}
                                />
                                <div className="w-full flex justify-between h-[100px]">
                                    <div
                                        className="border border-slate-400 flex items-center justify-center py-4 w-[100px] rounded h-[100px]  cursor-pointer hover:-translate-y-1 ease-in-out duration-100"
                                        onClick={() => imageRef.current.click()}
                                    >
                                        <AiOutlineCloudUpload
                                            size={40}
                                            className='text-sky-500'
                                        />
                                    </div>

                                    {formData.image && (
                                        <img
                                            src={formData.image_preview}
                                            alt=""
                                            className="h-full w-[200px]"
                                        />
                                    )}
                                </div>


                            </div>

                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
                                </button>
                            </div>
                        </form>
                    )}

                    {formType === 'Drug' && (
                        <form onSubmit={handleSubmit}>

                            <div className="space-y-2">
                                <div className="relative">
                                    <select
                                        className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                        name="drug_category"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    >
                                        <option value="">Drug Category</option>
                                        {drugCategories?.map((category, index) => (
                                            <option
                                                key={`${category.id}`}
                                                value={category.pkid}
                                            >{category.category}</option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder="Medicine Name"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        name="drug_substitute"
                                        placeholder="Medicine Substitute"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="hidden"
                                        name="quantity"
                                        placeholder="Quantity"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="number"
                                        name="drug_alert_quantity"
                                        min="1000"
                                        placeholder="Alert Quantity"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="flex items-center space-x-10">
                                    <div className="flex items-center space-x-3">
                                        <input
                                            className="rounded border-4 w-5 h-5 border-red-400"
                                            type="checkbox"
                                            name="is_counter_item"
                                            checked={checkIsCounterDrug}
                                            onChange={() => {
                                                setCheckIsCounterDrug(!checkIsCounterDrug);
                                                setFormData({ ...formData, is_counter_item: !checkIsCounterDrug });
                                            }}
                                        />
                                        <p className="text-[12px] text-gray-700 font-bold">Counter Drug</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PharmacistAddProvisionModal
