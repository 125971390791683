/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { createRadiologyInvestigationCategory, updateRadiologyInvestigationCategory } from '../../../state/actions/pharmacist.actions'


const PharmacistAddRadiologyInvestigationCategoryModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { rad_investigation_types, laboratory_data } = useSelector(state => state.pharmacist)

    const [formData, setFormData] = useState({
        title: '',
        id: '',
        investigation_type: ''
    })

    useEffect(() => {
        setFormData({
            ...formData,
            id: laboratory_data?.id,
            title: laboratory_data?.title,
            investigation_type: laboratory_data?.investigation_type
        })
    }, [laboratory_data])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!formData.title && !formData.investigation_type) return toast.warning('All fields are required!')
        dispatch(createRadiologyInvestigationCategory({ formData, toast, setModals, modals }))
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        if (!formData.title) return toast.warning('All fields are required!')
        dispatch(updateRadiologyInvestigationCategory({ formData, toast, setModals, modals }))
    }


    return (
        <div className="absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showPharmacistAddRadiologyInvestigationCategoryModal ? 'text-dark-green' : 'text-sky-600'} text-[16px] font-medium`}>
                            {modals.showPharmacistAddRadiologyInvestigationCategoryModal ? 'Add Investigation Category' : 'Update Investigation Category'}
                        </h4>
                        <FaTimesCircle
                            size={20}
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                modals.showPharmacistAddRadiologyInvestigationCategoryModal && setModals({ ...modals, showPharmacistAddRadiologyInvestigationCategoryModal: !modals.showPharmacistAddRadiologyInvestigationCategoryModal })
                                modals.showPharmacistUpdateRadiologyInvestigationCategoryModal && setModals({ ...modals, showPharmacistUpdateRadiologyInvestigationCategoryModal: !modals.showPharmacistUpdateRadiologyInvestigationCategoryModal })
                            }}
                        />
                    </div>

                    {modals.showPharmacistAddRadiologyInvestigationCategoryModal && (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <select
                                    name="investigation_type"
                                    className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option>Select Investigation Type</option>
                                    {rad_investigation_types?.map((type, index) => (
                                        <option value={type.pkid}>{type.title}</option>
                                    ))}
                                </select>
                                <input
                                    type="title"
                                    name="title"
                                    placeholder="Title"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                                <div className='mt-4 '>
                                    <button
                                        type='submit'
                                        className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                    >Add</button>
                                </div>
                            </div>
                        </form>
                    )}

                    {modals.showPharmacistUpdateRadiologyInvestigationCategoryModal && (
                        <form onSubmit={handleUpdate}>
                            <div>
                                <select
                                    name="investigation_type"
                                    className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option>Select Investigation Type</option>
                                    {rad_investigation_types?.map((type, index) => (
                                        <option value={type.pkid}>{type.title}</option>
                                    ))}
                                </select>
                                <input
                                    type="title"
                                    name="title"
                                    placeholder="Title"
                                    value={formData.title}
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                                <div className='mt-4 '>
                                    <button
                                        type='submit'
                                        className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                    >Add</button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>


            </div>
        </div>
    )
}

export default PharmacistAddRadiologyInvestigationCategoryModal