/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { getSMSBalance, getSubscriptionPlans } from '../../state/actions/auth.actions'
import { getBillings, getLaboratoryInvestigationCategories, getLaboratoryInvestigationSamples, getLaboratoryInvestigationTypes, getRadiologyInvestigationTypes, getRadiologyInvestigationCategories, getGatewaySettings, setCurrentSubscriptionPlan } from '../../state/actions/pharmacist.actions'
import { PageTitle, PharmacistBillingsTable, PharmacistGatewaySettingsTable, PharmacistInformationTable, PharmacistLabInvestigationCategoriesTable, PharmacistLabInvestigationSamplesTable, PharmacistLabInvestigationTypesTable, PharmacistRadInvestigationCategoriesTable, PharmacistRadInvestigationTypesTable } from '../../components'


const PharmacistBillingAndConfigs = () => {
	const dispatch = useDispatch()

	const { appState, setAppState, modals, setModals } = useStateContext()

	const [enableRenewal, setEnableRenewal] = useState(false)
	const [planExpDate, setPlanExpDate] = useState()
	const { user, subscription_plans } = useSelector(state => state.auth)
	const {
		billings, lab_investigation_types, lab_investigation_categories,
		lab_investigation_samples, rad_investigation_types, rad_investigation_categories,
		gateway_settings, subscription_plan
	} = useSelector(state => state.pharmacist)

	const [activeLink, setActiveLink] = useState("Billings")

	useEffect(() => {
		dispatch(getBillings())
		dispatch(getSMSBalance())
		dispatch(getGatewaySettings())
		dispatch(getLaboratoryInvestigationSamples())
		dispatch(getLaboratoryInvestigationTypes())
		dispatch(getLaboratoryInvestigationCategories())
		dispatch(getRadiologyInvestigationTypes())
		dispatch(getSubscriptionPlans())
		dispatch(getRadiologyInvestigationCategories())
	}, [])

	useEffect(() => {
		const date = new Date(subscription_plan?.expiration_date)
		date.setMonth(date.getMonth() - 1)
		setPlanExpDate(date.toISOString().slice(0, 10))
	}, [subscription_plan])

	useEffect(() => {
		const now = new Date()
		const futureTime = new Date(planExpDate)

		const timeDiffInMs = futureTime.getTime() - now.getTime();

		const diff_in_days = timeDiffInMs / (1000 * 3600 * 24)

		// console.log(futureTime, futureTime, diff_in_days)
		if (diff_in_days <= 30) {
			setEnableRenewal(true)
			console.log('Date is older than 30 days');
		} else {
			setEnableRenewal(false)
			console.log('Date is not older than 30 days');
		}
	}, [planExpDate])

	return (
		<div>
			<PageTitle type={'settings'} />

			<div className="space-y-5">
				<div>
					<div className="flex space-x-5 items-center border-b-2 border-gray-500 pb-2 w-full">
						{!subscription_plan?.is_expired && <button
							type="button"
							className={`${activeLink === 'Billings' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setActiveLink(e.target.textContent)
								setAppState({ ...appState, showSettingsBillings: true, showSettingsLaboratory: false, showSettingsRadiology: false, showSettingsGateway: false, })
							}}
						>
							Billings
						</button>}
						{!subscription_plan?.is_expired && user?.data.subscription_plan !== 'STARTER' &&
							<button
								type="button"
								className={`${activeLink === 'Laboratory' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
								onClick={(e) => {
									setActiveLink(e.target.textContent)
									setAppState({ ...appState, showSettingsLaboratory: true, showSettingsSubscriptionPlan: false, showSettingsBillings: false, showSettingsRadiology: false, showSettingsGateway: false, })
								}}
							>
								Laboratory
							</button>}

						{!subscription_plan?.is_expired && user?.data.subscription_plan !== 'STARTER' && <button
							type="button"
							className={`${activeLink === 'Radiology' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setActiveLink(e.target.textContent)
								setAppState({ ...appState, showSettingsRadiology: true, showSettingsSubscriptionPlan: false, showSettingsBillings: false, showSettingsLaboratory: false, showSettingsGateway: false, })
							}}
						>
							Radiology
						</button>}

						{!subscription_plan?.is_expired && (
							<button
								type="button"
								className={`${activeLink === 'Gateway' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
								onClick={(e) => {
									setActiveLink(e.target.textContent)
									setAppState({ ...appState, showSettingsGateway: true, showSettingsSubscriptionPlan: false, showSettingsRadiology: false, showSettingsBillings: false, showSettingsLaboratory: false })
								}}
							>
								Gateway
							</button>
						)}
						<button
							type="button"
							className={`${activeLink === 'Information' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setActiveLink(e.target.textContent)
								setAppState({ ...appState, showSettingsGateway: false, showSettingsSubscriptionPlan: false, showSettingsRadiology: false, showSettingsBillings: false, showSettingsLaboratory: false })
							}}
						>
							Information
						</button>

						<button
							type="button"
							className={`${activeLink === 'Subscription Plan' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setActiveLink(e.target.textContent)
								setAppState({ ...appState, showSettingsSubscriptionPlan: false, showSettingsGateway: false, showSettingsRadiology: false, showSettingsBillings: false, showSettingsLaboratory: false })
							}}
						>
							Subscription Plan
						</button>
					</div>

					{activeLink === 'Billings' && (
						<PharmacistBillingsTable
							data={billings}
						/>
					)}

					{activeLink === 'Laboratory' && (
						<div className="space-y-4">
							<PharmacistLabInvestigationTypesTable
								data={lab_investigation_types}
							/>

							<PharmacistLabInvestigationCategoriesTable
								data={lab_investigation_categories}
							/>

							<PharmacistLabInvestigationSamplesTable
								data={lab_investigation_samples}
							/>
						</div>
					)}

					{activeLink === 'Radiology' && (
						<div className="space-y-4">
							<PharmacistRadInvestigationTypesTable
								data={rad_investigation_types}
							/>

							<PharmacistRadInvestigationCategoriesTable
								data={rad_investigation_categories}
							/>
						</div>
					)}

					{activeLink === 'Information' && (
						<PharmacistInformationTable
						// data={gateway_settings}
						/>
					)}

					{activeLink === 'Gateway' && (
						<PharmacistGatewaySettingsTable
							data={gateway_settings}
						/>
					)}

					{activeLink === 'Subscription Plan' && (
						<div className="w-full flex flex-wrap justify-center mt-[40px]">
							<div className="flex flex-wrap space-x-0 md:space-x-5 items-start">
								{subscription_plans?.map((plan, index) => (
									<div className={`flex flex-col justify-between items-center w-[320px] border ${user?.data?.subscription_plan === 'STARTER' && index === 0 ? 'border-purple-400 inner__shadow__3' : user?.data?.subscription_plan === 'STANDARD' && index === 1 ? 'border-purple-400 inner__shadow__3' : user?.data?.subscription_plan === 'ADVANCED' && index === 2 ? 'border-purple-400 inner__shadow__3' : 'border-slate-200 inner__shadow__1'} rounded-md shadow-lg hover:-translate-y-2 duration-500 ease-in-out py-4`}>
										<div className="text-center">
											<h1 className='text-[20px] text-slate-600'>{plan?.title} Plan</h1>
											{plan.title === 'STARTER' && (
												<p className='text-[12px] text-slate-800'>Ideal solutions for small pharmacies</p>
											)}
											{plan.title === 'STANDARD' && (
												<p className='text-[12px] text-slate-800'>Ideal solutions for medium pharmacies</p>
											)}
											{plan.title === 'ADVANCED' && (
												<p className='text-[12px] text-slate-800'>Ideal solutions for large pharmacies</p>
											)}

											{plan.title === 'STARTER' && (
												<div className="mt-5 text-left">
													<ul>
														<li className="text-[12px] list-disc font-bold font-montserrat">Inventory management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Supply point management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Staff Schedule Management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Drug/Provision expiration and tracking</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">SMS Delivery (Messaging Systems) </li>
														<li className="text-[12px] list-disc font-bold font-montserrat">In system messaging</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Payment Systems</li>
														<ul className="ml-5">
															<li className="text-[12px] list-disc font-bold font-montserrat">Cash Purchase</li>
															<li className="text-[12px] list-disc font-bold font-montserrat">POS Purchase</li>
															<li className="text-[12px] list-disc font-bold font-montserrat">Bank Transfer</li>
														</ul>
													</ul>
												</div>
											)}
											{plan.title === 'STANDARD' && (

												<div className="mt-5 text-left">
													<ul>
														<li className="text-[12px] list-disc font-bold font-montserrat">Inventory management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Supply point management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Staff Schedule Management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Special patient management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Clinical data</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Online Sales and Purchase</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Drug/Provision expiration and tracking</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">SMS Delivery (Messaging Systems)</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Live Chat</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">In system messaging</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Annual Subscription for domain, <br /> hosting and data backup</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Payment Systems</li>
														<ul className="ml-5">
															<li className="text-[12px] list-disc font-bold font-montserrat">Cash Purchase</li>
															<li className="text-[12px] list-disc font-bold font-montserrat">POS Purchase</li>
															<li className="text-[12px] list-disc font-bold font-montserrat">Bank Transfer</li>
															<li className="text-[12px] list-disc font-bold font-montserrat">Online Payment</li>
														</ul>
														<li className="text-[12px] list-disc font-bold font-montserrat">Online Purchase delivery and <br /> Management </li>
													</ul>
												</div>

											)}
											{plan.title === 'ADVANCED' && (
												<div className="mt-5 text-left">
													<ul>
														<li className="text-[12px] list-disc font-bold font-montserrat">Inventory management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Supply point management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Staff Schedule Management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Special patient management</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Clinical data</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Online Sales and Purchase</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Drug/Provision expiration and tracking</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">SMS Delivery (Messaging Systems)</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Live Chat</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Video call</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">In system messaging</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Conference call </li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Consultation (Diagnosis and prescription) </li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Investigation request and Management </li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Sales management, statistics <br /> and reporting </li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Annual Subscription for domain, <br /> hosting and data backup</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Customer data capture and product <br /> promotion</li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Payment Systems</li>
														<ul className="ml-5">
															<li className="text-[12px] list-disc font-bold font-montserrat">Cash Purchase</li>
															<li className="text-[12px] list-disc font-bold font-montserrat">POS Purchase</li>
															<li className="text-[12px] list-disc font-bold font-montserrat">Bank Transfer</li>
															<li className="text-[12px] list-disc font-bold font-montserrat">Online Payment</li>
														</ul>
														<li className="text-[12px] list-disc font-bold font-montserrat">Online Purchase delivery and <br /> Management </li>
														<li className="text-[12px] list-disc font-bold font-montserrat">Online Customer Support </li>
													</ul>
												</div>
											)}
										</div>


										{!subscription_plan?.plans_subscribed?.includes(plan?.pkid) && (
											<div className="text-center mt-10">
												<button
													type="button"
													className="inline-block px-8 py-2 bg-teal-600 text-white font-medium text-sm leading-tight capitalize rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
													// disabled={user?.data?.subscription_plan === 'STARTER' ? true : false}
													onClick={() => {
														dispatch(setCurrentSubscriptionPlan({ data: plan }))
														setModals({ ...modals, showUpdateSubscriptionPlanModal: !modals.showUpdateSubscriptionPlanModal })
													}}
												>
													Upgrade
												</button>
											</div>
										)}
										{subscription_plan?.plans_subscribed?.includes(plan?.pkid) && user?.data?.subscription_plan === plan.title && (
											<Fragment>
												{enableRenewal && (
													<div className="text-center mt-10">
														<button
															type="button"
															className="inline-block px-8 py-2 bg-purple-800 text-white font-medium text-sm leading-tight capitalize rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
															// disabled={enableRenewal}
															onClick={() => {
																dispatch(setCurrentSubscriptionPlan({ data: plan }))
																setModals({ ...modals, showRenewSubscriptionPlanModal: !modals.showRenewSubscriptionPlanModal })
															}}
														>
															Renew
														</button>
													</div>
												)}
											</Fragment>
										)}
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default PharmacistBillingAndConfigs