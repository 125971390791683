import React from 'react'

export const PaymentStatus = ({ status }) => {
    return (
        <div className="flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-100">
            <span className={`w-2 h-2 rounded-full ${status ? 'bg-teal-600' : 'bg-orange-500'}`}></span>
            <p className='text-[10px]'>{status ? 'Paid' : 'Pending'}</p>
        </div>
    )
}


export const OrderStatus = ({ status }) => {
    return (
        <div className={`flex space-x-2 px-2 py-1 h-[25px] justify-center items-center rounded-full w-1/2 ${status === 'IN_STORE' ? 'bg-purple-100' : status === 'IN_ROUTE' ? 'bg-yellow-100' : 'bg-teal-100'}`}>
            <span className={`w-2 h-2 rounded-full ${status === 'IN_STORE' ? 'bg-purple-600' : status === 'IN_ROUTE' ? 'bg-orange-500' : 'bg-teal-600'}`}></span>
            <p className='text-[10px] font-medium'>{status}</p>
         </div>
    )
}

