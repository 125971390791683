import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentCustomerInvoice, customerGetInvoices } from '../../state/actions/pharmacist.actions'
import { setCurrentPharmacyInvoice, setAccumulatedPharmacyInvoice } from '../../state/actions/collection.actions'


import { useStateContext } from '../../state/context'
import Pagination from '../utils/Pagination'


const CustomerInvoicesTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [accumulatedList, setAccumulatedList] = useState([])
    useEffect(() => {
        dispatch(customerGetInvoices())


    }, [modals.showCustomerAddOrderModal, modals.showCustomerProcessOrderModal])



    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >

                <div className='flex justify-end space-x-3'>

                    <button
                        type="button"
                        className="w-[150px]  mb-2 mt-2 inline-block px-6 py-2 bg-sky-500 text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                        onClick={() => {
                            dispatch(setAccumulatedPharmacyInvoice({ data: accumulatedList }))
                            setModals({ ...modals, showCollectionsPayPharmacyMultipleInvoiceModal: !modals.showCollectionsPayPharmacyMultipleInvoiceModal })
                        }}
                    >
                        Pay accumulation
                    </button>
                    <button
                        type="button"
                        className="w-[150px]  mb-2 mt-2 inline-block px-6 py-2 bg-yellow-500 text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                        onClick={() => {
                            dispatch(setCurrentCustomerInvoice({ data: accumulatedList }))
                            setModals({ ...modals, showCustomerProcessOrderModal: !modals.showCustomerProcessOrderModal })
                        }}
                    >
                        Process accumulation
                    </button>



                </div>
                <div className="">
                    <Fragment>
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>

                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900  py-4 text-left"
                                    >
                                        Invoice ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900  py-4 text-left"
                                    >
                                        Drug
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900  py-4 text-left"
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900  py-4 text-left"
                                    >
                                        Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900  py-4 text-left"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900  py-4 text-left"
                                    >
                                    </th>
                                    {/* 
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                    </th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((invoice, index) => (
                                    <tr key={`${invoice.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>

                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {invoice.invoice_number}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {invoice?.item}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {invoice?.dispense_count}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            &#8358; {invoice.invoice_amount}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {invoice.created_at.slice(0, 10)}
                                        </td>

                                        <td className="flex items-center space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">

                                            {!invoice.is_paid && <Fragment>
                                                <input type="checkbox" name="" id=""
                                                    onChange={(e) => {
                                                        const myIndex = accumulatedList.findIndex(e => e.id === invoice.id)
                                                        console.log(e.target.value)
                                                        if (myIndex >= 0) {

                                                            setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== invoice.pkid)])

                                                            console.log(accumulatedList);


                                                        } else {
                                                            setAccumulatedList([...accumulatedList, invoice])


                                                        }



                                                    }}




                                                />
                                                <button
                                                    type="button"
                                                    className="w-[80px] inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentCustomerInvoice({ data: invoice }))
                                                        setModals({ ...modals, showCustomerPaymentMethodModal: !modals.showCustomerPaymentMethodModal })
                                                    }}
                                                >
                                                    Pay
                                                </button>
                                            </Fragment>}

                                            {invoice.is_paid && !invoice.is_order_generated && (
                                                <>
                                                    <input type="checkbox" name="" id=""
                                                        onChange={(e) => {
                                                            const myIndex = accumulatedList.findIndex(e => e.id === invoice.id)
                                                            console.log(e.target.value)
                                                            if (myIndex >= 0) {

                                                                setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== invoice.pkid)])

                                                                console.log(accumulatedList);


                                                            } else {
                                                                setAccumulatedList([...accumulatedList, invoice])


                                                            }



                                                        }}




                                                    />

                                                    <button
                                                        type="button"
                                                        className="inline-block px-6 py-2 bg-yellow-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                        onClick={() => {
                                                            dispatch(setCurrentCustomerInvoice({ data: invoice }))
                                                            setModals({ ...modals, showCustomerProcessOrderModal: !modals.showCustomerProcessOrderModal })
                                                        }}
                                                    >
                                                        Process
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                        {/* {!invoice.is_paid && <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            <input type="checkbox" name="" id="" />
                                        </td>} */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default CustomerInvoicesTable