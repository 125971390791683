/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { AiOutlineCloudUpload } from "react-icons/ai"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { createProvision, updateInventoryProvision, updateProvision } from "../../../state/actions/pharmacist.actions"


const PharmacistUpdateProvisionModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { provision } = useSelector(state => state.pharmacist)

    const imageRef = useRef(null)
    const [checked, setChecked] = useState(false)
    const [formData, setFormData] = useState({
        item_id: '',
        title: '',
        price: '',
        description: '',
        image: '',
        image_preview: '',
        is_counter_item: false,
    })

    useEffect(() => {
        setChecked(provision?.is_counter_item ? provision?.is_counter_item : checked)
        setFormData({
            ...formData,
            item_id: provision?.pkid,
            title: provision?.title,
            price: provision?.price,
            description: provision?.description,
            is_counter_item: provision?.is_counter_item,

        })
    }, [provision])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }
    const handleImageChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0], image_preview: URL.createObjectURL(e.target.files[0]) })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(updateInventoryProvision({ formData, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[600px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-600 text-[16px] font-bold capitalize`}>
                            Update Provision
                        </h4>
                        <FaTimesCircle
                            size={20}
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showPharmacistUpdateProvisionModal: !modals.showPharmacistUpdateProvisionModal })
                            }}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <div>
                                <input
                                    type="text"
                                    name="title"
                                    placeholder="Title | Name"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    value={formData?.title}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            {/* <div>
                                <input
                                    type="number"
                                    min={'5'}
                                    name="price"
                                    placeholder="Price"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    value={formData?.price}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                    />
                            </div> */}


                            <div className="relative">
                                <textarea
                                    name="description"
                                    placeholder='Description'
                                    className="border rounded w-full px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                    value={formData?.description}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                ></textarea>
                            </div>

                            <div className="flex items-center space-x-10 mb-4">
                                <div className="flex items-center space-x-3">
                                    <input
                                        className="rounded border-4 w-5 h-5 border-red-400"
                                        type="checkbox"
                                        name="is_counter_item"
                                        checked={formData.is_available}
                                        onChange={() => {
                                            setChecked(!checked);
                                            setFormData({ ...formData, is_counter_item: !checked });
                                        }}
                                    />
                                    <p className="text-[12px] text-gray-700 font-bold">Counter Item</p>
                                </div>
                            </div>

                            {/* <input
                                type="file"
                                name="image"
                                ref={imageRef}
                                accept="image/jpg, image/png, image/jpeg"
                                className="border hidden rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={handleImageChange}
                            />
                            <div className="w-full flex justify-between h-[200px]">
                                <div
                                    className="border border-slate-400 flex items-center justify-center py-4 w-[100px] rounded h-[100px]  cursor-pointer hover:-translate-y-1 ease-in-out duration-100"
                                    onClick={() => imageRef.current.click()}
                                >
                                    <AiOutlineCloudUpload
                                        size={40}
                                        className='text-sky-500'
                                    />
                                </div>

                                {formData.image && (
                                    <img
                                        src={formData.image_preview}
                                        alt=""
                                        className="h-full w-[200px]"
                                    />
                                )}
                            </div> */}


                        </div>

                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PharmacistUpdateProvisionModal
