/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from '../../../state/context'
import { setEmployee } from "../../../state/actions/actions"
import { updatePharmacyStaff } from "../../../state/actions/pharmacist.actions"


const PharmacistUpdateStaffModal = (props) => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()

    const { employee_update } = useSelector((state) => state.root)

    const [checkActive, setCheckActive] = useState(false)
    const [checkIsSales, setCheckIsSales] = useState(false)
    const [checkIsCourier, setCheckIsCourier] = useState(false)
    const [checkIsCollections, setCheckIsCollections] = useState(false)
    const [checkIsPharmacist, setCheckIsPharmacist] = useState(false)
    const [checkIsDepartmentHead, setCheckIsDepartmentHead] = useState(false)


    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        is_active: false,
        is_pharmacist: false,
        is_sales: false,
        is_courier: false,
        is_collections: false,
        is_department_head: false,
    })

    useEffect(() => {
        setFormData({
            ...formData,
            first_name: employee_update?.first_name,
            last_name: employee_update?.last_name,
            email: employee_update?.email,
            is_active: employee_update?.is_active ? employee_update?.is_active : checkActive,
            is_pharmacist: employee_update?.is_pharmacist ? employee_update?.is_pharmacist : checkIsPharmacist,
            is_sales: employee_update?.is_sales ? employee_update?.is_sales : checkIsSales,
            is_courier: employee_update?.is_courier ? employee_update?.is_courier : checkIsCourier,
            is_collections: employee_update?.is_collections ? employee_update?.is_collections : checkIsCollections,
            is_department_head: employee_update?.is_department_head ? employee_update?.is_department_head : checkIsDepartmentHead,
        })
    }, [employee_update])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }


    const handleUpdate = (e) => {
        e.preventDefault()

        dispatch(updatePharmacyStaff({ formData: { ...formData, pkid: employee_update?.pkid }, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white rounded px-[30px] py-[30px] w-[500px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-sky-600 text-[20px] font-bold">
                            Update Staff
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(setEmployee({ data: null }))
                                modals.showPharmacistUpdateStaffModal && setModals({ ...modals, showPharmacistUpdateStaffModal: !modals.showPharmacistUpdateStaffModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleUpdate} className="space-y-3">
                        <div className="flex justify-between-items-center space-x-10">
                            <div className="space-y-2 w-full">
                                <div>
                                    <input
                                        type="text"
                                        name="first_name"
                                        placeholder="First name"
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.first_name}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        name="last_name"
                                        placeholder="Last name"
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.last_name}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email address"
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.email}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="grid grid-cols-4 justify-between items-center pt-6">
                                    <div className="flex items-center space-x-3 w-[100px]">
                                        <input
                                            className="rounded border-4 w-5 h-5 border-red-400"
                                            type="checkbox"
                                            name="is_active"
                                            checked={formData.is_active}
                                            onChange={() => {
                                                setCheckActive(!checkActive)
                                                setFormData({ ...formData, is_active: !checkActive })
                                            }}
                                        />
                                        <p className="text-[12px] text-gray-700 font-bold">Status</p>
                                    </div>
                                  
                                    <div className="flex items-center space-x-3 w-[100px]">
                                        <input
                                            className="rounded border-4 w-5 h-5 border-red-400"
                                            type="checkbox"
                                            name="is_active"
                                            checked={formData.is_department_head}
                                            onChange={() => {
                                                setCheckIsDepartmentHead(!checkIsDepartmentHead)
                                                setFormData({ ...formData, is_department_head: !checkIsDepartmentHead })
                                            }}
                                        />
                                        <p className="text-[12px] text-gray-700 font-bold">Admin</p>
                                    </div>

                                    <div className="flex items-center space-x-2 w-[100px]">
                                        <input
                                            className="rounded border-4 w-5 h-5 border-red-400"
                                            type="checkbox"
                                            name="is_active"
                                            checked={formData.is_pharmacist}
                                            onChange={() => {
                                                setCheckIsPharmacist(!checkIsPharmacist)
                                                setFormData({ ...formData, is_pharmacist: !checkIsPharmacist })
                                            }}
                                        />
                                        <p className="text-[12px] text-gray-700 font-bold">Pharmacist</p>
                                    </div>

                                    <div className="flex items-center space-x-3 w-[100px]">
                                        <input
                                            className="rounded border-4 w-5 h-5 border-red-400"
                                            type="checkbox"
                                            name="is_active"
                                            checked={formData.is_collections}
                                            onChange={() => {
                                                setCheckIsCollections(!checkIsCollections)
                                                setFormData({ ...formData, is_collections: !checkIsCollections })
                                            }}
                                        />
                                        <p className="text-[12px] text-gray-700 font-bold">Collections</p>
                                    </div>


                                    <div className="flex items-center space-x-3 w-[100px] mt-5">
                                        <input
                                            className="rounded border-4 w-5 h-5 border-red-400"
                                            type="checkbox"
                                            name="is_active"
                                            checked={formData.is_sales}
                                            onChange={() => {
                                                setCheckIsSales(!checkIsSales)
                                                setFormData({ ...formData, is_sales: !checkIsSales })
                                            }}
                                        />
                                        <p className="text-[12px] text-gray-700 font-bold">Sales</p>
                                    </div>


                                    <div className="flex items-center space-x-3 w-[100px] mt-5">
                                        <input
                                            className="rounded border-4 w-5 h-5 border-red-400"
                                            type="checkbox"
                                            name="is_active"
                                            checked={formData.is_courier}
                                            onChange={() => {
                                                setCheckIsCourier(!checkIsCourier)
                                                setFormData({ ...formData, is_courier: !checkIsCourier })
                                            }}
                                        />
                                        <p className="text-[12px] text-gray-700 font-bold">Courier</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 flex justify-start">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PharmacistUpdateStaffModal
