import { createAsyncThunk } from '@reduxjs/toolkit'

import { checkFlutterWavePaymentStatus, checkPayStackPaymentStatus, fetchInvestigationInvoices, fetchLaboratoryInvoices, fetchInventoryDailyReports, fetchPharmacyInvoices, fetchRadiologyInvoices, fetchRecordsInvoices, generateFlutterWaveConfig, generatePayStackConfig, patchCustomerPharmacyInvoice, patchInvestigationInvoice, patchLaboratoryInvoice, patchPharmacyInvoice, patchProvisionInvoice, patchRadiologyInvoice, patchRecordsInvoice, fetchInventoryMonthlyReports, fetchInventoryYearlyReports, fetchInventoryRangeReports, fetchInventoryAuthorReports, fetchInvestigationDailyReports, fetchInvestigationMonthlyReports, fetchInvestigationYearlyReports, fetchInvestigationAuthorReports, fetchInvestigationRangeReports, patchMultipleinventoryInvoices, patchMultipleInvestigationInvoices, fetchInventoryItemReports, fetchPaidInventoryReports, fetchUnpaidInventoryReports, fetchPaidInvestigationReports, fetchUnpaidInvestigationReports, generateAdminPayStackConfig, checkAdminPayStackPaymentStatus } from '../routes/collection.routes'


export const getLaboratoryInvoices = createAsyncThunk(
    'collection/getLaboratoryInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyInvoices = createAsyncThunk(
    'collection/getPharmacyInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyInvoices = createAsyncThunk(
    'collection/getRadiologyInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsInvoices = createAsyncThunk(
    'collection/getRecordsInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentLabInvoice = createAsyncThunk(
    'collection/setCurrentLabInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createPayStackConfig = createAsyncThunk(
    'collection/createPayStackConfig',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePayStackConfig(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createAdminPayStackConfig = createAsyncThunk(
    'collection/createAdminPayStackConfig',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateAdminPayStackConfig(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createFlutterWaveConfig = createAsyncThunk(
    'collection/createFlutterWaveConfig',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateFlutterWaveConfig(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const verifyPayStackPayment = createAsyncThunk(
    'collection/verifyPayStackPayment',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await checkPayStackPaymentStatus(params, token)

            return data?.response?.data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const verifyAdminPayStackPayment = createAsyncThunk(
    'collection/verifyAdminPayStackPayment',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await checkAdminPayStackPaymentStatus(params, token)

            return data?.response?.data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const verifyFlutterWavePayment = createAsyncThunk(
    'collection/verifyFlutterWavePayment',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await checkFlutterWavePaymentStatus(params, token)

            return data.data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateLabInvoice = createAsyncThunk(
    'collection/updateLabInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateLabInvoiceViaPOSTransaction = createAsyncThunk(
    'collection/updateLabInvoiceViaPOSTransaction',
    async ({ formData, toast, dispatch, setCurrentLabInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentLabInvoice({ data: null }))
            setModals({ ...modals, showCollectionsLabPaymentModal: !modals.showCollectionsLabPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateLabInvoiceViaCash = createAsyncThunk(
    'collection/updateLabInvoiceViaCash',
    async ({ formData, toast, dispatch, setCurrentLabInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvoice(formData, token)

            toast.success('Cash transaction processed successfully.')
            dispatch(setCurrentLabInvoice({ data: null }))
            setModals({ ...modals, showCollectionsLabPaymentModal: !modals.showCollectionsLabPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentPharmacyInvoice = createAsyncThunk(
    'collection/setCurrentPharmacyInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateProvisionInvoice = createAsyncThunk(
    'collection/updateProvisionInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchProvisionInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updatePharmacyInvoice = createAsyncThunk(
    'collection/updatePharmacyInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacyInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updatePharmacyInvoiceViaPOSTransaction = createAsyncThunk(
    'collection/updatePharmacyInvoiceViaPOSTransaction',
    async ({ formData, toast, dispatch, setCurrentPharmacyInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacyInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentPharmacyInvoice({ data: null }))
            setModals({ ...modals, showCollectionsPharmacyPaymentModal: !modals.showCollectionsPharmacyPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updatePharmacyInvoiceViaCash = createAsyncThunk(
    'collection/updatePharmacyInvoiceViaCash',
    async ({ formData, toast, dispatch, setCurrentPharmacyInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacyInvoice(formData, token)

            toast.success('Cash transaction processed successfully.')
            dispatch(setCurrentPharmacyInvoice({ data: null }))
            setModals({ ...modals, showCollectionsPharmacyPaymentModal: !modals.showCollectionsPharmacyPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentRadiologyInvoice = createAsyncThunk(
    'collection/setCurrentRadiologyInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateRadiologyInvoice = createAsyncThunk(
    'collection/updateRadiologyInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateRadiologyInvoiceViaPOSTransaction = createAsyncThunk(
    'collection/updateRadiologyInvoiceViaPOSTransaction',
    async ({ formData, toast, dispatch, setCurrentRadiologyInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentRadiologyInvoice({ data: null }))
            setModals({ ...modals, showCollectionRadiologyPaymentModal: !modals.showCollectionRadiologyPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateRadiologyInvoiceViaCash = createAsyncThunk(
    'collection/updateRadiologyInvoiceViaCash',
    async ({ formData, toast, dispatch, setCurrentRadiologyInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvoice(formData, token)

            toast.success('Cash transaction processed successfully.')
            dispatch(setCurrentRadiologyInvoice({ data: null }))
            setModals({ ...modals, showCollectionRadiologyPaymentModal: !modals.showCollectionRadiologyPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentRecordsInvoice = createAsyncThunk(
    'collection/setCurrentRecordsInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateRecordsInvoice = createAsyncThunk(
    'collection/updateRecordsInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRecordsInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateRecordsInvoiceViaPOSTransaction = createAsyncThunk(
    'collection/updateRecordsInvoiceViaPOSTransaction',
    async ({ formData, toast, dispatch, setCurrentRecordsInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRecordsInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentRecordsInvoice({ data: null }))
            setModals({ ...modals, showCollectionRecordsPaymentModal: !modals.showCollectionRecordsPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateRecordsInvoiceViaCash = createAsyncThunk(
    'collection/updateRecordsInvoiceViaCash',
    async ({ formData, toast, dispatch, setCurrentRecordsInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRecordsInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentRecordsInvoice({ data: null }))
            setModals({ ...modals, showCollectionRecordsPaymentModal: !modals.showCollectionRecordsPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)



export const getInvestigationInvoices = createAsyncThunk(
    'collection/getInvestigationInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInvestigationInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const updateInvestigationInvoice = createAsyncThunk(
    'collection/updateInvestigationInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchInvestigationInvoice(formData, token)

            // setModals({...modals, showCollectionInvestigationPaymentModal: !modals.showCollectionInvestigationPaymentModal})
            // toast.success('Invoice updated successfully')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateInvestigationViaPOS = createAsyncThunk(
    'collection/updateInvestigationViaPOS',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchInvestigationInvoice(formData, token)

            toast.success('Invoice updated successfully')
            setModals({ ...modals, showCollectionsInvestigationsPaymentModal: !modals.showCollectionsInvestigationsPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const getInventoryDailyReports = createAsyncThunk(
    'accountant/getInventoryDailyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventoryDailyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInventoryMonthlyReports = createAsyncThunk(
    'accountant/getInventoryMonthlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventoryMonthlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInventoryYearlyReports = createAsyncThunk(
    'accountant/getInventoryYearlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventoryYearlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInventoryRangeReports = createAsyncThunk(
    'accountant/getInventoryRangeReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventoryRangeReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInventoryAuthorReports = createAsyncThunk(
    'accountant/getInventoryAuthorReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventoryAuthorReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInventoryItemReports = createAsyncThunk(
    'accountant/getInventoryItemReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventoryItemReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getPaidInventoryReports = createAsyncThunk(
    'accountant/getPaidInventoryReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPaidInventoryReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getUnpaidInventoryReports = createAsyncThunk(
    'accountant/getUnpaidInventoryReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchUnpaidInventoryReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInvestigationDailyReports = createAsyncThunk(
    'accountant/getInvestigationDailyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInvestigationDailyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInvestigationMonthlyReports = createAsyncThunk(
    'accountant/getInvestigationMonthlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInvestigationMonthlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInvestigationYearlyReports = createAsyncThunk(
    'accountant/getInvestigationYearlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInvestigationYearlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInvestigationRangeReports = createAsyncThunk(
    'accountant/getInvestigationRangeReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInvestigationRangeReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getInvestigationAuthorReports = createAsyncThunk(
    'accountant/getInvestigationAuthorReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInvestigationAuthorReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getPaidInvestigationReports = createAsyncThunk(
    'accountant/getPaidInvestigationReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPaidInvestigationReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getUnpaidInvestigationReports = createAsyncThunk(
    'accountant/getUnpaidInvestigationReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchUnpaidInvestigationReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const setAccumulatedPharmacyInvoice = createAsyncThunk(
    'collection/setAccumulatedPharmacyInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)




export const updateMultipleInventoryInvoices = createAsyncThunk(
    'collection/updateMultipleInventoryInvoices',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleinventoryInvoices(formData, token)

            toast.success('Invoices updated successfully')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoices')
            return rejectWithValue(null)
        }
    }
)



export const updateMultipleInventoryInvoicesViaPOS = createAsyncThunk(
    'collection/updateMultipleInventoryInvoicesViaPOS',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleinventoryInvoices(formData, token)

            toast.success('Invoices updated successfully')
            setModals({ ...modals, showCollectionsPayPharmacyMultipleInvoiceModal: !modals.showCollectionsPayPharmacyMultipleInvoiceModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoices')
            return rejectWithValue(null)
        }
    }
)




export const updateMultipleInventoryInvoicesViaCash = createAsyncThunk(
    'collection/updateMultipleInventoryInvoicesViaCash',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleinventoryInvoices(formData, token)

            toast.success('Invoices updated successfully')
            setModals({ ...modals, showCollectionsPayPharmacyMultipleInvoiceModal: !modals.showCollectionsPayPharmacyMultipleInvoiceModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoices')
            return rejectWithValue(null)
        }
    }
)





export const updateMultipleInvestigationInvoices = createAsyncThunk(
    'collection/updateMultipleInvestigationInvoices',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleInvestigationInvoices(formData, token)

            toast.success('Invoices updated successfully')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoices')
            return rejectWithValue(null)
        }
    }
)



export const updateMultipleInvestigationInvoicesViaPOS = createAsyncThunk(
    'collection/updateMultipleInvestigationInvoicesViaPOS',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleInvestigationInvoices(formData, token)

            toast.success('Invoices updated successfully')
            setModals({ ...modals, showCollectionsPayInvestigationMultipleInvoiceModal: !modals.showCollectionsPayInvestigationMultipleInvoiceModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoices')
            return rejectWithValue(null)
        }
    }
)




export const updateMultipleInvestigationInvoicesViaCash = createAsyncThunk(
    'collection/updateMultipleInvestigationInvoicesViaCash',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleInvestigationInvoices(formData, token)

            toast.success('Invoices updated successfully')
            setModals({ ...modals, showCollectionsPayInvestigationMultipleInvoiceModal: !modals.showCollectionsPayInvestigationMultipleInvoiceModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoices')
            return rejectWithValue(null)
        }
    }
)

