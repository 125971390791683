/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import { OrderStatus } from '../utils/PaymentStatus'
import { useStateContext } from '../../state/context'
import { setCurrentOrder } from '../../state/actions/pharmacist.actions'


const PharmacistOrdersTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [filterState, setFilterState] = useState({ search: '', orders: [] })

    const handleFilter = () => {
        const filtered = data?.filter(item => {
            return item.order_id.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, orders: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, orders: data })
        }
    }

    useEffect(() => {
        handleFilter()
    }, [filterState.search])


    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <input
                    type="text"
                    name="search"
                    // disabled
                    placeholder='Search'
                    className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                    onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                />
                <div className="">
                    <Fragment>
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Order ID
                                    </th>
                                    {/* <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Item
                                    </th> */}
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Amount Paid
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((order, index) => (
                                    <tr key={`${order.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {order.order_id}
                                        </td>
                                        {/* <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {order?.invoice_details?.item}
                                        </td> */}
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            &#8358; {order?.total}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {order.created_at.slice(0, 10)}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            <OrderStatus status={order?.status} />
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2 border border-teal-500 text-teal-500 font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentOrder({ data: order }))
                                                    setModals({ ...modals, showPharmacistViewOrderModal: !modals.showPharmacistViewOrderModal })
                                                }}
                                            >
                                                View
                                            </button>
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {order.status === 'IN_STORE' && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-6 py-2 bg-yellow-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentOrder({ data: order }))
                                                        setModals({ ...modals, showPharmacistProcessOrderModal: !modals.showPharmacistProcessOrderModal })
                                                    }}
                                                >
                                                    Process
                                                </button>
                                            )}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={filterState.orders}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default PharmacistOrdersTable
