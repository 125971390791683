import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    getEmployees, getLabEquipment, addLaboratoryEquipment, updateLaboratoryEquipment,
    deleteLaboratoryEquipment, getLaboratoryReport, getALabBillingDetails, generateLabInvoice,
    getAllLabBilling, uploadPatientLaboratoryReport, getInvoiceGeneratedByUser, getLaboratoryInvestigationCategory,
    createLaboratoryCategoryTest, updateLaboratoryCategoryTest, deleteLaboratoryCategoryTest, getLaboratoryInvestigationTypes,
    addLaboratoryInvestigationType, updateLaboratoryInvestigationType, deleteLaboratoryInvestigationType, addLaboratoryInvestigationSamples,
    getLabSampleTest, updateLabSampleTest, deleteLaboratorySampleTest
} from '../routes/laboratorist.routes'


export const getAllEmployeesForLaboratorist = createAsyncThunk(
    'laboratorist/getAllEmployeesForLaboratorist',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getEmployees(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)
export const getAllLabEquipments = createAsyncThunk(
    'laboratorist/getAllLabEquipment',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getLabEquipment(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)


export const addALaboratoryEquipment = createAsyncThunk(
    'laboratorist/addLaboratoryEquipment',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await addLaboratoryEquipment(formData, token)
            toast.success("Equipment added successfully")
            setModals({ ...modals, showAddLaboratoryEquipmentModal: !modals.showAddLaboratoryEquipmentModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while adding equipment")
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateALaboratoryEquipment = createAsyncThunk(
    'laboratorist/updateLaboratoryEquipment',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateLaboratoryEquipment(formData, token)
            toast.success("Update successful")
            setModals({ ...modals, showUpdateLaboratoryEquipmentModal: !modals.showUpdateLaboratoryEquipmentModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while updating equipment")
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteALaboratoryEquipment = createAsyncThunk(
    'laboratorist/deleteLaboratoryEquipment',
    async ({ equipment_id, type, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deleteLaboratoryEquipment(equipment_id, token)
            toast.success(`${type} deleted successfully `)
            setModals({ ...modals, showDeleteLaboratoryEquipmentModal: !modals.showDeleteLaboratoryEquipmentModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while deleting equipment")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllLabReports = createAsyncThunk(
    'laboratorist/getAllLabReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getLaboratoryReport(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllLabBillings = createAsyncThunk(
    'laboratorist/getAllLabBilling',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getAllLabBilling(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getALabBilling = createAsyncThunk(
    'laboratorist/getALabBilling',
    async ({ billing_id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getALabBillingDetails(billing_id, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const removeSingleLabBilling = createAsyncThunk(
    'laboratorist/removeSingleLabBilling',
    async ({ data }, { rejectWithValue }) => {
        try {
            // console.log(data);
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const generatePatientLabInvoice = createAsyncThunk(
    'laboratorist/generateLabPatientInvoice',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateLabInvoice(formData, token)
            toast.success("Invoice generated successfully")
            setModals({ ...modals, showLaboratoryGenerateInvoiceModal: !modals.showLaboratoryGenerateInvoiceModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while generating invoice")
            return rejectWithValue(error.response.data)
        }
    }
)

export const uploadAPatientLaboratoryReport = createAsyncThunk(
    'laboratorist/uploadPatientLaboratoryReport',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await uploadPatientLaboratoryReport(formData, token)
            toast.success("Upload successful")
            setModals({ ...modals, showLaboratoryUploadReportModal: !modals.showLaboratoryUploadReportModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while uploading laboratory report")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllInvoiceGeneratedByUser = createAsyncThunk(
    'laboratorist/getInvoiceGeneratedByUser',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getInvoiceGeneratedByUser(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllLaboratoryInvestigationCategory = createAsyncThunk(
    'laboratorist/getLaboratoryInvestigationCategory',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getLaboratoryInvestigationCategory(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)


export const createALaboratoryCategoryTest = createAsyncThunk(
    'laboratorist/createALaboratoryCategoryTest',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await createLaboratoryCategoryTest(formData, token)
            toast.success("Laboratory category created successfully")
            setModals({ ...modals, showLaboratoryAddCategoryModal: !modals.showLaboratoryAddCategoryModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while creating category")
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateALaboratoryCategoryTest = createAsyncThunk(
    'laboratorist/updateLaboratoryCategoryTest',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateLaboratoryCategoryTest(formData, token)
            toast.success("Laboratory category updated successfully")
            setModals({ ...modals, showLaboratoryUpdateCategoryModal: !modals.showLaboratoryUpdateCategoryModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while updating category")
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteALaboratoryCategoryTest = createAsyncThunk(
    'radiologist/deleteLaboratoryCategoryTest',
    async ({ cat_id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deleteLaboratoryCategoryTest(cat_id, token)
            toast.success("Category deleted successfully")

            setModals({ ...modals, showLaboratoryDeleteCategoryModal: !modals.showLaboratoryDeleteCategoryModal })


            return data
        } catch (error) {
            toast.warning("Error occured while deleting category")
            console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllLaboratoryInvestigationTypes = createAsyncThunk(
    'laboratorist/getLaboratoryInvestigationTypes',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getLaboratoryInvestigationTypes(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const addALaboratoryInvestigationType = createAsyncThunk(
    'laboratorist/addLaboratoryInvestigationType',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await addLaboratoryInvestigationType(formData, token)
            toast.success("Laboratory test type added successfully")
            setModals({ ...modals, showLaboratoryAddInvestigationType: !modals.showLaboratoryAddInvestigationType })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while adding test type")
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateALaboratoryInvestigationType = createAsyncThunk(
    'laboratorist/updateLaboratoryInvestigationType',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateLaboratoryInvestigationType(formData, token)
            toast.success("Laboratory test type updated successfully")
            setModals({ ...modals, showLaboratoryUpdateInvestigationType: !modals.showLaboratoryUpdateInvestigationType })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while updating test type")
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteALaboratoryInvestigationType = createAsyncThunk(
    'laboratorist/deleteLaboratoryInvestigationType',
    async ({ investigation_id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deleteLaboratoryInvestigationType(investigation_id, token)
            toast.success("Laboratory test type deleted successfully")
            setModals({ ...modals, showDeleteLaboratoryInvestigationTypeModal: !modals.showDeleteLaboratoryInvestigationTypeModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while deleting test type")
            return rejectWithValue(error.response.data)
        }
    }
)

export const addALaboratoryInvestigationSamples = createAsyncThunk(
    'laboratorist/addLaboratoryInvestigationSamples',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await addLaboratoryInvestigationSamples(formData, token)
            toast.success("Laboratory sample test added successfully")
            setModals({ ...modals, showAddLaboratorySamplesModal: !modals.showAddLaboratorySamplesModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while adding saple test")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllLabSampleTest = createAsyncThunk(
    'laboratorist/getLabSampleTest',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getLabSampleTest(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateALabSampleTest = createAsyncThunk(
    'laboratorist/updateLabSampleTest',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateLabSampleTest(formData, token)
            toast.success("Laboratory sample test updated successfully")
            setModals({ ...modals, showUpdateLaboratorySamplesModal: !modals.showUpdateLaboratorySamplesModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while updating sample test")
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteALaboratorySampleTest = createAsyncThunk(
    'laboratorist/deleteLaboratorySampleTest',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deleteLaboratorySampleTest(id, token)
            toast.success("Laboratory sample test deleted successfully")
            setModals({ ...modals, showDeleteLaboratorySampleModal: !modals.showDeleteLaboratorySampleModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while deleting sample test")
            return rejectWithValue(error.response.data)
        }
    }
)