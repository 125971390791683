import { configureStore } from '@reduxjs/toolkit'

import reducers from './reducers/reducers'
import AuthReducer from './reducers/auth.reducer'
import chatReducer from './reducers/chat.reducer'
import adminReducer from './reducers/admin.reducer'
import pharmacistReducer from './reducers/pharmacist.reducer'
import collectionsReducer from './reducers/collection.reducer'
import notificationsReducer from './reducers/notifications.reducer'
import roomReducer from './reducers/roomReducer'
import doctorReducer from './reducers/doctor.reducer'
import laboratoristReducer from './reducers/laboratorist.reducer'
import radiologistReducer from './reducers/radiologist.reducer'
import recordReducer from './reducers/records.reducer'



export default configureStore({
    reducer: {
        admin: adminReducer,
        auth: AuthReducer,
        chat: chatReducer,
        collection: collectionsReducer,
        root: reducers,
        pharmacist: pharmacistReducer,
        notification: notificationsReducer,
        radiologist: radiologistReducer,
        laboratorist: laboratoristReducer,
        records: recordReducer,
        // nurse: nurseReducer,
        // patient: patientReducer,
        doctor: doctorReducer,
        // notification: notificationsReducer,
        room: roomReducer
    }
})