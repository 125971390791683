import axios from 'axios'
import {decode} from 'html-entities'

const instance = axios.create({
    baseURL: process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API,
})

export const ZuumPayAPI = axios.create({
    baseURL: process.env.REACT_APP_ZUUMPAY_API,
})



let USERFROMLS = localStorage.getItem('pms__user') ? JSON.parse(localStorage.getItem('pms__user')) : null

instance.interceptors.request.use(
    (req) => {
        USERFROMLS = localStorage.getItem('pms__user') ? JSON.parse(localStorage.getItem('pms__user')) : null
        if (USERFROMLS) {
            req.headers['Authorization'] = `Bearer ${USERFROMLS.access}`;
        }
        return req;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        const test = decode(JSON.stringify(res));
        return JSON.parse(test);
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            // console.log(err.response)
            if (err.response.status === 401 && err.response.statusText === 'Unauthorized') {
                console.log('TOKEN EXPIRED!!')  
                try {
                    const body = {
                        refresh: USERFROMLS.refresh,
                        access: USERFROMLS.access
                    }

                    const { data } = await axios.post(`${process.env.REACT_APP_DEV_API}/auth/jwt/refresh/`, { refresh: body.refresh })
                    console.log(data)
                    const access = data?.access ? data?.access : null;

                    if (access) {
                        localStorage.removeItem('pms__user');
                        localStorage.setItem('pms__user', JSON.stringify({ ...USERFROMLS, access: data.access }));
                        instance.defaults.headers.common[
                            'Authorization'
                        ] = `Bearer ${access}`;

                        console.log('NEW TOKEN ADDED')
                    }
                    return instance(originalConfig);
                } catch (_error) {
                    if (_error.response.status === 401) {
                        alert('Session expired. Please login again!');
                        window.history.pushState({}, '', '/');
                        localStorage.removeItem('pms__user');
                        window.location.reload();
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

export const API = instance