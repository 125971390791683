import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { Logo } from '../../../assets'
import { useStateContext } from "../../../state/context"
import { setCurrentRecordsInvoice } from "../../../state/actions/collection.actions"


const CollectionsViewRecordsInvoiceModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { records_invoice } = useSelector(state => state.collection)

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center"
        >
            <div
                className="absolute right-[4em] top-0 text-[60px] font-medium text-white cursor-pointer"
                onClick={() => {
                    dispatch(setCurrentRecordsInvoice({ data: null }))
                    setModals({ ...modals, showCollectionsViewRecordsInvoiceModal: !modals.showCollectionsViewRecordsInvoiceModal })
                }}
            >
                x
            </div>

            <div className="bg-white w-[614px] rounded-t">
                <div className="h-[5px] bg-indigo-800 rounded-t"></div>
                <div className="flex justify-between space-y-5 px-[10px]">
                    <div className="flex flex-col items-center space-y-2">
                        <img
                            src={Logo}
                            alt="logo"
                            className="w-[50px]"
                        />
                        <p className="text-[20px] text-gray-800 font-bold font-montserrat">Joyland Hospital</p>
                    </div>

                    <div>
                        <p className="text-[12px] text-gray-600 font-medium">
                            N<span className="underline underline-offset-1 text-[10px]">0</span> 33 Long River Lane, Rayfield
                        </p>
                        <p className="text-[12px] text-gray-600 font-medium">
                            info@joylandhospital.com
                        </p>
                        <p className="text-[12px] text-gray-600 font-medium">
                            joylandhospital.org
                        </p>
                    </div>
                </div>

                <div className="space-y-4 px-[10px] mt-5">
                    <div className="flex justify-start space-x-4">
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Subject:</span> {records_invoice?.billing_type}
                        </p>
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Date:</span> {records_invoice?.date_generated ? records_invoice?.date_generated : records_invoice?.date}
                        </p>
                    </div>

                    <table className="rounded w-full cursor-default">
                        <thead className="">
                            <tr className='bg-yellow-100'>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pl-2 pr-[5em] py-2 text-left"
                                >
                                    Item
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-2 text-left"
                                >
                                    Quantity
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-2 text-left"
                                >
                                    Rate
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-2 text-left"
                                >
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                    {records_invoice?.billing_type}
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                    01
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                    {records_invoice?.amount}
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                    {records_invoice?.amount}
                                </td>
                            </tr>
                            <tr>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-bold py-2 whitespace-nowrap bg-yellow-200">
                                    Subtotal: &#8358;
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-medium underline underline-offset-1 py-2 whitespace-nowrap bg-yellow-200">
                                    {records_invoice?.amount}
                                </td>
                            </tr>
                            <tr>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[14px] text-white font-bold py-2 whitespace-nowrap bg-teal-900">
                                    Total:
                                </td>
                                <td className="border-t border-b text-[14px] text-white font-bold underline underline-offset-1 py-2 whitespace-nowrap bg-teal-900">
                                    {records_invoice?.amount}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="flex flex-col place-content-end w-1/2 italic text-[10px]">
                        <p className="underline font-bold">Terms And Conditions.</p>
                        <p>All payments finalized have no return policy.</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CollectionsViewRecordsInvoiceModal
