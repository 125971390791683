import { useState } from 'react'
import { useSelector } from 'react-redux'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'

import { Logo } from '../../assets'
import { menus__collections, menus__collections__advanced, menus__collections__standard, menus__collections__starter, menus__customer, menus__pharmacist, menus__pharmacist__advanced, menus__pharmacist__departmentalhead, menus__pharmacist__departmentalhead__advanced, menus__pharmacist__departmentalhead__standard, menus__pharmacist__departmentalhead__starter, menus__pharmacist__standard, menus__pharmacist__starter, menus__sales, menus__sales__advanced, menus__sales__standard, menus__sales__starter } from '../../data'


const SideBar = ({ activeLink, setActiveLink }) => {
	const [open, setOpen] = useState(true)

	const { user, pharmacy_config } = useSelector(state => state.auth)

	return (
		<div className={`${open ? 'w-72' : 'w-20'} h-screen duration-500 bg-sidebar p-5 pt-4 sticky top-0 left-0`}>
			<BsFillArrowLeftCircleFill
				className={`${!open && 'rotate-180'} absolute cursor-pointer -right-1 top-9 shadow-sm`}
				color='white'
				size={25}
				onClick={() => setOpen(!open)}
			/>

			<div className='flex gap-x-4 items-center '>
				<img
					src={pharmacy_config ? `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}` : Logo}
					alt=""
					className={`cursor-pointer w-8 duration-500 ${open && 'rotate-[360deg]'}`}
				/>
				<h1 className={` ${!open && 'scale-0'} text-white origin-left font-medium text-[18px] duration-300`}>{pharmacy_config ? pharmacy_config?.name : 'Adawa Pharmacy'}</h1>
			</div>

			<ul className='pt-6 relative'>
				{user?.data?.subscription_plan === 'STARTER' && user?.data?.is_sales && menus__sales__starter.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-2em] 
							cursor-pointer px-4 py-5 hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}
				{user?.data?.subscription_plan === 'STANDARD' && user?.data?.is_sales && menus__sales__standard.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-2em] 
							cursor-pointer px-4 py-5 hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}
				{user?.data?.subscription_plan === 'ADVANCED' && user?.data?.is_sales && menus__sales__advanced.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-2em] 
							cursor-pointer px-4 py-5 hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}

				{user?.data?.subscription_plan === 'STARTER' && user?.data?.is_pharmacist && !user?.data?.is_department_head && menus__pharmacist__starter.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer px-4 py-3 hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}
				{user?.data?.subscription_plan === 'STANDARD' && user?.data?.is_pharmacist && !user?.data?.is_department_head && menus__pharmacist__standard.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer px-4 py-3 hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}
				{user?.data?.subscription_plan === 'ADVANCED' && user?.data?.is_pharmacist && !user?.data?.is_department_head && menus__pharmacist__advanced.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer px-4 py-3 hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}

				{user?.data?.subscription_plan === "STARTER" && user?.data?.is_pharmacist && user?.data?.is_department_head && menus__pharmacist__departmentalhead__starter.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-[18px] hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}
				{user?.data?.subscription_plan === "STANDARD" && user?.data?.is_pharmacist && user?.data?.is_department_head && menus__pharmacist__departmentalhead__standard.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-[18px] hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}
				{user?.data?.subscription_plan === "ADVANCED" && user?.data?.is_pharmacist && user?.data?.is_department_head && menus__pharmacist__departmentalhead__advanced.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-[18px] hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_customer && menus__customer.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 hover:bg-gray-500 mt-4
							${index !== 0 && "mt-10"}
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img src={menu.icon} width={`${menu.icon === 'icon__bills__dispense' ? 20 : menu.icon === 'icon__file' ? 15 : 25}`} alt="" />
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Customer Dashboard' ? 'Dashboard' : menu.title === 'Customer Invoices' ? 'Invoices' : menu.title === 'Customer Orders' ? 'Orders' : menu.title}</span>
					</li>
				))}

				{user?.data?.subscription_plan === "STARTER" && user?.data?.is_collections && menus__collections__starter.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 mb-5 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img src={menu.icon} width={`${menu.icon === 'icon__bills__dispense' ? 20 : menu.icon === 'icon__file' ? 20 : 25}`} alt="" />
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Collections Dashboard' ? 'Dashboard' : menu.title === 'Collections Invoices' ? 'Invoices' : menu.title === 'Collections Paid Invoices' ? 'Paid Invoices' : menu.title}</span>
					</li>
				))}

				{user?.data?.subscription_plan === "STANDARD" && user?.data?.is_collections && menus__collections__standard.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 mb-5 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img src={menu.icon} width={`${menu.icon === 'icon__bills__dispense' ? 20 : menu.icon === 'icon__file' ? 20 : 25}`} alt="" />
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Collections Dashboard' ? 'Dashboard' : menu.title === 'Collections Invoices' ? 'Invoices' : menu.title === 'Collections Paid Invoices' ? 'Paid Invoices' : menu.title}</span>
					</li>
				))}

				{user?.data?.subscription_plan === "ADVANCED" && user?.data?.is_collections && menus__collections__advanced.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 mb-5 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img src={menu.icon} width={`${menu.icon === 'icon__bills__dispense' ? 20 : menu.icon === 'icon__file' ? 20 : 25}`} alt="" />
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Collections Dashboard' ? 'Dashboard' : menu.title === 'Collections Invoices' ? 'Invoices' : menu.title === 'Collections Paid Invoices' ? 'Paid Invoices' : menu.title}</span>
					</li>
				))}
			</ul>
		</div>
	)
}

export default SideBar