/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../state/context"
import { drug__compositions, inventory__types } from "../../data"
import { createInventory } from "../../state/actions/pharmacist.actions"
import ButtonLoader from "../utils/ButtonLoader"


const PharmacistDrugBinCardModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { drug_update } = useSelector(state => state.root)
    const { pharmacistLoading } = useSelector((state) => state.pharmacist)
    const [formData, setFormData] = useState({
        inventory_item: '',
        carton_count: 0,
        pills_count: 0,
        bincard_drug: drug_update?.pkid,
        inventory_item_drugtype: "",
        pack_count: 0,
        card_count: 0,
        bottle_count: 0,
        container_count: 0,
        manufacturer: "",
        composition: "",
        description: "",
        cost_price: "",
        selling_price: "",
        manufactured_date: "",
        expiry_date: "",
        receipt_or_issue_voucher_number: "",
        received_from_or_issued_to: "",
        quantity_received: 0,
        balance: parseInt(drug_update?.quantity),
        drug_category: drug_update?.drug_category?.id,
        name: drug_update?.name,
        drug_substitute: drug_update?.drug_substitute,
        quantity: drug_update?.quantity,
        alert_quantity: drug_update?.alert_quantity,
    })

    useEffect(() => {
        setFormData({ ...formData, inventory_item: drug_update?.pkid })
    }, [drug_update])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setFormData({
            ...formData,
            pack_count: parseInt(formData.pack_count),
            card_count: parseInt(formData.card_count),
            bottle_count: parseInt(formData.bottle_count),
            container_count: parseInt(formData.container_count),
            cost_price: parseInt(formData.cost_price),
            selling_price: parseInt(formData.selling_price),
        })

        if (!formData.manufactured_date && !formData.cost_price
            && !formData.selling_price && !formData.expiry_date &&
            !formData.receipt_or_issue_voucher_number

        ) return toast.warning('All fields are required!')
        
    
        dispatch(createInventory({ formData, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[700px] rounded px-[30px] py-[20px] max-h-[600px] overflow-y-auto scrollbar_mini">
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className="text-sky-500  text-[20px] font-bold capitalize">
                            Bin Card
                        </h4>
                        {/* <span className="font-medium text-[8px] text-white bg-gray-500 py-[2px] px-[5px] rounded">Paracetamol</span> */}
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            size={20}
                            onClick={() => {
                                // dispatch(setBilling({ data: null }))
                                setModals({ ...modals, showPharmacistBinCardModal: !modals.showPharmacistBinCardModal })
                            }}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col w-full pb-5 border-b-2 border-b-gray-800">
                            <div className="relative w-full">
                                <select
                                    className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                                    name="inventory_item_drugtype"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option value="">Type</option>
                                    {inventory__types.map((type, index) => (
                                        <option
                                            key={`${type.title}-${index}`}
                                            value={type.value}
                                        >{type.title}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>

                            {formData.inventory_item_drugtype === 'pack' && (
                                <Fragment>
                                    <div>
                                        <label className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded">Pack Count</label>
                                        <input
                                            type="number"
                                            name="pack_count"
                                            placeholder="Pack Count"
                                            className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    </div>
                                </Fragment>
                            )}

                            {formData.inventory_item_drugtype === 'card' && (
                                <Fragment>
                                    <div>
                                        <label className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded">Card Count</label>
                                        <input
                                            type="number"
                                            name="card_count"
                                            placeholder="Card Count"
                                            className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    </div>
                                </Fragment>
                            )}

                            {formData.inventory_item_drugtype === 'bottle' && (
                                <Fragment>
                                    <div>
                                        <label className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded">Bottle Count</label>
                                        <input
                                            type="number"
                                            name="bottle_count"
                                            placeholder="Bottle Count"
                                            className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    </div>
                                </Fragment>
                            )}

                            {formData.inventory_item_drugtype === 'container' && (
                                <Fragment>
                                    <div>
                                        <label className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded">Container Count {`[Per Carton]`}</label>
                                        <input
                                            type="number"
                                            name="container_count"
                                            placeholder="Container Count"
                                            className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    </div>
                                    <div>
                                        <label className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded">Pills Count {`[Per Container]`}</label>
                                        <input
                                            type="number"
                                            name="pills_count"
                                            placeholder="Pills Count"
                                            className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </div>

                        <div className="flex justify-between items-start pt-2">
                            <div className="w-[45%] space-y-1">
                                <div className="relative w-full">
                                    <select
                                        className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                                        name="composition"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    >
                                        <option value="">Composition</option>
                                        {drug__compositions.map((composition, index) => (
                                            <option
                                                key={`${composition.title}-${index}`}
                                                value={composition.value}
                                            >{composition.title}</option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>

                                <div>
                                    <label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded">Manufacturer</label>
                                    <input
                                        type="text"
                                        name="manufacturer"
                                        placeholder="Manufacturer"
                                        className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                {/* <div>
                                    <label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded">Description</label>
                                    <input
                                        type="text"
                                        name="description"
                                        placeholder="Description"
                                        className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div> */}

                                <div>
                                    <label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded">Cost Price</label>
                                    <input
                                        type="number"
                                        name="cost_price"
                                        placeholder="Cost Price"
                                        className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded">Selling Price</label>
                                    <input
                                        type="number"
                                        name="selling_price"
                                        placeholder="Selling Price"
                                        className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                            </div>

                            <div className="w-[45%] space-y-1">
                                <div>
                                    <label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded">Manufactured Date</label>
                                    <input
                                        type="date"
                                        name="manufactured_date"
                                        placeholder="Manufactured Date"
                                        className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded">Expiry Date</label>
                                    <input
                                        type="date"
                                        name="expiry_date"
                                        placeholder="Expiry Date"
                                        className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded">Receipt/Issued Voucher Number</label>
                                    <input
                                        type="text"
                                        name="receipt_or_issue_voucher_number"
                                        placeholder="Receipt/Issued Voucher Number"
                                        className="border rounded w-full px-4 py-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                {/* <div>
                                    <label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded">Received From Or Issued To</label>
                                    <input
                                        type="text"
                                        name="received_from_or_issued_to"
                                        placeholder="Received from or issued to"
                                        className="border rounded w-full px-4 py-1 hover:outline-none placeholder:text-[14px] focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div> */}

                            </div>
                        </div>


                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                {!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PharmacistDrugBinCardModal
