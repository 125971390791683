/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineMenu } from 'react-icons/ai'
import { IoPaperPlaneOutline } from 'react-icons/io5'

import { drug__affliates } from '../../data'
import { getPharmacyConfig, getSubscriptionPlan, getSubscriptionPlans } from '../../state/actions/auth.actions'
import { ArtLanding1, ArtLanding2, ArtLanding3, ArtLanding4, User1 } from '../../assets'


const LandingPage = () => {
    const dispatch = useDispatch()

    const { subscription_plan, pharmacy_config } = useSelector(state => state.auth)

    const [config, setConfig] = useState({ showMenu: false })

    useEffect(() => {
        dispatch(getPharmacyConfig())
        dispatch(getSubscriptionPlan())
        dispatch(getSubscriptionPlans())
    }, [])

    useEffect(() => {
        document.title = pharmacy_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${pharmacy_config?.logo}`;
    }, [pharmacy_config])


    console.log(subscription_plan)

    return (
        <div className=''>
            <header className='min-h-screen bg-slate-400 px-5 py-5 lg:px-[100px] lg:py-[40px] bg-[url("/src/assets/images/art__landing__1.svg")] bg-cover'>
                {/* nav */}
                <div className="flex justify-between items-center w-full mb-[100px] relative">
                    <Link to={''} className="font-bold text-white">
                        {pharmacy_config ? pharmacy_config?.name : 'Adawa Pharmacy'}
                    </Link>

                    <AiOutlineMenu
                        size={20}
                        onClick={() => setConfig({ ...config, showMenu: !config.showMenu })}
                        className='text-white cursor-pointer md:hidden'
                    />
                    <div className="justify-between items-center w-1/2 hidden md:flex">
                        <Link to={''} className="font-bold text-white">
                            About Us
                        </Link>

                        <Link to={''} className="font-bold text-white">
                            Contact Us
                        </Link>

                        <Link to={'/login'} className="text-[14px] text-white px-4 py-2 rounded-md bg-green-500">
                            Log In
                        </Link>
                        {subscription_plan === 'STANDARD' && (
                            <Link to={'/signup'} className="text-[14px] text-white px-4 py-2 rounded-md bg-blue-500">
                                Sign Up
                            </Link>
                        )}
                        {subscription_plan === 'ADVANCED' && (
                            <Link to={'/signup'} className="text-[14px] text-white px-4 py-2 rounded-md bg-blue-500">
                                Sign Up
                            </Link>
                        )}
                    </div>

                    {config.showMenu && (
                        <div className="absolute bg-white w-full top-8 right-0 flex flex-col justify-end items-end px-6 py-10">
                            <div className="flex flex-col justify-start space-y-5">
                                <Link
                                    to={''}
                                    className="font-bold text-blue-600"
                                    onClick={() => setConfig({ ...config, showMenu: false })}
                                >
                                    About Us
                                </Link>

                                <Link
                                    to={''}
                                    className="font-bold text-blue-600"
                                    onClick={() => setConfig({ ...config, showMenu: false })}
                                >
                                    Contact Us
                                </Link>

                                <Link
                                    to={'/login'}
                                    className="flex items-center justify-center text-[14px] text-white px-4 py-1 rounded-full bg-teal-600"
                                    onClick={() => setConfig({ ...config, showMenu: false })}
                                >
                                    Log In
                                </Link>
                                {subscription_plan === 'STANDARD' && (
                                    <Link

                                        to={'/signup'}
                                        className="flex items-center justify-center text-[14px] text-white px-4 py-1 rounded-full bg-blue-600"
                                        onClick={() => setConfig({ ...config, showMenu: false })}
                                    >
                                        Sign Up
                                    </Link>
                                )}
                                {subscription_plan === 'ADVANCED' && (
                                    <Link
                                        to={'/signup'}
                                        className="flex items-center justify-center text-[14px] text-white px-4 py-1 rounded-full bg-blue-600"
                                        onClick={() => setConfig({ ...config, showMenu: false })}
                                    >
                                        Sign Up
                                    </Link>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex flex-col md:flex-row md:justify-between items-start w-full">
                    <div className="flex flex-col justify-start items-center md:w-1/2">
                        <div className="space-y-4 mb-[44px] md:mt-[80px]">
                            <h1 className=' text-[30px] text-white font-bold'>We can get your Drug Prescriptions to You</h1>
                            <p className='md:text-[16px] xl:text-[18px] text-white'>We have all the drugs your doctor prescribed for your health and what’s more, we can get it to you.</p>
                        </div>
                        <div className="grid grid-cols-2 gap-3 md:flex md:justify-between md:flex-wrap">
                            {['Lamingo', 'Bukuru', 'Kwang', 'Rayfield'].map((route, index) => (
                                <button
                                    key={index}
                                    className={`py-[10px] px-[35px] rounded-full ${index !== 0 && 'ml-0'} ${index === 0 && 'bg-white text-blue-600 hover:bg-transparent '} hover:bg-white hover:text-blue-600 border border-white text-white`}>
                                    {route}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className='mt-5 md:mt-0 md:w-1/2 flex justify-end'>
                        <img
                            src={User1}
                            alt="pharmacist"
                            className='w-[100%] md:w-[78%]'
                        />
                    </div>
                </div>
            </header>

            <div className="mt-[90px]">
                <h1 className='text-center text-[30px] text-blue-800 font-bold underline underline-offset-2'>Our Affliations</h1>

                <div className='flex flex-wrap items-center md:flex-nowrap justify-center space-x-10 my-[50px]'>
                    {drug__affliates.map((affliate, index) => (
                        <img
                            key={affliate.id}
                            src={affliate.icon}
                            alt="affliate"
                            className=''
                        />
                    ))}
                </div>

            </div>


            <div className="mt-10">
                <h1 className='text-center text-[30px] text-blue-800 font-bold underline underline-offset-2'>Key Benefits</h1>

                <div className="flex justify-between items-center flex-wrap sm:justify-center lg:flex-nowrap px-[50px] lg:px-[100px] xl:px-[200px] mt-[20px]">
                    <div className="flex flex-col justify-start space-[40px]">
                        <p className='text-blue-600 text-[24px]'>All your Medicine needs in one place</p>

                        <ul className=" mt-6 space-y-2">
                            <li className='text-[18px]'>Search and find all kind of drugs</li>
                            <li className='text-[18px]'>We have drugs for soecial case treatments</li>
                            <li className='text-[18px]'>Get notified when your drug is available</li>
                        </ul>
                    </div>

                    <img
                        src={ArtLanding1}
                        alt=""
                        className='lg:w-[80%] xl:w-full'
                    />
                </div>
            </div>

            <div className="mt-10">
                <div className="flex justify-between items-center flex-wrap sm:justify-center lg:flex-nowrap px-[50px] md:px-[100px] mt-[20px] w-full">
                    <img
                        src={ArtLanding2}
                        alt=""
                        className='md:w-[40%]'
                    />

                    <div className="flex flex-col justify-start md:ml-10">
                        <p className='text-blue-600 text-[24px]'>Get your drugs at your doorstep</p>

                        <ul className=" mt-6 space-y-2">
                            <li className='text-[18px]'>Get straigh delivery to your doorstep</li>
                            <li className='text-[18px]'>We deliver within 24hrs of request</li>
                            <li className='text-[18px]'>We guarantee speedily response</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="mt-10">
                <div className="flex justify-between items-center flex-wrap sm:justify-center lg:flex-nowrap px-[50px] md:px-[100px] mt-[20px]">
                    <div className="flex flex-col justify-start space-[40px]">
                        <p className='text-blue-600 text-[24px]'>Set up your profile and get refill easily</p>

                        <ul className="mt-6 space-y-2">
                            <li className='text-[18px]'>When you are a member your refill is easier</li>
                            <li className='text-[18px]'>With one click your medicine is on it’s way</li>
                            <li className='text-[18px]'>Select a health care specialist</li>
                        </ul>
                    </div>

                    <img src={ArtLanding3} alt="" />
                </div>
            </div>

            <div className="flex items-center flex-wrap justify-center md:justify-between md:flex-nowrap mt-[20px] w-full">
                <img
                    src={ArtLanding4}
                    alt=""
                    className='md:w-[50%]'
                />

                <div className="flex flex-col justify-start px-[30px] md:px-0 md:w-1/2 items-start space-[40px] md:pl-[100px]">
                    <h1 className='text-[30px] text-blue-800 font-bold underline underline-offset-2 mb-[50px] text-center md:text-left'>What Our Clients Say</h1>

                    <p className='text-blue-600 text-[20px]'>Set up your profile and get refill easily</p>

                    <ul className="mt-6 space-y-2">
                        <li className='text-[18px]'>When you are a member your refill is easier</li>
                        <li className='text-[18px]'>With one click your medicine is on it’s way</li>
                        <li className='text-[18px]'>Select a health care specialist</li>
                    </ul>
                </div>
            </div>

            <footer className="w-full bg-[#043CAA] text-white mt-[138px] px-[20px] md:px-[170px] py-[100px]">
                <div className="flex flex-col">
                    <div className="flex flex-col justify-between items-center md:flex-row">
                        <div className='flex flex-col space-y-4 md:w-1/2 pr-2'>
                            <h1 className='text-[24px] font-bold'>Sign up for our Newsletter</h1>
                            <p>Get to know updates in the field of medicine and know how often our stores are stocked</p>
                        </div>

                        <div className="md:w-1/2 mt-5 md:mt-0">
                            <p>You Email</p>
                            <div className="flex">
                                <input
                                    type="text"
                                    placeholder='example@mail.com'
                                    className='bg-white outline-none py-2 rounded-l-full pl-6 text-gray-600 pr-2 w-1/2 md:w-full'
                                />
                                <button
                                    className='py-2 bg-green-500 text-white px-4 rounded-r-full flex items-center space-x-2'
                                >
                                    <span className='text-[14px]'>Subscribe</span> <IoPaperPlaneOutline />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex lg:flex-row flex-wrap justify-between items-start mt-[100px]">
                        <div className="flex flex-col w-[200px] space-y-4 mt-2 md:mt-0">
                            <h2 className='font-bold text-[20px]'>D-Express</h2>
                            <p className='text-[16px]'>Your favourite online pharmacy store. We offer onsite delivery and your health is our priority</p>
                        </div>

                        <div className="flex flex-col w-[200px] space-y-4 mt-10 md:mt-0">
                            <h2 className='font-bold text-[18px]'>Quick Links</h2>
                            <Link to='#' className='text-[14px]'>Contact Us</Link>
                            <Link to='#' className='text-[14px]'>About Us</Link>
                            <Link to='#' className='text-[14px]'>Careers</Link>
                        </div>

                        <div className="flex flex-col w-[200px] space-y-4 mt-10 md:mt-0">
                            <h2 className='font-bold text-[18px]'>Services</h2>
                            <Link to='#' className='text-[14px]'>Delivery</Link>
                            <Link to='#' className='text-[14px]'>Purchase</Link>
                            <Link to='#' className='text-[14px]'>Consult Specialist</Link>
                        </div>

                        <div className="flex flex-col w-[200px] space-y-4 mt-10 md:mt-0">
                            <h2 className='font-bold text-[18px]'>Facebook</h2>
                            <Link to='#' className='text-[14px]'>Twitter</Link>
                            <Link to='#' className='text-[14px]'>Linkedin</Link>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default LandingPage  