import React, { Fragment } from 'react'

import PharmacistAddDrugCategoryModal from '../../modals/pharmacist/PharmacistAddDrugCategoryModal'
import PharmacistAddSupplyPointModal from '../../modals/pharmacist/PharmacistAddSupplyPointModal'
import PharmacistAddDrugModal from '../../modals/pharmacist/PharmacistAddDrugModal'
import PharmacistDeleteDrugCategoryModal from '../../modals/pharmacist/PharmacistDeleteDrugCategoryModal'
import PharmacistDeleteDrugModal from '../../modals/pharmacist/PharmacistDeleteDrugModal'
import PharmacistDeleteSupplyPointModal from '../../modals/pharmacist/PharmacistDeleteSupplyPoint'
import PharmacistDrugBinCardModal from '../../tables/PharmacistDrugBinCardModal'
import PharmacistDrugSupplyPointModal from '../../modals/pharmacist/PharmacistDrugSupplyPointModal'
import PharmacistDrugDispenseModal from '../../modals/pharmacist/PharmacistDrugDispenseModal'
import PharmacistBinCardSummaryModal from '../../modals/pharmacist/PharmacistBinCardSummaryModal'
import PharmacistIssueOutDrugModal from '../../modals/pharmacist/PharmacistIssueOutDrugModal'
import PharmacistLendRefundDrugModal from '../../modals/pharmacist/PharmacistLendRefundDrugModal'
import PharmacistViewPrescriptionModal from '../../modals/pharmacist/PharmacistViewPrescriptionModal'
import PharmacistViewInvoiceModal from '../../modals/pharmacist/PharmacistViewInvoiceModal'
import PharmacistAssignSupplyPointModal from '../../modals/pharmacist/PharmacistAssignSupplyPointModal'
import PharmacistSupplyPointStaffModal from '../../modals/pharmacist/PharmacistSupplyPointStaffModal'
import PharmacistSupplyPointAssignStaffModal from '../../modals/pharmacist/PharmacistSupplyPointAssignStaffModal'
import PharmacistGenerateInvoiceModal from '../../modals/pharmacist/PharmacistGenerateInvoiceModal'

import PharmacistAddPrescriptionModal from '../../modals/pharmacist/PharmacistAddPrescriptionModal'
import PharmacistAddStaffModal from '../../modals/pharmacist/PharmacistAddStaffModal'
import PharmacistUpdateStaffModal from '../../modals/pharmacist/PharmacistUpdateStaffModal'
import PharmacistDeleteStaffModal from '../../modals/pharmacist/PharmacistDeleteStaffModal'
import CreateScheduleModal from '../../modals/CreateScheduleModal'
import ListOfSchedulesByDateModal from '../../modals/ListOfScheduleByDateModal'
import EditDeleteOptionalModal from '../../modals/EditDeleteOptionalModal'
import EditAndDeleteScheduleModal from '../../modals/EditAndDeleteScheduleModal'
import DeleteScheduleModal from '../../modals/DeleteScheduleModal'
import PharmacistProcessOrderModal from '../../modals/pharmacist/PharmacistProcessOrderModal'
import PharmacistViewOrderModal from '../../modals/pharmacist/PharmacistViewOrderModal'
import PharmacistAddProvisionModal from '../../modals/pharmacist/PharmacistAddProvisionModal'
import PharmacistUpdateProvisionModal from '../../modals/pharmacist/PharmacistUpdateProvision'
import PharmacistDeleteProvisionModal from '../../modals/pharmacist/PharmacistDeleteProvisionModal'

import PharmacistCreateRoomModal from '../../modals/pharmacist/PharmacistCreateRoomModal'
import PharmacistAddBillingModal from '../../modals/pharmacist/PharmacistAddBillingModal'
import PharmacistDeleteBillingModal from '../../modals/pharmacist/PharmacistDeleteBillingModal'
import PharmacistAddInvestigationTypeModal from '../../modals/pharmacist/PharmacistAddInvestigationTypeModal'
import PharmacistAddLabInvestigationCategoryModal from '../../modals/pharmacist/PharmacistAddLabInvestigationCategoryModal'
import PharmacistAddLabInvestigationSampleModal from '../../modals/pharmacist/PharmacistAddLabInvestigationSampleModal'
import PharmacistDeleteLabInvestigationTypeModal from '../../modals/pharmacist/PharmacistDeleteLabInvestigationTypeModal'
import PharmacistDeleteLabInvestigationCategoryModal from '../../modals/pharmacist/PharmacistDeleteLabInvestigationCategoryModal'
import PharmacistDeleteLabInvestigationSampleModal from '../../modals/pharmacist/PharmacistDeleteLabInvestigationSampleModal'
import PharmacistAddRadiologyInvestigationTypeModal from '../../modals/pharmacist/PharmacistAddRadiologyInvestigationTypeModal'
import PharmacistDeleteRadInvestigationTypeModal from '../../modals/pharmacist/PharmacistDeleteRadInvestigationTypeModal'
import PharmacistAddRadiologyInvestigationCategoryModal from '../../modals/pharmacist/PharmacistAddRadiologyInvestigationCategoryModal'
import PharmacistDeleteRadInvestigationCategoryModal from '../../modals/pharmacist/PharmacistDeleteRadInvestigationCategoryModal'

import DoctorUploadPatientFilesModal from '../../modals/doctor/DoctorUploadPatientFilesModal'
import DoctorAddOtherPrescriptionModal from '../../modals/doctor/DoctorAddOtherPrescriptionModal'
import DoctorAddOtherRadiologyTestModal from '../../modals/doctor/DoctorAddOtherRadiologyTestModal'
import DoctorAddRadiologyTestModal from '../../modals/doctor/DoctorAddRadiologyTestModal'
import DoctorAddTestReportModal from '../../modals/doctor/DoctorAddTestReportModal'
import DoctorTransferPatientModal from '../../modals/doctor/DoctorTransferPatientModal'
import DoctorUploadOtherRadiologyReportModal from '../../modals/doctor/DoctorUploadOtherRadiologyReportModal'
import DoctorViewRadiologyTestModal from '../../modals/doctor/DoctorViewRadiologyTestModal'
import DoctorViewTestModal from '../../modals/doctor/DoctorViewTestModal'
import DoctorAddOtherTestModal from '../../modals/doctor/DoctorAddOtherTestModal'
import DoctorAddPrescriptionModal from '../../modals/doctor/DoctorAddPrescriptionModal'
import DoctorAddTestModal from '../../modals/doctor/DoctorAddTestModal'
import DoctorViewFileModal from '../../modals/doctor/DoctorViewFileModal'
import DoctorAddRadiologyReportModal from '../../modals/doctor/DoctorAddRadiologyReportModal'
import DoctorUploadOtherTestReportModal from '../../modals/doctor/DoctorUploadOtherTestReportModal'
import LaboratoryGenerateInvoiceModal from '../../modals/laboratorist/LaboratoryGenerateInvoiceModal'
import LaboratoryUploadReport from '../../modals/laboratorist/LaboratoryUploadReport'
import RadiologyViewInvoiceModal from '../../modals/radiology/RadiologyViewInvoiceModal'
import RadiologyGenerateInvoiceModal from '../../modals/radiology/RadiologyGenerateInvoiceModal'
import RadiologyUploadReportModal from '../../modals/radiology/RadiologyUploadReportModal'
import PharmacistAddGatewaySettingModal from '../../modals/pharmacist/PharmacistAddGatewaySettingModal'
import PharmacistDeleteGatewaySettingModal from '../../modals/pharmacist/PharmacistDeleteGatewaySettingModal'
import PharmacistUpdateSubscriptionPlanModal from '../../modals/pharmacist/PharmacistUpdateSubscriptionPlanModal'
import PharmacistMutipleDispenseModal from '../../modals/pharmacist/PharmacistMutipleDispenseModal'
import PharmacistUpdatePharmacyInfoModal from '../../modals/pharmacist/PharmacistUpdatePharmacyInfoModal'
import PharmacistRenewSubscriptionPlanModal from '../../modals/pharmacist/PharmacistRenewSubscriptionPlanModal'
import PharmacistTopUpSMSUnitsModal from '../../modals/pharmacist/PharmacistTopUpSMSUnitsModal'
import PharmacistUpdatePharmacyLogoModal from '../../modals/pharmacist/PharmacistUpdatePharmacyLogoModal'
import PharmacistUpdateStaffProfileModal from '../../modals/pharmacist/PharmacistUpdateStaffProfileModal'
const PharmacistFormModals = (props) => {
    return (
        <Fragment>
            {props.type === 'pharmacist__add__drugcategory' && (
                <PharmacistAddDrugCategoryModal />
            )}

            {props.type === 'pharmacist__update__drugcategory' && (
                <PharmacistAddDrugCategoryModal />
            )}

            {props.type === 'pharmacist__delete__drugcategory' && (
                <PharmacistDeleteDrugCategoryModal />
            )}

            {props.type === 'pharmacist__add__drug' && (
                <PharmacistAddDrugModal />
            )}

            {props.type === 'pharmacist__update__drug' && (
                <PharmacistAddDrugModal />
            )}

            {props.type === 'pharmacist__view__drug' && (
                <PharmacistAddDrugModal />
            )}

            {props.type === 'pharmacist__delete__drug' && (
                <PharmacistDeleteDrugModal />
            )}

            {props.type === 'pharmacist__add__inventory' && (
                <PharmacistAddProvisionModal />
            )}

            {props.type === 'pharmacist__update__provision' && (
                <PharmacistUpdateProvisionModal />
            )}

            {props.type === 'pharmacist__delete__provision' && (
                <PharmacistDeleteProvisionModal />
            )}

            {props.type === 'pharmacist__assign__supplypoint__to__user' && (
                <PharmacistAssignSupplyPointModal />
            )}

            {props.type === 'pharmacist__add__staff' && (
                <PharmacistAddStaffModal />
            )}

            {props.type === 'pharmacist__update__staff__profile' && (
                <PharmacistUpdateStaffProfileModal />
            )}

            {props.type === 'pharmacist__update__staff' && (
                <PharmacistUpdateStaffModal />
            )}

            {props.type === 'pharmacist__delete__staff' && (
                <PharmacistDeleteStaffModal />
            )}

            {props.type === 'pharmacist__add__supplypoint' && (
                <PharmacistAddSupplyPointModal />
            )}

            {props.type === 'pharmacist__update__supplypoint' && (
                <PharmacistAddSupplyPointModal />
            )}

            {props.type === 'pharmacist__delete__supplypoint' && (
                <PharmacistDeleteSupplyPointModal />
            )}

            {props.type === 'pharmacist__view__supplypoint__staff' && (
                <PharmacistSupplyPointStaffModal />
            )}

            {props.type === 'pharmacist__supplypoint__assignstaff' && (
                <PharmacistSupplyPointAssignStaffModal />
            )}

            {props.type === 'pharmacist__drug__bincard' && (
                <PharmacistDrugBinCardModal />
            )}

            {props.type === 'pharmacist__drug__bincard__summary' && (
                <PharmacistBinCardSummaryModal />
            )}

            {props.type === 'pharmacist__drug__supplypoint' && (
                <PharmacistDrugSupplyPointModal />
            )}

            {props.type === 'pharmacist__generate__prescription__invoice' && (
                <PharmacistGenerateInvoiceModal />
            )}

            {props.type === 'pharmacist__dispense__drug' && (
                <PharmacistDrugDispenseModal />
            )}

            {props.type === 'pharmacist__issueout__drug' && (
                <PharmacistIssueOutDrugModal />
            )}

            {props.type === 'pharmacist__lend__refund__drug' && (
                <PharmacistLendRefundDrugModal />
            )}

            {props.type === 'pharmacist__create_prescription' && (
                <PharmacistAddPrescriptionModal />
            )}

            {props.type === 'pharmacist__view__prescription' && (
                <PharmacistViewPrescriptionModal />
            )}

            {props.type === 'pharmacist__view__invoice' && (
                <PharmacistViewInvoiceModal />
            )}

            {props.type === 'pharmacist__process__order' && (
                <PharmacistProcessOrderModal />
            )}

            {props.type === 'pharmacist__view__order' && (
                <PharmacistViewOrderModal />
            )}

            {props.type === 'pharmacist__add__billing__modal' && (
                <PharmacistAddBillingModal />
            )}

            {props.type === 'pharmacist__update__billing__modal' && (
                <PharmacistAddBillingModal />
            )}

            {props.type === 'pharmacist__delete__billing__modal' && (
                <PharmacistDeleteBillingModal />
            )}

            {props.type === 'pharmacist__add__lab__investigationtype__modal' && (
                <PharmacistAddInvestigationTypeModal />
            )}

            {props.type === 'pharmacist__update__lab__investigationtype__modal' && (
                <PharmacistAddInvestigationTypeModal />
            )}

            {props.type === 'pharmacist__delete__lab__investigationtype__modal' && (
                <PharmacistDeleteLabInvestigationTypeModal />
            )}

            {props.type === 'pharmacist__add__lab__investigationcategory__modal' && (
                <PharmacistAddLabInvestigationCategoryModal />
            )}

            {props.type === 'pharmacist__update__lab__investigationcategory__modal' && (
                <PharmacistAddLabInvestigationCategoryModal />
            )}

            {props.type === 'pharmacist__delete__lab__investigationcategory__modal' && (
                <PharmacistDeleteLabInvestigationCategoryModal />
            )}

            {props.type === 'pharmacist__add__lab__investigationsample__modal' && (
                <PharmacistAddLabInvestigationSampleModal />
            )}

            {props.type === 'pharmacist__update__lab__investigationsample__modal' && (
                <PharmacistAddLabInvestigationSampleModal />
            )}

            {props.type === 'pharmacist__delete__lab__investigationsample__modal' && (
                <PharmacistDeleteLabInvestigationSampleModal />
            )}

            {props.type === 'pharmacist__add__rad__investigationtype__modal' && (
                <PharmacistAddRadiologyInvestigationTypeModal />
            )}

            {props.type === 'pharmacist__update__rad__investigationtype__modal' && (
                <PharmacistAddRadiologyInvestigationTypeModal />
            )}

            {props.type === 'pharmacist__delete__rad__investigationtype__modal' && (
                <PharmacistDeleteRadInvestigationTypeModal />
            )}

            {props.type === 'pharmacist__add__rad__investigationcategory__modal' && (
                <PharmacistAddRadiologyInvestigationCategoryModal />
            )}

            {props.type === 'pharmacist__update__rad__investigationcategory__modal' && (
                <PharmacistAddRadiologyInvestigationCategoryModal />
            )}

            {props.type === 'pharmacist__delete__rad__investigationcategory__modal' && (
                <PharmacistDeleteRadInvestigationCategoryModal />
            )}

            {props.type === 'pharmacist__create__gateway__setting' && (
                <PharmacistAddGatewaySettingModal />
            )}

            {props.type === 'pharmacist__update__gateway__setting' && (
                <PharmacistAddGatewaySettingModal />
            )}

            {props.type === 'pharmacist__delete__gateway__setting' && (
                <PharmacistDeleteGatewaySettingModal />
            )}

            {props.type === 'pharmacist__update__subscriptionplan' && (
                <PharmacistUpdateSubscriptionPlanModal />
            )}

            {props.type === 'pharmacist__renew__subscriptionplan' && (
                <PharmacistRenewSubscriptionPlanModal />
            )}

            {props.type === 'pharmacist__multiple__dispense' && (
                <PharmacistMutipleDispenseModal />
            )}

            {props.type === 'pharmacist__topup__sms__units' && (
                <PharmacistTopUpSMSUnitsModal />
            )}


            {props.type === 'pharmacist__update__pharmacyinfo' && (
                <PharmacistUpdatePharmacyInfoModal />
            )}

            {props.type === 'pharmacist__update__pharmacylogo' && (
                <PharmacistUpdatePharmacyLogoModal />
            )}

            {props.type === 'create_scheduleModal' && (
                <CreateScheduleModal />
            )}

            {props.type === 'list_of_Schedules_by_date' && (
                <ListOfSchedulesByDateModal />
            )}

            {props.type === 'delete_schedule_modal' && (
                <DeleteScheduleModal />
            )}
            {props.type === 'edit_delete_optional_modal' && (
                <EditDeleteOptionalModal />
            )}

            {props.type === 'view_schedule_modal' && (
                <EditAndDeleteScheduleModal />
            )}
            {props.type === 'edit_delete_scheduleModal' && (
                <EditAndDeleteScheduleModal />
            )}

            {props.type === 'create_room_modal' && (
                <PharmacistCreateRoomModal />
            )}
            {/* Pharmacist diagnosis */}

            {props.type === 'doctor__upload__patientfile' && (
                <DoctorUploadPatientFilesModal />
            )}

            {props.type === 'doctor__view__patientfile' && (
                <DoctorViewFileModal />
            )}

            {props.type === 'doctor__transfer__patient' && (
                <DoctorTransferPatientModal />
            )}

            {props.type === 'doctor__add__prescription' && (
                <DoctorAddPrescriptionModal />
            )}

            {props.type === 'doctor__add__otherprescription' && (
                <DoctorAddOtherPrescriptionModal />
            )}

            {props.type === 'doctor__add__test' && (
                <DoctorAddTestModal />
            )}

            {props.type === 'doctor__add__othertest' && (
                <DoctorAddOtherTestModal />
            )}

            {props.type === 'doctor__view__labtest' && (
                <DoctorViewTestModal />
            )}

            {props.type === 'doctor__write__labreport' && (
                <DoctorAddTestReportModal />
            )}

            {props.type === 'doctor__upload__other__labreport' && (
                <DoctorUploadOtherTestReportModal />
            )}

            {props.type === 'doctor__add__radiologytest' && (
                <DoctorAddRadiologyTestModal />
            )}

            {props.type === 'doctor__add__otherradiologytest' && (
                <DoctorAddOtherRadiologyTestModal />
            )}

            {props.type === 'doctor__view__radiologytest' && (
                <DoctorViewRadiologyTestModal />
            )}

            {props.type === 'doctor__write__radiologyreport' && (
                <DoctorAddRadiologyReportModal />
            )}

            {props.type === 'doctor__upload__other__radiologyreport' && (
                <DoctorUploadOtherRadiologyReportModal />)}


            {/* Pharmacist Lab Investigations ==================>*/}

            {/* {props.type === "laboratory_add_equipment" && (
                <LaboratoryAddEquipmentModal />)}
            {props.type === "laboratory_update_equipment" && (
                <LaboratoryAddEquipmentModal />)}

            {props.type === "laboratory_delete_equipment" && (
                <DeleteLaboratoryEquipmentModal />)} */}
            {props.type === "laboratory_generate_invoice" && (
                <LaboratoryGenerateInvoiceModal />)}
            {props.type === "laboratory_upload_report" && (
                <LaboratoryUploadReport />)}
            {props.type === "laboratory_view_report" && (
                <LaboratoryUploadReport />)}
            {/* {props.type === "laboratory_view_invoice" && (
                <LaboratoryViewInvoice />)}
            {props.type === "laboratory_add_category_modal" && (
                <LaboratoryAddCategoryModal />)}
            {props.type === "laboratory_update_category_modal" && (
                <LaboratoryAddCategoryModal />)}
            {props.type === "laboratory_delete_category_modal" && (
                <LaboratoryDeleteCategoryModal />)} */}
            {/* 
            {props.type === "laboratory_add_investigation_type" && (
                <LaboratoryAddInvestigationTypeModal />)}
            {props.type === "laboratory_update_investigation_type" && (
                <LaboratoryAddInvestigationTypeModal />)}
            {props.type === "laboratory_delete_investigation_type" && (
                <LaboratoryDeleteInvestigationTypeModal />)}
            {props.type === "laboratory_add_sample_type" && (
                <LaboratoryAddSampleModal />)}
            {props.type === "laboratory_update_sample_type" && (
                <LaboratoryAddSampleModal />)}
            {props.type === "laboratory_delete_sample_type" && (
                <LaboratoryDeleteSampleTypeModal />)} */}



            {/* Pharmacist radiology investigations=====================> */}

            {/* {props.type === "radiology_add_equipment" && (
                <RadiologyAddEquipmentModal />)}

            {props.type === "radiology_update_equipment" && (
                <RadiologyAddEquipmentModal />)}

            {props.type === "radiology_delete_equipment" && (
                <RadiologyDeleteEquipment />)} */}
            {props.type === "radiology_upload_report" && (
                <RadiologyUploadReportModal />)}
            {props.type === "radiology_show_upload_report" && (
                <RadiologyUploadReportModal />)}
            {/* {props.type === "radiology_add_investigation" && (
                <RadiologyAddInvestigationModal />)}
            {props.type === "radiology_add_investigation_category" && (
                <RadiologyAddInvestigationCategoryModal />)}
            {props.type === "radiology_update_category_test" && (
                <RadiologyAddInvestigationCategoryModal />)}
            {props.type === "radiology_add_investigation_type" && (
                <RadiologyAddInvestigationTypeModal />)}
            {props.type === "radiology_update_investigation" && (
                <RadiologyUpdateInvestigationModal />)}
            {props.type === "radiology_view_investigation" && (
                <RadiologyUpdateInvestigationModal />)} */}
            {props.type === "radiology_view_invoice" && (
                <RadiologyViewInvoiceModal />)}

            {props.type === "radiology_generate_invoice" && (
                <RadiologyGenerateInvoiceModal />)}
            {/* {props.type === "radiology_delete_category_test" && (
                <RadiologyDeleteEquipment />)}

            {props.type === "radiology_delete_investigation_type" && (
                <RadiologyDeleteInvestigationTypeModal />)}
            {props.type === "radiology_update_investigation_type" && (
                <RadiologyAddInvestigationTypeModal />)} */}





        </Fragment>
    )
}


export default PharmacistFormModals