/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineSearch } from 'react-icons/ai'

import ChatUser from './ChatUser'
import ChatGroup from './ChatGroup'
import { ArtMessaging } from '../../assets'
import ChatInterface from './ChatInterface'
import ChatUserProfile from './ChatUserProfile'
import { useStateContext } from '../../state/context'
import { createChatConversation, getChatConversations, setCurrentChatConversation, setCurrentChatUser } from '../../state/actions/chat.actions'


const TextChat = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const {
        current_chatuser, chat_message, chat_conversation, current_chatconversation,
        chat_conversations, groupchat_conversation, active_chat, chat_readmessages
    } = useSelector(state => state.chat)

    const [chatConversations, setChatConversations] = useState([])
    const [searchState, setSearchState] = useState({ user__search: '', searching: false, filtered__users: null })

    useEffect(() => {
        dispatch(getChatConversations())
    }, [])

    useEffect(() => {
        dispatch(getChatConversations())
    }, [chat_message, chat_conversation, groupchat_conversation, chat_readmessages, current_chatconversation, active_chat])

    useEffect(() => {
        setChatConversations(chat_conversations)
    }, [chat_conversations, chat_message])

    useEffect(() => {
        if (chat_conversation?.id) {
            setChatConversations([chat_conversation, ...chatConversations])
        }
    }, [chat_conversation, groupchat_conversation])


    const handleSearch = (value) => {
        const current_users = props?.users?.filter(user => {
            return !chat_conversations?.find(conversation => conversation.sender === user.pkid || conversation.receiver === user.pkid)
        })

        const filteredUsers = current_users?.filter(user =>
            user.first_name.toLowerCase().includes(value)
            ||
            user.last_name.toLowerCase().includes(value)
            ||
            user.email.toLowerCase().includes(value)
        )
        setSearchState({ ...searchState, filtered__users: filteredUsers })
    }

    const handleSearchSubmit = (e, _user) => {
        e.preventDefault()

        const input = document.getElementById('user__search')
        input.value = ''


        const _data = {
            ..._user,
            profile_photo: _user.user_profile.profile_photo
        }

        dispatch(createChatConversation({ formData: { receiver: _user.pkid }, _data, dispatch, setCurrentChatUser, setCurrentChatConversation }))

        // dispatch(setCurrentChatUser({ data }))
        setSearchState({ ...searchState, searching: false })
    }


    return (
        <Fragment>
            {/* users */}
            <div className="flex-[0.3]">
                <div className="mt-[-5px] relative">
                    <AiOutlineSearch
                        size={20}
                        className='absolute top-[22px] left-2'
                    />
                    <input
                        type="search"
                        id='user__search'
                        name="user__search"
                        placeholder='Search chat'
                        autoComplete='off'
                        className="border-b-2 border-b-gray-600 bg-gradient-to-l from-gray-50 to-gray-200 w-full px-2 pl-8 font-thin py-2 mt-2 text-[12px] text-gray-700 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                        onClick={() => setSearchState({ ...searchState, searching: !searchState.searching })}
                        onChange={(e) => handleSearch(e.target.value.toLowerCase())}
                    />

                    {searchState.searching && (
                        <div className="absolute bg-white shadow-lg h-[300px] w-[315px] z-[2] overflow-y-auto users">
                            {!searchState.filtered__users && (
                                <div className="flex flex-col justify-center items-center">
                                    <p className="text-[12px] text-gray-400 italic">No users found. Check your search filter</p>
                                </div>
                            )}

                            {searchState.filtered__users?.map((user => (
                                <div
                                    key={`${user.id}`}
                                    className=" flex items-center space-x-4 border-b p-1 cursor-pointer group"
                                    onClick={(e) => handleSearchSubmit(e, user)}
                                >
                                    <img
                                        src={`http://localhost:8000${user?.user_profile?.profile_photo}`}
                                        className="w-[40px] h-[40px] rounded-[50%] group-hover:translate-x-2 duration-500 ease-in-out"
                                        alt=""
                                    />
                                    <div className="flex flex-col -space-y-4 group-hover:translate-x-2 duration-500 ease-in-out">
                                        <p className="text-[12px] text-gray-600 font-bold">{user.last_name} {user.first_name}</p>
                                        <p className="text-[10px] text-gray-600">{user?.email}</p>
                                    </div>
                                </div>
                            )))}
                        </div>
                    )}
                </div>

                <div className="h-[90%] max-h-[90%] overflow-y-auto users border-r border-r-gray-600 relative">
                    {chatConversations?.map((chat, index) => (
                        <Fragment key={`${chat?.id}-${index}`}>
                            {chat?.conversation_type === 1 && (
                                <Fragment>
                                    {chat?.sender === user?.data?.pkid && (
                                        <ChatUser
                                            key={`${chat?.id}`}
                                            conversation={chat}
                                            info={chat?.receiver_details}
                                        />
                                    )}
                                    {chat?.sender !== user?.data?.pkid && (
                                        <ChatUser
                                            key={`${chat?.id}`}
                                            conversation={chat}
                                            info={chat?.sender_details}
                                        />
                                    )}
                                </Fragment>
                            )}

                            {chat?.conversation_type === 2 && (
                                <Fragment>
                                    <ChatGroup
                                        key={`${chat?.id}`}
                                        conversation={chat}
                                        info={chat}
                                    />
                                </Fragment>
                            )}
                        </Fragment>
                    ))}

                    <div
                        onClick={() => {
                            setModals({ ...modals, showChatCreateGroupModal: !modals.showChatCreateGroupModal })
                        }}
                        className="absolute bg-violet-600 z-[2] bottom-3 right-1 text-white rounded-full w-[50px] h-[50px] flex justify-center items-center cursor-pointer hover:-translate-y-2 duration-500 ease-in-out">
                        +
                    </div>
                </div>
            </div>

            {/* chat */}
            {current_chatuser && (
                <Fragment>
                    <ChatInterface
                        showUserProfile={props.showUserProfile}
                        setShowUserProfile={props.setShowUserProfile}
                        showProfileDropDown={props.showProfileDropDown}
                        setShowProfileDropDown={props.setShowProfileDropDown}
                    />

                    <ChatUserProfile
                        showUserProfile={props.showUserProfile}
                        setShowUserProfile={props.setShowUserProfile}
                    />
                </Fragment>
            )}

            {!current_chatuser && (
                <div className='flex-[0.7] justify-center items-center mx-auto'>
                    {/* <ChatAnimation type={0} /> */}
                    <img
                        src={ArtMessaging}
                        alt=""
                        className="w-[70%] mx-auto"
                    />
                </div>
            )}

        </Fragment>
    )
}

export default TextChat