import React, { useRef } from "react"
import { useDispatch, useSelector, } from "react-redux"

import { Logo } from '../../../assets'
import { useStateContext } from "../../../state/context"
import { setCurrentPharmacyInvoice } from "../../../state/actions/collection.actions"
import { useReactToPrint } from 'react-to-print'


const CollectionsPrintInvoiceModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const cardRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => cardRef.current,
    })

    const { pharmacy_config } = useSelector(state => state.auth)
    const { pharmacy_invoice } = useSelector(state => state.collection)


    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div
                className="absolute right-[4em] top-0 text-[60px] font-medium text-white cursor-pointer"
                onClick={() => {
                    dispatch(setCurrentPharmacyInvoice({ data: null }))
                    setModals({ ...modals, showCollectionPrintInvoiceModal: !modals.showCollectionPrintInvoiceModal })
                }}
            >
                x
            </div>

            <div className="bg-white w-[400px] rounded-t"
                ref={cardRef}

            >
                <div className="h-[5px] bg-indigo-800 rounded-t"></div>
                <div className="flex justify-between space-y-5 px-[10px]">
                    <div className="flex flex-col items-center space-y-2 mt-2">
                        <img
                            src={pharmacy_config ? `http://localhost:8000${pharmacy_config?.logo}` : Logo}
                            alt="logo"
                            className="w-[50px]"
                        />
                        <p className="text-[20px] text-gray-800 font-bold font-montserrat">{pharmacy_config?.name}</p>
                    </div>

                    <div>
                        <p className="text-[12px] text-gray-600 font-medium">
                            {pharmacy_config?.address}
                        </p>
                        <p className="text-[12px] text-gray-600 font-medium">
                            {pharmacy_config?.email}
                        </p>
                        <p className="text-[12px] text-gray-600 font-medium">
                            {pharmacy_config?.phone}
                        </p>
                    </div>
                </div>

                <div className="space-y-4 px-[10px] mt-5">
                    <div className="flex justify-start space-x-4">
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Subject:</span>  Invoice
                        </p>
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Date:</span> {pharmacy_invoice?.created_at?.slice(0, 10)}
                        </p>
                        <p className="text-[12px] font-medium text-gray-500 pl-2">
                            <span className="text-gray-800">Invoice number:</span>  {pharmacy_invoice?.invoice_number}
                        </p>
                    </div>

                    <table className="rounded w-[390px] cursor-default">
                        <thead className="">
                            <tr className='bg-yellow-100'>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[1em] py-2 text-left"
                                >
                                    Item
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[1em] py-2 text-left"
                                >
                                    Quantity
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[1em] py-2 text-left"
                                >
                                    Rate
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[1em] py-2 text-left"
                                >
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                    {pharmacy_invoice?.labtest_details ? pharmacy_invoice?.labtest_details?.title : pharmacy_invoice?.radtest_details ? pharmacy_invoice?.radtest_details?.title : pharmacy_invoice?.prescription_details?.drugs}
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                    {pharmacy_invoice?.prescription_details?.dispense_count}
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                    {!pharmacy_invoice?.invoice_amount ? pharmacy_invoice?.amount : pharmacy_invoice?.prescription_details?.price}
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                    {!pharmacy_invoice?.invoice_amount ? pharmacy_invoice?.amount : pharmacy_invoice?.invoice_amount}
                                </td>
                            </tr>
                            <tr>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-bold py-2 whitespace-nowrap bg-yellow-200">
                                    Tax: &#8358;
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-medium underline underline-offset-1 py-2 whitespace-nowrap bg-yellow-200">
                                    {/* {pharmacy_invoice?.invoice_amount} */}0.00
                                </td>
                            </tr>
                            <tr>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[14px] text-white font-bold py-2 whitespace-nowrap bg-teal-900">
                                    Total:
                                </td>
                                <td className="border-t border-b text-[14px] text-white font-bold underline underline-offset-1 py-2 whitespace-nowrap bg-teal-900">
                                    ₦  {!pharmacy_invoice?.invoice_amount ? pharmacy_invoice?.amount : pharmacy_invoice?.invoice_amount}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {/* <div className="flex flex-col place-content-end w-1/2 italic text-[10px]">
                        <p className="underline font-bold">Terms And Conditions.</p>
                        <p>All payments finalized have no return policy.</p>
                    </div> */}

                    <div className="flex flex-col place-content-end w-1/2 italic text-[10px]">
                        <p className="underline font-bold">Thanks, for your patronage.</p>

                    </div>
                </div>

            </div>
            <button
                type='button'
                className='inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                onClick={handlePrint}
            >
                Print
            </button>
        </div >
    )

}

export default CollectionsPrintInvoiceModal
