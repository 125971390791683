import { API } from "../config";

export const getAllPatients = (token) => API.get('/records/patients/', { headers: { Authorization: `Bearer ${token}` } })
export const getAllDoctors = (token) => API.get('/records/doctors/', { headers: { Authorization: `Bearer ${token}` } })
// "change patient doctor"
export const assignPatientDoctor = (formData, token) => API.put(`/records/patient/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const AddPatient = (formData, token) => API.post('/records/patients/', formData, { headers: { Authorization: `Bearer ${token}` } })
export const BookPatientAppointment = (formData, token) => API.post(`/records/appointment/${formData.pat_id}/${formData.doc_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const AllBirthRecords = (token) => API.get('/records/birth_records/', { headers: { Authorization: `Bearer ${token}` } })

export const AllDeathRecords = (token) => API.get('/records/death_records/', { headers: { Authorization: `Bearer ${token}` } })
export const getBirthRecord = (patient_num, token) => API.get(`/records/birth_record/${patient_num}/`, { headers: { Authorization: `Bearer ${token}` } })
export const UpdateBirthRecord = (formData, token) => API.put(`/records/birth_record/${formData.patient_num}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const AddDeathRecord = (formData, token) => API.post(`/records/death_record/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const AllWards = (token) => API.get('/records/wards/', { headers: { Authorization: `Bearer ${token}` } })
export const getDeathRecordData = (patient_num, token) => API.get(`/records/death_record/${patient_num}/`, { headers: { Authorization: `Bearer ${token}` } })
export const WardReport = (formData, token) => API.post(`/records/add_ward_report/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const getWardReports = (id, token) => API.get(`/records/add_ward_reports/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const generateCard = (patient_id, token) => API.get(`/records/patient/card/${patient_id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const generateInvoice = (formData, token) => API.post(`/records/invoice/${formData.patient_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const getBillings = (token) => API.get('/records/billings/', { headers: { Authorization: `Bearer ${token}` } })
export const getInvoices = (token) => API.get('/records/invoices/', { headers: { Authorization: `Bearer ${token}` } })
export const getAppointments = (token) => API.get('/records/appointments/', { headers: { Authorization: `Bearer ${token}` } })
export const getABilling = (billing_id, token) => API.get(`/administrator/billings/${billing_id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const getAssignedDoctors = (token) => API.get('/records/assigned_doctors/', { headers: { Authorization: `Bearer ${token}` } })
export const assignPatientToDoctor = (formData, token) => API.post(`/records/assign_doctor/${formData.pat_id}/${formData.doc_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })






