/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { setCurrentSupplyPointInventory } from '../../state/actions/actions'
import { getSupplyPointInventory } from '../../state/actions/pharmacist.actions'
import Pagination from '../utils/Pagination'


const PharmacistSupplyPointTable = ({ showList }) => {
    const dispatch = useDispatch()

    const { appState, setAppState } = useStateContext()

    const { supplypoint_update } = useSelector(state => state.root)
    const { supplypoint_inventory } = useSelector(state => state.pharmacist)

    const [currentData, setCurrentData] = useState([])
    const [filterState, setFilterState] = useState({ search: '', supplypoint_inventory: [] })

    const handleFilter = () => {
        const filtered = supplypoint_inventory?.filter(item => {
            return item.supplypoint_inventory?.inventoryitem_details?.title.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, supplypoint_inventory: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, supplypoint_inventory })
        }
    }

    useEffect(() => {
        handleFilter()
    }, [filterState.search])


    useEffect(() => {
        if (supplypoint_update?.name) {
            dispatch(getSupplyPointInventory({ supplypoint_name: supplypoint_update?.name }))
        }
    }, [supplypoint_update])
    

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <input
                    type="text"
                    name="search"
                    // disabled
                    placeholder='Search'
                    className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                    onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                />
                <div className="">
                    {showList && (
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Item
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Category
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr key={`${item.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.supplypoint_inventory?.inventoryitem_details?.title}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.supplypoint_inventory?.inventoryitem_details?.drugcategory_details?.category}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentSupplyPointInventory({ data: item }))
                                                    setAppState({ ...appState, showManageBinCard: !appState.showManageBinCard })
                                                }}
                                            >
                                                Manage Bin Card
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Pagination
                        data={filterState.supplypoint_inventory}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PharmacistSupplyPointTable