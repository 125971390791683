/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../state/context'
import { DoctorPatientsTable } from '../../components'
import { getDoctorPatients } from '../../state/actions/doctor.actions'

import { PageTitle } from '../../components'
import PharmacistDiagnosis from './PharmacistDiagnosis'


const PharmacistPatient = () => {
    const dispatch = useDispatch()

    const { appState, setAppState } = useStateContext()
    const { data } = useSelector(state => state.auth.user)
    const { doctor_patients, patient_transfer } = useSelector(state => state.doctor)

    const [showList, setShowList] = useState(true)

    useEffect(() => {
        dispatch(getDoctorPatients({ doc_name: data.pkid }))
    }, [patient_transfer])

    return (
        <div>
            <PageTitle type={'pharmacist-investigations'} />

            <div className="space-y-5">
                {!appState.showDoctorManagePatient && (
                    <div className="space-y-5">
                        <DoctorPatientsTable
                            title={'Patients'}
                            data={doctor_patients}
                            showList={showList}
                            setShowList={setShowList}
                        />

                    </div>
                )}

                {appState.showDoctorManagePatient && (
                    <PharmacistDiagnosis />
                )}
            </div>
        </div>
    )
}

export default PharmacistPatient