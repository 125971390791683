import { createSlice } from '@reduxjs/toolkit'

import {
    createFlutterWaveConfig,
    createPayStackConfig, getInventoryAuthorReports, getInventoryDailyReports, getInventoryMonthlyReports, getInventoryRangeReports, getInventoryYearlyReports, getInvestigationAuthorReports, getInvestigationDailyReports, getInvestigationInvoices, getInvestigationMonthlyReports, getInvestigationRangeReports, getInvestigationYearlyReports, getLaboratoryInvoices, getPharmacyInvoices, getRadiologyInvoices,
    getRecordsInvoices, setCurrentLabInvoice, setCurrentPharmacyInvoice, setCurrentRadiologyInvoice, setCurrentRecordsInvoice, updateInvestigationViaPOS, updateLabInvoice, updateLabInvoiceViaCash, updateLabInvoiceViaPOSTransaction, updatePharmacyInvoice, updatePharmacyInvoiceViaCash, updatePharmacyInvoiceViaPOSTransaction, updateProvisionInvoice, updateRadiologyInvoice, updateRadiologyInvoiceViaCash,
    updateRadiologyInvoiceViaPOSTransaction, updateRecordsInvoice, updateRecordsInvoiceViaCash, updateRecordsInvoiceViaPOSTransaction, verifyFlutterWavePayment, verifyPayStackPayment, setAccumulatedPharmacyInvoice, updateMultipleInventoryInvoices, updateMultipleInventoryInvoicesViaPOS, updateMultipleInventoryInvoicesViaCash, updateMultipleInvestigationInvoices, updateMultipleInvestigationInvoicesViaPOS, updateMultipleInvestigationInvoicesViaCash,
     getInventoryItemReports, getPaidInventoryReports, getUnpaidInventoryReports, getPaidInvestigationReports, getUnpaidInvestigationReports, createAdminPayStackConfig, verifyAdminPayStackPayment
} from '../actions/collection.actions'


const collectionSlice = createSlice({
    name: 'collection',
    initialState: {
        laboratory_invoices: null,
        laboratory_invoice: null,
        laboratory_invoice_update: null,
        pharmacy_invoices: null,
        pharmacy_invoice: null,
        pharmacy_invoice_update: null,
        provision_invoice_update: null,
        radiology_invoices: null,
        radiology_invoice: null,
        radiology_invoice_update: null,
        records_invoices: null,
        records_invoice: null,
        records_invoice_update: null,
        paystack_config: null,
        flutterwave_config: null,
        paystack_payment_status: null,
        flutterwave_payment_status: null,
        investigation_invoices: null,
        investigation_invoice: null,
        inventory_reports: null,
        investigation_reports: null,
        collectionLoading: null,
        collectionRequestStatus: null,
        
    },
    extraReducers: {
        [getLaboratoryInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getLaboratoryInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoices = action.payload
        },
        [getLaboratoryInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getPharmacyInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getPharmacyInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoices = action.payload
        },
        [getPharmacyInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getRadiologyInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getRadiologyInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoices = action.payload
        },
        [getRadiologyInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getRecordsInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getRecordsInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoices = action.payload
        },
        [getRecordsInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentLabInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentLabInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoice = action.payload
        },
        [setCurrentLabInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [createPayStackConfig.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [createPayStackConfig.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.paystack_config = action.payload
        },
        [createPayStackConfig.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [createAdminPayStackConfig.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [createAdminPayStackConfig.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.paystack_config = action.payload
        },
        [createAdminPayStackConfig.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [createFlutterWaveConfig.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [createFlutterWaveConfig.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.flutterwave_config = action.payload
        },
        [createFlutterWaveConfig.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [verifyPayStackPayment.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [verifyPayStackPayment.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.paystack_payment_status = action.payload
        },
        [verifyPayStackPayment.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [verifyAdminPayStackPayment.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [verifyAdminPayStackPayment.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.paystack_payment_status = action.payload
        },
        [verifyAdminPayStackPayment.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [verifyFlutterWavePayment.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [verifyFlutterWavePayment.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.flutterwave_payment_status = action.payload
        },
        [verifyFlutterWavePayment.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateLabInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateLabInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoice_update = action.payload
        },
        [updateLabInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateLabInvoiceViaPOSTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateLabInvoiceViaPOSTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoice_update = action.payload
        },
        [updateLabInvoiceViaPOSTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateLabInvoiceViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateLabInvoiceViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoice_update = action.payload
        },
        [updateLabInvoiceViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentPharmacyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentPharmacyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice = action.payload
        },
        [setCurrentPharmacyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updatePharmacyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updatePharmacyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updatePharmacyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateProvisionInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateProvisionInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.provision_invoice_update = action.payload
        },
        [updateProvisionInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updatePharmacyInvoiceViaPOSTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updatePharmacyInvoiceViaPOSTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updatePharmacyInvoiceViaPOSTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updatePharmacyInvoiceViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updatePharmacyInvoiceViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updatePharmacyInvoiceViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentRadiologyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentRadiologyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoice = action.payload
        },
        [setCurrentRadiologyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRadiologyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRadiologyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoice_update = action.payload
        },
        [updateRadiologyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRadiologyInvoiceViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRadiologyInvoiceViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoice_update = action.payload
        },
        [updateRadiologyInvoiceViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRadiologyInvoiceViaPOSTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRadiologyInvoiceViaPOSTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoice_update = action.payload
        },
        [updateRadiologyInvoiceViaPOSTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentRecordsInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentRecordsInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoice = action.payload
        },
        [setCurrentRecordsInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRecordsInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRecordsInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoice_update = action.payload
        },
        [updateRecordsInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRecordsInvoiceViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRecordsInvoiceViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoice_update = action.payload
        },
        [updateRecordsInvoiceViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRecordsInvoiceViaPOSTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRecordsInvoiceViaPOSTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoice_update = action.payload
        },
        [updateRecordsInvoiceViaPOSTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInvestigationInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInvestigationInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_invoices = action.payload
        },
        [getInvestigationInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateInvestigationViaPOS.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateInvestigationViaPOS.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_invoice = action.payload
        },
        [updateInvestigationViaPOS.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInventoryDailyReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInventoryDailyReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.inventory_reports = action.payload
        },
        [getInventoryDailyReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInventoryMonthlyReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInventoryMonthlyReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.inventory_reports = action.payload
        },
        [getInventoryMonthlyReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInventoryYearlyReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInventoryYearlyReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.inventory_reports = action.payload
        },
        [getInventoryYearlyReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInventoryRangeReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInventoryRangeReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.inventory_reports = action.payload
        },
        [getInventoryRangeReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInventoryAuthorReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInventoryAuthorReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.inventory_reports = action.payload
        },
        [getInventoryAuthorReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInventoryItemReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInventoryItemReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.inventory_reports = action.payload
        },
        [getInventoryItemReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getPaidInventoryReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getPaidInventoryReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.inventory_reports = action.payload
        },
        [getPaidInventoryReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getUnpaidInventoryReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getUnpaidInventoryReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.inventory_reports = action.payload
        },
        [getUnpaidInventoryReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInvestigationDailyReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInvestigationDailyReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_reports = action.payload
        },
        [getInvestigationDailyReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInvestigationMonthlyReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInvestigationMonthlyReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_reports = action.payload
        },
        [getInvestigationMonthlyReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInvestigationYearlyReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInvestigationYearlyReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_reports = action.payload
        },
        [getInvestigationYearlyReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInvestigationRangeReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInvestigationRangeReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_reports = action.payload
        },
        [getInvestigationRangeReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getInvestigationAuthorReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getInvestigationAuthorReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_reports = action.payload
        },
        [getInvestigationAuthorReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getPaidInvestigationReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getPaidInvestigationReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_reports = action.payload
        },
        [getPaidInvestigationReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getUnpaidInvestigationReports.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getUnpaidInvestigationReports.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_reports = action.payload
        },
        [getUnpaidInvestigationReports.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setAccumulatedPharmacyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setAccumulatedPharmacyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.set_accumulated_bills = action.payload
        },
        [setAccumulatedPharmacyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInventoryInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInventoryInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoices = action.payload
        },
        [updateMultipleInventoryInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInventoryInvoicesViaPOS.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInventoryInvoicesViaPOS.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoices = action.payload
        },
        [updateMultipleInventoryInvoicesViaPOS.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInventoryInvoicesViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInventoryInvoicesViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoices = action.payload
        },
        [updateMultipleInventoryInvoicesViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInvestigationInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInvestigationInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_invoices = action.payload
        },
        [updateMultipleInvestigationInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInvestigationInvoicesViaPOS.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInvestigationInvoicesViaPOS.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_invoice = action.payload
        },
        [updateMultipleInvestigationInvoicesViaPOS.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInvestigationInvoicesViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInvestigationInvoicesViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.investigation_invoice = action.payload
        },
        [updateMultipleInvestigationInvoicesViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },
    }
})


export default collectionSlice.reducer