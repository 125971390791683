import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BiCategory } from 'react-icons/bi'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setDrugCategory } from '../../state/actions/actions'


const PharmacistDrugsCategoryTable = ({ data, showList, setShowList, title }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <BiCategory className="text-dark-blue" size={20} />
                    </div>
                    {showList && (
                        <table className="border rounded w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Description
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr key={`${item.id} ${index}`}>
                                        <td
                                            className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900"
                                        // value={billing.pkid}
                                        >
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.category}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.description.length > 30 ? `${item.description.slice(0, 30)}...` : item.description}
                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            <FaPencilAlt
                                                size={20}
                                                className='text-green-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setDrugCategory({ data: item }))
                                                    setModals({ ...modals, showPharmacistUpdateDrugCategoryModal: !modals.showPharmacistUpdateDrugCategoryModal })
                                                }}
                                            />
                                            <FaTrash
                                                onClick={() => {
                                                    dispatch(setDrugCategory({ data: item }))
                                                    setModals({ ...modals, showPharmacistUpdateDeleteCategoryModal: !modals.showPharmacistUpdateDeleteCategoryModal })
                                                }}
                                                className="text-orange-400 cursor-pointer"
                                                size={20}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Pagination
                        data={data}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PharmacistDrugsCategoryTable