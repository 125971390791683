import React, { useState, useEffect } from 'react'
import PharmacistClinicalRepositoryTable from '../../components/tables/PharmacistClinicalRepositoryTable'
import { getClinicalData } from '../../state/actions/pharmacist.actions'
import { useDispatch, useSelector } from 'react-redux'
import PharmacistClinicalRepositoryDetailsTable from '../../components/tables/PharmacistClinicalRepositoryDetailsTable'

const PharmacistClinicalDataRepository = () => {
    const [data, setData] = useState([])
    const dispatch = useDispatch()
    const { clinical_data } = useSelector(state => state.pharmacist)
    const [viewClinicalDataDetail, setViewClinicalDataDetail] = useState(false)



    useEffect(() => {
        dispatch(getClinicalData())
        setData(clinical_data)
    }, [])

    return (
        <div>

            {!viewClinicalDataDetail && <>

                <PharmacistClinicalRepositoryTable 
                // data={data}
                    viewClinicalDataDetail={viewClinicalDataDetail}
                    setViewClinicalDataDetail={setViewClinicalDataDetail}
                />
            </>}

            {viewClinicalDataDetail && <>

                <PharmacistClinicalRepositoryDetailsTable
                    viewClinicalDataDetail={viewClinicalDataDetail}
                    setViewClinicalDataDetail={setViewClinicalDataDetail}
                />
            </>}
        </div>
    )
}

export default PharmacistClinicalDataRepository


