import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useStateContext } from '../../state/context'
import { getMonth } from '../../components/utils/schedule_utils'
import CalenderMonth from '../../components/tables/CalenderMonth'
import { getAllSchedules } from '../../state/actions/admin.actions'
import CalenderHeader from '../../components/tables/CalenderHeader'
import CalendarSideBar from '../../components/tables/CalendarSideBar'
import PageTitle from '../../components/navigation/PageTitle'

const Schedule = () => {
    const currentMonthIndex = new Date().getMonth();
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()
    const [dateIndex, setDateIndex] = useState(currentMonthIndex)
    const [currentMonth, setCurrentMonth] = useState(getMonth())


    useEffect(() => {
        dispatch(getAllSchedules())
        setCurrentMonth(getMonth(dateIndex))

    }, [dateIndex, modals.showCreateScheduleModal, modals.showEditAndDeleteScheduleModal, modals.showDeleteScheduleModal])

    useEffect(() => {
        dispatch(getAllSchedules())
        setCurrentMonth(getMonth(dateIndex))

    }, [modals.showCreateScheduleModal], modals.showEditAndDeleteScheduleModal)

    return (
        <div>
            <PageTitle type={'schedule'} />

            <div className='flex flex-col'>
                <CalenderHeader
                    dateIndex={dateIndex}
                    setDateIndex={setDateIndex}
                    currentMonthIndex={currentMonthIndex}
                />

                <div className='flex'>
                    <CalendarSideBar dateIndex={dateIndex} setDateIndex={setDateIndex} currentMonthIndex={currentMonthIndex} month={currentMonth}

                    />
                    <CalenderMonth month={currentMonth} />
                </div>
            </div>
        </div>
    )
}

export default Schedule