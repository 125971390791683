import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { setScheduleEdit } from '../../state/actions/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../state/context'


const EditDeleteOptionalModal = () => {
    const { modals, setModals } = useStateContext()
    const { set_edit_schedule } = useSelector(state => state.root)
    const dispatch = useDispatch()
    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex justify-between mb-4">
                    <p>Edit or delete "{set_edit_schedule?.title}" schedule</p>
                    <FaTimesCircle
                        className='text-dark-yellow cursor-pointer '
                        onClick={() => {
                            dispatch(setScheduleEdit({ data: null }))

                            setModals({ ...modals, showEditDeleteOptionalModal: !modals.showEditDeleteOptionalModal })
                        }}
                        size={20}
                    />
                </div>
                <div className="flex justify-between items-center space-x-2">

                    <button
                        className='text-white text-lg font-semibold hover:cursor-pointer rounded-md  h-[40px] py-1 px-20 justify-center bg-blue-400'
                        onClick={() => {
                            setModals({ ...modals, showEditDeleteOptionalModal: !modals.showEditDeleteOptionalModal })
                            !modals.showEditAndDeleteScheduleModal && setModals({ ...modals, showEditAndDeleteScheduleModal: !modals.showEditAndDeleteScheduleModal })
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className='text-white text-lg font-semibold hover:cursor-pointer rounded-md  h-[40px] py-1 px-20 justify-center bg-orange-400'
                        onClick={() => {
                            setModals({ ...modals, showEditDeleteOptionalModal: !modals.showEditDeleteOptionalModal })
                            !modals.showDeleteScheduleModal && setModals({ ...modals, showDeleteScheduleModal: !modals.showDeleteScheduleModal })

                        }}

                    >
                        Delete
                    </button>


                </div>


            </div>
        </div>
    )
}

export default EditDeleteOptionalModal