import React, { Fragment } from "react"

import FormModal from "./FormModal"
import { useStateContext } from '../../state/context'

const Modals = ({ activeLink, setActiveLink }) => {
    const { modals } = useStateContext()
    return (
        <Fragment>

            {modals.showSendSmsModal && (
                <FormModal
                    type={'send__sms__modal'}
                />
            )}

            {modals.showSendMailModal && (
                <FormModal
                    type={'send__mail__modal'}
                />
            )}

            {modals.showPharmacistAddBillingModal && (
                <FormModal
                    type={'pharmacist__add__billing__modal'}
                />
            )}

            {modals.showPharmacistUpdateBillingModal && (
                <FormModal
                    type={'pharmacist__update__billing__modal'}
                />
            )}

            {modals.showPharmacistDeleteBillingModal && (
                <FormModal
                    type={'pharmacist__delete__billing__modal'}
                />
            )}

            {modals.showPharmacistAddInvestigationType && (
                <FormModal
                    type={'pharmacist__add__lab__investigationtype__modal'}
                />
            )}

            {modals.showPharmacistUpdateInvestigationType && (
                <FormModal
                    type={'pharmacist__update__lab__investigationtype__modal'}
                />
            )}

            {modals.showPharmacistDeleteInvestigationType && (
                <FormModal
                    type={'pharmacist__delete__lab__investigationtype__modal'}
                />
            )}

            {modals.showPharmacistAddInvestigationCategoryModal && (
                <FormModal
                    type={'pharmacist__add__lab__investigationcategory__modal'}
                />
            )}

            {modals.showPharmacistUpdateInvestigationCategoryModal && (
                <FormModal
                    type={'pharmacist__update__lab__investigationcategory__modal'}
                />
            )}

            {modals.showPharmacistDeleteInvestigationCategoryModal && (
                <FormModal
                    type={'pharmacist__delete__lab__investigationcategory__modal'}
                />
            )}

            {modals.showPharmacistAddInvestigationSampleModal && (
                <FormModal
                    type={'pharmacist__add__lab__investigationsample__modal'}
                />
            )}

            {modals.showPharmacistUpdateInvestigationSampleModal && (
                <FormModal
                    type={'pharmacist__update__lab__investigationsample__modal'}
                />
            )}

            {modals.showPharmacistDeleteInvestigationSampleModal && (
                <FormModal
                    type={'pharmacist__delete__lab__investigationsample__modal'}
                />
            )}

            {modals.showPharmacistAddRadiologyInvestigationTypeModal && (
                <FormModal
                    type={'pharmacist__add__rad__investigationtype__modal'}
                />
            )}

            {modals.showPharmacistUpdateRadiologyInvestigationTypeModal && (
                <FormModal
                    type={'pharmacist__update__rad__investigationtype__modal'}
                />
            )}

            {modals.showPharmacistDeleteRadiologyInvestigationTypeModal && (
                <FormModal
                    type={'pharmacist__delete__rad__investigationtype__modal'}
                />
            )}

            {modals.showPharmacistAddRadiologyInvestigationCategoryModal && (
                <FormModal
                    type={'pharmacist__add__rad__investigationcategory__modal'}
                />
            )}

            {modals.showPharmacistUpdateRadiologyInvestigationCategoryModal && (
                <FormModal
                    type={'pharmacist__update__rad__investigationcategory__modal'}
                />
            )}

            {modals.showPharmacistDeleteRadiologyInvestigationCategoryModal && (
                <FormModal
                    type={'pharmacist__delete__rad__investigationcategory__modal'}
                />
            )}

            {modals.showPharmacistAddDrugCategoryModal && (
                <FormModal
                    type={"pharmacist__add__drugcategory"}
                />
            )}

            {modals.showPharmacistUpdateDrugCategoryModal && (
                <FormModal
                    type={"pharmacist__update__drugcategory"}
                />
            )}

            {modals.showPharmacistUpdateDeleteCategoryModal && (
                <FormModal
                    type={"pharmacist__delete__drugcategory"}
                />
            )}

            {modals.showPharmacistAddDrugModal && (
                <FormModal
                    type={"pharmacist__add__drug"}
                />
            )}

            {modals.showPharmacistUpdateDrugModal && (
                <FormModal
                    type={"pharmacist__update__drug"}
                />
            )}

            {modals.showPharmacistViewDrugModal && (
                <FormModal
                    type={"pharmacist__view__drug"}
                />
            )}

            {modals.showPharmacistAddStaffModal && (
                <FormModal
                    type={"pharmacist__add__staff"}
                />
            )}

            {modals.showPharmacistUpdateStaffProfileModal && (
                <FormModal
                    type={"pharmacist__update__staff__profile"}
                />
            )}

            {modals.showPharmacistUpdateStaffModal && (
                <FormModal
                    type={"pharmacist__update__staff"}
                />
            )}

            {modals.showPharmacistDeleteStaffModal && (
                <FormModal
                    type={"pharmacist__delete__staff"}
                />
            )}

            {modals.showPharmacistAssignSupplyPointModal && (
                <FormModal
                    type={"pharmacist__assign__supplypoint__to__user"}
                />
            )}

            {modals.showPharmacistSupplyPointAssignStaffModal && (
                <FormModal
                    type={"pharmacist__supplypoint__assignstaff"}
                />
            )}

            {modals.showPharmacistAddSupplyPointModal && (
                <FormModal
                    type={"pharmacist__add__supplypoint"}
                />
            )}

            {modals.showPharmacistUpdateSupplyPointModal && (
                <FormModal
                    type={"pharmacist__update__supplypoint"}
                />
            )}

            {modals.showPharmacistDeleteSupplyPointModal && (
                <FormModal
                    type={"pharmacist__delete__supplypoint"}
                />
            )}

            {modals.showPharmacistSupplyPointStaffModal && (
                <FormModal
                    type={"pharmacist__view__supplypoint__staff"}
                />
            )}

            {modals.showPharmacistDeleteDrugModal && (
                <FormModal
                    type={"pharmacist__delete__drug"}
                />
            )}

            {modals.showPharmacistAddInventoryModal && (
                <FormModal
                    type={"pharmacist__add__inventory"}
                />
            )}

            {modals.showPharmacistViewProvisionModal && (
                <FormModal
                    type={"pharmacist__view__provision"}
                />
            )}

            {modals.showPharmacistUpdateProvisionModal && (
                <FormModal
                    type={"pharmacist__update__provision"}
                />
            )}

            {modals.showPharmacistDeleteProvisionModal && (
                <FormModal
                    type={"pharmacist__delete__provision"}
                />
            )}

            {modals.showPharmacistBinCardModal && (
                <FormModal
                    type={"pharmacist__drug__bincard"}
                />
            )}

            {modals.showPharmacistBinCardSummaryModal && (
                <FormModal
                    type={"pharmacist__drug__bincard__summary"}
                />
            )}

            {modals.showPharmacistIssueOutDrugModal && (
                <FormModal
                    type={"pharmacist__issueout__drug"}
                />
            )}

            {modals.showPharmacistLendRefundDrugModal && (
                <FormModal
                    type={"pharmacist__lend__refund__drug"}
                />
            )}

            {modals.showPharmacistDrugSupplyPointModal && (
                <FormModal
                    type={"pharmacist__drug__supplypoint"}
                />
            )}

            {modals.showPharmacistDrugInvoiceModal && (
                <FormModal
                    type={"pharmacist__generate__prescription__invoice"}
                />
            )}

            {modals.showPharmacistDispenseDrugModal && (
                <FormModal
                    type={"pharmacist__dispense__drug"}
                />
            )}

            {modals.showPharmacistViewPrescriptionModal && (
                <FormModal
                    type={"pharmacist__view__prescription"}
                />
            )}

            {modals.showPharmacistViewInvoiceModal && (
                <FormModal
                    type={"pharmacist__view__invoice"}
                />
            )}

            {modals.showPharmacistAddPrescriptionModal && (
                <FormModal
                    type={"pharmacist__create_prescription"}
                />
            )}

            {modals.showPharmacistProcessOrderModal && (
                <FormModal
                    type={"pharmacist__process__order"}
                />
            )}
            {modals.showPharmacistMultipleDispenseModal && (
                <FormModal
                    type={"pharmacist__multiple__dispense"}
                />
            )}

            {modals.showPharmacistViewOrderModal && (
                <FormModal
                    type={"pharmacist__view__order"}
                />
            )}

            {modals.showPharmacistAddGatewaySettingModal && (
                <FormModal
                    type={"pharmacist__create__gateway__setting"}
                />
            )}

            {modals.showPharmacistUpdateGatewaySettingModal && (
                <FormModal
                    type={"pharmacist__update__gateway__setting"}
                />
            )}

            {modals.showPharmacistDeleteGatewaySettingModal && (
                <FormModal
                    type={"pharmacist__delete__gateway__setting"}
                />
            )}

            {modals.showPharmacistUpdatePharmacyInfoModal && (
                <FormModal
                    type={"pharmacist__update__pharmacyinfo"}
                />
            )}

            {modals.showPharmacistUpdatePharmacyLogoModal && (
                <FormModal
                    type={"pharmacist__update__pharmacylogo"}
                />
            )}

            {modals.showTopUpSMSUnitsModal && (
                <FormModal
                    type={"pharmacist__topup__sms__units"}
                />
            )}

            {modals.showCustomerAddOrderModal && (
                <FormModal
                    type={"customer__add__order"}
                />
            )}

            {modals.showCustomerPaymentMethodModal && (
                <FormModal
                    type={"customer__view__paymentmethod__modal"}
                />
            )}

            {modals.showCustomerProcessOrderModal && (
                <FormModal
                    type={"customer__generate__order"}
                />
            )}

            {modals.showCustomerViewOrderModal && (
                <FormModal
                    type={"customer__view__order"}
                />
            )}

            {modals.showCustomerAddProvisionModal && (
                <FormModal
                    type={"customer__order__provision"}
                />
            )}

            {modals.showCustomerProvisionPaymentMethodModal && (
                <FormModal
                    type={"customer__view__provision__paymentmodal"}
                />
            )}

            {modals.showCustomerProcessProvisionOrderModal && (
                <FormModal
                    type={"customer__generate__provision__order"}
                />
            )}

            {modals.updatePasswordModal && (
                <FormModal
                    type={"profile__update__password"}
                />
            )}


            {modals.showWebCamModal && (
                <FormModal
                    type={"profile__photo__webcam"}
                />
            )}

            {modals.showPatientViewLabInvoiceModal && (
                <FormModal
                    type={"patient__view__labinvoice"}
                />
            )}

            {modals.showPatientPayForInvoicesModal && (
                <FormModal
                    type={"patient__pay__invoice"}
                />
            )}

            {modals.showAccountsViewLabInvoiceModal && (
                <FormModal
                    type={"accounts__view__labinvoice"}
                />
            )}

            {modals.showCreateScheduleModal && (
                <FormModal
                    type={'create_scheduleModal'}
                />
            )
            }
            {modals.showEditAndDeleteScheduleModal && (
                <FormModal
                    type={'edit_delete_scheduleModal'}
                />
            )
            }
            {modals.showListOfSchedulesByDateModal && (
                <FormModal
                    type={'list_of_Schedules_by_date'}
                />
            )}
            {modals.showDeleteScheduleModal && (
                <FormModal
                    type={'delete_schedule_modal'}
                />
            )}
            {modals.showEditDeleteOptionalModal && (
                <FormModal
                    type={'edit_delete_optional_modal'}
                />
            )}

            {modals.showViewScheduleModal && (
                <FormModal
                    type={'view_schedule_modal'}
                />
            )}

            {modals.showAccountsViewPharmacyInvoiceModal && (
                <FormModal
                    type={"accounts__view__pharmacyinvoice"}
                />
            )}

            {modals.showAccountsViewLabReportsModal && (
                <FormModal
                    type={"accounts__view__labreports"}
                />
            )}

            {modals.showAccountsViewPharmacyReportsModal && (
                <FormModal
                    type={"accounts__view__pharmarcyreports"}
                />
            )}

            {modals.showAccountsViewRadiologyReportsModal && (
                <FormModal
                    type={"accounts__view__radiologyreports"}
                />
            )}

            {modals.showAccountsViewRecordsReportsModal && (
                <FormModal
                    type={"accounts__view__recordsreports"}
                />
            )}

            {modals.showCollectionsLabPaymentModal && (
                <FormModal
                    type={"collections__laboratory__paymentmethod"}
                />
            )}

            {modals.showCollectionsPharmacyPaymentModal && (
                <FormModal
                    type={"collections__pharmacy__paymentmethod"}
                />
            )}

            {modals.showCollectionsInvestigationsPaymentModal && (
                <FormModal
                    type={"collections__investigations__paymentmethod"}
                />
            )}

            {modals.showCollectionRadiologyPaymentModal && (
                <FormModal
                    type={"collections__radiology__paymentmethod"}
                />
            )}

            {modals.showCollectionRecordsPaymentModal && (
                <FormModal
                    type={"collections__records__paymentmethod"}
                />
            )}

            {modals.showCollectionsViewLabInvoiceModal && (
                <FormModal
                    type={"collections__view__labinvoice"}
                />
            )}

            {modals.showCollectionsViewPharmacyInvoiceModal && (
                <FormModal
                    type={"collections__view__pharmacyinvoice"}
                />
            )}

            {modals.showCollectionsViewInvestigationInvoiceModal && (
                <FormModal
                    type={"collections__view__investigationinvoice"}
                />
            )}

            {modals.showCollectionsViewRadiologyInvoiceModal && (
                <FormModal
                    type={"collections__view__radiologyinvoice"}
                />
            )}

            {modals.showCollectionsViewRecordsInvoiceModal && (
                <FormModal
                    type={"collections__view__recordsinvoice"}
                />
            )}

            {modals.showCollectionsViewPharmacyReportsModal && (
                <FormModal
                    type={"collections__view__pharmarcyreports"}
                />
            )}
            {modals.showCollectionsViewInvestigationReportsModal && (
                <FormModal
                    type={"collections__view__investigationreports"}
                />
            )}

            {modals.showCollectionPrintInvoiceModal && (
                <FormModal
                    type={"collections__print__invoice"}
                />
            )}

            {modals.showCollectionsPayPharmacyMultipleInvoiceModal && (
                <FormModal
                    type={"collections__pay__pharmacy_multiple_invoice"}
                />
            )}

            {modals.showCollectionsPayInvestigationMultipleInvoiceModal && (
                <FormModal
                    type={"collections__pay__investigation_multiple_invoice"}
                />
            )}

            {modals.showCollectionPrintMultipleReceiptModal && (
                <FormModal
                    type={"collections__print_multiple__invoices"}
                />
            )}

            {modals.showCollectionsPrintInvestigationReceiptsModal && (
                <FormModal
                    type={"collections__print_multiple__investigations__invoices"}
                />
            )}

            {modals.showUpdateSubscriptionPlanModal && (
                <FormModal
                    type={"pharmacist__update__subscriptionplan"}
                />
            )}
            {modals.showRenewSubscriptionPlanModal && (
                <FormModal
                    type={"pharmacist__renew__subscriptionplan"}
                />
            )}


            {modals.showChatCreateGroupModal && (
                <FormModal
                    type={"chat__creategroup"}
                />
            )}

            {modals.showAddGroupParticipantModal && (
                <FormModal
                    type={"chat__addgroup_participant"}
                />
            )}
            {modals.showCreateRoomModal && (
                <FormModal
                    type={"create_room_modal"}
                />
            )}
            {modals.showDoctorUploadPatientFileModal && (
                <FormModal
                    type={"doctor__upload__patientfile"}
                />
            )}

            {modals.showDoctorViewPatientFileModal && (
                <FormModal
                    type={"doctor__view__patientfile"}
                />
            )}

            {modals.showDoctorTransferPatientModal && (
                <FormModal
                    type={"doctor__transfer__patient"}
                />
            )}

            {modals.showDoctorAddPrescriptionModal && (
                <FormModal
                    type={"doctor__add__prescription"}
                />
            )}

            {modals.showDoctorAddOtherPrescriptionModal && (
                <FormModal
                    type={"doctor__add__otherprescription"}
                />
            )}

            {modals.showDoctorAddTestModal && (
                <FormModal
                    type={"doctor__add__test"}
                />
            )}

            {modals.showDoctorAddOtherTestModal && (
                <FormModal
                    type={"doctor__add__othertest"}
                />
            )}

            {modals.showDoctorViewLabTestModal && (
                <FormModal
                    type={"doctor__view__labtest"}
                />
            )}

            {modals.showDoctorWriteLabReportModal && (
                <FormModal
                    type={"doctor__write__labreport"}
                />
            )}

            {modals.showDoctorUploadOtherLabReportModal && (
                <FormModal
                    type={"doctor__upload__other__labreport"}
                />
            )}

            {modals.showDoctorAddRadiologyTestModal && (
                <FormModal
                    type={"doctor__add__radiologytest"}
                />
            )}

            {modals.showDoctorAddOtherRadiologyTestModal && (
                <FormModal
                    type={"doctor__add__otherradiologytest"}
                />
            )}

            {modals.showDoctorViewRadiologyTestModal && (
                <FormModal
                    type={"doctor__view__radiologytest"}
                />
            )}

            {modals.showDoctorWriteRadiologyReportModal && (
                <FormModal
                    type={"doctor__write__radiologyreport"}
                />
            )}

            {modals.showDoctorUploadOtherRadiologyReportModal && (
                <FormModal
                    type={"doctor__upload__other__radiologyreport"}
                />
            )}

            {/* laboratorist */}
            {modals.showAddLaboratoryEquipmentModal && (
                <FormModal
                    type={"laboratory_add_equipment"}
                />
            )}
            {modals.showUpdateLaboratoryEquipmentModal && (
                <FormModal
                    type={"laboratory_update_equipment"}
                />
            )}
            {modals.showDeleteLaboratoryEquipmentModal && (
                <FormModal
                    type={"laboratory_delete_equipment"}
                />
            )}
            {modals.showLaboratoryGenerateInvoiceModal && (
                <FormModal
                    type={"laboratory_generate_invoice"}
                />
            )}

            {modals.showLaboratoryUploadReportModal && (
                <FormModal
                    type={"laboratory_upload_report"}
                />
            )}
            {modals.showAddLaboratoryTextReportModal && (
                <FormModal
                    type={"laboratory_upload_report"}
                />
            )}
            {modals.showLaboratoryViewReportModal && (
                <FormModal
                    type={"laboratory_view_report"}
                />
            )}
            {modals.showViewLaboratoryInvoiceModal
                && (
                    <FormModal
                        type={"laboratory_view_invoice"}
                    />
                )}
            {modals.showLaboratoryAddCategoryModal
                && (
                    <FormModal
                        type={"laboratory_add_category_modal"}
                    />
                )}
            {modals.showLaboratoryUpdateCategoryModal
                && (
                    <FormModal
                        type={"laboratory_update_category_modal"}
                    />
                )}
            {modals.showLaboratoryDeleteCategoryModal
                && (
                    <FormModal
                        type={"laboratory_delete_category_modal"}
                    />
                )}
            {modals.showLaboratoryAddInvestigationType
                && (
                    <FormModal
                        type={"laboratory_add_investigation_type"}
                    />
                )}
            {modals.showLaboratoryUpdateInvestigationType
                && (
                    <FormModal
                        type={"laboratory_update_investigation_type"}
                    />
                )}
            {modals.showDeleteLaboratoryInvestigationTypeModal
                && (
                    <FormModal
                        type={"laboratory_delete_investigation_type"}
                    />
                )}
            {modals.showAddLaboratorySamplesModal
                && (
                    <FormModal
                        type={"laboratory_add_sample_type"}
                    />
                )}
            {modals.showUpdateLaboratorySamplesModal
                && (
                    <FormModal
                        type={"laboratory_update_sample_type"}
                    />
                )}
            {modals.showDeleteLaboratorySampleModal
                && (
                    <FormModal
                        type={"laboratory_delete_sample_type"}
                    />
                )}
            {/* radiologist */}
            {modals.showAddRadiologyEquipment && (
                <FormModal
                    type={"radiology_add_equipment"}
                />
            )}
            {modals.showUpdateRadiologyEquipment && (
                <FormModal
                    type={"radiology_update_equipment"}
                />
            )}
            {modals.showDeleteRadiologyEquipment && (
                <FormModal
                    type={"radiology_delete_equipment"}
                />
            )}
            {modals.showUploadReportModal && (
                <FormModal
                    type={"radiology_upload_report"}
                />
            )}
            {modals.showRadiologyViewReportModal && (
                <FormModal
                    type={"radiology_show_upload_report"}
                />
            )}
            {modals.showAddRadiologyInvestigationModal && (
                <FormModal
                    type={"radiology_add_investigation"}
                />
            )}
            {modals.showAddRadiologyInvestigationCategoryModal && (
                <FormModal
                    type={"radiology_add_investigation_category"}
                />
            )}
            {modals.showAddRadiologyInvestigationTypeModal && (
                <FormModal
                    type={"radiology_add_investigation_type"}
                />
            )}
            {modals.showUpdateRadiologyInvestigationModal && (
                <FormModal
                    type={"radiology_update_investigation"}
                />
            )}
            {modals.showViewRadiologyInvestigationModal && (
                <FormModal
                    type={"radiology_view_investigation"}
                />
            )}
            {modals.showViewRadiologyInvoice && (
                <FormModal
                    type={"radiology_view_invoice"}
                />
            )}
            {modals.showRadiologyGenerateInvoiceModal && (
                <FormModal
                    type={"radiology_generate_invoice"}
                />
            )}
            {modals.showDeleteRadiologyCategoryTestModal && (
                <FormModal
                    type={"radiology_delete_category_test"}
                />
            )}
            {modals.showUpdateRadiologyCategoryTestModal && (
                <FormModal
                    type={"radiology_update_category_test"}
                />
            )}
            {modals.showDeleteRadiologyInvestigationTypeModal && (
                <FormModal
                    type={"radiology_delete_investigation_type"}
                />
            )}
            {modals.showUpdateRadiologyInvestigationTypeModal && (
                <FormModal
                    type={"radiology_update_investigation_type"}
                />
            )}
            {modals.showIncomingCallModal && (
                <FormModal
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    type={"show_incoming_call"}
                />
            )}
            {modals.showCallNotification && (
                <FormModal
                  
                    type={"show_call_notifications"}
                />
            )}


        </Fragment>
    )
}




export default Modals;

