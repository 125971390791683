import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from "react-icons/fa"
import * as roomHandler from '../../../config/groupcallConfig/roomHandler';
import * as socketConnection from '../../../config/groupcallConfig/socketConnection';
import { getUsers } from '../../../state/actions/chat.actions';
import { useDispatch, useSelector } from 'react-redux';

const PharmacistCreateRoomModal = () => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()
    const [formData, setFormData] = useState({ roomName: "", description: "", invitees: [] })
    const { users } = useSelector(state => state.chat)
    const { user } = useSelector(state => state.auth)




    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

    }
    const filtered__users = users?.filter((e) => e?.pkid !== user?.data?.pkid)


    const handleSubmit = async (e) => {

        e.preventDefault()

        // await socketConnection.getRoomName(formData.roomName)
        // console.log(formData)

        console.log(formData);

        await roomHandler.createNewRoom(formData)

        setModals({ ...modals, showCreateRoomModal: !modals.showCreateRoomModal })
    }

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    useEffect(() => {
        if (!formData.invitees.includes(user?.data?.pkid)) {
            setFormData({ ...formData, invitees: [...formData.invitees, user?.data?.pkid] })
        }
    }, [])

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1>Enter Room Name [The maximum number of invitees is 5]</h1>
                            <p>Invitees : {formData.invitees.includes(user?.data?.pkid) ? formData.invitees.length - 1 : formData.invitees.length}</p>
                        </div>

                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                // dispatch(setDrugCategory({ data: null }))
                                modals.showCreateRoomModal && setModals({ ...modals, showCreateRoomModal: !modals.showCreateRoomModal })

                            }}
                            size={20}
                        />
                    </div>


                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                name="roomName"
                                required
                                placeholder="Room name"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>
                        <div className="bg-white max-h-[250px] w-full  overflow-y-auto my-2 patient__prescriptions" >
                            {filtered__users?.map((user => (
                                <div
                                    key={`${user.id}`}
                                    className={`flex mb-2 items-center justify-between border-b p-1 cursor-pointer group bg-s;ate-200 border-b-white`}

                                >
                                    {console.log(user)}
                                    <div className='flex space-x-4'>
                                        <img
                                            src={`${process.env.REACT_APP_API_ROOT}${user?.user_profile?.profile_photo}`}
                                            className="w-[40px] h-[40px] rounded-[50%] group-hover:translate-x-2 duration-500 ease-in-out"
                                            alt=""
                                        />
                                        <div className="flex flex-col group-hover:translate-x-2 duration-500 ease-in-out">
                                            <p className="text-[12px] text-gray-600 font-bold">{user.last_name} {user.first_name}</p>
                                            <p className="text-[10px] text-gray-600">{user?.email}</p>
                                        </div>
                                    </div>
                                    {!formData.invitees.includes(user?.pkid) && formData.invitees.length <= 5 && (
                                        <button
                                            className="inline-block w-[100px] px-6 py-2.5 bg-dark-blue text-white font-bold capitalize text-xs leading-tight shadow-md hover:scale-90 transition duration-500 ease"

                                            onClick={
                                                (e) => {
                                                    console.log(formData.invitees)
                                                    e.preventDefault()
                                                    setFormData({ ...formData, invitees: [...formData.invitees, user.pkid] })

                                                }
                                            }>
                                            Invite
                                        </button>
                                    )}
                                    {formData.invitees.includes(user?.pkid) && (
                                        <button
                                            className="inline-block w-[100px] px-6 py-2.5 bg-orange-400 text-white font-bold capitalize text-xs leading-tight shadow-md hover:scale-90 transition duration-500 ease"

                                            onClick={
                                                (e) => {
                                                    e.preventDefault()
                                                    setFormData({ ...formData, invitees: formData.invitees.filter(e => e !== user.pkid) })
                                                    // setScheduleButtonListener(scheduleButtonListener - 1)
                                                    // setFormData({ ...formData, invitees: [...formData.invitees, user.pkid] })

                                                }
                                            }>
                                            remove
                                        </button>
                                    )}
                                </div>
                            )))}
                        </div>
                        {formData.roomName && formData.invitees.length > 1 &&
                            <div className="mt-10">
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    Create
                                </button>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )

}

export default PharmacistCreateRoomModal