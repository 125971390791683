import { API, ZuumPayAPI } from "../config"


export const signIn = (formData) => API.post('/user/login/', formData)

export const activateUserAccount = (token) => API.put(`/administrator/activate_account/${token}/`, { 'Content-Type': 'application/json' })

export const getProfile = (token) => API.get('/profile/me/', { headers: { Authorization: `Bearer ${token}` } })

export const userDetails = (email, token) => API.get(`/user/details/${email}/`, { headers: { Authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': '*' } })

export const patchOldPassword = (formData, token) => API.patch('/user/update_password/', formData, { headers: { Authorization: `Bearer ${token}` } })
// export const updatePassword = (formData, token) => API.post('/auth/users/set_password/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const generateResetPasswordLink = (params) => API.get(`/user/reset_password/${params.email}/`)

export const patchUserPassword = (formData) => API.patch(`/user/reset_password/`, formData)

export const updatePasswordStatus = (token) => API.put('/user/password_status/', {}, { headers: { 'Authorization': `Bearer ${token}` } })

export const updateProfile = (formData, token) => API.patch(`/profile/update/`, formData, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const updateUserDetails = (formData, token) => API.patch(`/user/update_details/`, formData, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const patchProfilePhoto = (formData, token) => API.patch(`/profile/update/photo/`, formData, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const patchOnlineStatus = (formData, token) => API.patch(`/user/update_online_status/`, formData, { headers: { 'Authorization': `Bearer ${token}` } })

export const updateSocketId = (formData, token) => API.put('/user/update_socket_id/', formData, { headers: { 'Authorization': `Bearer ${token}` } })

export const generateCustomer = (formData) => API.post('/user/customer_signup/', formData,)

export const generateCustomerProfile = (formData) => API.patch('/profile/create_customerprofile/', formData,)

export const fetchSubscriptionPlan = () => API.get('/pharmacist/subscription_plan/')

export const fetchSMSBalance = (token) => API.get('/pharmacist/sms_balance/', { headers: { 'Authorization': `Bearer ${token}` } })

export const fetchPharmacyConfig = () => API.get('/pharmacist/pharmacy_config/')

export const patchPharmacyConfig = (formData) => API.patch('/pharmacist/pharmacy_config/', formData)

export const patchPharmacyLogo = (formData) => API.patch('/pharmacist/pharmacy_logo/', formData, { headers: { 'Content-Type': 'multipart/form-data' } })

export const fetchSubscriptionPlans = () => API.get('/pharmacist/subscription_plans/',)

export const fetchPlanUpgradeDetails = (params) => API.get(`/pharmacist/upgrade_plan/${params.title}`,)

export const fetchPlanRenewalDetails = (params) => API.get(`/pharmacist/renewal_plan/${params.title}`,)

export const fetchAdminDashboardInfo = (token) => API.get(`/pharmacist/admin_dashboard/`, { headers: { 'Authorization': `Bearer ${token}` } })

export const generateZuumpayCredentials = (formData) => ZuumPayAPI.post(`/pharmacy/initialize/`, formData)