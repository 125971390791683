import { createAsyncThunk } from '@reduxjs/toolkit'

import {
    fetchBedSpaces,
    fetchDoctorAppointments,
    fetchDoctorAssignedPatients,
    fetchDoctorPatients, fetchDoctors, fetchLaboratoryInvestigationCategories, fetchLaboratoryInvestigationSamples,
    fetchLaboratoryInvestigationTypes, fetchPatientDiagnosis, fetchPatientDiagnosisFiles, fetchPatientLaboratoryTests,
    fetchPatientOtherPrescriptions, fetchPatientPrescriptions, fetchPatientRadiologyTests,
    fetchRadiologyTestCategories,
    fetchRadiologyTestTypes, fetchTeam, fetchTeams, generateAdmitPatient, generateCustomerPrescription, generatePatientAppointment, generatePatientDiagnosis,
    generatePatientDiagnosisFile, generatePatientLaboratoryTest, generatePatientPrescription, generatePatientRadiologyTest,
    generateTeam, patchOtherPatientLaboratoryTest, patchOtherPatientRadiologyTest, patchPatientAppointment, patchPatientDiagnosis, patchPatientDoctor, patchPatientLaboratoryTest, patchPatientRadiologyTest, patchTeam, removeAdmitPatient, removeTeam,
} from '../routes/doctor.routes'


export const getDoctors = createAsyncThunk(
    'doctor/getDoctors',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDoctors(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getTeams = createAsyncThunk(
    'doctor/getTeams',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchTeams(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const createTeam = createAsyncThunk(
    'doctor/createTeam',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateTeam(formData, token)

            toast.success('Team created successfully')
            setModals({ ...modals, showDoctorCreateTeamModal: !modals.showDoctorCreateTeamModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occurred while creating a team')
            return rejectWithValue(null)
        }
    }
)


export const getTeam = createAsyncThunk(
    'doctor/getTeam',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchTeam(id, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const resetCurrentTeam = createAsyncThunk(
    'doctor/resetCurrentTeam',
    async (_, { rejectWithValue, getState }) => {
        try {

            return null
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const deleteTeam = createAsyncThunk(
    'doctor/deleteTeam',
    async ({ id, toast, modals, setModals, dispatch, setCurrentTeam }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeTeam(id, token)

            toast.success('Team created successfully')
            setModals({ ...modals, showDoctorDeleteTeamModal: !modals.showDoctorDeleteTeamModal })
            dispatch(setCurrentTeam({ data: null }))

            return id
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occurred while deleting a team')
            return rejectWithValue(null)
        }
    }
)


export const updateTeam = createAsyncThunk(
    'doctor/updateTeam',
    async ({ formData, toast, modals, setModals, dispatch, setCurrentTeam, resetCurrentTeam }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchTeam(formData, token)

            toast.success('Team updated successfully')
            setModals({ ...modals, showDoctorUpdateTeamModal: !modals.showDoctorUpdateTeamModal })

            dispatch(setCurrentTeam({ data: null }))
            dispatch(resetCurrentTeam())

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occurred while updating team')
            return rejectWithValue(null)
        }
    }
)


export const getDoctorPatients = createAsyncThunk(
    'doctor/getDoctorPatients',
    async ({ doc_name }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDoctorPatients(doc_name, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPatientDiagnosis = createAsyncThunk(
    'doctor/getPatientDiagnosis',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPatientDiagnosis(params, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const createDiagnosis = createAsyncThunk(
    'doctor/createDiagnosis',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePatientDiagnosis(formData, token)

            toast.success('Diagnosis created successfully')

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured generating diagnosis')
            return rejectWithValue(null)
        }
    }
)


export const resetNewDiagnosis = createAsyncThunk(
    'doctor/resetNewDiagnosis',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const updateDiagnosis = createAsyncThunk(
    'doctor/updateDiagnosis',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData)
            const { data } = await patchPatientDiagnosis(formData, token)

            toast.success('Diagnosis updated successfully')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured updating diagnosis')
            return rejectWithValue(null)
        }
    }
)


export const getPatientPrescriptions = createAsyncThunk(
    'doctor/getPatientPrescriptions',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPatientPrescriptions(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPatientOtherPrescriptions = createAsyncThunk(
    'doctor/getPatientOtherPrescriptions',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPatientOtherPrescriptions(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createPatientPrescription = createAsyncThunk(
    'doctor/createPatientPrescription',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            // const { data } = await generatePatientPrescription(formData, token)
            const { data } = await generateCustomerPrescription(formData, token)

            toast.success('Prescription generated successfully.')
            setModals({ ...modals, showDoctorAddPrescriptionModal: !modals.showDoctorAddPrescriptionModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while generating prescription.')
            return rejectWithValue(null)
        }
    }
)


export const createPatientOtherPrescription = createAsyncThunk(
    'doctor/createPatientOtherPrescription',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            // const { data } = await generatePatientPrescription(formData, token)
            const { data } = await generateCustomerPrescription(formData, token)

            toast.success('Prescription generated successfully.')
            setModals({ ...modals, showDoctorAddOtherPrescriptionModal: !modals.showDoctorAddOtherPrescriptionModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while generating prescription.')
            return rejectWithValue(null)
        }
    }
)


export const getLabInvestigationTypes = createAsyncThunk(
    'doctor/getLabInvestigationTypes',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvestigationTypes(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getLabInvestigationCategories = createAsyncThunk(
    'doctor/getLabInvestigationCategories',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvestigationCategories(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getLabInvestigationSamples = createAsyncThunk(
    'doctor/getLabInvestigationSamples',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvestigationSamples(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const createPatientLabTest = createAsyncThunk(
    'doctor/createPatientLabTest',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePatientLaboratoryTest(formData, token)

            toast.success('Test request generated successfully.')
            setModals({ ...modals, showDoctorAddTestModal: !modals.showDoctorAddTestModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating test request.')
            return rejectWithValue(null)
        }
    }
)


export const updatePatientLabTest = createAsyncThunk(
    'doctor/updatePatientLabTest',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPatientLaboratoryTest(formData, token)

            toast.success('Test report updated successfully.')
            setModals({ ...modals, showDoctorWriteLabReportModal: !modals.showDoctorWriteLabReportModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating test report.')
            return rejectWithValue(null)
        }
    }
)


export const uploadOtherPatientLabTest = createAsyncThunk(
    'doctor/uploadOtherPatientLabTest',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchOtherPatientLaboratoryTest(formData, token)

            toast.info('Test report uploaded successfully.')
            setModals({ ...modals, showDoctorUploadOtherLabReportModal: !modals.showDoctorUploadOtherLabReportModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while uploading test report.')
            return rejectWithValue(null)
        }
    }
)


export const createOtherPatientLabTest = createAsyncThunk(
    'doctor/createOtherPatientLabTest',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePatientLaboratoryTest(formData, token)

            toast.success('Test request generated successfully.')
            setModals({ ...modals, showDoctorAddOtherTestModal: !modals.showDoctorAddOtherTestModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating test request.')
            return rejectWithValue(null)
        }
    }
)


export const getPatientLabTests = createAsyncThunk(
    'doctor/getPatientLabTests',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPatientLaboratoryTests(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyInvestigationTypes = createAsyncThunk(
    'doctor/getRadiologyInvestigationTypes',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyTestTypes(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyInvestigationCategories = createAsyncThunk(
    'doctor/getRadiologyInvestigationCategories',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyTestCategories(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getPatientRadioTests = createAsyncThunk(
    'doctor/getPatientRadioTests',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPatientRadiologyTests(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createPatientRadioTest = createAsyncThunk(
    'doctor/createPatientRadioTest',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePatientRadiologyTest(formData, token)

            toast.success('Test request generated successfully.')
            setModals({ ...modals, showDoctorAddRadiologyTestModal: !modals.showDoctorAddRadiologyTestModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating test request.')
            return rejectWithValue(null)
        }
    }
)


export const updatePatientRadioTest = createAsyncThunk(
    'doctor/updatePatientRadioTest',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPatientRadiologyTest(formData, token)

            toast.success('Radiology report updated successfully.')
            setModals({ ...modals, showDoctorWriteRadiologyReportModal: !modals.showDoctorWriteRadiologyReportModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating radiology report.')
            return rejectWithValue(null)
        }
    }
)


export const uploadOtherPatientRadioTest = createAsyncThunk(
    'doctor/uploadOtherPatientRadioTest',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchOtherPatientRadiologyTest(formData, token)

            toast.info('Test report uploaded successfully.')
            setModals({ ...modals, showDoctorUploadOtherRadiologyReportModal: !modals.showDoctorUploadOtherRadiologyReportModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while uploading test report.')
            return rejectWithValue(null)
        }
    }
)


export const createOtherPatientRadioTest = createAsyncThunk(
    'doctor/createOtherPatientRadioTest',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePatientRadiologyTest(formData, token)

            toast.success('Test request generated successfully.')
            setModals({ ...modals, showDoctorAddOtherRadiologyTestModal: !modals.showDoctorAddOtherRadiologyTestModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating test request.')
            return rejectWithValue(null)
        }
    }
)


export const uploadPatientFile = createAsyncThunk(
    'doctor/uploadPatientFile',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePatientDiagnosisFile(formData, token)

            toast.success('File uploaded successfully.')
            setModals({ ...modals, showDoctorUploadPatientFileModal: !modals.showDoctorUploadPatientFileModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while uploading file.')
            return rejectWithValue(null)
        }
    }
)


export const getPatientFileUploads = createAsyncThunk(
    'doctor/getPatientFileUploads',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPatientDiagnosisFiles(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const transferPatient = createAsyncThunk(
    'doctor/transferPatient',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPatientDoctor(formData, token)

            toast.success('Transfered patient successfully.')
            setModals({ ...modals, showDoctorTransferPatientModal: !modals.showDoctorTransferPatientModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while transferring patient.')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentDiagnosis = createAsyncThunk(
    'doctor/setCurrentDiagnosis',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const requestAdmitPatient = createAsyncThunk(
    'doctor/requestAdmitPatient',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateAdmitPatient(formData, token)

            toast.success('Patient admission request generated successfully.')
            setModals({ ...modals, showDoctorRequestPatientAdmissionModal: !modals.showDoctorRequestPatientAdmissionModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating request.')
            return rejectWithValue(null)
        }
    }
)


export const cancelAdmitPatient = createAsyncThunk(
    'doctor/cancelAdmitPatient',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await removeAdmitPatient(formData, token)

            toast.success('Patient admission request canceled successfully.')
            modals.showDoctorCancelRequestPatientAdmissionModal && setModals({ ...modals, showDoctorCancelRequestPatientAdmissionModal: !modals.showDoctorCancelRequestPatientAdmissionModal })
            modals.showDoctorDischargePatientAdmissionModal && setModals({ ...modals, showDoctorDischargePatientAdmissionModal: !modals.showDoctorDischargePatientAdmissionModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while canceling request.')
            return rejectWithValue(null)
        }
    }
)


export const getWardBedSpaces = createAsyncThunk(
    'doctor/getWardBedSpaces',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchBedSpaces(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getAppointments = createAsyncThunk(
    'doctor/getAppointments',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDoctorAppointments(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createPatientAppointment = createAsyncThunk(
    'doctor/createPatientAppointment',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePatientAppointment(formData, token)

            toast.success('Appointment generated successfully.')
            setModals({ ...modals, showDoctorBookAppointmentModal: !modals.showDoctorBookAppointmentModal })

            return data
        } catch (error) {
            console.log(error)
            if (error.response.data.max_count) {
                toast.info('Sorry, you have maximum appointments for this date.')
                return rejectWithValue(null)
            } else {
                toast.warning('An error occured while generating appointment.')
                return rejectWithValue(null)
            }
        }
    }
)


export const updatePatientAppointment = createAsyncThunk(
    'doctor/updatePatientAppointment',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPatientAppointment(formData, token)

            toast.success('Appointment updated successfully.')
            setModals({ ...modals, showDoctorUpdateAppointmentModal: !modals.showDoctorUpdateAppointmentModal })

            console.log(data)
            return data
        } catch (error) {
            console.log(error)
            if (error.response.data.max_count) {
                toast.info('Sorry, you have maximum appointments for this date.')
                return rejectWithValue(null)
            } else if (error.response.data.message === 'Appointment already exists for this date') {
                toast.info('Sorry, an appointment already exists for this date.')
                return rejectWithValue(null)
            } else {
                toast.warning('An error occured while udating appointment.')
                return rejectWithValue(null)
            }
        }
    }
)


export const getAssignedPatients = createAsyncThunk(
    'doctor/getAssignedPatients',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDoctorAssignedPatients(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)
