/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState, useRef } from 'react'
import { io } from 'socket.io-client'
import { useDispatch, useSelector } from 'react-redux'
import Peer from 'simple-peer'

import MessageAlert from '../../assets/audio/message__alert__2.mp3'
import { sendARandomEmailMessage } from '../actions/admin.actions'
import { getChatConversations, setActiveChatState } from '../actions/chat.actions'
import { UsersAllNotifications, createUserNotification } from '../actions/notifications.actions'
import { root__modals, root__appstate, root__opdstate, root__chatstate } from '../../data'
import { updateUserSocketId } from '../actions/auth.actions'
import { getUsers } from '../actions/chat.actions'


let socket = 0
const Context = createContext()
const SOCKET_URL = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_CHAT_SERVER_DEV : process.env.REACT_APP_CHAT_SERVER_PROD

export const StateContext = ({ children }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)
    const { sent_email, sent_sms } = useSelector(state => state.admin)
    const { current_chatuser, chat_message, chat_conversation, groupchat_conversation } = useSelector(state => state.chat)

    const playNotificationSound = () => {
        new Audio(MessageAlert).play()
    }

    const myVideo = useRef()
    const userVideo = useRef()
    const connectionRef = useRef()

    const [me, setMe] = useState('')
    const [name, setName] = useState('')
    const [call, setCall] = useState({})
    const [stream, setStream] = useState()
    const [getUserMedia, setGetUserMedia] = useState(false)
    const [callEnded, setCallEnded] = useState(false)
    const [callAccepted, setCallAccepted] = useState(false)
    const [modals, setModals] = useState({ ...root__modals })
    const [appState, setAppState] = useState({ ...root__appstate })
    const [chatState, setChatState] = useState({ ...root__chatstate })
    const [opdState, setOpdState] = useState({ ...root__opdstate })
    const [isStreamSet, setIsStreamSet] = useState(false)

    useEffect(() => {
        socket = io(SOCKET_URL)

        return () => {
            socket.disconnect()
            socket.off()
        }
    }, [])

    useEffect(() => {
        socket.emit('web_signin', ({ id: user?.data?.pkid, message: `${user?.data?.first_name} ${user?.data?.last_name} signed in` }))
    }, [user, socket])

    useEffect(() => {
        if (callEnded) {
            window.location.reload()
        }

    }, [callEnded])

    useEffect(() => {
        socket.on('command', (data) => {
            const config = {
                status: true,
                conversation: data.chat_conversation
            }
            playNotificationSound()
            dispatch(getChatConversations())
            dispatch(setActiveChatState({ data: config }))
        })

        socket.on('new_group', (data) => {
            dispatch(getChatConversations())
        })

        socket.on('group_message', ({ message }) => {
            const config = {
                status: true,
                conversation: message.conversation_id
            }
            dispatch(getChatConversations())
            dispatch(setActiveChatState({ data: config }))
        })

        socket.on('chat_media', (content) => {
            const config = {
                status: true,
                conversation: content?.conversation_id
            }
            dispatch(getChatConversations())
            dispatch(setActiveChatState({ data: config }))
        })

        socket.on('groupchat_media', (content) => {
            const config = {
                status: true,
                conversation: content?.conversation_id
            }
            dispatch(getChatConversations())
            dispatch(setActiveChatState({ data: config }))
        })

        socket.on("email_notification", (data) => {
            const id = data['message'].id
            dispatch(UsersAllNotifications())
        })

        socket.on("message_notification", (data) => {
            const id = data['message'].id
            dispatch(UsersAllNotifications())
        })

        socket.on("book_appointment_notification", (data) => {
            dispatch(sendARandomEmailMessage({ formData: data, dispatch, createUserNotification }))
        })

        socket.on("assign_doctor_notification", (data) => {
            dispatch(sendARandomEmailMessage({ formData: data, dispatch, createUserNotification }))
        })

        socket.on('user_disconnected', ({ status }) => {
            dispatch(getChatConversations())
        })
    }, [chat_message, current_chatuser, chat_conversation, groupchat_conversation, sent_email, sent_sms])

    useEffect(() => {
        if (getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then((currentStream) => {
                    setStream(currentStream)
                    setIsStreamSet(true)
                    myVideo.current.srcObject = currentStream
                })
        }

        socket.on('me', (id) => {
            const formData = { socket_id: id }

            setMe(id)
            setName(`${user?.data?.first_name} ${user?.data?.last_name}`)

            dispatch(getUsers())
            dispatch(updateUserSocketId({ formData }))
        });
        socket.on('callUser', ({ from, name: callerName, signal }) => {
            console.log(from, signal)

            setCall({ isReceivingCall: true, from, name: callerName, signal })
            setModals({ ...modals, showIncomingCallModal: !modals.showIncomingCallModal })
        })
    }, [getUserMedia, isStreamSet])

    const answerCall = () => {
        setCallAccepted(true)
        console.log("Call has been picked")
        const peer = new Peer({ initiator: false, trickle: false, stream })
        // const peer = new window.SimplePeer({ initiator: false, trickle: false, stream })

        peer.on('signal', (data) => {
            socket.emit('answerCall', { signal: data, to: call.from })
        })

        peer.on('stream', (currentStream) => {
            userVideo.current.srcObject = currentStream
        })

        peer.signal(call.signal)

        connectionRef.current = peer
    }

    const callUser = (id) => {
        const peer = new Peer({ initiator: true, trickle: false, stream })

        peer.on('signal', (data) => {
            socket.emit('callUser', { userToCall: id, signalData: data, from: me, name })
        })

        peer.on('stream', (currentStream) => {
            userVideo.current.srcObject = currentStream
        })

        socket.on('callAccepted', (signal) => {
            setCallAccepted(true)

            peer.signal(signal)
        })

        connectionRef.current = peer
    }

    const leaveCall = () => {
        setCallEnded(true)
        connectionRef.current.destroy()
    }

    return (
        <Context.Provider value={{
            socket,
            appState,
            setAppState,
            modals,
            setModals,
            opdState,
            setOpdState,
            chatState,
            setChatState,
            call,
            callAccepted,
            myVideo,
            userVideo,
            stream,
            name,
            setName,
            callEnded,
            me,
            setMe,
            callUser,
            leaveCall,
            answerCall,
            getUserMedia,
            setGetUserMedia,

        }}>
            {children}
        </Context.Provider>
    )
}


export const useStateContext = () => useContext(Context)