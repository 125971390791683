/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { customer__payment__methods } from '../../../data'
import { createFlutterWaveConfig, createPayStackConfig, setCurrentRecordsInvoice } from '../../../state/actions/collection.actions'


const CustomerPaymentMethodModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { data } = useSelector(state => state.auth.user)
    const { profile } = useSelector(state => state.auth)
    const { paystack_config, collectionLoading, flutterwave_config } = useSelector(state => state.collection)
    const { current_customer_invoice } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState({ payment_method: '', email: '', amount: 0, phone: '', fullname: '' })

    useEffect(() => {
        setFormData({
            ...formData,
            email: data?.email,
            amount: current_customer_invoice?.invoice_amount,
            phone: profile?.patient_phone,
            fullname: data?.last_name.concat(' ').concat(data?.first_name)
        })
    }, [data, profile, current_customer_invoice])

    useEffect(() => {
        if (paystack_config?.authorization_url) {
            localStorage.setItem('payment__invoice', JSON.stringify({ ...current_customer_invoice, type: 'pharmacy_payment' }))

            window.location.href = paystack_config?.authorization_url
        }
        if (flutterwave_config?.link) {
            localStorage.setItem('payment__invoice', JSON.stringify({ ...current_customer_invoice, type: 'pharmacy_payment' }))

            window.location.href = flutterwave_config?.link
        }
    }, [paystack_config, flutterwave_config])

    const handleChange = (name, e) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (formData.payment_method === 'paystack') {
            dispatch(createPayStackConfig({ formData }))
        }

        if (formData.payment_method === 'flutterwave') {
            dispatch(createFlutterWaveConfig({ formData }))
        }
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div
                className="absolute right-[4em] top-0 text-[60px] font-medium text-white cursor-pointer"
                onClick={() => {
                    dispatch(setCurrentRecordsInvoice({ data: null }))
                    setModals({ ...modals, showCustomerPaymentMethodModal: !modals.showCustomerPaymentMethodModal })
                }}
            >
                x
            </div>

            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-500 text-[20px] font-bold'>
                            {formData.payment_method ? formData.payment_method[0].toUpperCase().concat(formData.payment_method.slice(1, formData.payment_method.length)) : 'Payment Method'}
                        </h4>
                    </div>

                    {formData.payment_method !== 'cash' && formData.payment_method !== 'pos' && (
                        <form onSubmit={handleSubmit}>
                            <div className='space-y-2'>
                                <div className="relative">
                                    <select
                                        className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                        name="payment_method"
                                        onChange={(e) => handleChange(e.target.name, e)}
                                    >
                                        <option>Payment Method...</option>
                                        {customer__payment__methods?.map((type, index) => (
                                            <option
                                                key={`${type.id}-${index}`}
                                                value={type.value}
                                            >
                                                {type.title}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-start mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-full px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!collectionLoading ? (
                                        'Submit'
                                    ) : (
                                        <svg className="flex justify-center w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                            </path>
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )
}
export default CustomerPaymentMethodModal

