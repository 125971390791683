import { Fragment } from 'react'

import CollectionsLaboratoryPaymentMethodModal from '../../modals/collections/CollectionsLaboratoryPaymentMethodModal'
import CollectionsPharmacyPaymentMethodModal from '../../modals/collections/CollectionsPharmacyPaymentMethodModal'
import CollectionsRadiologyPaymentMethodModal from '../../modals/collections/CollectionsRadiologyPaymentMethodModal'
import CollectionsRecordsPaymentMethodModal from '../../modals/collections/CollectionsRecordsPaymentMethodModal'
import CollectionsViewLabInvoiceModal from '../../modals/collections/CollectionsViewLabInvoiceModal'
import CollectionsViewPharmacyInvoiceModal from '../../modals/collections/CollectionsViewPharmacyInvoiceModal'
import CollectionsViewRadiologyInvoiceModal from '../../modals/collections/CollectionsViewRadiologyInvoiceModal'
import CollectionsViewRecordsInvoiceModal from '../../modals/collections/CollectionsViewRecordsInvoiceModal'
import CollectionsInvestigationsPaymentMethodModal from '../../modals/collections/CollectionsInvestigationsPaymentMethodModal'
import CollectionsViewInvestigationInvoiceModal from '../../modals/collections/CollectionsViewInvestigationInvoiceModal'
import CollectionsViewPharmacyReportsModal from '../../modals/collections/CollectionsViewPharmacyReportsModal'
import CollectionsViewInvestigationReportsModal from '../../modals/collections/CollectionsViewInvestiationReportsModal'
import CollectionsPrintInvoiceModal from '../../modals/collections/CollectionPrintInvoiceModal'
import CollectionsPayPharmacyMultipleInvoiceModal from '../../modals/collections/CollectionsPayPharmacyMultipleInvoiceModal'
import CollectionsPayInvestigsationMultipleInvoicesModal from '../../modals/collections/CollectionsPayInvestigsationMultipleInvoicesModal'
import CollectionsPrintMultipleInvoiceModal from '../../modals/collections/CollectionsPrintMultipleInvoiceModal'
import CollectionsPrintMultipleInvestigationsInvoiceModal from '../../modals/collections/CollectionsPrintMultipleInvestigationsInvoiceModal'


const CollectionsFormModals = (props) => {
    return (
        <Fragment>
            {props.type === 'collections__laboratory__paymentmethod' && (
                <CollectionsLaboratoryPaymentMethodModal />
            )}

            {props.type === 'collections__pharmacy__paymentmethod' && (
                <CollectionsPharmacyPaymentMethodModal />
            )}

            {props.type === 'collections__investigations__paymentmethod' && (
                <CollectionsInvestigationsPaymentMethodModal />
            )}

            {props.type === 'collections__radiology__paymentmethod' && (
                <CollectionsRadiologyPaymentMethodModal />
            )}

            {props.type === 'collections__records__paymentmethod' && (
                <CollectionsRecordsPaymentMethodModal />
            )}

            {props.type === 'collections__view__labinvoice' && (
                <CollectionsViewLabInvoiceModal />
            )}

            {props.type === 'collections__view__pharmacyinvoice' && (
                <CollectionsViewPharmacyInvoiceModal />
            )}

            {props.type === 'collections__view__investigationinvoice' && (
                <CollectionsViewInvestigationInvoiceModal />
            )}

            {props.type === 'collections__view__radiologyinvoice' && (
                <CollectionsViewRadiologyInvoiceModal />
            )}

            {props.type === 'collections__view__recordsinvoice' && (
                <CollectionsViewRecordsInvoiceModal />
            )}

            {props.type === 'collections__view__pharmarcyreports' && (
                <CollectionsViewPharmacyReportsModal />
            )}

            {props.type === 'collections__view__investigationreports' && (
                <CollectionsViewInvestigationReportsModal />
            )}

            {props.type === 'collections__print__invoice' && (
                <CollectionsPrintInvoiceModal />
            )}

            {props.type === 'collections__pay__pharmacy_multiple_invoice' && (
                <CollectionsPayPharmacyMultipleInvoiceModal />
            )}

            {props.type === 'collections__pay__investigation_multiple_invoice' && (
                <CollectionsPayInvestigsationMultipleInvoicesModal />
            )}
            {props.type === 'collections__print_multiple__invoices' && (
                <CollectionsPrintMultipleInvoiceModal />
            )}
            {props.type === 'collections__print_multiple__investigations__invoices' && (
                <CollectionsPrintMultipleInvestigationsInvoiceModal />
            )}


        </Fragment>
    )
}
export default CollectionsFormModals