/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { TbReportAnalytics } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { PageTitle } from '..'
import { useStateContext } from '../../state/context'
import Pagination from '../utils/Pagination'


const PharmacistClinicalRepositoryDetailsTable = ({ setViewClinicalDataDetail, viewClinicalDataDetail }) => {
    const [buttonColorIndex, setButtonColorIndex] = useState(1)
    // const [showSymptomsTable, setShowSymptomsTable] = useState(true)
    // const [showPreventionTable, setShowPreventionTable] = useState(false)
    // const [showInvestigationTable, setShowInvestigationTable] = useState(false)

    const { current_clinical_data } = useSelector(state => state.root)

    return (
        <div>

            <div className='flex flex-col w-full cursor-pointer'>
                <div className='py-2'>
                    <div className='flex space-x-2 justify-end mb-2'>
                        <button
                            type="button"
                            className={`inline-block px-4 py-2 border ${buttonColorIndex === 1 ? 'bg-blue-400 text-white' : ' border-teal-600 text-teal-600 '} font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out`}
                            onClick={() => {
                                setButtonColorIndex(1)

                            }}
                        >
                            Symptoms
                        </button>
                        <button
                            type="button"
                            className={`inline-block px-4 py-2 border ${buttonColorIndex === 2 ? 'bg-blue-400 text-white' : ' border-teal-600 text-teal-600 '} font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out`}
                            onClick={() => {
                                setButtonColorIndex(2)

                            }}
                        >
                            Drug Treament
                        </button>
                        <button
                            type="button"
                            className={`inline-block px-4 py-2 border ${buttonColorIndex === 3 ? 'bg-blue-400 text-white' : ' border-teal-600 text-teal-600 '} font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out`}
                            onClick={() => {
                                setButtonColorIndex(3)

                            }}
                        >
                            Preventions
                        </button>
                        <button
                            type="button"
                            className={`inline-block px-4 py-2 border ${buttonColorIndex === 4 ? 'bg-blue-400 text-white' : ' border-teal-600 text-teal-600 '} font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out`}
                            onClick={() => {
                                setButtonColorIndex(4)

                            }}
                        >
                            Investigations
                        </button>
                        <button
                            type="button"
                            className={`inline-block px-4 py-2 border ${buttonColorIndex === 5 ? 'bg-blue-400 text-white' : ' border-teal-600 text-teal-600 '} font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out`}
                            onClick={() => {
                                setButtonColorIndex(5)

                            }}
                        >
                            Complications
                        </button>
                        <button
                            type="button"
                            className="inline-block px-4 py-2 border border-teal-600 text-teal-600 font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={() => {
                                setViewClinicalDataDetail(!viewClinicalDataDetail)
                            }}
                        >
                            Exit
                        </button>

                    </div>
                    <div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
                        <h4 className='text-dark-blue italic text-[14px] font-bold'>
                            {current_clinical_data?.diagnosis_name}
                        </h4>
                        <TbReportAnalytics
                            className='text-dark-blue'
                            size={20}
                        />
                        {/* <input className='ml-14 px-3 w-[500px] font-light rounded-sm text-[12px] italic' type="search" name="searchBox" placeholder='Enter symptoms to search' id="" /> */}
                    </div>
                    {/* Symtoms and Drug Treatment */}
                    {buttonColorIndex === 1 && <table className='border rounded w-full cursor-default'>
                        <thead className=''>
                            <tr>
                                <th
                                    scope='col'
                                    className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 text-left capitalize'
                                >

                                </th>


                            </tr>
                        </thead>
                        <tbody>

                            <tr
                                className={`relative w-full `}

                            // key={index}
                            >
                                <td className='px-4 py-2  text-sm font-light text-gray-900'>
                                    {current_clinical_data?.symptoms}
                                </td>
                            </tr>

                        </tbody>
                    </table>}

                    {buttonColorIndex === 2 && <table className='border rounded w-full cursor-default'>
                        <thead className=''>
                            <tr>
                                <th
                                    scope='col'
                                    className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 text-left capitalize'
                                >

                                </th>


                            </tr>
                        </thead>
                        <tbody>

                            <tr
                                className={`relative w-full  `}

                            // key={index}
                            >
                                <td className='px-4 py-2   text-sm font-light text-gray-900'>
                                    {current_clinical_data?.drug_treatment}
                                </td>
                            </tr>

                        </tbody>
                    </table>}

                    {buttonColorIndex === 3 && <table className='border rounded w-full cursor-default'>
                        <thead className=''>
                            <tr>
                                <th
                                    scope='col'
                                    className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 text-left capitalize'
                                >

                                </th>


                            </tr>
                        </thead>
                        <tbody>

                            <tr
                                className={`relative w-full `}

                            // key={index}
                            >
                                <td className='px-4 py-2  text-sm font-light text-gray-900'>
                                    {current_clinical_data?.prevention}
                                </td>
                            </tr>

                        </tbody>
                    </table>}
                    {buttonColorIndex === 4 && <table className='border rounded w-full cursor-default'>
                        <thead className=''>
                            <tr>
                                <th
                                    scope='col'
                                    className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 text-left capitalize'
                                >

                                </th>


                            </tr>
                        </thead>
                        <tbody>

                            <tr
                                className={`relative w-full `}

                            // key={index}
                            >
                                <td className='px-4 py-2  text-sm font-light text-gray-900'>
                                    {current_clinical_data?.investigations}
                                </td>
                            </tr>

                        </tbody>
                    </table>}

                    {buttonColorIndex === 5 && <table className='border  rounded w-full cursor-default'>
                        <thead className=''>
                            <tr>
                                <th
                                    scope='col'
                                    className='text-[10px] overflow-y-auto font-medium text-gray-900 pr-[4em] px-4 text-left capitalize'
                                >

                                </th>


                            </tr>
                        </thead>
                        <tbody>

                            <tr
                                className={`relative w-full `}

                            // key={index}
                            >
                                <td className='px-4 py-2 text-sm font-light text-gray-900'>
                                    {current_clinical_data?.complications}
                                </td>
                            </tr>

                        </tbody>
                    </table>}

                </div>
            </div>

        </div>
    )
}

export default PharmacistClinicalRepositoryDetailsTable