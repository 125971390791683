import React, { useState } from "react"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { FaTimesCircle } from "react-icons/fa"

import { useStateContext } from '../../../state/context'
import { createPharmacyStaff } from "../../../state/actions/pharmacist.actions"
import ButtonLoader from "../../utils/ButtonLoader"
import { useSelector } from "react-redux"

const PharmacistAddStaffModal = (props) => {
	const { modals, setModals } = useStateContext()
	const dispatch = useDispatch()

	const [checkActive, setCheckActive] = useState(false)
	const [checkIsSales, setCheckIsSales] = useState(false)
	const [checkIsCollections, setCheckIsCollections] = useState(false)
	const [checkIsPharmacist, setCheckIsPharmacist] = useState(false)
	const [checkIsCourier, setCheckIsCourier] = useState(false)
	const [checkIsDepartmentHead, setCheckIsDepartmentHead] = useState(false)
	const { pharmacistLoading } = useSelector((state) => state.pharmacist)



	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		is_active: false,
		is_courier: false,
		is_sales: false,
		is_pharmacist: false,
		is_collections: false,
		is_department_head: false,
	})

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		console.log(formData)
		if (!formData.first_name && !formData.last_name && !formData.email) return toast.warning('All fields are required!')

		dispatch(createPharmacyStaff({ formData, toast, setModals, modals }))
	}


	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white rounded px-[30px] py-[30px] w-[500px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className="text-dark-green text-[20px] font-bold">
							{modals.showPharmacistAddStaffModal ? 'Add Staff' : 'Update Staff'}
						</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								modals.showPharmacistAddStaffModal && setModals({ ...modals, showPharmacistAddStaffModal: !modals.showPharmacistAddStaffModal })
							}}
							size={20}
						/>
					</div>

					{modals.showPharmacistAddStaffModal && (
						<form onSubmit={handleSubmit} className="space-y-3">
							<div className="flex justify-between-items-center space-x-10 w-full">
								<div className="space-y-2 w-full">
									<div>
										<input
											type="text"
											name="first_name"
											placeholder="First name"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="text"
											name="last_name"
											placeholder="Last name"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="email"
											name="email"
											placeholder="Email address"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div className="grid grid-cols-3 gap-5 py-6">
										<div className="flex items-center space-x-3">
											<input
												className="rounded border-4 w-5 h-5 border-red-400"
												type="checkbox"
												name="is_active"
												checked={checkActive}
												onChange={() => {
													setCheckActive(!checkActive)
													setFormData({ ...formData, is_active: !checkActive })
												}}
											/>
											<p className="text-[12px] text-gray-700 font-bold">Status</p>
										</div>

										<div className="flex items-center space-x-3">
											<input
												className="rounded border-4 w-5 h-5 border-red-400"
												type="checkbox"
												name="is_active"
												checked={checkIsPharmacist}
												onChange={() => {
													setCheckIsPharmacist(!checkIsPharmacist)
													setFormData({ ...formData, is_pharmacist: !checkIsPharmacist })
												}}
											/>
											<p className="text-[12px] text-gray-700 font-bold">Pharmacist</p>
										</div>

										<div className="flex items-center space-x-3">
											<input
												className="rounded border-4 w-5 h-5 border-red-400"
												type="checkbox"
												name="is_active"
												checked={checkIsCollections}
												onChange={() => {
													setCheckIsCollections(!checkIsCollections)
													setFormData({ ...formData, is_collections: !checkIsCollections })
												}}
											/>
											<p className="text-[12px] text-gray-700 font-bold">Accountant</p>
										</div>

										<div className="flex items-center space-x-3">
											<input
												className="rounded border-4 w-5 h-5 border-red-400"
												type="checkbox"
												name="is_active"
												checked={checkIsSales}
												onChange={() => {
													setCheckIsSales(!checkIsSales)
													setFormData({ ...formData, is_sales: !checkIsSales })
												}}
											/>
											<p className="text-[12px] text-gray-700 font-bold">Sales</p>
										</div>

										<div className="flex items-center space-x-3">
											<input
												className="rounded border-4 w-5 h-5 border-red-400"
												type="checkbox"
												name="is_active"
												checked={checkIsDepartmentHead}
												onChange={() => {
													setCheckIsDepartmentHead(!checkIsDepartmentHead)
													setFormData({ ...formData, is_department_head: !checkIsDepartmentHead })
												}}
											/>
											<p className="text-[12px] text-gray-700 font-bold">Admin</p>
										</div>

										<div className="flex items-center space-x-3">
											<input
												className="rounded border-4 w-5 h-5 border-red-400"
												type="checkbox"
												name="is_active"
												checked={checkIsCourier}
												onChange={() => {
													setCheckIsCourier(!checkIsCourier)
													setFormData({ ...formData, is_courier: !checkIsCourier })
												}}
											/>
											<p className="text-[12px] text-gray-700 font-bold">Courier</p>
										</div>
									</div>
								</div>
							</div>

							<div className="mt-10 flex justify-start">

								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									{!pharmacistLoading ? ("Submit") : (
										<ButtonLoader />
									)}
								</button>


							</div>
						</form>
					)}

				</div>
			</div>
		</div>
	)
}

export default PharmacistAddStaffModal
