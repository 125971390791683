/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { BiPhone } from "react-icons/bi"
import { useSelector } from "react-redux"
import { MdLocationPin } from "react-icons/md"
import { FaTimesCircle } from "react-icons/fa"

import { OrderStatus } from "../../utils/PaymentStatus"
import { useStateContext } from "../../../state/context"


const PharmacistViewOrderModal = () => {

    const { modals, setModals } = useStateContext()
    const { current_order } = useSelector(state => state.pharmacist)


    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`w-[500px] bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-800 text-[18px] font-medium capitalize`}>
                            Order <span className="text-[12px] text-teal-600 underline">{current_order?.order_id}</span>
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showPharmacistViewOrderModal: !modals.showPharmacistViewOrderModal })
                            }}
                            size={20}
                        />
                    </div>

                    <div className="space-y-2">
                        <div className="relative space-y-1">
                            <label className="bg-slate-600 text-white px-4 py-[1px] text-[10px] rounded">Customer</label>
                            <input
                                className="border-b border-b-slate-600 w-full text-[12px] px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                disabled
                                value={`${current_order?.customer_details?.first_name} ${current_order?.customer_details?.last_name}`}
                            />
                        </div>

                        <div className="relative space-y-1">
                            <label className="bg-slate-600 text-white px-4 py-[1px] text-[10px] rounded">Item</label>

                            {current_order?.invoice_details?.map((e, index) => (
                                <input
                                    className="border-b border-b-slate-600 w-full text-[12px] px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                    disabled
                                    value={e?.item}
                                />))}
                        </div>

                        <div className="relative space-y-1">
                            <label className="bg-slate-600 text-white px-4 py-[1px] text-[10px] rounded">Amount (&#x20A6;)</label>
                            <input
                                className="border-b border-b-slate-600 w-full text-[12px] px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                disabled
                                value={`${current_order?.total}`}
                            />
                        </div>

                        <div className="relative">
                            <label className="bg-slate-600 text-white px-4 py-[1px] text-[10px] rounded">Delivery Address</label>
                            <textarea
                                className="border-b border-b-slate-600 w-full px-4 py-2 text-[12px] h-max mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                disabled
                                value={current_order?.delivery_address}
                            ></textarea>
                        </div>

                        <div className="relative w-[200px] space-y-1">
                            <label className="bg-sky-600 text-white px-4 py-[1px] text-[10px] rounded">Status</label>
                            <OrderStatus status={current_order?.status} />
                        </div>

                        <div className="relative space-y-1">
                            <label className="bg-sky-600 text-white px-4 py-[1px] text-[10px] rounded">Delivery Time</label>
                            <input
                                className="border-b border-b-sky-600 w-full text-[12px] px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                disabled
                                value={current_order?.delivery_time ? current_order?.delivery_time : 'Processing...'}
                            />
                        </div>

                        <div className="relative space-y-1">
                            <label className="bg-sky-600 text-white px-4 py-[1px] text-[10px] rounded">Courier</label>
                            <input
                                className="border-b border-b-sky-600 w-full text-[12px] px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                disabled
                                value={current_order?.order_courier ? `${current_order?.courier_details.first_name} ${current_order?.courier_details.last_name}` : 'Processing...'}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PharmacistViewOrderModal
