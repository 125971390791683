/* eslint-disable no-unreachable */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    customerFetchDrugs, customerFetchInvoices, customerFetchOrders, customerFetchPrescriptions, customerGenerateOrder,
    customerGeneratePrescription, customerGenerateProvisionOrder, fetchCouriers, fetchDrugBinCards, fetchDrugCategories,
    fetchDrugPrices, fetchDrugs, fetchOrders, fetchPaidPrescriptions, fetchPharmacistProfiles, fetchPharmacyStaffs,
    fetchPrescriptionInvoices, fetchPrescriptions, fetchProvisionInvoices, fetchProvisions, fetchSupplyPointBinCards,
    fetchSupplyPointInventories, fetchSupplyPointInventoryBinCards, fetchSupplyPoints, fetchSupplyPointStaff, generateDrug,
    generateDrugBinCard, generateDrugCategory, generateDrugDispense, generateInventoryForSupplyPoint, generatePharmacyStaff,
    generatePrescription, generateInvoice, generateProvision, generateProvisionInvoice, generateSupplyPoint, issueDrugOut,
    patchDrug, patchDrugCategory, patchDrugInventory, patchInventoryForSupplyPoint, patchOrder, patchPharmacistSupplyPoint,
    patchPharmacyStaff, patchProvision, patchSupplyPoint, removeDrug, removeDrugCategory, removePharmacyStaff, removeProvision,
    removeSupplyPoint, stockDrugToSupplyPoint, getCustomersProfile, consultationFee, generateInventoryItem, fetchInventoryDrugItems,
    fetchInventoryProvisionItems, patchInventoryDrug, removeInventoryDrug, patchInventoryProvision, removeInventoryProvision, fetchInventoryItemInventory,
    generateInventory, patchInventory, stockInventoryToSupplyPoint, generateBilling, fetchBillings, patchBilling, removeBilling, fetchLaboratoryInvestigationTypes,
    generateLaboratoryInvestigationType, patchLaboratoryInvestigationType, removeLaboratoryInvestigationType, fetchLaboratoryInvestigationCategories, generateLaboratoryInvestigationCategory, patchLaboratoryInvestigationCategory, removeLaboratoryInvestigationCategory, fetchLaboratoryInvestigationSamples, generateLaboratoryInvestigationSample, patchLaboratoryInvestigationSample, removeLaboratoryInvestigationSample, fetchRadiologyInvestigationTypes, generateRadiologyInvestigationType, patchRadiologyInvestigationType, removeRadiologyInvestigationType, generateRadiologyInvestigationCategory, fetchRadiologyInvestigationCategories, patchRadiologyInvestigationCategory, removeRadiologyInvestigationCategory, fetchLaboratoryInvestigationReports, fetchRadiologyInvestigationReports, patchLaboratoryInvestigationReport, patchLaboratoryInvestigationResult, patchRadiologyInvestigationReport, patchRadiologyInvestigationResult,
    fetchClinicalData, fetchInventories, fetchPharmacistInventoryItems, fetchGatewaySettings, generateGatewaySetting, patchGatewaySetting, removeGatewaySetting, patchSubcriptionPlan, fetchSubscriptionPlan, multipleDispense, generateSmsTopupNotification, fetchPharmacyCourierProfile, patchPharmacyCourierProfile
} from '../routes/pharmacist.routes'


export const getPharmacyStaff = createAsyncThunk(
    'pharmacist/getPharmacyStaff',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyStaffs(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createPharmacyStaff = createAsyncThunk(
    'pharmacist/createPharmacyStaff',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePharmacyStaff(formData, token)

            toast.success('Staff created sucessfully')
            setModals({ ...modals, showPharmacistAddStaffModal: !modals.showPharmacistAddStaffModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while creating pharmacy staff')
            return rejectWithValue(null)
        }
    }
)

export const getPharmacyCourierProfile = createAsyncThunk(
    'pharmacist/getPharmacyCourierProfile',
    async ({ courier }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyCourierProfile(courier, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const updatePharmacyCourierProfile = createAsyncThunk(
    'pharmacist/updatePharmacyCourierProfile',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacyCourierProfile(formData, token)

            toast.success("Courier profile update successfully")
            setModals({ ...modals, showPharmacistUpdateStaffProfileModal: !modals.showPharmacistUpdateStaffProfileModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating courier profile')
            return rejectWithValue(null)
        }
    }
)

export const updatePharmacyStaff = createAsyncThunk(
    'pharmacist/updatePharmacyStaff',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacyStaff(formData, token)

            toast.success('Staff updated sucessfully')
            setModals({ ...modals, showPharmacistUpdateStaffModal: !modals.showPharmacistUpdateStaffModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating pharmacy staff')
            return rejectWithValue(null)
        }
    }
)

export const deletePharmacyStaff = createAsyncThunk(
    'pharmacist/deletePharmacyStaff',
    async ({ id, toast, modals, setModals, dispatch, setEmployee }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removePharmacyStaff(id, token)

            toast.success('Staff deleted sucessfully')

            dispatch(setEmployee({ data: null }))
            setModals({ ...modals, showPharmacistDeleteStaffModal: !modals.showPharmacistDeleteStaffModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while deleting pharmacy staff')
            return rejectWithValue(null)
        }
    }
)

export const getDrugCategories = createAsyncThunk(
    'pharmacist/getDrugCategories',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDrugCategories(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createDrugCategory = createAsyncThunk(
    'pharmacist/createDrugCategory',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateDrugCategory(formData, token)

            toast.success('Drug category created successfully.')
            setModals({ ...modals, showPharmacistAddDrugCategoryModal: !modals.showPharmacistAddDrugCategoryModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while creating drug category.')
            return rejectWithValue(null)
        }
    }
)

export const updateDrugCategory = createAsyncThunk(
    'pharmacist/updateDrugCategory',
    async ({ formData, toast, setModals, modals, dispatch, setDrugCategory }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchDrugCategory(formData, token)

            toast.success('Drug category updated successfully.')
            setModals({ ...modals, showPharmacistUpdateDrugCategoryModal: !modals.showPharmacistUpdateDrugCategoryModal })
            dispatch(setDrugCategory({ data: null }))

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating drug category.')
            return rejectWithValue(null)
        }
    }
)

export const deleteDrugCategory = createAsyncThunk(
    'pharmacist/deleteDrugCategory',
    async ({ id, toast, setModals, modals, dispatch, setDrugCategory }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeDrugCategory(id, token)

            toast.success('Drug category deleted successfully.')
            setModals({ ...modals, showPharmacistUpdateDeleteCategoryModal: !modals.showPharmacistUpdateDeleteCategoryModal })
            dispatch(setDrugCategory({ data: null }))

            return id
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while deleting drug category.')
            return rejectWithValue(null)
        }
    }
)

export const getSupplyPoints = createAsyncThunk(
    'pharmacist/getSupplyPoints',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSupplyPoints(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createSupplyPoint = createAsyncThunk(
    'pharmacist/createSupplyPoint',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateSupplyPoint(formData, token)

            toast.success('Supply point created successfully.')
            setModals({ ...modals, showPharmacistAddSupplyPointModal: !modals.showPharmacistAddSupplyPointModal })

            return data
        } catch (error) {
            console.log(error)

            if (null?.name) {
                toast.warning(error.response?.data?.name[0])
            } else {
                toast.warning('An error occured while creating supply point.')
            }

            return rejectWithValue(null)
        }
    }
)

export const updateSupplyPoint = createAsyncThunk(
    'pharmacist/updateSupplyPoint',
    async ({ formData, toast, setModals, modals, dispatch, setSupplyPoint }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchSupplyPoint(formData, token)

            toast.success('Supply point updated successfully.')
            setModals({ ...modals, showPharmacistUpdateSupplyPointModal: !modals.showPharmacistUpdateSupplyPointModal })
            dispatch(setSupplyPoint({ data: null }))

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating supply point.')
            return rejectWithValue(null)
        }
    }
)

export const deleteSupplyPoint = createAsyncThunk(
    'pharmacist/deleteSupplyPoint',
    async ({ id, toast, setModals, modals, dispatch, setSupplyPoint }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeSupplyPoint(id, token)

            toast.success('Supply point deleted successfully.')
            setModals({ ...modals, showPharmacistDeleteSupplyPointModal: !modals.showPharmacistDeleteSupplyPointModal })
            dispatch(setSupplyPoint({ data: null }))

            return id
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while deleting supply point.')
            return rejectWithValue(null)
        }
    }
)

export const getDrugs = createAsyncThunk(
    'pharmacist/getDrugs',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDrugs(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const customerGetDrugs = createAsyncThunk(
    'pharmacist/customerGetDrugs',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await customerFetchDrugs(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createDrug = createAsyncThunk(
    'pharmacist/creatDrug',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateDrug(formData, token)

            toast.success('Medicine added successfully.')
            setModals({ ...modals, showPharmacistAddDrugModal: !modals.showPharmacistAddDrugModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while adding medicine.')
            return rejectWithValue(null)
        }
    }
)

export const updateDrug = createAsyncThunk(
    'pharmacist/updateDrug',
    async ({ formData, toast, setModals, modals, dispatch, setCurrentDrug }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchDrug(formData, token)

            toast.success('Medicine updated successfully.')
            setModals({ ...modals, showPharmacistUpdateDrugModal: !modals.showPharmacistUpdateDrugModal })
            dispatch(setCurrentDrug({ data: null }))

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating medicine.')
            return rejectWithValue(null)
        }
    }
)

export const deleteDrug = createAsyncThunk(
    'pharmacist/deleteDrug',
    async ({ id, toast, setModals, modals, dispatch, setCurrentDrug }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeDrug(id, token)

            toast.success('Drug deleted successfully.')
            setModals({ ...modals, showPharmacistDeleteDrugModal: !modals.showPharmacistDeleteDrugModal })
            dispatch(setCurrentDrug({ data: null }))

            return id
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while deleting drug.')
            return rejectWithValue(null)
        }
    }
)


export const getDrugBinCards = createAsyncThunk(
    'pharmacist/getDrugBinCards',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDrugBinCards(id, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createDrugBinCard = createAsyncThunk(
    'pharmacist/createDrugBinCard',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateDrugBinCard(formData, token)

            toast.success('Drug bin card added successfully.')
            setModals({ ...modals, showPharmacistBinCardModal: !modals.showPharmacistBinCardModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while adding bin card.')
            return rejectWithValue(null)
        }
    }
)



export const dispenseDrugToSupplyPoint = createAsyncThunk(
    'pharmacist/dispenseDrugToSupplyPoint',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData)
            const { data } = await stockInventoryToSupplyPoint(formData, token)
            const req = await patchInventory(formData, token)

            console.log(req.data)
            toast.success('Item dispensed to supply point successfully.')
            setModals({ ...modals, showPharmacistDrugSupplyPointModal: !modals.showPharmacistDrugSupplyPointModal })

            return { data, inventory: req.data }
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while dispensing drug to supply point.')
            return rejectWithValue(null)
        }
    }
)



export const resetDrugBinCards = createAsyncThunk(
    'pharmacist/resetDrugBinCards',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const getSupplyPointInventory = createAsyncThunk(
    'pharmacist/getSupplyPointInventory',
    async ({ supplypoint_name }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSupplyPointInventories(supplypoint_name, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const resetSupplyPointInventory = createAsyncThunk(
    'pharmacist/resetSupplyPointInventory',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const getSupplyPointInventoryBinCards = createAsyncThunk(
    'pharmacist/getSupplyPointInventoryBinCards',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(params)
            const { data } = await fetchSupplyPointInventoryBinCards(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)


export const resetSupplyPointDrugBinCards = createAsyncThunk(
    'pharmacist/resetSupplyPointDrugBinCards',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const lendDrugToSupplyPoint = createAsyncThunk(
    'pharmacist/lendDrugToSupplyPoint',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData)

            const { data } = await patchInventoryForSupplyPoint(formData, token)

            toast.success('Dispensed to supply point successfully.')
            setModals({ ...modals, showPharmacistLendRefundDrugModal: !modals.showPharmacistLendRefundDrugModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while dispensing drug to supply point.')
            return rejectWithValue(error)
        }
    }
)


export const issueOutDrug = createAsyncThunk(
    'pharmacist/issueOutDrug',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await issueDrugOut(formData, token)

            toast.success('Dispensed drug successfully.')
            setModals({ ...modals, showPharmacistIssueOutDrugModal: !modals.showPharmacistIssueOutDrugModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while issuing out drug.')
            return rejectWithValue(error)
        }
    }
)


export const getSupplyPointBinCards = createAsyncThunk(
    'pharmacist/getSupplyPointBinCards',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSupplyPointBinCards(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)


export const resetSupplyPointBinCards = createAsyncThunk(
    'pharmacist/resetSupplyPointBinCards',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)


export const getPrescriptions = createAsyncThunk(
    'pharmacist/getPrescriptions',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPrescriptions(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getDrugPrices = createAsyncThunk(
    'pharmacist/getDrugPrices',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDrugPrices(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createInvoice = createAsyncThunk(
    'pharmacist/createInvoice',
    async ({ formData, toast, setModals, modals, dispatch, setCurrentPrescriptions }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateInvoice(formData, token)

            toast.success('Invoice generated successfully.')
            setModals({ ...modals, showPharmacistDrugInvoiceModal: !modals.showPharmacistDrugInvoiceModal })
            dispatch(setCurrentPrescriptions({ data: null }))

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating prescriptions invoice.')
            return rejectWithValue(null)
        }
    }
)


export const getInvoices = createAsyncThunk(
    'pharmacist/getInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPrescriptionInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPaidPrescriptions = createAsyncThunk(
    'pharmacist/getPaidPrescriptions',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPaidPrescriptions(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const resetPaidPrescriptions = createAsyncThunk(
    'pharmacist/resetPaidPrescriptions',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacistProfiles = createAsyncThunk(
    'pharmacist/getPharmacistProfiles',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacistProfiles(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const assignPharmacistToSupplyPoint = createAsyncThunk(
    'pharmacist/assignPharmacistToSupplyPoint',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacistSupplyPoint(formData, token)

            toast.success('Pharmacist assigned to supplypoint successfully.')

            modals.showPharmacistAssignSupplyPointModal && setModals({ ...modals, showPharmacistAssignSupplyPointModal: !modals.showPharmacistAssignSupplyPointModal })
            modals.showPharmacistSupplyPointAssignStaffModal && setModals({ ...modals, showPharmacistSupplyPointAssignStaffModal: !modals.showPharmacistSupplyPointAssignStaffModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while assigning pharmacist to supplypoint.')
            return rejectWithValue(null)
        }
    }
)


export const getSupplyPointStaff = createAsyncThunk(
    'pharmacist/getSupplyPointStaff',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSupplyPointStaff(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const resetSupplyPointStaff = createAsyncThunk(
    'pharmacist/resetSupplyPointStaff',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const dispenseDrug = createAsyncThunk(
    'pharmacist/dispenseDrug',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateDrugDispense(formData, token)

            toast.success('Drug dispensed successfully')
            setModals({ ...modals, showPharmacistDispenseDrugModal: !modals.showPharmacistDispenseDrugModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while dispensing drug')
            return rejectWithValue(null)
        }
    }
)


export const createPrescription = createAsyncThunk(
    'pharmacist/createPrescription',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePrescription(formData, token)

            toast.success('Prescription generated successfully')
            setModals({ ...modals, showPharmacistAddPrescriptionModal: !modals.showPharmacistAddPrescriptionModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating prescription')
            return rejectWithValue(null)
        }
    }
)


export const getCustomerPrescriptions = createAsyncThunk(
    'pharmacist/getCustomerPrescriptions',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await customerFetchPrescriptions(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const customerCreatePrescription = createAsyncThunk(
    'pharmacist/customerCreatePrescription',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await customerGeneratePrescription(formData, token)

            toast.success('Order generated successfully')
            setModals({ ...modals, showCustomerAddOrderModal: !modals.showCustomerAddOrderModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating prescription order')
            return rejectWithValue(null)
        }
    }
)


export const customerGetInvoices = createAsyncThunk(
    'pharmacist/customerGetInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await customerFetchInvoices(token)


            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentCustomerInvoice = createAsyncThunk(
    'pharmacist/setCurrentCustomerInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const customerCreateOrder = createAsyncThunk(
    'pharmacist/customerCreateOrder',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await customerGenerateOrder(formData, token)

            toast.success('Order generated successfully')

            setModals({ ...modals, showCustomerProcessOrderModal: !modals.showCustomerProcessOrderModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating order')
            return rejectWithValue(null)
        }
    }
)



export const customerCreateProvisionOrder = createAsyncThunk(
    'pharmacist/customerCreateProvisionOrder',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await customerGenerateProvisionOrder(formData, token)

            toast.success('Order generated successfully')

            setModals({ ...modals, showCustomerProcessProvisionOrderModal: !modals.showCustomerProcessProvisionOrderModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating order')
            return rejectWithValue(null)
        }
    }
)


export const getCustomerOrders = createAsyncThunk(
    'pharmacist/getCustomerOrders',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await customerFetchOrders(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getOrders = createAsyncThunk(
    'pharmacist/getOrders',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchOrders(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getCouriers = createAsyncThunk(
    'pharmacist/getCouriers',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchCouriers(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const processOrder = createAsyncThunk(
    'pharmacist/processOrder',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchOrder(formData, token)

            toast.success('Order process successfully.')
            setModals({ ...modals, showPharmacistProcessOrderModal: !modals.showPharmacistProcessOrderModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while processing order.')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentOrder = createAsyncThunk(
    'pharmacist/setCurrentOrder',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getProvisions = createAsyncThunk(
    'pharmacist/getProvisions',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchProvisions(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createProvision = createAsyncThunk(
    'pharmacist/createProvision',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateProvision(formData, token)

            toast.success('Provision created successfully.')
            setModals({ ...modals, showPharmacistAddProvisionModal: !modals.showPharmacistAddProvisionModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while creating provision.')
            return rejectWithValue(null)
        }
    }
)


export const updateProvision = createAsyncThunk(
    'pharmacist/updateProvision',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchProvision(formData, token)

            toast.success('Provision updated successfully.')
            setModals({ ...modals, showPharmacistUpdateProvisionModal: !modals.showPharmacistUpdateProvisionModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating provision.')
            return rejectWithValue(null)
        }
    }
)


export const deleteProvision = createAsyncThunk(
    'pharmacist/deleteProvision',
    async ({ id, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeProvision(id, token)

            toast.success('Provision deleted successfully.')
            setModals({ ...modals, showPharmacistDeleteProvisionModal: !modals.showPharmacistDeleteProvisionModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while deleting provision.')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentProvision = createAsyncThunk(
    'pharmacist/setCurrentProvision',
    async ({ data }, { rejectWithValue }) => {
        try {

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const getProvisionInvoices = createAsyncThunk(
    'pharmacist/getProvisionInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchProvisionInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createProvisionInvoice = createAsyncThunk(
    'pharmacist/createProvisionInvoice',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateProvisionInvoice(formData, token)

            toast.success('Invoice updated successfully.')
            setModals({ ...modals, showCustomerAddProvisionModal: !modals.showCustomerAddProvisionModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating invoice.')
            return rejectWithValue(null)
        }
    }
)


export const getAllProfiles = createAsyncThunk(
    'pharmacist/getAllProfiles',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getCustomersProfile(token)




            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const getConsultationFee = createAsyncThunk(
    'pharmacist/getConsultationFee',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await consultationFee(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getInventoryDrugs = createAsyncThunk(
    'pharmacist/getInventoryDrugs',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await fetchInventoryDrugItems(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const getInventoryProvisions = createAsyncThunk(
    'pharmacist/getInventoryProvisions',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventoryProvisionItems(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createInventoryItem = createAsyncThunk(
    'pharmacist/createInventoryItem',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateInventoryItem(formData, token)

            toast.success('Inventory item added successfully.')
            setModals({ ...modals, showPharmacistAddInventoryModal: !modals.showPharmacistAddInventoryModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating inventory item.')
            return rejectWithValue(null)
        }
    }
)


export const updateInventoryDrug = createAsyncThunk(
    'pharmacist/updateInventoryDrug',
    async ({ formData, toast, modals, setModals, dispatch, setCurrentDrug }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchInventoryDrug(formData, token)

            toast.success('Inventory drug updated successfully.')
            setModals({ ...modals, showPharmacistUpdateDrugModal: !modals.showPharmacistUpdateDrugModal })

            dispatch(setCurrentDrug({ data: null }))

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating inventory drug.')
            return rejectWithValue(null)
        }
    }
)


export const deleteInventoryDrug = createAsyncThunk(
    'pharmacist/deleteInventoryDrug',
    async ({ id, toast, modals, setModals, dispatch, setCurrentDrug }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeInventoryDrug(id, token)

            toast.success('Inventory drug deleted successfully.')
            setModals({ ...modals, showPharmacistDeleteDrugModal: !modals.showPharmacistDeleteDrugModal })

            dispatch(setCurrentDrug({ data: null }))

            return id
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while deleting inventory drug.')
            return rejectWithValue(null)
        }
    }
)


export const updateInventoryProvision = createAsyncThunk(
    'pharmacist/updateInventoryProvision',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchInventoryProvision(formData, token)

            toast.success('Inventory provision updated successfully.')
            setModals({ ...modals, showPharmacistUpdateProvisionModal: !modals.showPharmacistUpdateProvisionModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating inventory provision.')
            return rejectWithValue(null)
        }
    }
)


export const deleteInventoryProvision = createAsyncThunk(
    'pharmacist/deleteInventoryProvision',
    async ({ id, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeInventoryProvision(id, token)

            toast.success('Inventory provision deleted successfully.')
            setModals({ ...modals, showPharmacistDeleteProvisionModal: !modals.showPharmacistDeleteProvisionModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while deleting inventory drug.')
            return rejectWithValue(null)
        }
    }
)


export const getInventoryItemInventory = createAsyncThunk(
    'pharmacist/getInventoryItemInventory',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventoryItemInventory(id, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createInventory = createAsyncThunk(
    'pharmacist/createInventory',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateInventory(formData, token)

            toast.success('Inventory generated successfully.')
            setModals({ ...modals, showPharmacistBinCardModal: !modals.showPharmacistBinCardModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while generating inventory.')
            return rejectWithValue(null)
        }
    }
)



export const getBillings = createAsyncThunk(
    'pharmacist/getBillings',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchBillings(token)
            console.log(data)
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createBilling = createAsyncThunk(
    'pharmacist/createBilling',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await generateBilling(formData, token)

            toast.success("Billing created successfully")
            setModals({ ...modals, showPharmacistAddBillingModal: !modals.showPharmacistAddBillingModal })
            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occured while creating billing")
            return rejectWithValue(null)
        }
    }
)

export const getClinicalData = createAsyncThunk(
    'pharmacist/getClinicalData',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchClinicalData(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const updateBilling = createAsyncThunk(
    'pharmacist/updateBilling',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            console.log(formData);
            const { data } = await patchBilling(formData, token)

            toast.success("Billing updated successfully")
            setModals({ ...modals, showPharmacistUpdateBillingModal: !modals.showPharmacistUpdateBillingModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on updating billing")
            return rejectWithValue(null)
        }
    }
)


export const deleteBilling = createAsyncThunk(
    'pharmacist/deleteBilling',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeBilling(id, token)

            toast.success("Billing deleted successfully")
            setModals({ ...modals, showPharmacistDeleteBillingModal: !modals.showPharmacistDeleteBillingModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred on deleting a billing type")
            return rejectWithValue(null)
        }
    }
)


export const getLaboratoryInvestigationTypes = createAsyncThunk(
    'pharmacist/getLaboratoryInvestigationTypes',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvestigationTypes(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const fetchAllInventories = createAsyncThunk(
    'pharmacist/fetchAllInventories',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchInventories(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createLaboratoryInvestigationType = createAsyncThunk(
    'pharmacist/createLaboratoryInvestigationType',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateLaboratoryInvestigationType(formData, token)

            toast.success("Laboratory test type added successfully")
            setModals({ ...modals, showPharmacistAddInvestigationType: !modals.showPharmacistAddInvestigationType })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while adding test type")
            return rejectWithValue(null)
        }
    }
)


export const updateLaboratoryInvestigationType = createAsyncThunk(
    'pharmacist/updateLaboratoryInvestigationType',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvestigationType(formData, token)

            toast.success("Update successful")
            setModals({ ...modals, showPharmacistUpdateInvestigationType: !modals.showPharmacistUpdateInvestigationType })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred during update")
            return rejectWithValue(null)
        }
    }
)


export const deleteLaboratoryInvestigationType = createAsyncThunk(
    'pharmacist/deleteLaboratoryInvestigationType',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeLaboratoryInvestigationType(id, token)

            toast.success("Laboratory test type deleted successfully")
            setModals({ ...modals, showPharmacistDeleteInvestigationType: !modals.showPharmacistDeleteInvestigationType })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while deleting test type")
            return rejectWithValue(null)
        }
    }
)

export const getLaboratoryInvestigationCategories = createAsyncThunk(
    'pharmacist/getLaboratoryInvestigationCategories',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvestigationCategories(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const createLaboratoryInvestigationCategory = createAsyncThunk(
    'pharmacist/createLaboratoryInvestigationCategory',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateLaboratoryInvestigationCategory(formData, token)

            toast.success("Laboratory category created successfully")
            setModals({ ...modals, showPharmacistAddInvestigationCategoryModal: !modals.showPharmacistAddInvestigationCategoryModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while creating category")
            return rejectWithValue(null)
        }
    }
)

export const updateLaboratoryInvestigationCategory = createAsyncThunk(
    'pharmacist/updateLaboratoryInvestigationCategory',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvestigationCategory(formData, token)

            toast.success("Laboratory category updated successfully")
            setModals({ ...modals, showPharmacistUpdateInvestigationCategoryModal: !modals.showPharmacistUpdateInvestigationCategoryModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while updating category")
            return rejectWithValue(null)
        }
    }
)

export const deleteLaboratoryInvestigationCategory = createAsyncThunk(
    'pharmacist/deleteLaboratoryInvestigationCategory',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeLaboratoryInvestigationCategory(id, token)

            toast.success("Category deleted successfully")

            setModals({ ...modals, showPharmacistDeleteInvestigationCategoryModal: !modals.showPharmacistDeleteInvestigationCategoryModal })

            return id
        } catch (error) {
            toast.warning("Error occured while deleting category")
            console.log(error)

            return rejectWithValue(null)
        }
    }
)

export const getLaboratoryInvestigationSamples = createAsyncThunk(
    'pharmacist/getLaboratoryInvestigationSamples',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvestigationSamples(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const createLaboratoryInvestigationSample = createAsyncThunk(
    'pharmacist/createLaboratoryInvestigationSample',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateLaboratoryInvestigationSample(formData, token)

            toast.success("Laboratory sample created successfully")
            setModals({ ...modals, showPharmacistAddInvestigationSampleModal: !modals.showPharmacistAddInvestigationSampleModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while creating sample")
            return rejectWithValue(null)
        }
    }
)

export const updateLaboratoryInvestigationSample = createAsyncThunk(
    'pharmacist/updateLaboratoryInvestigationSample',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvestigationSample(formData, token)

            toast.success("Laboratory sample updated successfully")
            setModals({ ...modals, showPharmacistUpdateInvestigationSampleModal: !modals.showPharmacistUpdateInvestigationSampleModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while updating sample")
            return rejectWithValue(null)
        }
    }
)

export const deleteLaboratoryInvestigationSample = createAsyncThunk(
    'pharmacist/deleteLaboratoryInvestigationSample',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeLaboratoryInvestigationSample(id, token)

            toast.success("Sample deleted successfully")

            setModals({ ...modals, showPharmacistDeleteInvestigationSampleModal: !modals.showPharmacistDeleteInvestigationSampleModal })

            return id
        } catch (error) {
            toast.warning("Error occured while deleting sample")
            console.log(error)

            return rejectWithValue(null)
        }
    }
)

export const setCurrentLaboratoryData = createAsyncThunk(
    'pharmacist/setCurrentLaboratoryData',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const getRadiologyInvestigationTypes = createAsyncThunk(
    'pharmacist/getRadiologyInvestigationTypes',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyInvestigationTypes(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const createRadiologyInvestigationType = createAsyncThunk(
    'pharmacist/createRadiologyInvestigationType',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateRadiologyInvestigationType(formData, token)

            toast.success("Investigation type added successfully")
            setModals({ ...modals, showPharmacistAddRadiologyInvestigationTypeModal: !modals.showPharmacistAddRadiologyInvestigationTypeModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while adding investigation type")
            return rejectWithValue(null)
        }
    }
)



export const updateRadiologyInvestigationType = createAsyncThunk(
    'pharmacist/updateRadiologyInvestigationType',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvestigationType(formData, token)

            toast.success("Investigation type updated successfully")
            setModals({ ...modals, showPharmacistUpdateRadiologyInvestigationTypeModal: !modals.showPharmacistUpdateRadiologyInvestigationTypeModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while updating investigation type")
            return rejectWithValue(null)
        }
    }
)



export const deleteRadiologyInvestigationType = createAsyncThunk(
    'pharmacist/deleteRadiologyInvestigationType',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeRadiologyInvestigationType(id, token)

            toast.success("Investigation type deleted successfully")
            setModals({ ...modals, showPharmacistDeleteRadiologyInvestigationTypeModal: !modals.showPharmacistDeleteRadiologyInvestigationTypeModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while deleting investigation type")
            return rejectWithValue(null)
        }
    }
)



export const getRadiologyInvestigationCategories = createAsyncThunk(
    'pharmacist/getRadiologyInvestigationCategories',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyInvestigationCategories(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const createRadiologyInvestigationCategory = createAsyncThunk(
    'pharmacist/createRadiologyInvestigationCategory',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateRadiologyInvestigationCategory(formData, token)

            toast.success("Investigation type added successfully")
            setModals({ ...modals, showPharmacistAddRadiologyInvestigationCategoryModal: !modals.showPharmacistAddRadiologyInvestigationCategoryModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while adding investigation type")
            return rejectWithValue(null)
        }
    }
)



export const updateRadiologyInvestigationCategory = createAsyncThunk(
    'pharmacist/updateRadiologyInvestigationCategory',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvestigationCategory(formData, token)

            toast.success("Investigation type updated successfully")
            setModals({ ...modals, showPharmacistUpdateRadiologyInvestigationCategoryModal: !modals.showPharmacistUpdateRadiologyInvestigationCategoryModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while updating investigation type")
            return rejectWithValue(null)
        }
    }
)



export const deleteRadiologyInvestigationCategory = createAsyncThunk(
    'pharmacist/deleteRadiologyInvestigationCategory',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeRadiologyInvestigationCategory(id, token)

            toast.success("Investigation type deleted successfully")
            setModals({ ...modals, showPharmacistDeleteRadiologyInvestigationCategoryModal: !modals.showPharmacistDeleteRadiologyInvestigationCategoryModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning("An error occurred while deleting investigation type")
            return rejectWithValue(null)
        }
    }
)



export const getLabInvestigationReports = createAsyncThunk(
    'pharmacist/getLabInvestigationReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvestigationReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createLabInvestigationReportInvoice = createAsyncThunk(
    'pharmacist/createLabInvestigationReportInvoice',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvestigationReport(formData, token)

            toast.success("Invoice generated successfully")
            setModals({ ...modals, showLaboratoryGenerateInvoiceModal: !modals.showLaboratoryGenerateInvoiceModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occured while generating invoice.")
            return rejectWithValue(null)
        }
    }
)


export const createRadInvestigationReportInvoice = createAsyncThunk(
    'pharmacist/createRadInvestigationReportInvoice',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvestigationReport(formData, token)

            toast.success("Invoice generated successfully")
            setModals({ ...modals, showRadiologyGenerateInvoiceModal: !modals.showRadiologyGenerateInvoiceModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occured while generating invoice.")
            return rejectWithValue(null)
        }
    }
)



export const getRadInvestigationReports = createAsyncThunk(
    'pharmacist/getRadInvestigationReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyInvestigationReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const uploadLabReportResult = createAsyncThunk(
    'pharmacist/uploadLabReportResult',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvestigationResult(formData, token)

            toast.success("Report result uploaded successfully")
            setModals({ ...modals, showLaboratoryUploadReportModal: !modals.showLaboratoryUploadReportModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occured while uploading report result.")
            return rejectWithValue(null)
        }
    }
)



export const uploadRadReportResult = createAsyncThunk(
    'pharmacist/uploadRadReportResult',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvestigationResult(formData, token)

            toast.success("Report result uploaded successfully")
            setModals({ ...modals, showUploadReportModal: !modals.showUploadReportModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning("An error occured while uploading report result.")
            return rejectWithValue(null)
        }
    }
)



export const getPharmacistInventoryItems = createAsyncThunk(
    'pharmacist/getPharmacistInventoryItems',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await fetchPharmacistInventoryItems(token)

            return data
        } catch (error) {
            // console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const getGatewaySettings = createAsyncThunk(
    'pharmacist/getGatewaySettings',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchGatewaySettings(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const createGatewaySetting = createAsyncThunk(
    'pharmacist/createGatewaySetting',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateGatewaySetting(formData, token)

            toast.success('Gateway setting created successfully')
            setModals({ ...modals, showPharmacistAddGatewaySettingModal: !modals.showPharmacistAddGatewaySettingModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occurred while creating gateway setting')
            return rejectWithValue(null)
        }
    }
)



export const updateGatewaySetting = createAsyncThunk(
    'pharmacist/updateGatewaySetting',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchGatewaySetting(formData, token)

            toast.success('Gateway setting updated successfully')
            setModals({ ...modals, showPharmacistUpdateGatewaySettingModal: !modals.showPharmacistUpdateGatewaySettingModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occurred while updating gateway setting')
            return rejectWithValue(null)
        }
    }
)



export const deleteGatewaySetting = createAsyncThunk(
    'pharmacist/deleteGatewaySetting',
    async ({ id, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeGatewaySetting(id, token)

            toast.success('Gateway setting deleted successfully')
            setModals({ ...modals, showPharmacistDeleteGatewaySettingModal: !modals.showPharmacistDeleteGatewaySettingModal })

            return id
        } catch (error) {
            console.log(error)
            toast.warning('An error occurred while deleting gateway setting')
            return rejectWithValue(null)
        }
    }
)



export const setCurrentSubscriptionPlan = createAsyncThunk(
    'pharmacist/setCurrentSubscriptionPlan',
    async ({ data }, { rejectWithValue }) => {
        try {

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateSubscriptionPlan = createAsyncThunk(
    'pharmacist/updateSubscriptionPlan',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchSubcriptionPlan(formData, token)
            let user = localStorage.getItem("pms__user") ? JSON.parse(localStorage.getItem("pms__user")) : null
            user.data = { ...user.data, subscription_plan: data.title }
            localStorage.setItem("pms__user", JSON.stringify(user))

            toast.success('Subscription plan updated successfully.')
            navigate('/dashboard')
            window.location.reload()

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occurred while updating subscription plan.')
            return rejectWithValue(null)
        }
    }
)


export const getSubscriptionPlan = createAsyncThunk(
    'pharmacist/getSubscriptionPlan',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSubscriptionPlan(token)
            localStorage.setItem("plan", JSON.stringify(data))
            return data


        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const SalesMutlipleDispense = createAsyncThunk(
    'pharmacist/SalesMutlipleDispense',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const DispenseMultipleItems = createAsyncThunk(
    'pharmacist/DispenseMultipleItems',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await multipleDispense(formData, token)

            toast.success('Items Dispensed sucessfully')
            setModals({ ...modals, showPharmacistMultipleDispenseModal: !modals.showPharmacistMultipleDispenseModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while dispensing item')
            return rejectWithValue(null)
        }
    }
)

export const sendSmsTopupNotification = createAsyncThunk(
    'pharmacist/sendSmsTopupNotification',
    async ({ formData, toast, navigate }, { rejectWithValue }) => {
        try {
            const { data } = await generateSmsTopupNotification(formData)

            toast.success('SMS topup invoice verified successfully')

            navigate('/dashboard')
            window.location.reload()

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while verifying SMS topup invoice')
            return rejectWithValue(null)
        }
    }
)
