import React from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { deleteLaboratoryInvestigationType } from '../../../state/actions/pharmacist.actions'


const PharmacistDeleteLabInvestigationTypeModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { laboratory_data } = useSelector((state) => state.pharmacist)

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(deleteLaboratoryInvestigationType({ id: laboratory_data?.id, toast, setModals, modals }));
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-yellow text-[20px] font-bold'>Delete</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                setModals({ ...modals, showPharmacistDeleteInvestigationType: !modals.showPharmacistDeleteInvestigationType })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <p className="text-left">Are you sure you want to delete the laboratory investigation type <span className='font-bold italic'>{laboratory_data?.type_title}</span> ?</p>

                        <div className='mt-4 flex justify-end'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-yellow text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Delete</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default PharmacistDeleteLabInvestigationTypeModal