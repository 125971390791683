import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import { useStateContext } from '../../state/context'
import { setBilling } from '../../state/actions/actions'


const PharmacistBillingsTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    <Fragment>
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((billing, index) => (
                                    <tr key={`${billing.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {billing.name}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {billing.billing_type}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            &#8358; {billing?.price}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {billing.status ? "Active" : "Inactive"}
                                        </td>
                                        <td className="flex space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            <FaPencilAlt
                                                size={20}
                                                className='text-green-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setBilling({ data: billing }))
                                                    setModals({ ...modals, showPharmacistUpdateBillingModal: !modals.showPharmacistUpdateBillingModal })
                                                }}
                                            />
                                            <FaTrash
                                                size={20}
                                                className="text-orange-400 cursor-pointer"
                                                onClick={() => {
                                                    dispatch(setBilling({ data: billing }))
                                                    setModals({ ...modals, showPharmacistDeleteBillingModal: !modals.showPharmacistDeleteBillingModal })
                                                }}
                                            />
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default PharmacistBillingsTable
