import { API } from "../config"


export const fetchLaboratoryInvoices = (token) => API.get('/collection/laboratory_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyInvoices = (token) => API.get('/collection/pharmacy_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyInvoices = (token) => API.get('/collection/radiology_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsInvoices = (token) => API.get('/collection/records_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const generatePayStackConfig = (formData, token) => API.post('/collection/payments_paystack_initialize/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateAdminPayStackConfig = (formData, token) => API.post('/collection/payments_admin_paystack_initialize/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const checkPayStackPaymentStatus = (params, token) => API.get(`/collection/payments_paystack_verify/${params.ref}`, { headers: { Authorization: `Bearer ${token}` } })


export const checkAdminPayStackPaymentStatus = (params, token) => API.get(`/collection/payments_admin_paystack_verify/${params.ref}`, { headers: { Authorization: `Bearer ${token}` } })


export const patchLaboratoryInvoice = (formData, token) => API.patch(`/collection/payments_update_labinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateFlutterWaveConfig = (formData, token) => API.post('/collection/payments_flutterwave_initialize/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const checkFlutterWavePaymentStatus = (params, token) => API.get(`/collection/payments_flutterwave_verify/${params.ref}`, { headers: { Authorization: `Bearer ${token}` } })


export const patchProvisionInvoice = (formData, token) => API.patch(`/collection/payments_update_provisioninvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchPharmacyInvoice = (formData, token) => API.patch(`/collection/payments_update_pharmacyinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchCustomerPharmacyInvoice = (formData, token) => API.patch(`/collection/payments_customerupdate_pharmacyinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchRadiologyInvoice = (formData, token) => API.patch(`/collection/payments_update_radiologyinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchRecordsInvoice = (formData, token) => API.patch(`/collection/payments_update_recordsinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInvestigationInvoices = (token) => API.get(`/collection/investigation_invoice/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchInvestigationInvoice = (formData, token) => API.patch(`/collection/investigation_invoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInventoryDailyReports = (params, token) => API.get(`/collection/inventory_daily_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInventoryMonthlyReports = (params, token) => API.get(`/collection/inventory_monthly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInventoryYearlyReports = (params, token) => API.get(`/collection/inventory_yearly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInventoryRangeReports = (params, token) => API.get(`/collection/inventory_reports_range/${params.start_date}/${params.end_date}`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInventoryAuthorReports = (params, token) => API.get(`/collection/inventory_reports_author/${params.author}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInventoryItemReports = (params, token) => API.get(`/collection/inventory_reports_item/${params.inventory_item}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPaidInventoryReports = (token) => API.get(`/collection/inventory_reports_paid/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchUnpaidInventoryReports = (token) => API.get(`/collection/inventory_reports_unpaid/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInvestigationDailyReports = (params, token) => API.get(`/collection/investigation_daily_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInvestigationMonthlyReports = (params, token) => API.get(`/collection/investigation_monthly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInvestigationYearlyReports = (params, token) => API.get(`/collection/investigation_yearly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInvestigationRangeReports = (params, token) => API.get(`/collection/investigation_reports_range/${params.start_date}/${params.end_date}`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchInvestigationAuthorReports = (params, token) => API.get(`/collection/investigation_reports_author/${params.author}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPaidInvestigationReports = (token) => API.get(`/collection/investigation_reports_paid/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchUnpaidInvestigationReports = (token) => API.get(`/collection/investigation_reports_unpaid/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchMultipleinventoryInvoices = (formData, token) => API.patch(`/collection/payments_update_multiple_inventoryinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchMultipleInvestigationInvoices = (formData, token) => API.patch(`/collection/investigation_multiple_invoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })
