/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { MdKeyboardArrowDown, MdOutlineDateRange } from 'react-icons/md'

import { useStateContext } from '../../state/context'
import { CollectionsInvestigationInvoicesTable, CollectionsPharmacyInvoicesTable, DatePicker, PageTitle } from '../../components'
import { getInventoryAuthorReports, getInventoryDailyReports, getInventoryItemReports, getInventoryMonthlyReports, getInventoryRangeReports, getInventoryYearlyReports, getInvestigationAuthorReports, getInvestigationDailyReports, getInvestigationInvoices, getInvestigationMonthlyReports, getInvestigationRangeReports, getInvestigationYearlyReports, getLaboratoryInvoices, getPaidInventoryReports, getPaidInvestigationReports, getPharmacyInvoices, getRadiologyInvoices, getRecordsInvoices, getUnpaidInventoryReports, getUnpaidInvestigationReports } from '../../state/actions/collection.actions'


const CollectionsUnpaidInvoices = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const {
        pharmacy_invoices, pharmacy_invoice_update, investigation_invoices, investigation_invoice
    } = useSelector(state => state.collection)
    const { user } = useSelector(state => state.auth)

    const [endDate, setEndDate] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date())
    const [activeLink, setActiveLink] = useState('Inventory')
    const [showTables, setShowTables] = useState({ investigations: true, pharmacy: true, })
    const [filterState, setFilterState] = useState({ display: false, filter: 'Day', staff_patient_email: '', inventory_item: '' })

    useEffect(() => {
        dispatch(getPharmacyInvoices())
        dispatch(getInvestigationInvoices())
    }, [pharmacy_invoice_update, investigation_invoice])

    const handleChange = (e, name) => {
        setFilterState({ ...filterState, [name]: e.target.value })
    }

    const generateReport = () => {
        const end_date = new Date(endDate).toISOString().slice(0, 10)
        const start_date = new Date(startDate).toISOString().slice(0, 10)


        if (activeLink === 'Inventory') {
            if (filterState.filter === 'Day') {
                dispatch(getInventoryDailyReports({ params: { start_date } }))
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Month') {
                dispatch(getInventoryMonthlyReports({ params: { start_date } }))
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Year') {
                dispatch(getInventoryYearlyReports({ params: { start_date } }))
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Range') {
                dispatch(getInventoryRangeReports({ params: { start_date, end_date } }))
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Author') {
                dispatch(getInventoryAuthorReports({ params: { author: filterState.staff_patient_email } }))
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Item') {
                dispatch(getInventoryItemReports({ params: { inventory_item: filterState.inventory_item } }))
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Paid') {
                dispatch(getPaidInventoryReports())
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Unpaid') {
                dispatch(getUnpaidInventoryReports())
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }
        }
        if (activeLink === 'Investigations') {
            if (filterState.filter === 'Day') {
                dispatch(getInvestigationDailyReports({ params: { start_date } }))
                setModals({ ...modals, showCollectionsViewInvestigationReportsModal: !modals.showCollectionsViewInvestigationReportsModal })
            }

            if (filterState.filter === 'Month') {
                dispatch(getInvestigationMonthlyReports({ params: { start_date } }))
                setModals({ ...modals, showCollectionsViewInvestigationReportsModal: !modals.showCollectionsViewInvestigationReportsModal })
            }

            if (filterState.filter === 'Year') {
                dispatch(getInvestigationYearlyReports({ params: { start_date } }))
                setModals({ ...modals, showCollectionsViewInvestigationReportsModal: !modals.showCollectionsViewInvestigationReportsModal })
            }

            if (filterState.filter === 'Range') {
                dispatch(getInvestigationRangeReports({ params: { start_date, end_date } }))
                setModals({ ...modals, showCollectionsViewInvestigationReportsModal: !modals.showCollectionsViewInvestigationReportsModal })
            }

            if (filterState.filter === 'Author') {
                dispatch(getInvestigationAuthorReports({ params: { author: filterState.staff_patient_email } }))
                setModals({ ...modals, showCollectionsViewInvestigationReportsModal: !modals.showCollectionsViewInvestigationReportsModal })
            }

            if (filterState.filter === 'Paid') {
                dispatch(getPaidInvestigationReports())
                setModals({ ...modals, showCollectionsViewInvestigationReportsModal: !modals.showCollectionsViewInvestigationReportsModal })
            }

            if (filterState.filter === 'Unpaid') {
                dispatch(getUnpaidInvestigationReports())
                setModals({ ...modals, showCollectionsViewInvestigationReportsModal: !modals.showCollectionsViewInvestigationReportsModal })
            }
        }
    }


    return (
        <div>
            <PageTitle type={'collections-unpaid-invoices'} />

            <div className="">
                <div className="flex space-x-5 items-center border-b-2 border-gray-500 pb-2 w-1/2">
                    <button
                        type="button"
                        className={`${activeLink === 'Inventory' ? 'bg-indigo-600 text-white' : 'text-indigo-500 border border-indigo-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-indigo-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Inventory
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Investigations' ? 'bg-indigo-600 text-white' : 'text-indigo-500 border border-indigo-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-indigo-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Investigations
                    </button>
                </div>
                {user?.data?.subscription_plan === "ADVANCED" && <div className="flex justify-between items-center border-b-2 border-gray-500 py-2">
                    <div className="flex items-center space-x-2 relative">
                        <div className="flex items-center space-x-2 bg-gray-200 px-2 rounded-lg cursor-pointer text-[16px] text-gray-500"
                            onClick={() => setFilterState({ ...filterState, display: !filterState.display })}
                        >
                            {filterState.filter !== 'Author' ? (
                                <MdOutlineDateRange
                                    className='text-gray-500'
                                />
                            ) : (
                                <AiOutlineUser
                                    className='text-gray-500'
                                />
                            )}
                            <p className='text-[14px]'>{filterState.filter}</p>
                            <MdKeyboardArrowDown
                                size={20}
                                className='text-gray-600'
                            />
                        </div>

                        {filterState.display && (
                            <div className="absolute top-5 bg-white rounded shadow-md">
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >
                                    Day
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Month
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Year
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Range
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Item
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Author
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Paid
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Unpaid
                                </p>
                            </div>
                        )}

                        <DatePicker
                            type={filterState.filter === 'Day' ? 1 : filterState.filter === 'Month' ? 2 : filterState.filter === 'Year' ? 3 : filterState.filter === 'Range' ? 4 : 5}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                    </div>


                    <div className='space-x-4'>
                        {filterState.filter === 'Item' && (
                            <input
                                type="text"
                                name="inventory_item"
                                placeholder='Inventory Item'
                                className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        )}
                        {filterState.filter === 'Author' && (
                            <input
                                type="email"
                                name="staff_patient_email"
                                placeholder='Patient | Author Email Address'
                                className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        )}

                        <button
                            type="button"
                            className="w-[160px] inline-block px-6 py-2 bg-teal-600 text-white font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={generateReport}
                        >
                            Generate Report
                        </button>
                    </div>
                </div>}

                {activeLink === 'Inventory' && (
                    <CollectionsPharmacyInvoicesTable
                        showList={showTables.pharmacy}
                        data={pharmacy_invoices?.filter(invoice => invoice.is_paid === false)}
                    />
                )}

                {activeLink === 'Investigations' && (
                    <CollectionsInvestigationInvoicesTable
                        showList={showTables.investigations}
                        data={investigation_invoices?.filter(invoice => invoice.is_paid === false)}
                    />
                )}
            </div>
        </div>
    )
}

export default CollectionsUnpaidInvoices