import React from 'react'
import { BsShieldPlus } from 'react-icons/bs'
import { GiFirstAidKit } from 'react-icons/gi'
import { GrFormNext } from 'react-icons/gr'

const PharmacistSectionsSummary = ({ data }) => {


    return (
        <div className="flex flex-wrap justify-between items-center">
            <div className="border-2 border-green-600 rounded w-[220px] cursor-pointer hover:shadow-lg hover:translate-x-1 ease-in-out duration-500">
                <div className="flex flex-col">
                    <div className="py-4 flex flex-col items-center">
                        <BsShieldPlus
                            className='text-green-600'
                        />

                        <h4 className='text-[20px] text-dark-blue font-bold'>Good</h4>
                        <p className='text-[18px] text-dark-blue'>Inventory Status</p>
                    </div>

                    <div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-green-200 w-full">
                        View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
                    </div>
                </div>
            </div>

            <div className="border-2 border-yellow-600 rounded w-[220px] cursor-pointer hover:shadow-lg hover:translate-x-1 ease-in-out duration-500">
                <div className="flex flex-col">
                    <div className="py-4 flex flex-col items-center">
                        <BsShieldPlus
                            className='text-yellow-600'
                        />

                        <h4 className='text-[20px] text-dark-blue font-bold'>Good</h4>
                        <p className='text-[18px] text-dark-blue'>Provision Status</p>
                    </div>

                    <div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-yellow-200 w-full">
                        View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
                    </div>
                </div>
            </div>

            <div className="border-2 border-indigo-600 rounded w-[220px] cursor-pointer hover:shadow-lg hover:translate-x-1 ease-in-out duration-500">
                <div className="flex flex-col">
                    <div className="py-4 flex flex-col items-center">
                        <GiFirstAidKit
                            className='text-indigo-600'
                        />

                        <h4 className='text-[20px] text-dark-blue font-bold'>{data?.drugs}</h4>
                        <p className='text-[18px] text-dark-blue'>Medicines Available</p>
                    </div>

                    <div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-indigo-200 w-full">
                        View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
                    </div>
                </div>
            </div>

            <div className="border-2 border-fuchsia-600 rounded w-[220px] cursor-pointer hover:shadow-lg hover:translate-x-1 ease-in-out duration-500">
                <div className="flex flex-col">
                    <div className="py-4 flex flex-col items-center">
                        <GiFirstAidKit
                            className='text-fuchsia-600'
                        />

                        <h4 className='text-[20px] text-dark-blue font-bold'>{data?.drugs_shortages}</h4>
                        <p className='text-[18px] text-dark-blue'>Medicine Shortage</p>
                    </div>

                    <div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-fuchsia-200 w-full">
                        View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PharmacistSectionsSummary