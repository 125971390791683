import React, { useState, useEffect } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { setScheduleDate, setScheduleEdit } from '../../state/actions/actions'
import { deleteASchedule, setUsersByDeparmentToNull } from '../../state/actions/admin.actions'
import { toast } from 'react-toastify'


const DeleteScheduleModal = () => {
    const { set_Schedule_date, set_edit_schedule } = useSelector(state => state.root)
    const { modals, setModals } = useStateContext()
    const { filtered_schedules } = useSelector(state => state.root)
    const id = set_edit_schedule?.id
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(deleteASchedule({ id, toast, setModals: setModals, modals: modals }))
        dispatch(setScheduleEdit({ data: null }))
    };

    useEffect(() => {
        setModals({ ...modals, showListOfSchedulesByDateModal: false })
        // setModals({ ...modals, showEditDeleteOptionalModal: false })

    }, [])


    // useEffect(() => {
    //     setModals({ ...modals, showListOfSchedulesByDateModal: false })
    //     setModals({ ...modals, showEditDeleteOptionalModal: false })

    // }, [])

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[14px] font-semibold'>Are you sure you want to delete  - {set_edit_schedule?.title} schedule ?</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(setScheduleEdit({ data: null }))

                                setModals({ ...modals, showDeleteScheduleModal: !modals.showDeleteScheduleModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form
                        onSubmit={handleSubmit}
                    >

                        <div className='mt-4 flex justify-center'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Delete</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default DeleteScheduleModal