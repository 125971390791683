import React, { Fragment } from 'react'

const Role = ({ user, type }) => {
    return (
        <Fragment>
            {type !== 'group' && (
                <p className={`text-[8px] ${type === 'chat' ? 'text-gray-400 font-medium' : 'text-blue-500'}`}>
                    {user.is_accountant && 'Accountant'}
                    {user.is_sales && 'Sales'}
                    {user.is_collections && 'Accountant'}
                    {user.is_customer && 'Customer'}
                    {user.is_doctor && 'Doctor'}
                    {user.is_ophthalmologists && 'Ophthalmologist'}
                    {user.is_otolaryngologists && 'Otolaryngologists'}
                    {user.is_laboratorist && 'Laboratorist'}
                    {user.is_nurse && 'Nurse'}
                    {user.is_patient && 'Patient'}
                    {user.is_pharmacist && 'Pharmacist'}
                    {user.is_radiologist && 'Radiologist'}
                    {user.is_records && 'Records'}
                </p>
            )}
        </Fragment>
    )
}

export default Role