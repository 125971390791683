import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import AdminVerifyPayStackPayment from './pages/AdminVerifyPayStackPayment'
import { Login, NotFound, UpdatePassword, UpdateProfile, PrivateRoute, Dashboard, ResetPassword, PatchPassword, ActivateAccount, CollectionsVerifyPayStackPayment, CollectionsVerifyFlutterWavePayment, LandingPage, Signup } from "./pages"


const App = () => {
	return (
		<BrowserRouter>
			<ToastContainer />

			<Routes>
				<Route path="" element={<LandingPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/resetpassword" element={<ResetPassword />} />
				<Route path="/patchpassword/:token" element={<PatchPassword />} />
				<Route path="/account/activate/:token/" element={<ActivateAccount />} />

				<Route path="/updatepassword" element={
					<PrivateRoute>
						<UpdatePassword />
					</PrivateRoute>
				} />

				<Route path="/updateprofile" element={
					<PrivateRoute>
						<UpdateProfile />
					</PrivateRoute>
				} />

				<Route path="/dashboard" element={
					<PrivateRoute>
						<Dashboard />
					</PrivateRoute>
				} />


				<Route path="/collections/payments_paystack/verify" element={
					<PrivateRoute>
						<CollectionsVerifyPayStackPayment />
					</PrivateRoute>
				} />

				<Route path="/zuumatek/payments_paystack/verify" element={
					<PrivateRoute>
						<AdminVerifyPayStackPayment />
					</PrivateRoute>
				} />

				<Route path="/collections/payments_flutterwave/verify" element={
					<PrivateRoute>
						<CollectionsVerifyFlutterWavePayment />
					</PrivateRoute>
				} />

				<Route path='*' element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
