/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { createProvisionInvoice, getProvisions } from '../../../state/actions/pharmacist.actions'


const CustomerOrderProvisionModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { provisions } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState({
        provision: '', item_quantity: ''
    })

    useEffect(() => {
        dispatch(getProvisions())
    }, [])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        // console.log(formData)

        dispatch(createProvisionInvoice({ formData, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[600px] px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-600 text-[16px] font-medium'>Order Provision</h4>
                        <FaTimesCircle
                            size={20}
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                setModals({ ...modals, showCustomerAddProvisionModal: !modals.showCustomerAddProvisionModal })
                            }}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="relative">
                            <select
                                className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                name="provision"
                                onChange={handleChange}
                            >
                                <option>Item</option>
                                {provisions?.map((provision, index) => (
                                    <option
                                        key={`${provision.id}`}
                                        value={provision.pkid}
                                    >{provision.title}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>

                        <div>
                            <input
                                type="number"
                                min={'1'}
                                name="item_quantity"
                                placeholder="Quantity"
                                className="border rounded w-full text-[14px] px-4 py-3 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={handleChange}
                            />
                        </div>

                        <div className='flex justify-start mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-800 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>
                        </div>

                    </form>
                </div>


            </div>
        </div>
    )
}
export default CustomerOrderProvisionModal
