/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { getCouriers, processOrder } from "../../../state/actions/pharmacist.actions"
import { order__delivery__time } from "../../../data"

const PharmacistProcessOrderModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { couriers, current_order } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState({ order_courier: '', delivery_time: '', order: '', provision_invoice: '' })

    useEffect(() => {
        dispatch(getCouriers())
    }, [])

    useEffect(() => {
        setFormData({ ...formData, order: current_order?.pkid, provision_invoice: current_order?.provision_invoice })
    }, [current_order])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)

        dispatch(processOrder({ formData, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`w-[500px] bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-800 text-[18px] font-medium capitalize`}>
                            Process Order
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showPharmacistProcessOrderModal: !modals.showPharmacistProcessOrderModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-3">

                        <div className="relative space-y-1">
                            <label className="bg-slate-600 text-white px-4 py-[1px] text-[10px] rounded">Order ID</label>
                            <input
                                className="border-b border-b-slate-600 w-full text-[12px] px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                disabled
                                value={`${current_order?.order_id}`}
                            />
                        </div>

                        <div className="relative">
                            <label className="bg-slate-600 text-white px-4 py-[1px] text-[10px] rounded">Delivery Address</label>
                            <textarea
                                className="border-b border-b-slate-600 w-full px-4 py-2 text-[12px] h-max mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                disabled
                                value={current_order?.delivery_address}
                            ></textarea>
                        </div>

                        <div className="relative">
                            <select
                                className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                name="order_courier"
                                onChange={(e) => handleChange(e)}
                            >
                                <option value="">Courier</option>
                                {couriers?.map((courier, index) => (
                                    <option
                                        key={`${courier.id}`}
                                        value={courier.pkid}
                                    >{courier.last_name} {courier.first_name}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>

                        <div className="relative">
                            <select
                                className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                name="delivery_time"
                                onChange={(e) => handleChange(e)}
                            >
                                <option value="">Delivery Time</option>
                                {order__delivery__time?.map((item) => (
                                    <option
                                        key={`${item.id}`}
                                        value={item.time}
                                    >{item.time}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>

                        <div className="flex justify-start items-center space-y-5">
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-800 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default PharmacistProcessOrderModal