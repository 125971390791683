import React, { useState, useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { FaTimesCircle } from 'react-icons/fa'
import { TiCancel } from 'react-icons/ti'
import { useStateContext } from '../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { setScheduleDate } from '../../state/actions/actions'
import { createASchedule, fetchAllUsersByDepartment, getAllDepartments, setUsersByDeparmentToNull } from '../../state/actions/admin.actions'
import { toast } from 'react-toastify'
import { getSupplyPoints } from '../../state/actions/pharmacist.actions'
import ButtonLoader from '../utils/ButtonLoader'

const CreateScheduleModal = () => {
    const dispatch = useDispatch()
    const { set_Schedule_date } = useSelector(state => state.root)
    const [scheduleButtonListener, setScheduleButtonListener] = useState(0)
    const dateString = `${set_Schedule_date?.year}-${set_Schedule_date?.month}-${set_Schedule_date?.current_day}`
    const dayString = `${set_Schedule_date?.day}`
    const [labelState, setLabelState] = useState('')



    const [formData, setFormData] = useState({
        schedule_title: '',
        supply_point: '',
        members: [],
        date: dateString,
        day: dayString,
        label: ''

    })
    const membersAdded = []
    const { users_by_department, departments, new_schedule, adminLoading } = useSelector(state => state.admin)
    const { supplyPoints } = useSelector(state => state.pharmacist)
    const listEvent = localStorage.setItem("Events", JSON.stringify([{ title: "Vacations", label: "green", day: Date.now() }, { title: "Holiday", label: "blue", day: Date.now() }]))
    const { modals, setModals } = useStateContext()
    const [selectedColor, setSelectedColor] = useState("")

    const colorClasses = [

        "green",
        "purple",
        "indigo",
    ]
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        if (!formData.schedule_title && !formData.supply_point && !formData.members.length > 0) return toast.warning('All fields are required!')
        dispatch(createASchedule({ formData, toast, setModals: setModals, modals: modals }))
        dispatch(setUsersByDeparmentToNull({ data: null }))


    };
    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
    };


    useEffect(() => {
        dispatch(getSupplyPoints())

    }, [])

    useEffect(() => {

    }, [scheduleButtonListener])

    useEffect(() => {
        setFormData({ ...formData, label: selectedColor })
    }, [selectedColor])





    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[14px] font-semibold'>Create schedule </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(setScheduleDate({ data: null }))
                                dispatch(setUsersByDeparmentToNull({ data: null }))
                                setModals({ ...modals, showCreateScheduleModal: !modals.showCreateScheduleModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form
                        onSubmit={handleSubmit}
                    >
                        <p className='text-sm font-semibold mb-1'>Schedule Date : {set_Schedule_date?.stringDay}</p>
                        <div>
                            <input
                                type="text"
                                name="schedule_title"
                                placeholder="Title"
                                className="border rounded w-full h-4 px-4 py-5 mb-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>
                        <div>
                            <p className='text-sm font-light'>Time</p>
                            <input
                                type="time"
                                name="time"
                                placeholder="Select time"
                                className="border rounded w-full h-4 px-4 py-5 mb-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>
                        <div className='relative'>
                            <select
                                className="appearance-none mb-2  w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none " id="grid-state"
                                name='supply_point'
                                onChange={(e) => handleChange(e, e.target.name)}
                                onClick={() => {
                                    dispatch(fetchAllUsersByDepartment({ supply_point: formData.supply_point }))

                                }}
                            >
                                <option >Select SupplyPoint </option>
                                {supplyPoints?.map((item, index) => (
                                    <option value={item.pkid} key={index}>{item.name}</option>
                                ))}


                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>

                        <p className='text-sm text-gray-400 font-semibold'>Add Members: </p>
                        <div className="flex flex-col space-y-2 max-h-[200px] my-4 overflow-y-auto patient__prescriptions">
                            {users_by_department?.map((user, index) => (
                                <div key={`${user.pkid}`} className="flex justify-between w-full">
                                    <div className="w-[90%] flex items-center space-x-4 border p-1 rounded-tl rounded-bl">
                                        {/* <img
                                            src={`http://localhost:8000${user?.profile?.profile_photo}`}
                                            className="w-[40px] h-[40px] rounded-[50%]"
                                            alt=""
                                        /> */}
                                        <div className="flex flex-col -space-y-1">
                                            <p className="text-[12px] text-gray-600 font-bold">{user.first_name} {user.last_name} - {user.email}</p>

                                        </div>
                                    </div>

                                    <div className="w-[10%] flex items-center justify-center border-r border-t border-b p-1 rounded-tr rounded-br hover:cursor-pointer bg-yellow-400 ease-in-out duration-500">
                                        {formData.members.includes(user.pkid) == true && (

                                            <TiCancel
                                                size={25}
                                                onClick={() => {
                                                    const item = formData.members.indexOf(user.pkid)
                                                    formData.members.splice(item, 1)
                                                    setScheduleButtonListener(scheduleButtonListener - 1)
                                                }}
                                                className="text-white ease-in-out duration-500"
                                            />
                                        )}
                                        {formData.members.includes(user.pkid) == false && (
                                            <AiOutlinePlus
                                                size={20}
                                                onClick={() => {
                                                    formData.members.push(user.pkid)
                                                    setScheduleButtonListener(scheduleButtonListener + 1)


                                                }}
                                                className="text-teal-600 hover:text-white ease-in-out duration-500"
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}


                        </div>
                        <div className='flex gap-x-2'>
                            {colorClasses?.map((color_chosen, i) => (
                                <span key={i}
                                    onClick={() => {
                                        setSelectedColor(color_chosen)
                                    }}
                                    className={`bg-${color_chosen}-500 w-6 h-6 rounded-full flex items-center border hover:cursor-pointer`}

                                >
                                    {selectedColor === color_chosen &&
                                        <span className='text-white ml-1'>
                                            &#10004;
                                        </span>}

                                </span>
                            ))}
                        </div>
                        <div className='mt-4 flex justify-center'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >{!adminLoading ? ("Create schedule") : <ButtonLoader />}</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )

}

export default CreateScheduleModal