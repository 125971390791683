import React, { useEffect, useState } from "react"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { useStateContext } from "../../../state/context"
import { setDrugCategory } from "../../../state/actions/actions"
import { createDrugCategory, updateDrugCategory } from "../../../state/actions/pharmacist.actions"
import ButtonLoader from "../../utils/ButtonLoader"


const PharmacistAddDrugCategoryModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { drug_category_update } = useSelector(state => state.root)
    const [formData, setFormData] = useState({ category: "", description: "" })
    const { pharmacistLoading } = useSelector((state) => state.pharmacist)

    useEffect(() => {
        setFormData({ ...formData, category: drug_category_update?.category, description: drug_category_update?.description })
    }, [drug_category_update])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(createDrugCategory({ formData, toast, setModals: setModals, modals: modals }))
    }

    const handleUpdate = (e) => {
        e.preventDefault()

        console.log(formData)
        if (!formData.category && !formData.description) return toast.warning('All fields are required!')
        dispatch(updateDrugCategory({ formData: { ...formData, id: drug_category_update?.id }, toast, setModals, modals, dispatch, setDrugCategory }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`${drug_category_update ? 'text-sky-500' : 'text-dark-green'} text-[16px] font-bold capitalize`}>
                            {modals.showPharmacistAddDrugCategoryModal ? 'Add drug category' : 'Update drug category'}
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(setDrugCategory({ data: null }))
                                modals.showPharmacistAddDrugCategoryModal && setModals({ ...modals, showPharmacistAddDrugCategoryModal: !modals.showPharmacistAddDrugCategoryModal })
                                modals.showPharmacistUpdateDrugCategoryModal && setModals({ ...modals, showPharmacistUpdateDrugCategoryModal: !modals.showPharmacistUpdateDrugCategoryModal })
                            }}
                            size={20}
                        />
                    </div>

                    {modals.showPharmacistAddDrugCategoryModal && (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <input
                                    type="text"
                                    name="category"
                                    placeholder="Category name"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            <div>
                                <textarea
                                    cols="30"
                                    rows="3"
                                    name="description"
                                    placeholder="Description"
                                    className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                ></textarea>
                            </div>

                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!pharmacistLoading ? ("Submit") : (<ButtonLoader />)}
                                </button>
                            </div>
                        </form>
                    )}

                    {modals.showPharmacistUpdateDrugCategoryModal && (
                        <form onSubmit={handleUpdate}>
                            <div>
                                <input
                                    type="text"
                                    name="category"
                                    placeholder="Billing name"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    value={formData.category}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            <div>
                                <textarea
                                    cols="30"
                                    rows="3"
                                    name="description"
                                    placeholder="Description"
                                    className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                    value={formData.description}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                ></textarea>
                            </div>

                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!pharmacistLoading ? ("Update") : (<ButtonLoader />)}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PharmacistAddDrugCategoryModal

