/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { assignPharmacistToSupplyPoint, getPharmacistProfiles } from "../../../state/actions/pharmacist.actions"


const PharmacistSupplyPointAssignStaffModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const { supplypoint_update } = useSelector(state => state.root)
    const { pharmacist_profiles } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState({ email: "", supplypoint: supplypoint_update?.pkid })
    const [searching, setSearching] = useState(false)
    const [profiles, setProfiles] = useState([])

    useEffect(() => {
        dispatch(getPharmacistProfiles())
    }, [])

    useEffect(() => {
        if (formData.email.length <= 0) setSearching(false)

    }, [formData.email])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

        const initials = pharmacist_profiles?.filter(profile => {
            return Object.keys(profile.details.assigned_supplypoint).length === 0
        })

        const filtered = initials?.filter(profile => profile.user !== user?.data?.pkid)?.filter(profile => profile.details.email.includes(e.target.value))
        setProfiles(filtered)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(assignPharmacistToSupplyPoint({ formData, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-500 text-[20px] font-bold capitalize`}>
                            Assign Staff
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                // dispatch(setSupplyPoint({ data: null }))
                                modals.showPharmacistSupplyPointAssignStaffModal && setModals({ ...modals, showPharmacistSupplyPointAssignStaffModal: !modals.showPharmacistSupplyPointAssignStaffModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                value={supplypoint_update?.name}
                                className="border rounded w-full bg-gray-400 font-bold text-white h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>

                        <div className="relative">
                            <input
                                type="text"
                                name="email"
                                autocomplete="off"
                                placeholder="Pharmacist Email"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                value={formData.email}
                                onChange={(e) => {
                                    setSearching(true)
                                    handleChange(e, e.target.name)
                                }}
                            />

                            {searching && (
                                <div className="absolute bg-white w-full flex flex-col shadow-md rounded-b-md h-[200px] overflow-y-auto patient__prescriptions">
                                    <Fragment>
                                        {profiles?.map((item, index) => (
                                            <div
                                                key={`${index}`}
                                                className={`p-2 px-10 ${(index + 1) <= profiles.length && 'border-b-2 border-gray-200'} hover:bg-sky-300 hover:rounded-lg hover:scale-[0.96] ease-in duration-500`}
                                                onClick={() => {
                                                    setFormData({ ...formData, email: item.details.email })
                                                    setSearching(false)
                                                }}
                                            >
                                                <p className="cursor-pointer font-bold text-[12px]">Pharm. {item.details.last_name} {item.details.first_name}</p>
                                                <p className="cursor-pointer text-sm">{item.details.email}</p>
                                            </div>
                                        ))}
                                    </Fragment>
                                </div>
                            )}
                        </div>


                        {!searching && (
                            <div className={`${searching ? 'mt-[80px]' : 'mt-4'}`}>
                                <button
                                    type="submit"
                                    className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    Submit
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PharmacistSupplyPointAssignStaffModal

