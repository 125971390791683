/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PageTitle from '../../components/navigation/PageTitle'
import { CustomerInvoicesTable, CustomerPrescriptionsTable, CustomerProvisionInvoicesTable } from '../../components'
import { customerGetInvoices, getCustomerPrescriptions, getProvisionInvoices } from '../../state/actions/pharmacist.actions'


const CustomerInvoices = () => {
	const dispatch = useDispatch()

	const { customer_prescriptions, customer_invoices, prescription_order, provision_invoices, provision_order } = useSelector(state => state.pharmacist)

	const [activeLink, setActiveLink] = useState('Prescriptions')
	const [filterState, setFilterState] = useState({ search: '', prescriptions: [], invoices: [] })

	const handlePrescriptionsFilter = () => {
		const filtered = customer_prescriptions?.filter(item => {
			return item.drugs.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, prescriptions: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, prescriptions: customer_prescriptions })
		}
	}

	const handleInvoicesFilter = () => {
		const filtered = customer_invoices?.filter(invoice => {
			return invoice.invoice_number.toLowerCase().includes(filterState.search.toLowerCase())
				|| invoice.item.toLowerCase().includes(filterState.search.toLowerCase())
				|| invoice.invoice_amount.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, prescriptions: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, prescriptions: customer_invoices })
		}
	}


	useEffect(() => {
		dispatch(getCustomerPrescriptions())
		dispatch(customerGetInvoices())
		dispatch(getProvisionInvoices())
	}, [prescription_order, provision_order])

	useEffect(() => {
		if (activeLink === 'Prescriptions') {
			handlePrescriptionsFilter()
		}
		if (activeLink === 'Invoices') {
			handleInvoicesFilter()
		}
	}, [filterState.search])

	useEffect(() => {
		setFilterState({ ...filterState, prescriptions: customer_prescriptions, invoices: customer_invoices })
	}, [customer_invoices?.length, customer_prescriptions?.length])


	return (
		<div>
			<PageTitle type={'customer-invoices'} />

			<div className="">
				<div className="flex justify-between items-center border-b-2 border-gray-500 pb-2 ">
					<div className="space-x-5">
						<button
							type="button"
							className={`${activeLink === 'Prescriptions' ? 'bg-indigo-600 text-white' : 'text-indigo-500 border border-indigo-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-indigo-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Prescriptions
						</button>
						<button
							type="button"
							className={`${activeLink === 'Invoices' ? 'bg-indigo-600 text-white' : 'text-indigo-500 border border-indigo-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-indigo-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Invoices
						</button>
					</div>
					<div>
						<input
							type="text"
							name="search"
							// disabled
							placeholder='Search'
							className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
							onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
						/>
					</div>
					{/* <button
						type="button"
						className={`${activeLink === 'Provision Invoices' ? 'bg-indigo-600 text-white' : 'text-indigo-500 border border-indigo-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-indigo-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
						onClick={(e) => setActiveLink(e.target.textContent)}
					>
						Provision Invoices
					</button> */}
				</div>

				{activeLink === 'Prescriptions' && (
					<CustomerPrescriptionsTable
						data={customer_prescriptions} //customer prescriptions
					/>
				)}

				{activeLink === 'Invoices' && (
					<CustomerInvoicesTable
						data={customer_invoices} //customer prescriptions
					/>
				)}
			</div>
		</div>
	)
}

export default CustomerInvoices